import { If } from 'aa_common/front-end/components'
import { ifElse } from 'aa_common/front-end/helpers'
import { yen } from 'common/helpers'
import { getNs } from 'common/i18n-config'
import { ChangeEvent, useEffect, useRef } from 'react'

import { StyledAntTable, StyledBreakLine, StyledErrorMessage, StyledNumberFormat } from '../../styles'
import { AcceleratedDepreciationItem } from '../../types'

const { t, useNsReady } = getNs(['common', 'components/organisms/assets/asset-detail'])

export const EditableTable = ({ dataSource, handleInputChanged }: Props) => {
  useNsReady()
  const cursorPosition = useRef<number | null>(null)
  const editingInput = useRef<HTMLInputElement | null>(null)

  const columns = [
    {
      title: t('accelerated_depreciation_table_fields.period'),
      dataIndex: 'period',
      width: 125,
      render: (period: number, record: AcceleratedDepreciationItem) => {
        const month = record?.month?.toString().padStart(2, '0')
        return (
          <>
            <If condition={record.isParent}>
              <span>{t('format.year_term', { year: record.fiscal_year, term: period })}</span>
            </If>
            <If condition={!record.isParent}>
              <span>{t('format.term_year_month', { month, year: record.fiscal_year })}</span>
            </If>
          </>
        )
      },
    },
    {
      title: (
        <StyledBreakLine>
          {t('accelerated_depreciation_table_fields.depreciation_amount_before_accelerated_depreciation')}
        </StyledBreakLine>
      ),
      dataIndex: 'original_depreciation_amount',
      width: 160,
      render: yen,
    },
    {
      title: t('accelerated_depreciation_table_fields.depreciation_amount'),
      dataIndex: 'depreciation_amount',
      width: 160,
      render: (value: number, record: AcceleratedDepreciationItem, index: number) => {
        const isNegative = value < 0
        return (
          <>
            <If condition={record.is_adjustable}>
              <StyledNumberFormat
                disabled={index === dataSource.length - 1}
                prefix="¥"
                thousandSeparator
                className={ifElse(isNegative, 'negative', '')}
                value={value}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  cursorPosition.current = event.target.selectionStart
                  editingInput.current = event.target
                  handleInputChanged(event.target.value, record.key as string)
                }}
              />
              <If condition={isNegative}>
                <StyledErrorMessage>{t('enter_a_value_of_0_or_greater')}</StyledErrorMessage>
              </If>
            </If>

            <If condition={!record.is_adjustable}>
              <span>{yen(value)}</span>
            </If>
          </>
        )
      },
    },
    {
      title: (
        <StyledBreakLine>
          {t('accelerated_depreciation_table_fields.book_value_after_accelerated_depreciation')}
        </StyledBreakLine>
      ),
      dataIndex: 'end_of_term_book_value',
      width: 160,
      render: (value: number) => <span className={ifElse(value < 0, 'negative', '')}>{yen(value)}</span>,
    },
  ] as any

  useEffect(() => {
    setTimeout(() => {
      editingInput.current?.setSelectionRange(cursorPosition.current as number, cursorPosition.current as number)
    })
  }, [dataSource])

  return (
    <StyledAntTable
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      rowClassName={(record: any) => ifElse(!record.isParent, 'child', '')}
    />
  )
}

type Props = {
  dataSource: AcceleratedDepreciationItem[]
  handleInputChanged: (value: string, key: string) => void
}
