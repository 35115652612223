import { AcceleratedDepreciationItem, TermDepreciation } from '../types'

export const calculateTermsDepreciation = (
  terms: { [p: string]: TermDepreciation } | null,
  acceleratedDepreciation: AcceleratedDepreciationItem[]
) => {
  if (!terms) return {}

  const acceleratedDepreciationGroups: { [key: string]: AcceleratedDepreciationItem } = {}
  Object.keys(terms).forEach(period => {
    acceleratedDepreciationGroups[period] = {
      original_depreciation_amount: 0,
      depreciation_amount: 0,
      end_of_term_book_value: 0,
      fiscal_year: terms[period].fiscal_year,
      period: terms[period].period,
      isParent: true,
      key: `parent-${period}`,
    }
  })
  acceleratedDepreciation.forEach(item => {
    if (acceleratedDepreciationGroups[String(item.period)]) {
      acceleratedDepreciationGroups[String(item.period)].original_depreciation_amount =
        (acceleratedDepreciationGroups[String(item.period)].original_depreciation_amount as number) +
        (item.original_depreciation_amount as number)
      acceleratedDepreciationGroups[String(item.period)].depreciation_amount =
        (acceleratedDepreciationGroups[String(item.period)].depreciation_amount as number) +
        (item.depreciation_amount as number)
      acceleratedDepreciationGroups[String(item.period)].end_of_term_book_value = item.end_of_term_book_value
    }
  })

  return acceleratedDepreciationGroups
}
