import * as depreciationRateApi from 'api/app/depreciationRates'
import { getIn, useFormikContext } from 'formik'
import useResource from 'lib/hooks/useResource'
import { useEffect } from 'react'

import { DEPRECIATION_FIELDS } from '../const'
import { genLedgerFieldName } from '../helper'

export const useCalculateDepreciationRate = (ledgerIndex: number) => {
  const { values, setFieldValue } = useFormikContext()

  const serviceLifeField = genLedgerFieldName(DEPRECIATION_FIELDS.SERVICE_LIFE, ledgerIndex)
  const serviceLifeAfterChangeField = genLedgerFieldName(DEPRECIATION_FIELDS.SERVICE_LIFE_AFTER_CHANGE, ledgerIndex)
  const depreciationMethodCodeField = genLedgerFieldName(DEPRECIATION_FIELDS.DEPRECIATION_METHOD_CODE, ledgerIndex)
  const depreciationRateField = genLedgerFieldName(DEPRECIATION_FIELDS.DEPRECIATION_RATE, ledgerIndex)
  const guaranteeRateField = genLedgerFieldName(DEPRECIATION_FIELDS.GUARANTEE_RATE, ledgerIndex)
  const revisedDepreciationRateField = genLedgerFieldName(DEPRECIATION_FIELDS.REVISED_DEPRECIATION_RATE, ledgerIndex)

  const depreciationCode = getIn(values, depreciationMethodCodeField)
  const serviceLifeValue = getIn(values, serviceLifeField)
  const serviceLifeAfterChangeValue = getIn(values, serviceLifeAfterChangeField)

  const [depreciationRateResponse, getDepreciationRate] = useResource(depreciationRateApi.get)

  useEffect(() => {
    if (depreciationRateResponse.data) {
      const depreciationRateItem = depreciationRateResponse.data[0]
      setFieldValue(depreciationRateField, getIn(depreciationRateItem, DEPRECIATION_FIELDS.DEPRECIATION_RATE))
      setFieldValue(guaranteeRateField, getIn(depreciationRateItem, DEPRECIATION_FIELDS.GUARANTEE_RATE))
      setFieldValue(
        revisedDepreciationRateField,
        getIn(depreciationRateItem, DEPRECIATION_FIELDS.REVISED_DEPRECIATION_RATE)
      )
    }
  }, [depreciationRateResponse.data]) // eslint-disable-line

  const serviceLifeForCalc = serviceLifeAfterChangeValue || serviceLifeValue

  useEffect(() => {
    setFieldValue(depreciationRateField, undefined)
    setFieldValue(guaranteeRateField, undefined)
    setFieldValue(revisedDepreciationRateField, undefined)

    if (depreciationCode && serviceLifeForCalc) {
      getDepreciationRate({ depreciation_method: depreciationCode, service_life: serviceLifeForCalc })
    }
  }, [depreciationCode, serviceLifeForCalc])
}
