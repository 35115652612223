import { PresenterOneTermMonthResponse } from 'common/open-api'
import moment from 'moment'

import { AcceleratedDepreciationItem } from '../types'

export const addExtraRowsAcceleratedDepreciation = (
  monthlyDepreciation: AcceleratedDepreciationItem[],
  extraTermMonths: PresenterOneTermMonthResponse[]
) => {
  if (!monthlyDepreciation.length || !extraTermMonths.length) return monthlyDepreciation

  const lastMonthlyDepreciation = monthlyDepreciation[monthlyDepreciation.length - 1]
  const extraRows: AcceleratedDepreciationItem[] = []
  extraTermMonths.forEach(termMonth => {
    if (moment(termMonth.start_date).isAfter(lastMonthlyDepreciation.end_date)) {
      extraRows.push({
        term_month_id: termMonth.id,
        start_date: termMonth.start_date,
        end_date: termMonth.end_date,
        month: termMonth.month,
        year: termMonth.year,
        fiscal_year: termMonth.fiscal_year?.year,
        period: termMonth.fiscal_year?.period,
        beginning_book_value: lastMonthlyDepreciation.end_of_term_book_value,
        original_depreciation_amount: 0,
        depreciation_amount: 0,
        end_of_term_book_value: lastMonthlyDepreciation.end_of_term_book_value,
        key: String(termMonth.id),
        is_adjustable: true,
        isExtraMonth: true,
      })
    }
  })

  return [...monthlyDepreciation, ...extraRows]
}
