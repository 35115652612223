import { If } from 'aa_common/front-end/components'
import { ifElse } from 'aa_common/front-end/helpers'
import { RadioChangeEvent } from 'antd'
import { Asset, OperationLog } from 'common/models'
import RadioGroup from 'components/atoms/v2/RadioGroup'
import { ImageCarousel } from 'components/molecules'
import get from 'lodash/get'
import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { fetchOperationLogItem } from 'store/internalControl/operationLog/actions'

import { OperationLogType, UPDATE_CHANGE_MODES, UpdateChangeMode } from '../constant'
import { Carousel, TopContent as TopContentWrapper } from '../styles'
import BasicInfo from './BasicInfo'

type TopContentProps = {
  logItem: OperationLog
  fixedAsset: Asset | null
  viewUpdateMode: UpdateChangeMode
  setViewUpdateMode: Dispatch<SetStateAction<UpdateChangeMode>>
}

const UpdateTypes = [OperationLogType.UPDATE, OperationLogType.CORRECT, OperationLogType.CORRECT_FA]

export const TopContent = ({ logItem, fixedAsset, viewUpdateMode, setViewUpdateMode }: TopContentProps) => {
  const dispatch = useDispatch()
  const isUpdateType = useMemo(() => UpdateTypes.indexOf(logItem.type) >= 0, [logItem])

  const handleModeChange = useCallback(
    (event: RadioChangeEvent) => {
      const mode = event.target.value
      const isBefore = mode === UpdateChangeMode.BEFORE
      setViewUpdateMode(mode)
      dispatch(fetchOperationLogItem(logItem.asset.biid, ifElse(isBefore, logItem.beforeId, logItem.afterId)))
    },
    [logItem, setViewUpdateMode]
  )

  return (
    <>
      <If condition={isUpdateType}>
        <div style={{ marginBottom: 20 }}>
          <RadioGroup value={viewUpdateMode} name="mode" options={UPDATE_CHANGE_MODES} onChange={handleModeChange} />
        </div>
      </If>
      <TopContentWrapper>
        <Carousel>
          <ImageCarousel photos={get(fixedAsset, 'photos', [])} />
        </Carousel>
        <BasicInfo data={fixedAsset} />
      </TopContentWrapper>
    </>
  )
}
