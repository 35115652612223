import { ConfirmModal } from 'aa_common/front-end/components'
import { Permission } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import { ImageCarousel, Tabs } from 'components/molecules'
import CommentTextbox from 'components/molecules/CommentTextbox'
import { AssetDetailTabType } from 'containers/assets/asset-detail-container/schema'
import { get } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
  cleanupAssetComment,
  createAssetComment,
  deleteAssetComment,
  fetchAssetItemComments,
  updateAssetComment,
} from 'store/assetComment/actions'
import { getAssetItemCommentSelector } from 'store/assetComment/selectors'
import { fetchLedgerSettingsList } from 'store/settings/ledgerSetting/actions'

import AssetCommentList from '../AssetCommentList'
import { convertComments, IAssetCommentItem } from '../AssetCommentList/model'
import BasicInfo from './BasicInfo'
import { BodyContent, Carousel, CommentHeaderWrapper, CommentWrapper, TopContent, Wrapper } from './styles'
import Amortization from './tabs/Amortization'
import EventHistory from './tabs/EventHistory'
import ConfirmMessage from './tabs/EventHistory/components/confirm-message'
import Information from './tabs/Information'

const t = loadNs(['components/organisms/assets/asset-detail', 'components/organisms/assets/asset-comment'])

type Props = {
  data: any
  dataByValidAt: any
  commentPermissions: Permission
  onUpdateEvent: (eventData: any) => void
  isShowConfirmMessage?: boolean
  setIsShowConfirmMessage?: (value: boolean) => void
}

const AssetDetail: React.FC<Props> = ({
  data,
  dataByValidAt,
  commentPermissions,
  onUpdateEvent,
  isShowConfirmMessage,
  setIsShowConfirmMessage,
}) => {
  const [deletedComment, setDeletedComment] = useState<IAssetCommentItem | null>()
  const [activeKey, setActiveKey] = useState<string>(AssetDetailTabType.INFORMATION)
  const [isHighlightLastestEvent, setIsHighlightLastestEvent] = useState<boolean>(false)

  const dispatch = useDispatch()
  const { state }: any = useLocation()

  const commentSelector = useSelector(getAssetItemCommentSelector)
  const memoizedComments = useMemo(() => {
    return convertComments(commentSelector.data)
  }, [commentSelector])

  useEffect(() => {
    return () => {
      dispatch(cleanupAssetComment())
    }
  }, [dispatch])

  useEffect(() => {
    if (data && data.biid) {
      dispatch(fetchAssetItemComments(data.biid))
    }
  }, [data, dispatch])

  useEffect(() => {
    const underOverDepreciationReportPath = '/depreciation/under-over-depreciation-report'
    if (state?.fromPath === underOverDepreciationReportPath) {
      setActiveKey(AssetDetailTabType.AMORTIZATION)
    }
  }, [state])

  const handleOnCommentSubmit = (content: string) => {
    data && data.biid && dispatch(createAssetComment(data.biid, content))
  }

  const handleOnItemDeleteClick = useCallback((deletedItem: IAssetCommentItem) => {
    setDeletedComment(deletedItem)
  }, [])

  const handleOnItemSubmitEdit = useCallback(
    (editedItem: IAssetCommentItem, newValue: string) => {
      dispatch(updateAssetComment(editedItem.id, newValue))
    },
    [dispatch]
  )

  const handleSubmitDelete = () => {
    deletedComment && dispatch(deleteAssetComment(deletedComment.id))
    handleCancelDelete()
  }

  const handleCancelDelete = () => {
    setDeletedComment(null)
  }

  useEffect(() => {
    dispatch(fetchLedgerSettingsList())
  }, []) // eslint-disable-line

  const handleTabClick = useCallback((key: string) => {
    setActiveKey(key)
  }, [])

  return (
    <Wrapper>
      <TopContent>
        <Carousel>
          <ImageCarousel photos={get(data, 'photos') || []} />
        </Carousel>
        <BasicInfo data={dataByValidAt} />
      </TopContent>
      <BodyContent>
        <Tabs activeKey={activeKey} onTabClick={handleTabClick}>
          <Tabs.TabPane tab={t('fix_asset_information')} key="1">
            <Information data={dataByValidAt} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('amortization')} key="2">
            <Amortization data={data} changeToTab={handleTabClick} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('event_history')} key="3">
            <EventHistory
              onUpdateEvent={onUpdateEvent}
              isHighlightLastestEvent={isHighlightLastestEvent}
              setIsHighlightLastestEvent={setIsHighlightLastestEvent}
            />
          </Tabs.TabPane>
        </Tabs>
        <CommentWrapper>
          <CommentHeaderWrapper>
            <h3>{t('comment')}</h3>
          </CommentHeaderWrapper>
          <AssetCommentList
            commentList={memoizedComments}
            loading={commentSelector.isLoading}
            onItemDeleteClick={handleOnItemDeleteClick}
            onItemEditSubmit={handleOnItemSubmitEdit}
            permissions={commentPermissions}
          />
          <CommentTextbox
            className={memoizedComments.length > 0 ? 'comment-box' : 'comment-empty-box'}
            onCommentSubmit={handleOnCommentSubmit}
            permissions={commentPermissions}
          />
        </CommentWrapper>
        <ConfirmModal
          title={t('delete_confirm_title')}
          message={t('delete_confirm_message')}
          visible={!!deletedComment}
          onOK={handleSubmitDelete}
          onCancel={handleCancelDelete}
        />
      </BodyContent>
      <ConfirmMessage
        isShow={isShowConfirmMessage ?? false}
        onClick={() => {
          setActiveKey(AssetDetailTabType.EVENT_HISTORY)
          setIsHighlightLastestEvent(true)
        }}
        onClose={() => {
          setIsShowConfirmMessage && setIsShowConfirmMessage(false)
        }}
      />
    </Wrapper>
  )
}

export default AssetDetail
