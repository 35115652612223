import { isEmptyValue } from 'aa_common/front-end/helpers'
import { AssetLedger } from 'common/models'
import { PresenterFixedAssetDepreciationResponse } from 'common/open-api'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/selectors'
import { selectAssetItemData } from 'store/asset/selectors'
import { useDebouncedCallback } from 'use-debounce'

import { adjustHistoryStore } from '../components/accelerated-depreciation-drawer/stores/adjust-history'
import { addExtraRowsAcceleratedDepreciation } from '../helpers/add-extra-rows-accelerated-depreciation'
import { filterEndMonthlyDepreciation, filterStartMonthlyDepreciation } from '../helpers/filter-monthly-depreciation'
import { initializeDepreciationAmount } from '../helpers/initialize-depreciation-amount'
import { selectFromFiscalYearAcceleratedDepr } from '../helpers/select-from-fiscal-year-accelerated-depr'
import { shouldShowEmptyDepreciationTable } from '../helpers/should-show-empty-depreciation-table'
import { updateDepreciationAmount } from '../helpers/update-depreciation-amount'
import { AcceleratedDepreciationItem } from '../types'
import { useGetExtraTermMonths } from './use-get-extra-term-months'
import { useMonthlyDepreciation } from './use-monthly-depreciation'

export const useCalculateAcceleratedDepreciation = (
  currentDepreciationResult: PresenterFixedAssetDepreciationResponse,
  assetLedger?: AssetLedger,
  selectedDate?: string,
  isDateChanged?: boolean
) => {
  const [acceleratedDepreciation, setAcceleratedDepreciation] = useState<AcceleratedDepreciationItem[]>([])
  const originalMonthlyDepreciation = useMonthlyDepreciation(
    currentDepreciationResult,
    assetLedger?.ledger_setting,
    isDateChanged
  )
  const extraTermMonths = useGetExtraTermMonths(originalMonthlyDepreciation, selectedDate)
  const currentMonthlyClosing = useSelector(selectMonthlyClosingCurrent, isEqual)
  const monthlyClosingWhenRegister = adjustHistoryStore.useSlice(state => state.data?.monthly_closing)
  const assetDetail = useSelector(selectAssetItemData, isEqual)
  const startUsage = get(assetDetail, 'usage_started_at') as string
  const transitionAt = get(assetDetail, 'transition_at') as string

  useEffect(() => {
    if (!selectedDate || !originalMonthlyDepreciation.length) return
    if (
      isDateChanged &&
      shouldShowEmptyDepreciationTable(selectedDate, startUsage, transitionAt, currentMonthlyClosing)
    ) {
      return setAcceleratedDepreciation([])
    }

    const retirementDepreciationEndedMethod = assetLedger?.ledger_setting
      ?.retirement_depreciation_ended_method as number
    const memorandum = assetLedger?.memorandum_value

    if (!isDateChanged) {
      const selectedFiscalYear = selectFromFiscalYearAcceleratedDepr(currentMonthlyClosing, monthlyClosingWhenRegister)
      setAcceleratedDepreciation(
        filterStartMonthlyDepreciation(originalMonthlyDepreciation, {
          from_term_month: { term: { year: selectedFiscalYear } },
        })
      )
    } else {
      const monthlyDepreciationExtraRows = addExtraRowsAcceleratedDepreciation(
        originalMonthlyDepreciation,
        extraTermMonths
      )
      const filteredStartMonthlyDepreciation = filterStartMonthlyDepreciation(
        monthlyDepreciationExtraRows,
        currentMonthlyClosing
      )
      const filteredEndMonthlyDepreciation = filterEndMonthlyDepreciation(
        filteredStartMonthlyDepreciation,
        selectedDate,
        retirementDepreciationEndedMethod
      )
      setAcceleratedDepreciation(initializeDepreciationAmount(filteredEndMonthlyDepreciation, memorandum))
    }
  }, [
    originalMonthlyDepreciation,
    assetLedger,
    selectedDate,
    isDateChanged,
    currentMonthlyClosing,
    monthlyClosingWhenRegister,
    transitionAt,
    startUsage,
    extraTermMonths,
  ])

  const handleInputChanged = useDebouncedCallback((newValue: string, key: string) => {
    const value = isEmptyValue(newValue.trim()) ? '0' : newValue.replace(/\D/g, '')
    setAcceleratedDepreciation(updateDepreciationAmount(acceleratedDepreciation, key, parseInt(value, 10)))
  }, 250)

  return {
    acceleratedDepreciation,
    handleInputChanged,
  }
}
