import { If, UnborderCollapse } from 'aa_common/front-end/components'
import { everyTrue, ifElse } from 'aa_common/front-end/helpers'
import classNames from 'classnames'
import { loadNs } from 'common/i18n-config'
import { FORM_MODES } from 'common/models'
import { DatePicker } from 'components/molecules'
import { getIn, useFormikContext } from 'formik'
import parser from 'html-react-parser'
import moment from 'moment/moment'
import React from 'react'

import { DEPRECIATION_FIELDS } from '../const'
import { genLedgerFieldName, getFormMode } from '../helper'
import { useClearValueOnHide } from '../hooks/useClearOnHide'
import { Error } from '../styles'
import { ItemLabelTooltip } from './ItemLabelTooltip'

const { Item } = UnborderCollapse
const t = loadNs(['components/organisms/assets/asset-form', 'common'])

type TransitionAtProps = {
  ledgerIndex: number
  ledgerName: string
  formMode: FORM_MODES
  showRequiredError: boolean
  disabled: boolean
  onAfterChangedTransitionAt: (transitionAtValue: any) => void
}

export const TransitionAt = ({
  ledgerIndex,
  ledgerName,
  formMode,
  showRequiredError,
  disabled,
  onAfterChangedTransitionAt,
}: TransitionAtProps) => {
  const { values, setFieldValue } = useFormikContext()
  const accountingPeriodDate = getIn(values, 'min_acquired_at')
  const acquiredAt = getIn(values, 'acquired_at')
  const hasTransition = getIn(values, 'has_transition')

  const isAccountingPeriodDateAfterAcquiredAt = everyTrue(
    accountingPeriodDate,
    moment(acquiredAt).isBefore(accountingPeriodDate)
  )

  const minDateField = ifElse(isAccountingPeriodDateAfterAcquiredAt, 'min_acquired_at', 'acquired_at')

  const transitionAtField = genLedgerFieldName(DEPRECIATION_FIELDS.TRANSITION_AT, ledgerIndex)
  const { isEditMode } = getFormMode(formMode)

  useClearValueOnHide(transitionAtField, setFieldValue, hasTransition)

  return (
    <If condition={hasTransition}>
      <Item
        label={<ItemLabelTooltip label={t('transition_at')} tooltip={parser(t('transition_at_tooltip'))} />}
        required
        jsLabel={t('transition_at_of', { name: ledgerName })}
      >
        <DatePicker
          name={transitionAtField}
          disabled={disabled || isEditMode}
          minDateField={minDateField}
          className={classNames({ error: everyTrue(showRequiredError, !getIn(values, transitionAtField)) })}
          afterChanged={onAfterChangedTransitionAt}
        />
        <If condition={everyTrue(showRequiredError, !getIn(values, transitionAtField))}>
          <Error>{t('transition_at_required_error')}</Error>
        </If>
      </Item>
    </If>
  )
}
