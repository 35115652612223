/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { PresenterDepreciationResultsAdjustHistoryResponse, PresenterError } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class DepreciationResultAdjustHistory<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description Get depreciation result adjust history by BIID
   *
   * @tags depreciation_result_adjust_history
   * @name AdjustHistoryBiidList
   * @summary Get depreciation result adjust history by BIID
   * @request GET:/depreciation_results/adjust_history/:biid
   */
  adjustHistoryBiidList = (biid: string, params: RequestParams = {}) =>
    this.http.request<PresenterDepreciationResultsAdjustHistoryResponse, PresenterError>({
      path: `/depreciation_results/adjust_history/${biid}`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
