import { axiosClient } from './axios'
import { AccountingPeriods } from './gen/AccountingPeriods'
import { AccountingPlus } from './gen/AccountingPlus'
import { AccountItems } from './gen/AccountItems'
import { Areas } from './gen/Areas'
import { AssetCategories } from './gen/AssetCategories'
import { AsyncJob } from './gen/AsyncJob'
import { Attachments } from './gen/Attachments'
import { AutoGeneratingSerialNumberSettings } from './gen/AutoGeneratingSerialNumberSettings'
import { AWebInternalUser } from './gen/AWebInternalUser'
import { AWebNotification } from './gen/AWebNotification'
import { AWebOffices } from './gen/AWebOffices'
import { AWebUsers } from './gen/AWebUsers'
import { Causes } from './gen/Causes'
import { Departments } from './gen/Departments'
import { DepreciationRates } from './gen/DepreciationRates'
import { DepreciationResultAdjustHistory } from './gen/DepreciationResultAdjustHistory'
import { DepreciationResults } from './gen/DepreciationResults'
import { Excises } from './gen/Excises'
import { ExciseSettings } from './gen/ExciseSettings'
import { Export } from './gen/Export'
import { ExportJournalHistories } from './gen/ExportJournalHistories'
import { FeatureFlag } from './gen/FeatureFlag'
import { FixedAssetComments } from './gen/FixedAssetComments'
import { FixedAssetEvents } from './gen/FixedAssetEvents'
import { FixedAssetOperationLogs } from './gen/FixedAssetOperationLogs'
import { FixedAssets } from './gen/FixedAssets'
import { HttpClient } from './gen/http-client'
import { InternalApi } from './gen/InternalApi'
import { JournalItemSettings } from './gen/JournalItemSettings'
import { JournalItemSettingsItemSetting } from './gen/JournalItemSettingsItemSetting'
import { Journals } from './gen/Journals'
import { JournalSettings } from './gen/JournalSettings'
import { LedgerSettings } from './gen/LedgerSettings'
import { LoggedinLogs } from './gen/LoggedinLogs'
import { MasterData } from './gen/MasterData'
import { MonthlyClosing } from './gen/MonthlyClosing'
import { MonthlyClosingSettings } from './gen/MonthlyClosingSettings'
import { Offices } from './gen/Offices'
import { OfficeUsers } from './gen/OfficeUsers'
import { Photos } from './gen/Photos'
import { Reports } from './gen/Reports'
import { Roles } from './gen/Roles'
import { Search } from './gen/Search'
import { SelectAll } from './gen/SelectAll'
import { Sessions } from './gen/Sessions'
import { SubmitDestinations } from './gen/SubmitDestinations'
import { Tags } from './gen/Tags'
import { TaxDeclaration } from './gen/TaxDeclaration'
import { Temporary } from './gen/Temporary'
import { TermOfUseAgreements } from './gen/TermOfUseAgreements'
import { Units } from './gen/Units'
import { Ws } from './gen/Ws'

export * from './gen/data-contracts'
export const basePath = '/api/v1'

const httpClient = new HttpClient()
httpClient.instance = axiosClient

export const AWebInternalUserService = new AWebInternalUser(httpClient)
export const AWebNotificationService = new AWebNotification(httpClient)
export const AWebOfficesService = new AWebOffices(httpClient)
export const AWebUsersService = new AWebUsers(httpClient)
export const AccountItemsService = new AccountItems(httpClient)
export const AccountingPeriodsService = new AccountingPeriods(httpClient)
export const AccountingPlusService = new AccountingPlus(httpClient)
export const AreasService = new Areas(httpClient)
export const AssetCategoriesService = new AssetCategories(httpClient)
export const AttachmentsService = new Attachments(httpClient)
export const AutoGeneratingSerialNumberSettingsService = new AutoGeneratingSerialNumberSettings(httpClient)
export const CausesService = new Causes(httpClient)
export const DepartmentsService = new Departments(httpClient)
export const DepreciationRatesService = new DepreciationRates(httpClient)
export const DepreciationResultsService = new DepreciationResults(httpClient)
export const ExciseSettingsService = new ExciseSettings(httpClient)
export const ExcisesService = new Excises(httpClient)
export const ExportService = new Export(httpClient)
export const ExportJournalHistoriesService = new ExportJournalHistories(httpClient)
export const FixedAssetCommentsService = new FixedAssetComments(httpClient)
export const FixedAssetOperationLogsService = new FixedAssetOperationLogs(httpClient)
export const FixedAssetsService = new FixedAssets(httpClient)
export const InternalApiService = new InternalApi(httpClient)
export const JournalItemSettingsService = new JournalItemSettings(httpClient)
export const JournalItemSettingsItemSettingService = new JournalItemSettingsItemSetting(httpClient)
export const JournalSettingsService = new JournalSettings(httpClient)
export const JournalsService = new Journals(httpClient)
export const LedgerSettingsService = new LedgerSettings(httpClient)
export const LoggedinLogsService = new LoggedinLogs(httpClient)
export const MasterDataService = new MasterData(httpClient)
export const MonthlyClosingService = new MonthlyClosing(httpClient)
export const MonthlyClosingSettingsService = new MonthlyClosingSettings(httpClient)
export const OfficeUsersService = new OfficeUsers(httpClient)
export const OfficesService = new Offices(httpClient)
export const PhotosService = new Photos(httpClient)
export const ReportsService = new Reports(httpClient)
export const RolesService = new Roles(httpClient)
export const SearchService = new Search(httpClient)
export const SessionsService = new Sessions(httpClient)
export const SubmitDestinationsService = new SubmitDestinations(httpClient)
export const TagsService = new Tags(httpClient)
export const TaxDeclarationService = new TaxDeclaration(httpClient)
export const TemporaryService = new Temporary(httpClient)
export const TermOfUseAgreementsService = new TermOfUseAgreements(httpClient)
export const UnitsService = new Units(httpClient)
export const WsService = new Ws(httpClient)
export const AsyncjobService = new AsyncJob(httpClient)
export const SelectAllService = new SelectAll(httpClient)
export const FixedAssetEventsService = new FixedAssetEvents(httpClient)
export const FeatureFlagsService = new FeatureFlag(httpClient)
export const DepreciationResultAdjustHistoryService = new DepreciationResultAdjustHistory(httpClient)
