import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  adjustHistoryStore,
  getAdjustHistory,
  resetAdjustHistory,
} from '../components/accelerated-depreciation-drawer/stores/adjust-history'

export const usePlannedDate = () => {
  const { biid } = useParams<{ biid: string }>()

  const [selectedDate, setSelectedDate] = useState<string | undefined>(undefined)
  const [isDateChanged, setIsDateChanged] = useState(false)
  const plannedDate = adjustHistoryStore.useSlice(state => state.data?.selected_date)

  const changeSelectedDate = useCallback(
    (date: string) => {
      setIsDateChanged(true)
      setSelectedDate(date)
    },
    [setSelectedDate, setIsDateChanged]
  )

  useEffect(() => {
    setSelectedDate(plannedDate)
  }, [plannedDate, setSelectedDate])

  useEffect(() => {
    getAdjustHistory(biid)
    return () => {
      resetAdjustHistory()
    }
  }, [biid])

  return {
    isDateChanged,
    selectedDate,
    changeSelectedDate,
  }
}
