import { defaultTo, ifElse } from 'aa_common/front-end/helpers'
import { EMAIL_SUPPORT, SUPPORT_URL } from 'common/constants'
import { getNs } from 'common/i18n-config'
import { useMemo } from 'react'

import { StyledUl } from './styles'

const { t, useNsReady } = getNs('pages/error-pages')
export const SupportLinkList = ({ showDefaultLinks = true, ...rest }: Props) => {
  useNsReady()
  const links = useMemo(() => {
    const baseLinks = [
      {
        text: t('how_to_contact_us'),
        href: SUPPORT_URL,
      },
      { text: t('email_us'), href: EMAIL_SUPPORT },
    ]

    return [...defaultTo(rest.links, []), ...ifElse(showDefaultLinks, baseLinks, [])]
  }, [rest.links, showDefaultLinks])

  return (
    <StyledUl>
      {links.map(({ text, href }) => (
        <li key={href}>
          <a href={href} target="_blank" rel="noreferrer">
            {text}
          </a>
        </li>
      ))}
    </StyledUl>
  )
}

type Props = {
  links?: { text: string; href: string }[]
  showDefaultLinks?: boolean
}
