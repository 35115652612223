import { MonthlyClosing } from 'common/models'
import moment from 'moment'

export const shouldShowEmptyDepreciationTable = (
  selectedDate: string,
  startUsage: string,
  transitionAt: string,
  currentMonthlyClosing: MonthlyClosing
) => {
  const termMonthEndDate = currentMonthlyClosing?.to_term_month?.end_date
  let startDepreciationDate = startUsage
  if (!startUsage || (transitionAt && moment(transitionAt).isAfter(moment(startUsage)))) {
    startDepreciationDate = transitionAt
  }

  return (
    !startDepreciationDate ||
    moment(termMonthEndDate).isBefore(moment(startDepreciationDate)) ||
    moment(selectedDate).isBefore(startDepreciationDate)
  )
}
