import { DEPRECIATION_METHOD_CODE, RETIREMENT_DEPRECIATION_ENDED_METHOD } from 'common/constants'
import { AssetLedger, LedgerSetting } from 'common/models'
import { get } from 'lodash'

interface RetirementFixedAssetLedgerRequests {
  ledger_setting_id: number
  retirement_depreciation_ended_method: number
}

export interface ValueFields {
  retired_at: any
  event_cause_id: null
  disposal_cost: number
  disposal_destination_name: string
  event_photos: any
  event_attachments: any
  event_urls: any
  retirement_fixed_asset_ledger_requests: RetirementFixedAssetLedgerRequests[]
}

export const validateForm = (
  values: ValueFields,
  isRetirementDepreciationEndedMethod = false,
  isSmallAmountDepreciableAsset?: boolean
) => {
  const { retired_at, event_cause_id, retirement_fixed_asset_ledger_requests } = values

  const depreciationEndedMethodsField = (retirement_fixed_asset_ledger_requests || []).filter(
    item => item.retirement_depreciation_ended_method
  ).length

  if (isSmallAmountDepreciableAsset) {
    return retired_at && event_cause_id
  }

  return isRetirementDepreciationEndedMethod
    ? retired_at && event_cause_id && depreciationEndedMethodsField
    : retired_at && event_cause_id
}

export const calculateRetirementLoss = (
  bookValue: number | null,
  values: any,
  conditions: {
    isBulkEqualMethod: boolean
    isSmallAmountDepreciableAsset: boolean
    isTaxMethodExcluded: boolean
    isContinueToDepreciate: boolean
    previousBulkEqualSetting: any
    isEditMode?: boolean
  }
) => {
  const {
    isBulkEqualMethod,
    isSmallAmountDepreciableAsset,
    isTaxMethodExcluded,
    isContinueToDepreciate,
    previousBulkEqualSetting,
    isEditMode,
  } = conditions
  let result
  bookValue = bookValue || 0
  const disposalCostIncluded = get(values, 'disposal_cost')
  const disposalCostExcluded = get(values, 'disposal_cost_excluded_excise')

  const currentContinueToDepreciate = isEditMode
    ? previousBulkEqualSetting && isBulkEqualMethod
    : isContinueToDepreciate && isBulkEqualMethod

  if (currentContinueToDepreciate) {
    return 0
  }

  if (!isTaxMethodExcluded) {
    result = isSmallAmountDepreciableAsset ? disposalCostIncluded : disposalCostIncluded + bookValue
  } else {
    result = isSmallAmountDepreciableAsset ? disposalCostExcluded : disposalCostExcluded + bookValue
  }
  return Math.abs(result)
}

export const checkHasDepreciationEndedMethod = (ledgerSettings: LedgerSetting[]) =>
  ledgerSettings &&
  ledgerSettings.some(
    ledgerSetting =>
      ledgerSetting.retirement_depreciation_ended_method ===
      RETIREMENT_DEPRECIATION_ENDED_METHOD.SELECT_WHEN_SELL_AND_RETIREMENT
  )

export const getDepreciationEndedMethods = (ledgerSettings: LedgerSetting[], assetLedgers: AssetLedger[]) => {
  const result: any = []

  if (!ledgerSettings || !assetLedgers) return []

  ledgerSettings.forEach(ledgerSetting => {
    const assetLedger = assetLedgers.find(ledger => ledger.ledger_setting_id === ledgerSetting.id)
    if (assetLedger) {
      result.push({
        ledger_setting_id: ledgerSetting.id,
        retirement_depreciation_ended_method: assetLedger?.retirement_depreciation_ended_method,
      })
    }
  })

  return result
}

export const isNonDepreciationOrNonTarget = (assetLedger: AssetLedger) => {
  const isNonDepreciationMethod = assetLedger?.depreciation_method_code === DEPRECIATION_METHOD_CODE.NON_DEPRECIABLE
  const isNonTargetMethod = assetLedger?.depreciation_method_code === DEPRECIATION_METHOD_CODE.NON_DEPRECIATION

  return isNonDepreciationMethod || isNonTargetMethod
}

export const nonDepreciationMethodLength = (assetLedgers: AssetLedger[]) =>
  assetLedgers.filter((assetLedger: AssetLedger) => isNonDepreciationOrNonTarget(assetLedger)).length
