export function isNumberHaveValue(number?: number | null): boolean {
  return number !== null && number !== undefined
}

export function isEmptyValue(value: any): boolean {
  return value === null || value === undefined || value === ''
}

export function randomNumber(): number {
  const crypto = window.crypto || (window as any).msCrypto
  const array = new Uint32Array(1)
  crypto.getRandomValues(array)
  return array[0] / (0xffffffff + 1)
}
