import { DEPRECIATION_METHOD_CODE } from 'common/constants'
import { useFormikContext } from 'formik'
import { useCallback } from 'react'

import { DEPRECIATION_FIELDS } from '../const'
import { genLedgerFieldName } from '../helper'

export const useDepreciationMethodChanged = (ledgerIndex: number, afterDepreciationChanged: () => void) => {
  const { setFieldValue } = useFormikContext()

  const serviceLifeField = genLedgerFieldName(DEPRECIATION_FIELDS.SERVICE_LIFE, ledgerIndex)
  const usagePeriodMonthsField = genLedgerFieldName(DEPRECIATION_FIELDS.USAGE_PERIOD_MONTHS, ledgerIndex)
  const depreciatedMonthsField = genLedgerFieldName(DEPRECIATION_FIELDS.DEPRECIATED_MONTHS, ledgerIndex)

  return useCallback(
    (depreciationCode?: number | string) => {
      switch (depreciationCode) {
        case DEPRECIATION_METHOD_CODE.AVERAGE_USAGE_PERIOD:
        case DEPRECIATION_METHOD_CODE.LEASE_PERIOD_STRAIGHT_LINE:
        case DEPRECIATION_METHOD_CODE.NON_DEPRECIATION:
        case DEPRECIATION_METHOD_CODE.NON_DEPRECIABLE:
          setFieldValue(serviceLifeField, undefined)
          break
        case DEPRECIATION_METHOD_CODE.DEFERRED_EQUAL_INSTALLMENT:
          setFieldValue(serviceLifeField, 1)
          break
        case DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD:
          setFieldValue(serviceLifeField, 3)
          break
        case DEPRECIATION_METHOD_CODE.SMALL_AMOUNT_DEPRECIABLE_ASSET:
          setFieldValue(serviceLifeField, 0)
          break
        default:
          setFieldValue(serviceLifeField, 2)
      }

      afterDepreciationChanged()
    },
    [serviceLifeField, usagePeriodMonthsField, depreciatedMonthsField, afterDepreciationChanged]
  )
}
