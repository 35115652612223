import { parseErrorResponse } from 'common/utils/response-helper'

import {
  ERR_CANCEL_ACCELERATED_DEPRECIATION_DUE_TO_EVENT,
  ERR_CANCEL_ACCELERATED_DEPRECIATION_DUE_TO_MONTHLY_CLOSING,
} from '../types'

export const parseCancelAcceleratedError = (error: any) => {
  const parsedError = parseErrorResponse(error)
  const isMonthlyClosingError = parsedError?.[0]?.code === ERR_CANCEL_ACCELERATED_DEPRECIATION_DUE_TO_MONTHLY_CLOSING
  const isEventError = parsedError?.[0]?.code === ERR_CANCEL_ACCELERATED_DEPRECIATION_DUE_TO_EVENT

  return { isMonthlyClosingError, isEventError, isKnownError: isMonthlyClosingError || isEventError }
}
