import { CONFIRM_TYPES, DATE_FORMAT_RFC } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import { AssetLedger } from 'common/models'
import { useFormikContext } from 'formik'
import useModalHook from 'hooks/useModalHook'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchExciseSettingsByDate } from 'store/settings/excise/actions'
import { getExciseSettingsByDateList } from 'store/settings/excise/selectors'

import { DEPRECIATION_FIELDS, LEDGERS_FIELD_NAME } from '../const'
import { fetchNewExciseSetting, genLedgerFieldName, isDifferentExciseSetting } from '../helper'

const t = loadNs(['components/organisms/assets/asset-form', 'common'])

export const useTransitionAtChanged = (
  setBookValueReferUpdatedManually: () => void,
  setExciseSettingDate: React.Dispatch<React.SetStateAction<string>>
) => {
  const dispatch = useDispatch()
  const { values, setFieldValue } = useFormikContext()
  const preTransitionAt = get(values, 'transition_at')
  const fixedAssetLedgers = get(values, LEDGERS_FIELD_NAME) as AssetLedger[]

  const { data } = useSelector(getExciseSettingsByDateList, isEqual)
  const modal: any = useModalHook()

  const updateTransitionAt = useCallback(
    (transitionAt: any) => {
      fixedAssetLedgers.forEach((_, index) => {
        setFieldValue(genLedgerFieldName(DEPRECIATION_FIELDS.TRANSITION_AT, index), transitionAt)
      })
      setFieldValue('transition_at', transitionAt)
    },
    [fixedAssetLedgers]
  )

  return useCallback(
    (transitionAtValue: any) => {
      if (transitionAtValue) {
        const newTransitionAtValue = transitionAtValue.format(DATE_FORMAT_RFC)

        const currentExciseSetting = {
          inputTaxWhenRegisterFixedAsset: data?.input_tax_when_register_fixed_asset,
          taxMethod: data?.tax_method,
          taxInputMethod: data?.tax_input_format,
        }

        setFieldValue('area_biid', null)
        setFieldValue('area', undefined)
        setFieldValue('department_biid', undefined)
        fetchNewExciseSetting(newTransitionAtValue, dispatch, fetchExciseSettingsByDate)
          .then((newExciseSetting: any) => {
            updateTransitionAt(newTransitionAtValue)
            setExciseSettingDate(newTransitionAtValue)

            if (!!data && isDifferentExciseSetting(currentExciseSetting, newExciseSetting)) {
              return modal.confirm({
                title: t('notify_change_excise_setting_title'),
                message: t('notify_change_excise_setting_message'),
                type: CONFIRM_TYPES.CONFIRM,
              })
            }
          })
          .then((isOK?: boolean) => {
            if (isOK === false) {
              updateTransitionAt(preTransitionAt)
              setExciseSettingDate(preTransitionAt)
            }
          })
      }
      setBookValueReferUpdatedManually()
    },
    [dispatch, setFieldValue, setExciseSettingDate, preTransitionAt, data, modal, updateTransitionAt]
  )
}
