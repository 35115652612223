import { Checkbox } from 'aa_common/front-end'
import { ReportAssetReduction } from 'common/components/report-asset-reduction'
import { Row } from 'common/components/report-row'
import { AssetDisplayDataRow } from 'common/components/report-row/styles'
import { ASSET_EVENT_FIELD } from 'common/constants'
import {
  formatDate,
  formatDateTime,
  identifyJournalStatusType,
  objectToQueryParams,
  renderAssetFields,
  yen,
} from 'common/helpers'
import { getNs } from 'common/i18n-config'
import { AssetReport } from 'common/models'
import { assetEventListReportSlice } from 'pages/accounting/AssetEventListReportPage/store'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { changeLocation } from 'store/app/actions'

import { handleSelectedItem, renderAssetPhoto } from '../helpers'
import { AssetDisplay, AssetDisplayHeader } from '../styles'
import { JournalStatus } from './journal-status'
import { Media } from './media'

const { t, useNsReady } = getNs(['common', 'components/organisms/accounting/monthly-closing-asset-event-list'])
export const ImpairmentEventView = ({ assets }: Props) => {
  useNsReady()
  const dispatch = useDispatch()

  const handleAssetClick = useCallback(
    (asset: AssetReport) => {
      const paramObj = {
        valid_at: asset.impaired_at,
      }
      const params = `?${objectToQueryParams(paramObj)}`
      dispatch(changeLocation(`/assets/${asset.biid || ''}${params}`))
    },
    [dispatch]
  )

  if (!assets) return null
  return (
    <>
      {assets.map(asset => (
        <ImpairmentEvent key={asset.biid} asset={asset} onAssetClick={handleAssetClick} />
      ))}
    </>
  )
}

const ImpairmentEvent = ({ asset, onAssetClick }: ImpairmentEventProps) => {
  const { isDetailMode, selectedItem, errorIds } = assetEventListReportSlice.useSlice(state => ({
    isDetailMode: state?.data?.isDetailMode,
    selectedItem: state?.data?.selectedItem,
    errorIds: state?.data?.errorIds,
  }))
  const hasError = asset.id && errorIds?.includes(`${asset.id}`)
  return (
    <AssetDisplay key={asset.id} onClick={() => onAssetClick(asset)} className={hasError ? 'highlight-row' : ''}>
      <AssetDisplayHeader className={hasError ? 'highlight-row' : ''}>
        <div className="checkbox-item" data-testid="checkbox-item">
          <Checkbox
            name={`${asset?.id}`}
            checked={selectedItem && selectedItem[asset.id!]}
            onChange={handleSelectedItem}
          />
        </div>

        <div className="status">
          <JournalStatus type={identifyJournalStatusType(asset)} termMonthId={asset?.term_month?.month || null} />
        </div>
        <div className="photo">{renderAssetPhoto(asset)}</div>
        <div className="asset-code">
          {asset.code}-{asset?.branch_code}
        </div>
        <div className="asset-name">{asset.name}</div>
      </AssetDisplayHeader>
      {isDetailMode && (
        <div data-testid="detailMode">
          <AssetDisplayDataRow>
            <div className="short-label">{asset.impaired_at}</div>
            <div className="short-label">{t('impairment')}</div>
            <div className="label">{t('impaired_register_date')}</div>
            <div className="value">{formatDateTime(asset.created_at || '')}</div>
          </AssetDisplayDataRow>
          <Row label={t('impairment_cause')} value={asset.event_cause?.content} />
          <Row label={t('acquisition_date')} value={formatDate(asset?.acquired_at) as string} />
          <Row label={t('service_start_date')} value={formatDate(asset.usage_started_at) as string} />
          <Row
            label={
              asset?.acquisition_cost_excluded_excise === asset?.acquisition_cost
                ? t('acquisition_cost_exclude')
                : t('acquisition_cost_include')
            }
            value={yen(asset.acquisition_cost)}
          />
          <ReportAssetReduction data={asset} />
          <AssetDisplayDataRow>
            <div className="label">{t('accumulated_depreciation')}</div>
            <div className="value">
              {renderAssetFields(asset.fixed_asset_ledgers, ASSET_EVENT_FIELD.ACCUMULATED_DEPRECIATION)}
            </div>
          </AssetDisplayDataRow>
          <Row label={t('impairment_amount')} value={yen(asset.impaired_amount)} />
          <Row label={t('accumulated_impairment_amount')} value={yen(asset.accumulated_impaired_amount)} />
          <Row label={t('impairment_remain_book_value')} value={yen(asset.remaining_book_value)} />
          <Row label={t('department')} value={asset.department?.name} />
          <Row label={t('area')} value={asset.area?.name} />
          <Media asset={asset} />
          <Row label={t('impair_by')} value={asset.user_info?.display_name} />
        </div>
      )}
    </AssetDisplay>
  )
}

type Props = {
  assets: AssetReport[]
}

type ImpairmentEventProps = {
  asset: AssetReport
  onAssetClick: (asset: AssetReport) => void
}
