import { AcceleratedDepreciationItem } from '../types'

export const updateDepreciationAmount = (
  acceleratedDepreciation: AcceleratedDepreciationItem[],
  key: string,
  value: number
) => {
  const clonedData = acceleratedDepreciation.map(item => ({ ...item }))

  const updatedItemIndex = clonedData.findIndex(item => item.key === key)
  if (updatedItemIndex < 0) return acceleratedDepreciation

  const delta = value - (clonedData[updatedItemIndex].depreciation_amount as number)
  clonedData[updatedItemIndex].depreciation_amount = value
  clonedData[updatedItemIndex].end_of_term_book_value =
    (clonedData[updatedItemIndex].end_of_term_book_value as number) - delta

  clonedData.forEach((item, index) => {
    if (index > updatedItemIndex && item.is_adjustable) {
      if (index === clonedData.length - 1) {
        item.depreciation_amount = (item.depreciation_amount as number) - delta
      }
      item.end_of_term_book_value =
        (clonedData[index - 1].end_of_term_book_value as number) - (item.depreciation_amount as number)
    }
  })

  return clonedData
}
