import { ifElse } from 'aa_common/front-end'
import { ReportAssetReduction } from 'common/components/report-asset-reduction'
import { AssetDisplayDataRow } from 'common/components/report-row/styles'
import { ASSET_EVENT_FIELD } from 'common/constants'
import {
  formatDate,
  formatDateTime,
  identifyJournalStatusType,
  objectToQueryParams,
  renderAssetFields,
  yen,
} from 'common/helpers'
import { loadNs } from 'common/i18n-config'
import { ASSET_ITEM_STATUS_PARAM_TYPE, AssetReport } from 'common/models'
import { NoImage } from 'components/atoms'
import get from 'lodash/get'
import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { cleanupMonthlyClosingAssetEvents } from 'store/accounting/monthlyClosing/actions'
import { changeLocation } from 'store/app/actions'

import { AssetDisplay, AssetDisplayHeader } from '../styles'
import { JournalStatus } from './journal-status'
import { Media } from './media'

type Props = {
  asset: AssetReport
  isSaleEvent?: boolean
}

const t = loadNs(['components/organisms/accounting/monthly-closing-asset-event-list'])
export const SharedEventView = ({ asset, isSaleEvent = false }: Props) => {
  const dispatch = useDispatch()

  const renderAssetPhoto = useCallback((asset: AssetReport) => {
    const isEmpty = !asset.photos || asset.photos.length === 0

    return isEmpty ? <NoImage /> : <img src={get(asset, 'photos[0].thumbnail_url')} alt="" />
  }, [])

  const handleAssetClick = (asset: AssetReport) => {
    const paramObj = {
      valid_at: isSaleEvent ? asset.sold_at : asset.retired_at,
      type: ASSET_ITEM_STATUS_PARAM_TYPE.RETIRED_OR_SOLD,
    }
    const params = `?${objectToQueryParams(paramObj)}`
    dispatch(changeLocation(`/assets/${asset.biid}${params}`))
  }

  useEffect(() => {
    return function cleanup() {
      dispatch(cleanupMonthlyClosingAssetEvents())
    }
  }, []) // eslint-disable-line

  return (
    <AssetDisplay onClick={() => handleAssetClick(asset)}>
      <AssetDisplayHeader>
        <div className="status">
          <JournalStatus type={identifyJournalStatusType(asset)} />
        </div>
        <div className="photo">{renderAssetPhoto(asset)}</div>
        <div className="asset-code">{`${asset.code}-${asset.branch_code}`}</div>
        <div className="asset-name">{asset.name}</div>
      </AssetDisplayHeader>
      <AssetDisplayDataRow>
        <div className="short-label">{asset.valid_from}</div>
        <div className="short-value">{get(asset.change_situation, 'name_jp')}</div>
        <div className="label text-color-666">{t(isSaleEvent ? 'sale_at' : 'retire_at')}</div>
        <div className="value text-color-666">{formatDateTime(asset.created_at as string)}</div>
      </AssetDisplayDataRow>
      <AssetDisplayDataRow>
        <div className="label">{t(isSaleEvent ? 'sale_reason' : 'retire_reason')}</div>
        <div className="value">{get(asset.event_cause, 'content')}</div>
      </AssetDisplayDataRow>
      <AssetDisplayDataRow>
        <div className="label">{t('acquisition_date')}</div>
        <div className="value">{formatDate(asset.acquired_at as string)}</div>
      </AssetDisplayDataRow>
      <AssetDisplayDataRow>
        <div className="label">{t('service_start_date')}</div>
        <div className="value">{formatDate(asset.usage_started_at as string)}</div>
      </AssetDisplayDataRow>
      <SharedRow asset={asset} isImpairmentEvent={false} />
      {isSaleEvent ? (
        <AssetDisplayDataRow>
          <div className="label">{t('sale_price')}</div>
          <div className="value">{asset.sold_amount && yen(asset.sold_amount)}</div>
        </AssetDisplayDataRow>
      ) : null}
      <AssetDisplayDataRow>
        <div className="label">{t('disposal_cost')}</div>
        <div className="value">{yen(asset.disposal_cost)}</div>
      </AssetDisplayDataRow>
      <AssetDisplayDataRow>
        <div className="label">{t(isSaleEvent ? 'loss_and_gain' : 'loss_on_retirement')}</div>
        <div className="value">{renderAssetFields(asset.fixed_asset_ledgers, ASSET_EVENT_FIELD.LOSS_AND_GAIN)}</div>
      </AssetDisplayDataRow>
      <AssetDisplayDataRow>
        <div className="label">{t('department')}</div>
        <div className="value">{get(asset.department, 'name')}</div>
      </AssetDisplayDataRow>
      <AssetDisplayDataRow>
        <div className="label">{t('area')}</div>
        <div className="value">{get(asset.area, 'name')}</div>
      </AssetDisplayDataRow>
      <Media asset={asset} />
      <AssetDisplayDataRow className="last">
        <div className="label">{t(isSaleEvent ? 'sale_by' : 'retire_by')}</div>
        <div className="value">{get(asset.user_info, 'display_name')}</div>
      </AssetDisplayDataRow>
    </AssetDisplay>
  )
}

export const SharedRow = ({ asset, isImpairmentEvent }: { asset: AssetReport; isImpairmentEvent: boolean }) => {
  return (
    <>
      <AssetDisplayDataRow>
        <div className="label">
          {get(asset, 'acquisition_cost_excluded_excise') === get(asset, 'acquisition_cost')
            ? t('acquisition_cost_exclude')
            : t('acquisition_cost_include')}
        </div>
        <div className="value">{yen(asset.acquisition_cost)}</div>
      </AssetDisplayDataRow>
      <ReportAssetReduction data={asset} />
      <AssetDisplayDataRow>
        <div className="label">{t('accumulated_depreciation')}</div>
        <div className="value">
          {renderAssetFields(asset.fixed_asset_ledgers, ASSET_EVENT_FIELD.ACCUMULATED_DEPRECIATION)}
        </div>
      </AssetDisplayDataRow>
      <AssetDisplayDataRow>
        <div className="label">{t(ifElse(isImpairmentEvent, 'impairment_amount', 'accumulated_impairment'))}</div>
        <div className="value">
          {yen(ifElse(isImpairmentEvent, asset.impaired_amount, asset.accumulated_impaired_amount))}
        </div>
      </AssetDisplayDataRow>
    </>
  )
}
