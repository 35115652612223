import { Drawer } from 'antd'
import classNames from 'classnames'
import React, { ComponentProps, ReactNode } from 'react'

import { Faq } from '../main-header'
import { RightDrawerClass } from './styles'

export const CloseBlueIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.571429 0L0 0.571429L5.42857 6L0 11.4286L0.571429 12L6 6.57143L11.4286 12L12 11.4286L6.57143 6L12 0.571429L11.4286 0L6 5.42857L0.571429 0Z"
        fill="#3B7DE9"
      />
    </svg>
  )
}

type Props = ComponentProps<typeof Drawer>

const TooltipTitle = ({ extra, title }: { extra: ReactNode; title?: React.ReactNode }) => (
  <>
    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: 45, marginRight: 10 }}>
      <Faq />
    </div>
    <div className="divider" style={{ height: 1, background: '#D4D8DD', width: '100%' }} />
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginRight: 15 }}>
      <label style={{ fontSize: 18, height: 50, marginLeft: 40, paddingTop: 12 }}>{title}</label>
      {extra}
    </div>
  </>
)

export const RightDrawer: React.FC<Props> = ({
  visible = false,
  mask = false,
  title,
  children,
  className,
  onClose,
  extra,
  ...rest
}) => {
  return (
    <Drawer
      {...rest}
      title={<TooltipTitle extra={extra} title={title} />}
      placement="right"
      closable
      onClose={onClose}
      visible={visible}
      className={classNames('app-right-drawer', RightDrawerClass, className)}
      destroyOnClose
      mask={mask}
      closeIcon={
        <div>
          <CloseBlueIcon />
        </div>
      }
    >
      <div style={{ maxHeight: '100%' }}>{children}</div>
    </Drawer>
  )
}
