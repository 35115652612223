import styled from '@emotion/styled'
import { If } from 'aa_common/front-end'
import { getNs } from 'common/i18n-config'
import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'

const { t, useNsReady } = getNs(['common'])
export const AbolishLabel = ({ name }: { name: string }) => {
  useNsReady()

  const { isEnable: isDepartmentFlagOn } = useCheckFlag(FeatureFlagNames.DepartmentDepreciationAmountReport)
  return (
    <If condition={isDepartmentFlagOn}>
      <AbolishWrapper>
        <span className="name">{name}</span>
        <span className="abolish">{t('actions.abolition')}</span>
      </AbolishWrapper>
    </If>
  )
}

const AbolishWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;

  .name {
    flex: 1;
    word-break: break-word;
    margin-right: 10px;
  }

  .abolish {
    white-space: nowrap;
    color: #747474;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    padding: 0px 4px;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    background: #e8e8e8;
  }
`
