import { ifElse } from 'aa_common/front-end/helpers'
import { useFormikContext } from 'formik'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getExciseSettingsByDateList } from 'store/settings/excise/selectors'

import { FORM_FIELDS } from '../const'
import { getExciseInputSetting } from '../helper'
import { useFormReady } from './use-form-ready'

export const useAcquisitionCostReference = () => {
  const { data } = useSelector(getExciseSettingsByDateList, isEqual)
  const { isTaxMethodExcluded } = getExciseInputSetting(data)

  const { values, setFieldValue } = useFormikContext()
  const isFormReady = useFormReady()

  const acquisitionCost = get(values, 'acquisition_cost')
  const acquisitionCostExcludedExcise = get(values, 'acquisition_cost_excluded_excise')

  const acquisitionCostReference: number | undefined = ifElse(
    isTaxMethodExcluded,
    acquisitionCostExcludedExcise,
    acquisitionCost
  )

  useEffect(() => {
    isFormReady && setFieldValue(FORM_FIELDS.ACQUISITION_COST_REFERENCE, acquisitionCostReference)
  }, [acquisitionCostReference, isFormReady])

  return acquisitionCostReference
}
