/* eslint-disable jsx-a11y/anchor-is-valid */

import { Tooltip } from 'aa_common/front-end/antd'
import { defaultTo } from 'aa_common/front-end/helpers'
import { ColumnType } from 'antd/es/table'
import { yenWithoutSymbol } from 'common/helpers'
import { TermMonth } from 'common/models'
import {
  AmountMonth,
  AssetCategoryObject,
  AssetObject,
  AssetTypeObject,
  DataTypes,
  DepartmentObject,
  DepreciationData,
  MoneyUnits,
} from 'common/models/depreciation'
import i18n from 'i18n'
import { get } from 'lodash'
import React from 'react'

import { AbolishLabel } from './components/abolish-label'
import { NOT_SET } from './const'
import { Link } from './styles'

export const parseMoney = (value: number, unit: MoneyUnits) => {
  if (unit === MoneyUnits.THOUSAND_YEN) {
    value = Math.floor(value / 1000)
  }

  return yenWithoutSymbol(value)
}

const createDefaultTableData = (columns: ColumnType<any>[]) => {
  const result: any = {}

  columns.forEach(column => {
    result[column.dataIndex as string] = yenWithoutSymbol(0)
  })

  return result
}

const parseDataForAssetType = (columns: ColumnType<any>[], data: AssetTypeObject[], unit: MoneyUnits) => {
  const result: any = []

  data.forEach((assetType: AssetTypeObject) => {
    const record: any = {
      ...createDefaultTableData(columns),
      key: 'asset-type',
      name: assetType.type,
      total_amount: parseMoney(assetType.total_amount, unit),
      type: DataTypes.ASSET_TYPE,
    }

    assetType.amount_months.forEach(amountMonth => {
      record[amountMonth.term_month_id.toString()] = parseMoney(amountMonth.amount, unit)
    })

    if (assetType.items) {
      record.children = parseDataForAssetCategory(columns, assetType.items, unit)
    }

    result.push(record)
  })

  return result
}

const parseDataForAssetCategory = (columns: ColumnType<any>[], data: AssetCategoryObject[], unit: MoneyUnits) => {
  const result: any = []

  data.forEach(assetCategory => {
    const record: any = {
      ...createDefaultTableData(columns),
      key: `asset-category-${assetCategory.asset_category_code}`,
      name: assetCategory.asset_category_name,
      total_amount: parseMoney(assetCategory.total_amount, unit),
      type: DataTypes.ASSET_CATEGORY,
    }

    assetCategory.amount_months.forEach(amountMonth => {
      record[amountMonth.term_month_id.toString()] = parseMoney(amountMonth.amount, unit)
    })

    if (assetCategory.items) {
      record.children = parseDataForAsset(columns, assetCategory.items, unit)
    }

    result.push(record)
  })

  return result
}

const parseDataForAsset = (columns: ColumnType<any>[], data: AssetObject[], unit: MoneyUnits) => {
  const result: any = []

  data.forEach(asset => {
    const record: any = {
      ...createDefaultTableData(columns),
      key: `asset-${asset.biid}`,
      biid: asset.biid,
      name: `${asset.code}-${defaultTo(asset.branch_code, '')} ${asset.name}`,
      service_life: asset.service_life,
      usage_started_at: asset.usage_started_at,
      depreciation_method: asset.depreciation_method,
      total_amount: parseMoney(asset.total_amount, unit),
      type: DataTypes.ASSET,
    }

    asset.amount_months.forEach(amountMonth => {
      record[amountMonth.term_month_id.toString()] = parseMoney(amountMonth.amount, unit)
    })

    result.push(record)
  })

  return result
}

const builtTooltipText = (asset: AssetObject) => {
  return (
    <div>
      <label>
        {i18n.t('components.DepreciationResultList.start_of_service')}:{' '}
        {asset.usage_started_at || i18n.t('common.form_values.unset')}
      </label>
      <label style={{ marginLeft: 22 }}>
        {i18n.t('components.DepreciationResultList.depreciation_method')}: {get(asset.depreciation_method, 'name_jp')}
      </label>
      <label style={{ marginLeft: 22 }}>
        {i18n.t('components.DepreciationResultList.service_life')}: {asset.service_life}
        {i18n.t('common.units.year')}
      </label>
    </div>
  )
}

export const buildMonthIds = (fromMonth: TermMonth, toMonth: TermMonth) => {
  const monthIds = []

  for (let i = fromMonth.id; i <= toMonth.id; i++) {
    monthIds.push(i)
  }

  return monthIds
}

export const renderColumnDetail = (value: string, record: any, onClickAssetName: any, summaryMode?: string) => {
  if (record.type === DataTypes.ASSET) {
    return (
      <Tooltip content={builtTooltipText(record)} key={`tooltip-${record.key}`}>
        <Link className={summaryMode} onClick={() => onClickAssetName(record)}>{`${get(record, 'code') || ''} ${get(
          record,
          'name'
        )}`}</Link>
      </Tooltip>
    )
  }
  if (record?.abolished) {
    return <AbolishLabel key={`abolish-${record.key}`} name={get(record, 'name')} />
  }

  return value
}

export const buildColumns = (
  data: DepreciationData,
  onClickAssetName?: any,
  highlightMonths?: number[],
  summaryMode?: string
): ColumnType<any>[] => {
  const columns: ColumnType<any>[] = [
    {
      title: '',
      key: 'name',
      dataIndex: 'name',
      width: 280,
      fixed: 'left',
      render: function render(value: string, record: any) {
        return renderColumnDetail(value, record, onClickAssetName, summaryMode)
      },
    },
  ]

  data?.term_months?.forEach(termMonth => {
    const { month } = termMonth

    columns.push({
      title: i18n.t('common.format.month', { month }),
      key: `col-${termMonth.id}`,
      dataIndex: `${termMonth.id}`,
      className: highlightMonths?.includes(termMonth.id) ? 'highlight' : '',
      width: 120,
    })
  })

  columns.push({
    title: i18n.t('common.form_values.total'),
    key: 'total_amount',
    dataIndex: 'total_amount',
    width: 120,
    fixed: 'right',
  })

  return columns
}

export const parseDataForReport = (data: DepreciationData, unit: MoneyUnits): any[] => {
  const columns = buildColumns(data)

  return parseDataForAssetType(columns, data.items, unit)
}
// ======================================== Helper for Department===================================================
// If next time you need to refactor to common logic from BE, please update only below method
export const getSpecialFields = (data: any, parentData?: any): any => {
  // Case FA data
  if (data.code) {
    return {
      key: `${parentData?.key}${data.biid}`,
      biid: data.biid,
      name: `${data.code}-${defaultTo(data.branch_code, '')} ${data.name}`,
      service_life: data.service_life,
      usage_started_at: data.usage_started_at,
      depreciation_method: data.depreciation_method,
      type: DataTypes.ASSET,
    }
  }
  // Case Category data
  if (data.asset_category_code) {
    return {
      key: `${parentData?.key}${data.asset_category_code}`,
      name: data.asset_category_name,
    }
  }
  // Case Department data
  if (data.department_code !== undefined) {
    if (data.department_code) {
      return {
        key: `${parentData?.key}${data.department_code}`,
        name: data?.department_name,
        abolished: data?.abolished,
      }
    }
    return {
      key: `${parentData?.key}${NOT_SET}`,
      name: i18n.t('components.DepreciationResultList.not-set'),
      abolished: data?.abolished,
    }
  }
  // Case Overall data
  if (data.type) {
    return { key: `AssetType`, name: data.type }
  }
  return {}
}

type DeprecationTypes = AssetTypeObject | DepartmentObject | AssetCategoryObject | AssetObject

export const parseRowData = (
  columns: ColumnType<any>[],
  data: DeprecationTypes,
  unit: MoneyUnits,
  parentData?: DeprecationTypes
): any => {
  const rowData: any = {}
  columns.forEach(column => {
    if (column.dataIndex === 'total_amount') {
      rowData[column.dataIndex] = parseMoney(data.total_amount, unit)
    } else {
      rowData[column.dataIndex as string] = parseMoney(
        data.amount_months?.find((amount_month: AmountMonth) => `${amount_month.term_month_id}` === column.dataIndex)
          ?.amount || 0,
        unit
      )
    }
  })
  return { ...rowData, ...getSpecialFields(data, parentData) }
}

export const depthFirstSearch = (
  columns: ColumnType<any>[],
  data: DeprecationTypes[],
  unit: MoneyUnits,
  parentData?: DeprecationTypes
): any => {
  const result: any = []
  data?.forEach((item: any) => {
    const rowData = parseRowData(columns, item, unit, parentData)
    const dataChildren = item.items || item.items_department
    if (dataChildren) {
      rowData.children = depthFirstSearch(columns, dataChildren, unit, { ...item, key: rowData.key })
    }
    result.push(rowData)
  })
  return result
}

export const createDataTable = (data: DepreciationData, unit: MoneyUnits, summaryMode: string): any[] => {
  const columns: ColumnType<any>[] = buildColumns(data, summaryMode)
  return depthFirstSearch(columns, data?.items, unit)
}
