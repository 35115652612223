import { useFormikContext } from 'formik'
import get from 'lodash/get'
import React, { useCallback } from 'react'

export const useTransitionCheckChanged = (
  setBookValueReferUpdatedManually: () => void,
  setExciseSettingDate: React.Dispatch<React.SetStateAction<string>>
) => {
  const { values, setFieldValue } = useFormikContext()
  const acquiredAt = get(values, 'acquired_at')

  return useCallback(
    (value: any) => {
      if (!value) {
        setFieldValue('area_biid', null)
        setFieldValue('area', undefined)
        setFieldValue('department_biid', undefined)
        setFieldValue('transition_at', undefined)
        setExciseSettingDate(acquiredAt)
      }
      setBookValueReferUpdatedManually()
    },
    [setFieldValue, acquiredAt, setExciseSettingDate]
  )
}
