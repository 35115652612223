import { EXCISE_TAX_INPUT_FORMAT } from 'common/constants'
import { useFormikContext } from 'formik'
import isEqual from 'lodash/isEqual'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getExciseSettingsByDateList } from 'store/settings/excise/selectors'

import { FORM_FIELDS } from '../const'
import { getExciseInputSetting } from '../helper'
import { useFormReady } from './use-form-ready'

export const useAcquisitionCostReference = (acquisitionCost?: number, acquisitionExcludedTax?: number) => {
  const { data } = useSelector(getExciseSettingsByDateList, isEqual)
  const { isTaxMethodExcluded } = getExciseInputSetting(data)
  const taxInputFormat = data?.tax_input_format

  const { setFieldValue } = useFormikContext()
  const isFormReady = useFormReady()

  const acquisitionCostReference: number | undefined = useMemo(() => {
    if (isTaxMethodExcluded && taxInputFormat === EXCISE_TAX_INPUT_FORMAT.INTERNAL_TAX) {
      return acquisitionExcludedTax
    }
    return acquisitionCost
  }, [isTaxMethodExcluded, taxInputFormat, acquisitionCost, acquisitionExcludedTax])

  useEffect(() => {
    isFormReady && setFieldValue(FORM_FIELDS.ACQUISITION_COST_REFERENCE, acquisitionCostReference)
  }, [acquisitionCostReference, isFormReady])

  return acquisitionCostReference
}
