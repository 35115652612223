import { AssetLedger } from 'common/models'
import { PresenterFixedAssetDepreciationResponse } from 'common/open-api'
import { useEffect, useState } from 'react'

export const useAcceleratedDepreciationDrawer = (
  selectedAssetLedger: AssetLedger | null,
  depreciationResult: PresenterFixedAssetDepreciationResponse,
  isShowDrawer: boolean
) => {
  const [drawerAssetLedger, setDrawerAssetLedger] = useState<AssetLedger | undefined>(undefined)
  const [drawerDepreciationResult, setDrawerDepreciationResult] = useState<PresenterFixedAssetDepreciationResponse>({
    data: [],
    terms: [],
  })

  useEffect(() => {
    if (!isShowDrawer && selectedAssetLedger) {
      setDrawerAssetLedger(selectedAssetLedger)
    }
  }, [isShowDrawer, selectedAssetLedger])

  useEffect(() => {
    if (!isShowDrawer && depreciationResult) {
      setDrawerDepreciationResult(depreciationResult)
    }
  }, [isShowDrawer, depreciationResult])

  return {
    drawerAssetLedger,
    drawerDepreciationResult,
  }
}
