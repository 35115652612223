import { everyTrue, isEmptyValue } from 'aa_common/front-end/helpers'
import * as exciseApi from 'api/app/excise'
import { DATE_FORMAT_RFC, ERR_EXCISE_NOT_VALID_AT_TIME } from 'common/constants'
import { FORM_MODES } from 'common/models'
import { useFormikContext } from 'formik'
import useResource from 'lib/hooks/useResource'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import moment from 'moment/moment'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setAppMessage } from 'store/app/actions'
import { fetchExciseSettingsByDate } from 'store/settings/excise/actions'
import { getExciseSettingsByDateList } from 'store/settings/excise/selectors'
import { useDebounce } from 'use-debounce'

import { getExciseInputSetting } from '../helper'

const currentDate = moment().format(DATE_FORMAT_RFC)

export const useExciseData = (formMode: FORM_MODES) => {
  const dispatch = useDispatch()
  const { values, setFieldValue } = useFormikContext()
  const exciseId = get(values, 'excise_id')
  const acquisitionCostInput = get(values, 'acquisition_cost_input')
  const [debouncedAcquisitionCost] = useDebounce(acquisitionCostInput, 500)

  const [exciseSettingDate, setExciseSettingDate] = useState<string>(currentDate)
  const { data: exciseSetting, isLoading: isExciseSettingLoading } = useSelector(getExciseSettingsByDateList, isEqual)
  const [calculatedExciseValueResponse, getCalculatedExciseValue] = useResource(exciseApi.getExciseVatInfo)

  const { isInputTaxWhenRegister, isTaxMethodExcluded, isExciseSettingAvailable } = getExciseInputSetting(exciseSetting)
  const shouldCalculateExcise = isInputTaxWhenRegister && isTaxMethodExcluded
  const firstTimeRef = useRef(true)

  useEffect(() => {
    exciseSettingDate && dispatch(fetchExciseSettingsByDate(exciseSettingDate))
  }, [exciseSettingDate, dispatch])

  useEffect(() => {
    // Do not calculate excise value if entering the form first time and form mode is not NEW
    if (
      everyTrue(
        firstTimeRef.current,
        formMode !== FORM_MODES.NEW,
        isExciseSettingAvailable,
        debouncedAcquisitionCost,
        !isExciseSettingLoading
      )
    ) {
      firstTimeRef.current = false
      return
    }
    if (
      everyTrue(shouldCalculateExcise, debouncedAcquisitionCost, !!exciseSettingDate, exciseId, !isExciseSettingLoading)
    ) {
      getCalculatedExciseValue(exciseSettingDate, exciseId, debouncedAcquisitionCost)
    }
  }, [
    exciseId,
    exciseSettingDate,
    debouncedAcquisitionCost,
    shouldCalculateExcise,
    isExciseSettingLoading,
    formMode,
    isExciseSettingAvailable,
  ])

  useEffect(() => {
    const errorResponse = get(calculatedExciseValueResponse, 'error[0]')
    if (calculatedExciseValueResponse?.status === 400 && errorResponse?.code === ERR_EXCISE_NOT_VALID_AT_TIME) {
      dispatch(setAppMessage({ type: 'failure', content: errorResponse?.detail }))
      return
    }

    if (calculatedExciseValueResponse?.data) {
      const { included_excise_value, excluded_excise_value, excise_value } = calculatedExciseValueResponse.data
      setFieldValue('calculated_excise_value', excise_value)
      setFieldValue('acquisition_cost', included_excise_value)
      setFieldValue('acquisition_cost_excluded_excise', excluded_excise_value)
      if (!isEmptyValue(excise_value)) {
        setFieldValue('manual_excise_value', undefined)
      }
    }
  }, [calculatedExciseValueResponse])

  return {
    setExciseSettingDate,
  }
}
