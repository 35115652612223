import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CommonErrorMsg } from 'aa_common/front-end/antd'
import { Button } from 'antd'
import { FORM_MODES } from 'common/models'
import { getIn, useFormikContext } from 'formik'
import i18n from 'i18n'
import React, { useEffect, useState } from 'react'
import NumberFormat, { NumberFormatValues, SourceInfo } from 'react-number-format'

import { Wrapper, WrapperReset } from './styles'

export const getNumberValue = (value: number, min?: number, max?: number) => {
  if (Number.isNaN(parseInt(String(value), 10))) {
    return value
  }
  if (min && value < min) {
    return min
  }
  if (max && value > max) {
    return max
  }
  return value
}

type Props = {
  name: string
  placeholder?: string
  editable?: boolean
  style?: React.CSSProperties
  minValue?: number
  maxValue?: number
  isResetManualValue?: boolean
  onChange?: (newValue: number) => void
  onHandleClickOnReset?: () => void
  defaultValue?: number
}

export const UnderlineMoneyInput: React.FC<Props> = ({
  name,
  placeholder,
  editable = false,
  minValue,
  maxValue,
  style,
  isResetManualValue,
  onChange,
  onHandleClickOnReset,
  defaultValue = 0,
}) => {
  const { values, errors, setFieldValue } = useFormikContext()
  const [mode, setMode] = useState<FORM_MODES>(FORM_MODES.VIEW)
  const fieldValue = getIn(values, name)
  const isViewMode = mode === FORM_MODES.VIEW
  const [currentValue, setCurrentValue] = useState<number>(fieldValue)
  let inputRef: HTMLInputElement | null = null
  const error = getIn(errors, name)

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter' && inputRef) {
      inputRef.blur()
    }
    if (event.key === '.') {
      event.preventDefault()
    }
  }

  const handleBlur = () => {
    onChange && onChange(currentValue)
    setMode(FORM_MODES.VIEW)
    setFieldValue(name, currentValue)
  }

  const handleChange = (event: NumberFormatValues, sourceInfo: SourceInfo) => {
    if (sourceInfo?.source === 'prop') return

    const newValue = event.floatValue || defaultValue
    const newValueFormat = newValue ? parseInt(newValue.toString().replace(/\./g, ','), 10) : newValue
    setCurrentValue(getNumberValue(newValueFormat, minValue, maxValue))
  }

  const handleClickOnReset = () => {
    onHandleClickOnReset?.()
  }

  useEffect(() => {
    if (!isViewMode && inputRef) {
      inputRef.focus()
    }
  }, [mode, isViewMode, inputRef])

  useEffect(() => {
    const value = getNumberValue(fieldValue, minValue, maxValue)
    if (value !== fieldValue) setFieldValue(name, value)
    if (value !== currentValue) setCurrentValue(value)
  }, [fieldValue, minValue, maxValue])

  return (
    <Wrapper style={style}>
      <NumberFormat
        name={name}
        getInputRef={(el: any) => (inputRef = el)}
        placeholder={placeholder}
        value={currentValue ?? ''} // a trick from the library to display empty when value is null or undefined
        onValueChange={handleChange}
        onBlur={handleBlur}
        onKeyPress={handleKeyPress}
        allowNegative={false}
        allowLeadingZeros={false}
        allowEmptyFormatting={false}
        thousandSeparator
        prefix="¥"
        style={style}
        disabled={isViewMode}
        inputMode="numeric"
      />
      {isViewMode && editable && (
        <FontAwesomeIcon icon={faPencilAlt} className="edit-icon" onClick={() => setMode(FORM_MODES.EDIT)} />
      )}
      {isResetManualValue && (
        <WrapperReset>
          <span className="reset-label">{i18n.t('common.label.reset_manual_value')}</span>
          <Button className="btn-reset" type="link" onClick={handleClickOnReset}>
            {i18n.t('common.actions.reset')}
          </Button>
        </WrapperReset>
      )}
      {error && <CommonErrorMsg style={{ textAlign: 'left' }}>{error}</CommonErrorMsg>}
    </Wrapper>
  )
}
