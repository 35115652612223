import { Tooltip } from 'aa_common/front-end/antd'
import { If, LinkLabel, RightArrowIcon } from 'aa_common/front-end/components'
import { everyTrue, ifElse, someTrue } from 'aa_common/front-end/helpers'
import { ColumnsType } from 'antd/es/table'
import { ASSET_CHANGE_SITUATION_TYPES, DEPRECIATION_METHOD_CODE, EMPTY_SYMBOL, FEATURES_ID } from 'common/constants'
import { formatDate, formatDateTime, handlePermissionByFeatureId, isRetroactiveAsset, yen } from 'common/helpers'
import { getNs } from 'common/i18n-config'
import { FixedAssetsService } from 'common/open-api'
import { getAxiosSilent } from 'common/open-api/helper'
import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import { FileLink, ReferenceLink } from 'components/atoms'
import { useTaxMethodExciseAsset } from 'components/organisms/assets/hooks/useTaxMethodExciseAsset'
import { useActionsStatus } from 'containers/assets/asset-list-container/hooks/use-actions-status'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/selectors'
import { selectAssetDetailPage, selectEventHistories } from 'store/asset/selectors'
import { selectUserPermission } from 'store/session/selectors'

import { useCorrection } from '../../../hooks/use-correction'
import { Photos } from './components/photos'
import { DetailList, DetailListField, ExtraLine, StyledAction } from './styles'

const DateTemplate = (date: string) => <span className="valid-from">{formatDate(date)}</span>
const { t, useNsReady } = getNs([
  'components/organisms/assets/asset-detail',
  'components/organisms/assets/asset-list',
  'common',
])

type OnActionEventClick = (record: any, type: EventHistoryActionType) => void
type DetailTemplateProps = {
  data: any
  onActionEventClick: OnActionEventClick
  isLatestEvent: boolean
}

const renderAttachments = (assetData: any) => {
  let attachments = get(assetData, 'attachments') || []

  if (get(assetData, ['change_situation', 'code']) !== ASSET_CHANGE_SITUATION_TYPES.ACQUISITION) {
    attachments = get(assetData, 'event_attachments') || []
  }

  if (attachments.length === 0) return EMPTY_SYMBOL

  return attachments.map((item: any) => (
    <div key={item?.id}>
      <FileLink file={item} maxLength={28} />
    </div>
  ))
}

const renderUrls = (assetData: any) => {
  let urls: string[] = get(assetData, 'urls') || []

  if (get(assetData, ['change_situation', 'code']) !== ASSET_CHANGE_SITUATION_TYPES.ACQUISITION) {
    urls = get(assetData, 'event_urls') || []
  }

  return urls.length === 0
    ? EMPTY_SYMBOL
    : urls.map(url => <ReferenceLink key={url} url={url} style={{ marginRight: 10 }} />)
}

const EVENT_CODE_TO_FEATURE_ID_MAP: any = {
  [ASSET_CHANGE_SITUATION_TYPES.ACQUISITION]: FEATURES_ID.FIXED_ASSETS,
  [ASSET_CHANGE_SITUATION_TYPES.USAGE_STARTED]: FEATURES_ID.START_USAGE,
  [ASSET_CHANGE_SITUATION_TYPES.MOVEMENT]: FEATURES_ID.MOVEMENT,
  [ASSET_CHANGE_SITUATION_TYPES.SELL]: FEATURES_ID.SALE,
  [ASSET_CHANGE_SITUATION_TYPES.RETIREMENT]: FEATURES_ID.RETIRE,
  [ASSET_CHANGE_SITUATION_TYPES.OTHER_CHANGE]: FEATURES_ID.FIXED_ASSETS,
  [ASSET_CHANGE_SITUATION_TYPES.SWITCH]: FEATURES_ID.SWITCH_CATEGORY,
  [ASSET_CHANGE_SITUATION_TYPES.IMPAIRMENT]: FEATURES_ID.IMPAIRMENT,
  [ASSET_CHANGE_SITUATION_TYPES.DIVIDING]: FEATURES_ID.DIVIDING,
}

const getPermissionForEvent = (eventCode: ASSET_CHANGE_SITUATION_TYPES, userPermissions: any) => {
  const featureId = EVENT_CODE_TO_FEATURE_ID_MAP[eventCode]
  return handlePermissionByFeatureId(featureId, userPermissions) || {}
}

const DetailTemplate: React.FC<DetailTemplateProps> = ({ data, onActionEventClick, isLatestEvent }) => {
  useNsReady()
  const {
    isCorrectable: isCorrectableWithPermission,
    isCorrectionLinkHidden,
    checkDateWithMonthlyClosing,
    checkEventsAllowedForCorrection,
  } = useCorrection()
  const { asset } = useSelector(selectAssetDetailPage, isEqual)
  const eventHistories = useSelector(selectEventHistories, isEqual)
  const { data: userPermissions } = useSelector(selectUserPermission, isEqual)
  const currentMonthlyClosing = useSelector(selectMonthlyClosingCurrent, isEqual)
  const { isEnable: isEnableForRegisterAcceleratedDepreciation } = useCheckFlag(
    FeatureFlagNames.RegisterAcceleratedDepreciation
  )

  const [isCorrectable, setCorrectable] = useState<boolean>(false)

  const code = data?.change_situation?.code
  const isMovementEvent = code === ASSET_CHANGE_SITUATION_TYPES.MOVEMENT
  const isSwitchEvent = code === ASSET_CHANGE_SITUATION_TYPES.SWITCH
  const isAcquisitionEvent = code === ASSET_CHANGE_SITUATION_TYPES.ACQUISITION
  const isOtherChangeEvent = code === ASSET_CHANGE_SITUATION_TYPES.OTHER_CHANGE
  const isAcceptanceOfTransition = code === ASSET_CHANGE_SITUATION_TYPES.ACCEPTANCE_OF_TRANSITION
  const isImpairmentEvent = code === ASSET_CHANGE_SITUATION_TYPES.IMPAIRMENT
  const isDividingEvent = code === ASSET_CHANGE_SITUATION_TYPES.DIVIDING
  const dMethodOfFirstLedger: number = get(data, 'fixed_asset_ledgers[0].depreciation_method_code')

  useEffect(() => {
    const checkCorrectable = async () => {
      try {
        const response = await FixedAssetsService.isCorrectableDetail(data.biid, getAxiosSilent())
        setCorrectable(Boolean(response?.data))
      } catch (err) {
        setCorrectable(false)
      }
    }

    isAcquisitionEvent && checkCorrectable()
  }, [eventHistories.length])

  const isHiddenMemorandumValueField = [
    DEPRECIATION_METHOD_CODE.DEFERRED_EQUAL_INSTALLMENT,
    DEPRECIATION_METHOD_CODE.SMALL_AMOUNT_DEPRECIABLE_ASSET,
    DEPRECIATION_METHOD_CODE.NON_DEPRECIABLE,
    DEPRECIATION_METHOD_CODE.NON_DEPRECIATION,
  ].includes(dMethodOfFirstLedger)

  const isShowResidualAmountField = [
    DEPRECIATION_METHOD_CODE.AVERAGE_USAGE_PERIOD,
    DEPRECIATION_METHOD_CODE.LEASE_PERIOD_STRAIGHT_LINE,
  ].includes(dMethodOfFirstLedger)

  const permission = useMemo(() => getPermissionForEvent(code, userPermissions), [code, userPermissions])
  const isDividingSourceFixedAsset =
    !!get(data, 'dividing_source_id') &&
    !get(data, 'dividing_source_fixed_asset') &&
    !get(data, 'dividing_destination_fixed_asset')

  const movementEventFields = [
    {
      title: t('area'),
      previousValue: get(data, ['previous', 'area', 'name']) || EMPTY_SYMBOL,
      value: get(data, ['area', 'name']),
      hasArrowIcon: true,
    },
    {
      title: t('submit_destination'),
      previousValue: get(data, ['previous', 'area', 'submit_destination', 'name']) || EMPTY_SYMBOL,
      value: get(data, ['area', 'submit_destination', 'name']),
      hasArrowIcon: true,
    },
    {
      title: t('department'),
      previousValue: get(data, ['previous', 'department', 'name']) || EMPTY_SYMBOL,
      value: get(data, ['department', 'name']),
      hasArrowIcon: true,
    },
  ]

  const dividingDestinationFixedAsset = eventHistories.find(
    history => history.id === get(data, 'dividing_source_id')
  )?.dividing_destination_fixed_asset

  const checkLatestEvent = (eventCode: number) => asset?.change_situation?.code === eventCode

  const { isIncludedTax } = useTaxMethodExciseAsset()
  const dividingEventFields = useMemo(() => {
    return [
      {
        title: t('divided_at'),
        value: formatDate(get(data, 'divided_at')),
      },
      {
        title: t('divided_cause'),
        value: get(data, ['event_cause', 'content']),
      },
      {
        title: t('dividing_book_value_standard_date'),
        value: formatDate(get(data, 'fixed_asset_ledgers[0].dividing_book_value_standard_date')),
      },
      {
        title: t('book_value_when_dividing'),
        value: get(data, 'fixed_asset_ledgers[0].dividing_book_value'),
      },
      {
        title: t('acquisition_cost'),
        value: get(data, 'acquisition_cost_excluded_excise'),
      },
      {
        title: t('excise_amount'),
        isHidden: isIncludedTax,
        value: get(data, 'calculated_excise_value') || get(data, 'manual_excise_value'),
      },
      {
        title: t('acquisition_cost_included_excise'),
        isHidden: isIncludedTax,
        value: get(data, 'acquisition_cost'),
      },
      {
        title: t('quantity'),
        value: get(data, 'quantity'),
      },
      {
        isHidden: isHiddenMemorandumValueField || isShowResidualAmountField,
        title: t('memorandum'),
        value: get(data, 'fixed_asset_ledgers[0].memorandum_value'),
      },
      {
        isHidden: !isShowResidualAmountField,
        title: t('residual_value'),
        value: get(data, 'fixed_asset_ledgers[0].residual_amount'),
      },
    ]
  }, [isIncludedTax])

  const { transition_at, acquired_at, change_situation } = asset
  const isDisableCorrection =
    !isCorrectable ||
    !isCorrectableWithPermission ||
    !checkEventsAllowedForCorrection(change_situation?.code) ||
    (!checkDateWithMonthlyClosing((transition_at || acquired_at) as string) &&
      !isRetroactiveAsset(asset, currentMonthlyClosing))

  const { findActionsStatusByBiid } = useActionsStatus()
  const actionsStatusForAssetDetail = findActionsStatusByBiid(data.biid)
  const isAdjustedOrFinishedDepreciation = someTrue(
    actionsStatusForAssetDetail?.adjusted as boolean,
    actionsStatusForAssetDetail?.depr_finished as boolean
  )

  const disableDeleteButtonForDividingEvent = everyTrue(
    Boolean(isEnableForRegisterAcceleratedDepreciation),
    someTrue(isAdjustedOrFinishedDepreciation, !!actionsStatusForAssetDetail?.divide_related_fas?.length),
    isDividingEvent
  )

  return (
    <>
      <ExtraLine />
      <DetailList>
        <DetailListField>
          <div className="detail-field-wrapper">
            <div className="title created_at">
              {t('created_at', {
                created_at: formatDateTime(data?.created_at),
              })}
            </div>
          </div>
        </DetailListField>
        <If condition={isMovementEvent}>
          <>{renderDetailListField(movementEventFields)}</>
        </If>
        <If condition={isSwitchEvent}>
          <DetailListField>
            <div className="detail-field-wrapper">
              <div className="title">{t('asset_category')}</div>
              <div className="value" style={{ display: 'flex', alignItems: 'center' }}>
                <label>{get(data, ['previous', 'asset_category', 'name']) || EMPTY_SYMBOL}</label>
                <RightArrowIcon style={{ marginLeft: 20, marginRight: 20 }} />
                <label>{get(data, ['asset_category', 'name']) || EMPTY_SYMBOL}</label>
              </div>
            </div>
          </DetailListField>
        </If>
        <If condition={isDividingEvent}>
          <>
            <DetailListField>
              <div className="detail-field-wrapper">
                <div className="title">
                  {t(`${isDividingSourceFixedAsset ? 'divided_asset_name' : 'source_asset_name'}`)}
                </div>
                <div className="value">
                  <a
                    href={`/assets/${
                      get(data, ['dividing_source_fixed_asset', 'biid']) || get(dividingDestinationFixedAsset, 'biid')
                    }`}
                    target="_blank"
                    className="aa-link"
                    rel="noreferrer"
                  >
                    {get(data, ['dividing_source_fixed_asset', 'name']) || get(dividingDestinationFixedAsset, 'name')}
                  </a>
                </div>
              </div>
            </DetailListField>
            {renderDetailListField(dividingEventFields)}
          </>
        </If>
        <DetailListField>
          <div className="detail-field-wrapper">
            <div className="title">{t('photo')}</div>
            <div className="value" style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Photos assetData={data} />
            </div>
          </div>
        </DetailListField>
        <DetailListField>
          <div className="detail-field-wrapper">
            <div className="title">{t('attachment_file')}</div>
            <div className="value">{renderAttachments(data)}</div>
          </div>
        </DetailListField>
        <DetailListField>
          <div className="detail-field-wrapper">
            <div className="title">{t('reference_link')}</div>
            <div className="value">{renderUrls(data)}</div>
          </div>
        </DetailListField>
        <DetailListField>
          <div className="detail-field-wrapper">
            <div className="title">{t('registered_by')}</div>
            <div className="value">{get(data, ['user_info', 'display_name'])}</div>
          </div>
        </DetailListField>
        <If condition={everyTrue(isLatestEvent, !isAcquisitionEvent, !isAcceptanceOfTransition)}>
          <DetailListField>
            <div className="detail-field-wrapper">
              <StyledAction>
                <TooltipWrapper
                  isShowTooltipMessage={disableDeleteButtonForDividingEvent}
                  isDividingEvent={isDividingEvent}
                >
                  <LinkLabel
                    className="delete"
                    showTooltipMessage={!permission?.isDelete ? t('actions.no_permission') : undefined}
                    disabled={someTrue(!permission?.isDelete, disableDeleteButtonForDividingEvent)}
                    type="danger"
                    onClick={() => permission?.isDelete && onActionEventClick(data, 'delete')}
                  >
                    {t('actions.delete_event_history')}
                  </LinkLabel>
                </TooltipWrapper>
                <If condition={everyTrue(!isOtherChangeEvent, !isImpairmentEvent, !isDividingEvent)}>
                  <TooltipWrapper isShowTooltipMessage={isAdjustedOrFinishedDepreciation}>
                    <LinkLabel
                      className="correct"
                      showTooltipMessage={!permission?.isEdit ? t('actions.no_permission') : undefined}
                      disabled={someTrue(!permission?.isEdit, isAdjustedOrFinishedDepreciation)}
                      onClick={() => permission?.isEdit && onActionEventClick(data, 'correct')}
                    >
                      {t('actions.correct')}
                    </LinkLabel>
                  </TooltipWrapper>
                </If>
              </StyledAction>
            </div>
          </DetailListField>
        </If>
        <If condition={isAcquisitionEvent}>
          <DetailListField>
            <div className="detail-field-wrapper">
              <StyledAction>
                <If condition={checkLatestEvent(ASSET_CHANGE_SITUATION_TYPES.ACQUISITION)}>
                  <LinkLabel
                    className="delete"
                    type="danger"
                    onClick={() => permission?.isDelete && onActionEventClick(data, 'delete')}
                  >
                    {t('actions.remove')}
                  </LinkLabel>
                </If>
                <If condition={!isCorrectionLinkHidden}>
                  <TooltipWrapper isShowTooltipMessage={isAdjustedOrFinishedDepreciation}>
                    <LinkLabel.RedirectLink
                      className="correct"
                      redirectTo={`/assets/${data.biid}/correction`}
                      disabled={someTrue(isDisableCorrection, isAdjustedOrFinishedDepreciation)}
                    >
                      {t('actions.correct')}
                    </LinkLabel.RedirectLink>
                  </TooltipWrapper>
                </If>
              </StyledAction>
            </div>
          </DetailListField>
        </If>
      </DetailList>
    </>
  )
}

const TooltipWrapper: React.FC<{
  children: React.ReactNode
  isShowTooltipMessage: boolean
  isDividingEvent?: boolean
}> = ({ children, isShowTooltipMessage, isDividingEvent }) => {
  const tooltipMessage = ifElse(
    isDividingEvent,
    t('tooltip_content.cannot_cancel_divide_with_accelerated_depreciation'),
    t('tooltip_message.cancel_accelerated_depreciation_to_execute')
  )

  return (
    <Tooltip content={ifElse(isShowTooltipMessage, tooltipMessage, '')} placement="bottomRight">
      {children}
    </Tooltip>
  )
}

export type EventHistoryActionType = 'delete' | 'correct'
export const buildEventHistoryColumns = (lastRecord: any, onActionEventClick: OnActionEventClick): ColumnsType<any> => {
  return [
    {
      title: t('date'),
      dataIndex: 'valid_from',
      key: 'valid_from',
      showSorterTooltip: false,
      width: 100,
      sorter: (a: any, b: any) => (a.valid_from >= b.valid_from ? 1 : -1),
      defaultSortOrder: 'descend',
      render: DateTemplate,
    },
    {
      title: t('change_situation_type'),
      dataIndex: ['change_situation', 'name_jp'],
      key: 'change_situation',
      sorter: false,
      showSorterTooltip: false,
      width: 160,
      className: 'change_situation',
    },
    {
      title: t('content'),
      key: 'details',
      sorter: false,
      showSorterTooltip: false,
      width: 600,
      className: 'content',
      render: function renderDetail(record: any) {
        return (
          <DetailTemplate
            data={record}
            onActionEventClick={onActionEventClick}
            isLatestEvent={record.id === lastRecord.id}
          />
        )
      },
    },
  ]
}

const renderDetailListField = (fields: any) => {
  return fields.map(
    ({
      title,
      value,
      previousValue,
      hasArrowIcon,
      isHidden,
    }: {
      title: string
      value: string | number
      previousValue?: string
      hasArrowIcon?: boolean
      isHidden?: boolean
    }) => {
      if (isHidden) return null
      return (
        <DetailListField key={title}>
          <div className="detail-field-wrapper">
            <div className="title">{title}</div>
            <div className="value" style={{ display: 'flex', alignItems: 'center' }}>
              {previousValue && <label>{previousValue}</label>}
              {hasArrowIcon && <RightArrowIcon style={{ marginLeft: 20, marginRight: 20 }} />}
              <label>{typeof value === 'number' ? yen(value) : value || EMPTY_SYMBOL}</label>
            </div>
          </div>
        </DetailListField>
      )
    }
  )
}
