/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  DtoTaxDeclarationDecreaseResponse,
  PresenterAnnualDepreciationSummaryResponse,
  PresenterCorporateTaxDataResponse,
  PresenterCorporateTaxExportHistoryResponse,
  PresenterCorporateTaxHistoryCountResponse,
  PresenterError,
  PresenterJob,
  PresenterOneFixedAssetHistoryResponse,
  PresenterOneTermResponse,
  PresenterReportCostResponse,
  PresenterResponse,
  PresenterShortOneAssetCategoryResponse,
  PresenterSummaryFixedAssetEvent,
  PresenterTaxDeclarationExportReportResponse,
  PresenterTaxDeclarationResponse,
  PresenterTaxDeclarationSummaryResponse,
  ValueobjectAssetTypeForCorporateTaxCode,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Reports<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description GetAnnualDepreciationReport get submit destination info and summary tax declaration in the fiscal year
   *
   * @tags reports
   * @name AnnualDepreciationList
   * @summary GetAnnualDepreciationReport get submit destination info and summary tax declaration in the fiscal year
   * @request GET:/reports/annual_depreciation
   */
  annualDepreciationList = (termId: number, params: RequestParams = {}) =>
    this.http.request<PresenterAnnualDepreciationSummaryResponse[], PresenterError>({
      path: `/reports/annual_depreciation`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description GetCorporateTaxAssetCategories returns the list of asset categories associated in the corporate tax report
   *
   * @tags reports
   * @name CorporateTaxAssetCategoriesList
   * @summary GetCorporateTaxAssetCategories returns the list of asset categories associated in the corporate tax report
   * @request GET:/reports/corporate_tax/asset_categories
   */
  corporateTaxAssetCategoriesList = (
    query: {
      /** ID of term */
      term_id: number
      /** ID of report template */
      report_template_id: number
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterShortOneAssetCategoryResponse[], PresenterError>({
      path: `/reports/corporate_tax/asset_categories`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description GetCorporateTaxAssetTypes returns the list of asset types associated in the corporate tax report
   *
   * @tags reports
   * @name CorporateTaxAssetTypesList
   * @summary GetCorporateTaxAssetTypes returns the list of asset types associated in the corporate tax report
   * @request GET:/reports/corporate_tax/asset_types
   */
  corporateTaxAssetTypesList = (
    query: {
      /** ID of term */
      term_id: number
      /** ID of report template */
      report_template_id: number
    },
    params: RequestParams = {}
  ) =>
    this.http.request<ValueobjectAssetTypeForCorporateTaxCode[], PresenterError>({
      path: `/reports/corporate_tax/asset_types`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description GetCorporateTaxData return summarized fixed asset data used in corporate tax report
   *
   * @tags reports
   * @name CorporateTaxDataList
   * @summary GetCorporateTaxData return summarized fixed asset data used in corporate tax report
   * @request GET:/reports/corporate_tax/data
   */
  corporateTaxDataList = (
    query: {
      /** ID of term */
      term_id: number
      /** ID of report template */
      report_template_id: number
      /** report type (1/2) */
      report_type: number
      /** Array of IDs of asset categories */
      asset_category_ids?: string
      /** Array of IDs of asset types */
      asset_type_ids?: number
      /** Array of IDs of asset types */
      asset_type_codes?: number
      /** include total numbers or not */
      sum_ids?: string
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterCorporateTaxDataResponse[], PresenterError>({
      path: `/reports/corporate_tax/data`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description ExportCorporateTax init a job and trigger background job to handle exporting report work
   *
   * @tags reports
   * @name CorporateTaxExportList
   * @summary ExportCorporateTax init a job and trigger background job to handle exporting report work
   * @request GET:/reports/corporate_tax/export
   */
  corporateTaxExportList = (
    query: {
      /** ID of term */
      term_id: number
      /** ID of report template */
      report_template_id: number
      /** report type (1/2) */
      report_type: number
      /** include total numbers or not */
      sum_ids?: string
      /** Export file format (value: pdf, csv) */
      export_file_format: string
      /** Charset in export file (value: shift_jis, utf-8) */
      charset: string
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterJob, PresenterError>({
      path: `/reports/corporate_tax/export`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description GetCorporateTaxHistorySummary summarizes corporate tax export history
   *
   * @tags reports
   * @name CorporateTaxHistoryCountList
   * @summary GetCorporateTaxHistorySummary summarizes corporate tax export history
   * @request GET:/reports/corporate_tax/history/count
   */
  corporateTaxHistoryCountList = (
    query: {
      /** ID of term */
      term_id: number
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterCorporateTaxHistoryCountResponse[], PresenterError>({
      path: `/reports/corporate_tax/history/count`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description GetCorporateTaxHistoryList get corporate tax export history with a specific report template
   *
   * @tags reports
   * @name CorporateTaxHistoryListList
   * @summary GetCorporateTaxHistoryList get corporate tax export history with a specific report template
   * @request GET:/reports/corporate_tax/history/list
   */
  corporateTaxHistoryListList = (
    query: {
      /** ID of term */
      term_id: number
      /** Report template id */
      report_template_id: number
      'page[number]'?: number
      'page[size]'?: number
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterCorporateTaxExportHistoryResponse[], PresenterError>({
      path: `/reports/corporate_tax/history/list`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description GetJobStatusCorporateTax return status of job generating pdf or csv
   *
   * @tags reports
   * @name CorporateTaxJobIdList
   * @summary GetJobStatusCorporateTax return status of job generating pdf or csv
   * @request GET:/reports/corporate_tax/job/:id
   */
  corporateTaxJobIdList = (id: string, params: RequestParams = {}) =>
    this.http.request<PresenterJob, PresenterError>({
      path: `/reports/corporate_tax/job/${id}`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description GetCorporateTaxAvailableTerms summarizes corporate tax export history
   *
   * @tags reports
   * @name CorporateTaxTermsList
   * @summary GetCorporateTaxAvailableTerms summarizes corporate tax export history
   * @request GET:/reports/corporate_tax/terms
   */
  corporateTaxTermsList = (params: RequestParams = {}) =>
    this.http.request<PresenterOneTermResponse, PresenterError>({
      path: `/reports/corporate_tax/terms`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description GetEventsByTermMonth get histories of fixed asset by term_month
   *
   * @tags reports
   * @name FixedAssetEventsList
   * @summary GetEventsByTermMonth get histories of fixed asset by term_month
   * @request GET:/reports/fixed_asset_events
   */
  fixedAssetEventsList = (
    query: {
      change_situation_code: 10 | 11 | 20 | 30 | 40 | 50 | 53 | 60 | 90 | 99
      term_month_id: number
      'page[number]'?: number
      'page[size]'?: number
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterOneFixedAssetHistoryResponse[], PresenterError>({
      path: `/reports/fixed_asset_events`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Aggregate increase/decrease cost
   *
   * @tags reports
   * @name IncreaseDecreaseCostList
   * @summary Aggregate the increase/decrease cost
   * @request GET:/reports/increase_decrease_cost
   */
  increaseDecreaseCostList = (
    query: {
      /** @max 12 */
      from_month_serial_number: number
      ledger_setting_id: number
      term_id: number
      /** @max 12 */
      to_month_serial_number: number
    },
    params: RequestParams = {}
  ) =>
    this.http.request<
      PresenterResponse & {
        data?: PresenterReportCostResponse[]
      },
      PresenterError
    >({
      path: `/reports/increase_decrease_cost`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Get summary fixed asset event by term_month
   *
   * @tags reports
   * @name SummaryFixedAssetEventsByTermMonthDetail
   * @summary Get summary fixed asset event by term_month
   * @request GET:/reports/summary_fixed_asset_events/by_term_month/{id}
   */
  summaryFixedAssetEventsByTermMonthDetail = (id: number, params: RequestParams = {}) =>
    this.http.request<
      PresenterResponse & {
        data?: PresenterSummaryFixedAssetEvent[]
      },
      PresenterError
    >({
      path: `/reports/summary_fixed_asset_events/by_term_month/${id}`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description ListTaxDeclarationHistories list submit destination of fixed asset and histories exporting pdf
   *
   * @tags reports
   * @name TaxDeclarationList
   * @summary ListTaxDeclarationHistories list submit destination of fixed asset and histories exporting pdf
   * @request GET:/reports/tax_declaration
   */
  taxDeclarationList = (
    query: {
      /** fiscal year of depreciable fixed asset tax declaration */
      year: number
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterTaxDeclarationResponse[], PresenterError>({
      path: `/reports/tax_declaration`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description TaxDeclarationDecrease get detail list fixed assset decrease in the fiscal year
   *
   * @tags reports
   * @name TaxDeclarationDecreaseDetail
   * @summary TaxDeclarationDecrease get detail list fixed assset decrease in the fiscal year
   * @request GET:/reports/tax_declaration/decrease/{submit_destination_id}
   */
  taxDeclarationDecreaseDetail = (
    submitDestinationId: number,
    query: {
      /** fiscal year of depreciable fixed asset tax declaration */
      year: number
    },
    params: RequestParams = {}
  ) =>
    this.http.request<DtoTaxDeclarationDecreaseResponse[], PresenterError>({
      path: `/reports/tax_declaration/decrease/${submitDestinationId}`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description TaxDeclarationExportReport export report of tax declaration to PDF file
   *
   * @tags reports
   * @name TaxDeclarationExportList
   * @summary TaxDeclarationExportReport export report of tax declaration to PDF file
   * @request GET:/reports/tax_declaration/export
   */
  taxDeclarationExportList = (
    query: {
      /** export type: all, summary, decrease, increase (1,2,3,4) */
      type: number
      /** ID of submit destination */
      submit_destination_id: number
      /** fiscal year of depreciable fixed asset tax declaration */
      year: number
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterTaxDeclarationExportReportResponse, PresenterError>({
      path: `/reports/tax_declaration/export`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description TaxDeclarationExportMultipleReport export multiple reports of tax declaration to zip file
   *
   * @tags reports
   * @name TaxDeclarationExportMultipleList
   * @summary TaxDeclarationExportMultipleReport export multiple reports of tax declaration to zip file
   * @request GET:/reports/tax_declaration/export_multiple
   */
  taxDeclarationExportMultipleList = (
    query: {
      /** export type: all, summary, decrease, increase (1,2,3,4) */
      type: number
      /** IDs array of submit destinations */
      submit_destination_ids: number
      /** fiscal year of depreciable fixed asset tax declaration */
      year: number
      /** submit date, example: 2021-01-25 */
      submit_date: string
      /** value must be 'export' */
      path: string
      /** mode of export: 1: all, 2: in_fiscal_year */
      mode: number
      /** with asset code option to show {asset_code-branch_code} in report */
      with_asset_code: boolean
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterTaxDeclarationExportReportResponse, PresenterError>({
      path: `/reports/tax_declaration/export_multiple`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description TaxDeclarationIncrease get detail list fixed assset increase by the param `mode`
   *
   * @tags reports
   * @name TaxDeclarationIncreaseDetail
   * @summary TaxDeclarationIncrease get detail list fixed assset increase by the param `mode`
   * @request GET:/reports/tax_declaration/increase/{submit_destination_id}
   */
  taxDeclarationIncreaseDetail = (
    submitDestinationId: number,
    query: {
      /** fiscal year of depreciable fixed asset tax declaration */
      year: number
      /** view mode of depreciable: 1: all, 2: in_fiscal_year */
      mode?: number
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterTaxDeclarationResponse[], PresenterError>({
      path: `/reports/tax_declaration/increase/${submitDestinationId}`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description TaxDeclarationSummary get submit destination info and summary tax declaration in the fiscal year
   *
   * @tags reports
   * @name TaxDeclarationSummarySubmitDestinationIdList
   * @summary TaxDeclarationSummary get submit destination info and summary tax declaration in the fiscal year
   * @request GET:/reports/tax_declaration/summary/:submit_destination_id
   */
  taxDeclarationSummarySubmitDestinationIdList = (
    submitDestinationId: number,
    query: {
      /** fiscal year of depreciable fixed asset tax declaration */
      year: number
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterTaxDeclarationSummaryResponse[], PresenterError>({
      path: `/reports/tax_declaration/summary/${submitDestinationId}`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
