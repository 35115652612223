import styled from '@emotion/styled'

import { GREY_BORDER_COLOR } from 'common/constants'
export const AssetDisplayDataRow = styled.div`
  display: flex;
  justify-content: flex-end;

  & > div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: 1px solid ${GREY_BORDER_COLOR};
    padding: 8px 0px;
    min-height: 30px;
  }

  &.border-top {
    & > div {
      border-top: 1px solid ${GREY_BORDER_COLOR};
    }
  }

  &.last {
    & > div {
      border-bottom: 0px;
    }
  }

  .label {
    width: 200px;
  }

  .value {
    width: 400px;
    word-break: break-all;
  }

  .short-label {
    width: 100px;
  }

  .short-value {
    width: 160px;
  }

  .empty-space {
    width: 70px;
  }

  .top-border {
    border-top: 1px solid ${GREY_BORDER_COLOR};
    margin-top: -1px;
  }

  .no-bottom-border {
    border-bottom: none;
  }

  span.arrow,
  label.arrow {
    margin-left: 15px;
    margin-right: 15px;
  }

  .text-color-666 {
    font-size: 12px;
    color: #666666;
    line-height: 20px;
  }
`
