import { UnborderCollapse } from 'aa_common/front-end/components'
import { useBoolean } from 'aa_common/front-end/hooks'
import { loadNs } from 'common/i18n-config'
import { Asset, FORM_MODES } from 'common/models'
import { HorizontalLine } from 'components/atoms'
import { useFormikContext } from 'formik'
import get from 'lodash/get'
import React from 'react'

import { AcquisitionCostSection } from './components/AcquisitionCostSection'
import { AfterAcquisitionSection } from './components/AfterAcquisitionSection'
import { BeforeAcquisitionSection } from './components/BeforeAcquisitionSection'
import { DepreciationLedgers } from './components/DepreciationLedgers'
import { OtherArea } from './components/OtherArea'
import { RightArea } from './components/RightArea'
import { SubmitArea } from './components/submit-area'
import { UploadArea } from './components/UploadArea'
import { useAcquisitionCostReference } from './hooks/use-acquisition-cost-reference-new'
import { useBookValueReference } from './hooks/use-book-value-reference'
import { useExciseData } from './hooks/use-excise-data'
import { useInitAcquisitionCostInput } from './hooks/use-init-acquisition-cost-input'
import { useInitialFormData } from './hooks/use-initial-form-data'
import { useSaveTempData } from './hooks/use-save-temp-data'
import { useSelectAssetCategory } from './hooks/use-select-asset-category'
import { useSelectedAssetCategoryChange } from './hooks/use-selected-asset-category-change'
import { useFormValidation, useSubmitOnError } from './hooks/useFormValidation'

const t = loadNs(['components/organisms/assets/asset-form', 'common'])

const { LeftGroup } = UnborderCollapse

export const FormData: React.FC<Props> = ({
  initialValues,
  formMode,
  isTemporaryData,
  onSaveTemp,
  onHandleReloadMasterOptions,
  showRequiredError,
  onSubmit,
  confirming,
}) => {
  const { values } = useFormikContext()

  const reductionAmount = get(values, 'reduction_amount')
  const netAcquisitionCost = get(values, 'net_acquisition_cost')

  const { setExciseSettingDate } = useExciseData(formMode)
  const [bookValueReferUpdatedManually, setBookValueReferUpdatedManually] = useBoolean(false)
  const acquisitionCostReference = useAcquisitionCostReference()
  const bookValueReference = useBookValueReference(acquisitionCostReference, netAcquisitionCost)
  const { resultSelectedAssetCategory, assetType } = useSelectAssetCategory()

  useFormValidation()
  useSubmitOnError(onSubmit)
  useSaveTempData(formMode, onSaveTemp)

  useInitialFormData(initialValues, isTemporaryData, setExciseSettingDate)
  useInitAcquisitionCostInput(initialValues)
  useSelectedAssetCategoryChange(formMode, resultSelectedAssetCategory.data)

  return (
    <>
      <UnborderCollapse title={t('title_basic_info')} collapsable={false}>
        <LeftGroup>
          <BeforeAcquisitionSection
            formMode={formMode}
            loadingAssetCategory={resultSelectedAssetCategory.isLoading}
            showRequiredError={showRequiredError}
            setBookValueReferUpdatedManually={setBookValueReferUpdatedManually}
            setExciseSettingDate={setExciseSettingDate}
          />
          <AcquisitionCostSection
            formMode={formMode}
            loadingAssetCategory={resultSelectedAssetCategory.isLoading}
            showRequiredError={showRequiredError}
            setBookValueReferUpdatedManually={setBookValueReferUpdatedManually}
            acquisitionCostValueRefer={acquisitionCostReference}
          />
          <AfterAcquisitionSection formMode={formMode} showRequiredError={showRequiredError} />
        </LeftGroup>
        <RightArea onHandleReloadMasterOptions={onHandleReloadMasterOptions} />
      </UnborderCollapse>
      <HorizontalLine />
      <UploadArea />
      <HorizontalLine />
      <DepreciationLedgers
        initialValues={initialValues}
        formMode={formMode}
        assetType={assetType}
        bookValueReference={bookValueReference}
        bookValueReferUpdatedManually={bookValueReferUpdatedManually}
        showRequiredError={showRequiredError}
        setBookValueReferUpdatedManually={setBookValueReferUpdatedManually}
        setExciseSettingDate={setExciseSettingDate}
        acquisitionCostValueRefer={acquisitionCostReference}
      />
      <HorizontalLine />
      <OtherArea />
      <SubmitArea
        formMode={formMode}
        confirming={confirming}
        disable={Boolean(acquisitionCostReference && reductionAmount > acquisitionCostReference)}
      />
    </>
  )
}

type Props = {
  initialValues: Asset
  formMode: FORM_MODES
  isTemporaryData: boolean
  onSaveTemp?: (dataType: string, data: any, extraKey?: string) => void
  onHandleReloadMasterOptions?: (pageName: string) => void
  showRequiredError?: boolean
  onSubmit?: (values: any) => void
  confirming?: boolean
}
