import { TempDataTypes } from 'api/app/tempData'
import { FORM_MODES } from 'common/models'
import { useFormikContext } from 'formik'
import get from 'lodash/get'
import { useEffect } from 'react'
import { useDebounce } from 'use-debounce'

import { AUTO_SAVE_DELAY_TIME } from '../const'

export const useSaveTempData = (
  formMode: FORM_MODES,
  onSaveTemp?: (dataType: string, data: any, extraKey?: string) => void
) => {
  const { values, dirty } = useFormikContext()
  const [debounceValues] = useDebounce(values, AUTO_SAVE_DELAY_TIME)

  useEffect(() => {
    if (dirty && debounceValues) {
      if ([FORM_MODES.NEW, FORM_MODES.COPY].includes(formMode)) {
        onSaveTemp?.(TempDataTypes.CREATE_ASSET, debounceValues)
      } else if (formMode === FORM_MODES.EDIT) {
        onSaveTemp?.(TempDataTypes.EDIT_ASSET, debounceValues, get(debounceValues, 'biid'))
      }
    }
  }, [dirty, debounceValues])
}
