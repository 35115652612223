import { Aggregation, MoneyUnits } from 'common/models/depreciation'
import i18n from 'i18n'

export const UNITS = [
  {
    label: i18n.t('common.units.yen'),
    value: MoneyUnits.YEN,
  },
  {
    label: i18n.t('common.units.thousand_yen'),
    value: MoneyUnits.THOUSAND_YEN,
  },
]

export const AGGREGATIONS = [
  {
    label: i18n.t('components.DepreciationResultList.depreciation_calculation'),
    value: Aggregation.DEPRECIATION_CALCULATION,
    disabled: false,
  },
  {
    label: i18n.t('components.DepreciationResultList.journal_result'),
    value: Aggregation.JOURNAL_RESULT,
    disabled: false,
  },
]

export const SUMMARY_METHODS = {
  CATEGORY: 'category',
  DEPARTMENT: 'department',
}

export enum ExportByUnit {
  ASSET_UNIT = 2,
  ASSET_CATEGORY = 1,
}
export enum ExportBySummarize {
  ASSET_UNIT = 2,
  DEPARTMENT_UNIT = 1,
}

export const EXPORT_BY_UNIT_OPTIONS = [
  {
    label: i18n.t('components.DepreciationResultList.asset_unit'),
    value: ExportByUnit.ASSET_UNIT,
  },
  {
    label: i18n.t('components.DepreciationResultList.asset_category'),
    value: ExportByUnit.ASSET_CATEGORY,
  },
]

export const EXPORT_BY_SUMMARIZE_OPTIONS = [
  {
    label: i18n.t('components.DepreciationResultList.asset_unit'),
    value: ExportBySummarize.ASSET_UNIT,
  },
  {
    label: i18n.t('components.DepreciationResultList.department_unit'),
    value: ExportBySummarize.DEPARTMENT_UNIT,
  },
]

export const NOT_SET = 'Notset'
