import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Space } from 'antd'
import { getNs } from 'common/i18n-config'
import { PresenterFixedAssetDepreciationResponse } from 'common/open-api'
import React, { useMemo } from 'react'

const { t, useNsReady } = getNs(['common', 'components/organisms/assets/asset-detail'])

type ErrorDialogContentProps = {
  depreciationResults: PresenterFixedAssetDepreciationResponse
  isMonthlyClosingError: boolean
  onClickDetail: () => void
}
export const ErrorDialogContent = ({
  isMonthlyClosingError,
  depreciationResults,
  onClickDetail,
}: ErrorDialogContentProps) => {
  useNsReady()

  const depreciationPeriod = useMemo(() => {
    const periods = {
      start_year: depreciationResults.data?.[0]?.fiscal_year,
      start_month: depreciationResults.data?.[0]?.months?.[0]?.month,
      end_year: depreciationResults.data?.[depreciationResults.data.length - 1]?.fiscal_year,
      end_month: 0 as number | undefined,
    }
    depreciationResults.data?.[depreciationResults.data.length - 1]?.months?.forEach(month => {
      periods.end_month = month.month
    })
    return periods
  }, [depreciationResults])

  return (
    <>
      <div>
        {isMonthlyClosingError
          ? t('cancel_modal.cannot_accelerated_due_to_monthly_closing')
          : t('cancel_modal.cannot_accelerated_due_to_event')}
      </div>
      <br />
      <Space size="small">
        <span>
          {t('cancel_modal.accelerated_depreciation_period', {
            start_year: depreciationPeriod.start_year,
            start_month: depreciationPeriod.start_month,
            end_year: depreciationPeriod.end_year,
            end_month: depreciationPeriod.end_month,
          })}
        </span>
        <a onClick={onClickDetail}>
          {t('cancel_modal.detail_is_here')} <FontAwesomeIcon icon={faUpRightFromSquare} />
        </a>
      </Space>
    </>
  )
}
