import { If, UnborderCollapse } from 'aa_common/front-end/components'
import { defaultTo, ifElse } from 'aa_common/front-end/helpers'
import classNames from 'classnames'
import { loadNs } from 'common/i18n-config'
import { FORM_MODES } from 'common/models'
import { MoneyInput } from 'components/molecules'
import { unitsSlice } from 'containers/master/units/store'
import { getIn, useFormikContext } from 'formik'
import isEqual from 'lodash/isEqual'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectShowMemorandumValueErrorMessage } from 'store/asset/selectors'

import { DEPRECIATION_FIELDS } from '../const'
import { checkShowMemorandumValue, genLedgerFieldName } from '../helper'
import { useDisableMemorandum } from '../hooks/use-disable-memorandum'
import { useShowMemorandumValueError } from '../hooks/use-show-memorandom-value-error'
import { Error } from '../styles'
import LocalUnderlineInput from '../UnderlineInput'

const { Item } = UnborderCollapse

const t = loadNs(['components/organisms/assets/asset-form', 'common'])

function getErrorMessage(formMode: FORM_MODES, hasTransition: boolean) {
  if (formMode === FORM_MODES.EDIT) {
    return t('memorandum_value_error_message_edit')
  }
  return ifElse(hasTransition, t('memorandum_value_error_message_transaction'), t('memorandum_value_error_message_new'))
}

type MemorandumValueProps = {
  ledgerIndex: number
  formMode: FORM_MODES
  bookValueRefer?: number
}

export const MemorandumValue = ({ ledgerIndex, formMode, bookValueRefer }: MemorandumValueProps) => {
  const { values, setFieldValue } = useFormikContext()
  const showMemorandumValueErrorMessage = useSelector(selectShowMemorandumValueErrorMessage, isEqual)
  const units = defaultTo(
    unitsSlice.useSlice(state => state?.data?.units),
    []
  )

  const depreciationMethodCodeField = genLedgerFieldName(DEPRECIATION_FIELDS.DEPRECIATION_METHOD_CODE, ledgerIndex)
  const ledgerIdField = genLedgerFieldName(DEPRECIATION_FIELDS.LEDGER_SETTING_ID, ledgerIndex)
  const memorandumValueField = genLedgerFieldName(DEPRECIATION_FIELDS.MEMORANDUM_VALUE, ledgerIndex)
  const isEnteredField = genLedgerFieldName(DEPRECIATION_FIELDS.IS_MEMORANDUM_VALUE_ENTERED, ledgerIndex)

  const unitId = getIn(values, 'unit_id')
  const ledgerId = getIn(values, ledgerIdField)
  const depreciationCode = getIn(values, depreciationMethodCodeField)
  const memorandumValue = getIn(values, memorandumValueField)

  const isShowErrorMessage = showMemorandumValueErrorMessage?.[ledgerId]
  const isShowMemorandumValue = checkShowMemorandumValue(depreciationCode)
  const linkMemorandumValueQuantity = units?.find(unit => unit.id === unitId)?.link_memorandum_value_quantity
  const isDisable = useDisableMemorandum(ledgerIndex, formMode)

  useShowMemorandumValueError(ledgerIndex, bookValueRefer)

  return (
    <If condition={isShowMemorandumValue}>
      <Item label={t('memorandum_value')}>
        <If condition={linkMemorandumValueQuantity}>
          <LocalUnderlineInput
            value={memorandumValue}
            placeholder=""
            hasManualValue={false}
            maxValue={1000000000000}
            editable={false}
            labelClassName={classNames({ error: isShowErrorMessage })}
          />
        </If>
        <If condition={!linkMemorandumValueQuantity}>
          <MoneyInput
            name={memorandumValueField}
            style={{ textAlign: 'right' }}
            className={classNames({ error: isShowErrorMessage })}
            disabled={isDisable}
            onChange={() => setFieldValue(isEnteredField, true)}
          />
        </If>
        <If condition={isShowErrorMessage}>
          <Error>{getErrorMessage(formMode, getIn(values, 'has_transition'))}</Error>
        </If>
      </Item>
    </If>
  )
}
