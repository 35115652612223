import { UnderlineInput } from 'aa_common/front-end/antd'
import { If, UnborderCollapse } from 'aa_common/front-end/components'
import { DEPRECIATION_METHOD_CODE } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import { FORM_MODES } from 'common/models'
import { getIn, useFormikContext } from 'formik'
import React from 'react'

import { DEPRECIATION_FIELDS } from '../const'
import { genLedgerFieldName } from '../helper'
import LocalUnderlineInput from '../UnderlineInput'
import { AccumulatedDepreciationAmount } from './AccumulatedDepreciationAmount'
import { AccumulatedExcessDepreciation } from './AccumulatedExcessDepreciation'
import { AccumulatedImpairmentAmount } from './AccumulatedImpairmentAmount'
import { MemorandumValue } from './MemorandumValue'
import { ResidualAmount } from './ResidualAmount'

const { Item } = UnborderCollapse

const t = loadNs(['components/organisms/assets/asset-form', 'common'])

type DepreciationAmountFieldsProps = {
  ledgerIndex: number
  ledgerName: string
  disabled: boolean
  formMode: FORM_MODES
  bookValueRefer?: number
}

export const DepreciationAmountFields = ({
  ledgerIndex,
  ledgerName,
  disabled,
  formMode,
  bookValueRefer,
}: DepreciationAmountFieldsProps) => {
  const { values } = useFormikContext()

  const depreciationMethodCodeField = genLedgerFieldName(DEPRECIATION_FIELDS.DEPRECIATION_METHOD_CODE, ledgerIndex)
  const revisedDepreciationRateField = genLedgerFieldName(DEPRECIATION_FIELDS.REVISED_DEPRECIATION_RATE, ledgerIndex)
  const guaranteeRateField = genLedgerFieldName(DEPRECIATION_FIELDS.GUARANTEE_RATE, ledgerIndex)
  const depreciationCode = getIn(values, depreciationMethodCodeField)
  const depreciationRateField = genLedgerFieldName(DEPRECIATION_FIELDS.DEPRECIATION_RATE, ledgerIndex)

  const isEnableFullFields = ![
    DEPRECIATION_METHOD_CODE.NON_DEPRECIATION,
    DEPRECIATION_METHOD_CODE.NON_DEPRECIABLE,
  ].includes(depreciationCode)

  const shouldRenderDepreciationRate = ![
    DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD,
    DEPRECIATION_METHOD_CODE.AVERAGE_USAGE_PERIOD,
    DEPRECIATION_METHOD_CODE.LEASE_PERIOD_STRAIGHT_LINE,
    DEPRECIATION_METHOD_CODE.SMALL_AMOUNT_DEPRECIABLE_ASSET,
    DEPRECIATION_METHOD_CODE.DEFERRED_EQUAL_INSTALLMENT,
    DEPRECIATION_METHOD_CODE.NON_DEPRECIATION,
    DEPRECIATION_METHOD_CODE.NON_DEPRECIABLE,
  ].includes(depreciationCode)

  return (
    <>
      <ResidualAmount ledgerIndex={ledgerIndex} ledgerName={ledgerName} disabled={disabled} />
      <AccumulatedExcessDepreciation ledgerIndex={ledgerIndex} disabled={disabled} />
      <If condition={DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD === depreciationCode}>
        <Item label={t('memorandum_value')}>
          <LocalUnderlineInput value={0} hasManualValue={false} maxValue={1000000000000} editable={false} />
        </Item>
      </If>
      <If condition={shouldRenderDepreciationRate}>
        <Item label={t('depreciation_rate')}>
          <UnderlineInput name={depreciationRateField} placeholder={t('depreciation_rate_placeholder')} />
        </Item>
      </If>
      <AccumulatedDepreciationAmount ledgerIndex={ledgerIndex} formMode={formMode} />
      <AccumulatedImpairmentAmount ledgerIndex={ledgerIndex} formMode={formMode} />
      <If condition={isEnableFullFields}>
        <If condition={!!getIn(values, guaranteeRateField)}>
          <Item label={t('guarantee_rate')}>
            <UnderlineInput name={guaranteeRateField} placeholder={t('guarantee_rate_placeholder')} />
          </Item>
        </If>
        <If condition={!!getIn(values, revisedDepreciationRateField)}>
          <Item label={t('revised_depreciation_rate')}>
            <UnderlineInput
              name={revisedDepreciationRateField}
              placeholder={t('revised_depreciation_rate_placeholder')}
            />
          </Item>
        </If>
      </If>
      <MemorandumValue ledgerIndex={ledgerIndex} formMode={formMode} bookValueRefer={bookValueRefer} />
    </>
  )
}
