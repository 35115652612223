import { UnderlineInput } from 'aa_common/front-end/antd'
import { If, UnborderCollapse, UnderlineMoneyInput } from 'aa_common/front-end/components'
import { checkIsAfterDate, defaultTo, isEmptyValue } from 'aa_common/front-end/helpers'
import { useBoolean } from 'aa_common/front-end/hooks'
import * as depreciationRateApi from 'api/app/depreciationRates'
import classNames from 'classnames'
import { ASSET_TYPE, DEFAULT_MEMORANDUM_VALUE, DEPRECIATION_METHOD_CODE, LEDGER_PURPOSE } from 'common/constants'
import { isAccountingJournalTarget, isTaxJournalTarget } from 'common/helpers'
import { loadNs } from 'common/i18n-config'
import { AssetLedger, FORM_MODES, IMasterData } from 'common/models'
import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import { Checkbox, NumberInput } from 'components/atoms'
import { AutoCompleteSelect, DatePicker, MoneyInput } from 'components/molecules'
import { unitsSlice } from 'containers/master/units/store'
import { getIn, useFormikContext } from 'formik'
import parser from 'html-react-parser'
import useResource from 'lib/hooks/useResource'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setShowMemorandumValueErrorMessage } from 'store/asset/actions'
import { selectShowMemorandumValueErrorMessage } from 'store/asset/selectors'
import { selectLedgerSettingList } from 'store/settings/ledgerSetting/selectors'

import { DEPRECIATION_FIELDS, LEDGERS_FIELD_NAME } from '../const'
import {
  checkShowMemorandumValue,
  genLedgerFieldName,
  shouldRenderAtChangeFields,
  shouldRenderBookValueAtChange,
  transitionAtSameAsStartUsage,
} from '../helper'
import { useLedgerCopy } from '../hooks/useLedgerCopy'
import { Error, LedgerHeader, prelineStyle, TitleName } from '../styles'
import LocalUnderlineInput from '../UnderlineInput'
import { BeginningBookValue } from './BeginningBookValue'
import { BookValueAtChange } from './BookValueAtChange'
import { ItemLabelTooltip } from './ItemLabelTooltip'
import { ServiceLifeAfterChange } from './ServiceLifeAfterChange'
import { TransitionBookValues } from './TransitionBookValues'

const t = loadNs(['components/organisms/assets/asset-form', 'common'])

const { Item } = UnborderCollapse

type Props = {
  masterData?: IMasterData
  ledgerIndex: number
  disabled?: boolean
  isTransitionDayIsFirstDayOfTerm?: boolean
  ledgerName?: string
  isEditMode?: boolean
  showRequiredError?: boolean
  isDisabledTransitionAt?: boolean
  onAfterChangedTransitionAt: any
  formMode: FORM_MODES
  isImportMode: boolean
  bookValueRefer?: number
  bookValueReferUpdatedManually?: boolean
  acquisitionCostValueRefer?: number
}

const DepreciationLedge: React.FC<Props> = ({
  ledgerIndex,
  masterData,
  disabled = false,
  isTransitionDayIsFirstDayOfTerm = false,
  ledgerName = '',
  isEditMode,
  showRequiredError = false,
  isDisabledTransitionAt,
  onAfterChangedTransitionAt,
  formMode,
  isImportMode,
  bookValueRefer,
  bookValueReferUpdatedManually,
  acquisitionCostValueRefer,
}) => {
  const dispatch = useDispatch()
  const { values, errors, setFieldValue } = useFormikContext<{ id: string }>()
  const [depreciationRateResponse, getDepreciationRate] = useResource(depreciationRateApi.get)
  const ledgerSettingList = useSelector(selectLedgerSettingList, isEqual)
  const units = defaultTo(
    unitsSlice.useSlice(state => state?.data?.units),
    []
  )
  const showMemorandumValueErrorMessage = useSelector(selectShowMemorandumValueErrorMessage, isEqual)

  const ledgerIdField = genLedgerFieldName(DEPRECIATION_FIELDS.LEDGER_SETTING_ID, ledgerIndex)

  const serviceLifeField = genLedgerFieldName(DEPRECIATION_FIELDS.SERVICE_LIFE, ledgerIndex)
  const serviceLifeAfterChangeField = genLedgerFieldName(DEPRECIATION_FIELDS.SERVICE_LIFE_AFTER_CHANGE, ledgerIndex)
  const bookValueAtChangeField = genLedgerFieldName(DEPRECIATION_FIELDS.BOOK_VALUE_AT_CHANGE, ledgerIndex)
  const usagePeriodMonthsField = genLedgerFieldName(DEPRECIATION_FIELDS.USAGE_PERIOD_MONTHS, ledgerIndex)
  const residualAmountField = genLedgerFieldName(DEPRECIATION_FIELDS.RESIDUAL_AMOUNT, ledgerIndex)
  const depreciationMethodCodeField = genLedgerFieldName(DEPRECIATION_FIELDS.DEPRECIATION_METHOD_CODE, ledgerIndex)
  const depreciationRateField = genLedgerFieldName(DEPRECIATION_FIELDS.DEPRECIATION_RATE, ledgerIndex)
  const guaranteeRateField = genLedgerFieldName(DEPRECIATION_FIELDS.GUARANTEE_RATE, ledgerIndex)
  const revisedDepreciationRateField = genLedgerFieldName(DEPRECIATION_FIELDS.REVISED_DEPRECIATION_RATE, ledgerIndex)
  const transitionBookValueField = genLedgerFieldName(DEPRECIATION_FIELDS.TRANSITION_BOOK_VALUE, ledgerIndex)
  const revisedAcquisitionAmountField = genLedgerFieldName(DEPRECIATION_FIELDS.REVISED_ACQUISITION_AMOUNT, ledgerIndex)
  const depreciatedMonthsField = genLedgerFieldName(DEPRECIATION_FIELDS.DEPRECIATED_MONTHS, ledgerIndex)
  const transitionAtField = genLedgerFieldName(DEPRECIATION_FIELDS.TRANSITION_AT, ledgerIndex)
  const accumulatedDepreciationAmount = genLedgerFieldName(
    DEPRECIATION_FIELDS.ACCUMULATED_DEPRECIATION_AMOUNT,
    ledgerIndex
  )
  const accumulatedImpairmentAmount = genLedgerFieldName(DEPRECIATION_FIELDS.ACCUMULATED_IMPAIRED_AMOUNT, ledgerIndex)
  const bookValue = genLedgerFieldName(DEPRECIATION_FIELDS.BOOK_VALUE, ledgerIndex)
  const memorandumValueField = genLedgerFieldName(DEPRECIATION_FIELDS.MEMORANDUM_VALUE, ledgerIndex)
  const retirementDepreciationEndedMethod = genLedgerFieldName(
    DEPRECIATION_FIELDS.RETIREMENT_DEPRECIATION_ENDED_METHOD,
    ledgerIndex
  )
  const gainField = genLedgerFieldName(DEPRECIATION_FIELDS.GAIN, ledgerIndex)
  const lossField = genLedgerFieldName(DEPRECIATION_FIELDS.LOSS, ledgerIndex)
  const isCopiedField = genLedgerFieldName(DEPRECIATION_FIELDS.IS_COPIED, ledgerIndex)
  const accumulatedExcessDepreciationValueField = genLedgerFieldName(
    DEPRECIATION_FIELDS.ACCUMULATED_OVER_DEPRECIATION_AMT,
    ledgerIndex
  )
  const isEnteredField = genLedgerFieldName(DEPRECIATION_FIELDS.IS_MEMORANDUM_VALUE_ENTERED, ledgerIndex)

  const ledgerId = getIn(values, ledgerIdField)
  const depreciationCode = getIn(values, depreciationMethodCodeField)
  const fixedAssetLedgers = getIn(values, LEDGERS_FIELD_NAME) as AssetLedger[]
  const transitionAt = getIn(values, transitionAtField)
  const usageStartedAt = getIn(values, 'usage_started_at')
  const hasTransition = getIn(values, 'has_transition')
  const acquisitionCostInput = get(values, 'acquisition_cost_input')
  const accumulatedImpairmentAmountValue = get(values, accumulatedImpairmentAmount)
  const accumulatedDepreciationAmountValue = get(values, accumulatedDepreciationAmount)
  const transitionBookValueFieldValue = get(values, transitionBookValueField)
  const memorandumValue = get(values, memorandumValueField)
  const isCopied = getIn(values, isCopiedField)
  const unitId = get(values, 'unit_id')
  const assetType = get(values, 'asset_type') || get(values, 'asset_category.asset_type')
  const linkMemorandumValueQuantity = units?.find(unit => unit.id === unitId)?.link_memorandum_value_quantity
  const serviceLifeValue = getIn(values, serviceLifeField)
  const serviceLifeAfterChangeValue = getIn(values, serviceLifeAfterChangeField)
  const [serviceLifeUpdatedManually, setServiceLifeUpdatedManually] = useBoolean(false)

  const fieldItems = [
    revisedAcquisitionAmountField,
    depreciationRateField,
    depreciatedMonthsField,
    accumulatedDepreciationAmount,
    accumulatedImpairmentAmount,
    bookValue,
    guaranteeRateField,
    memorandumValueField,
    residualAmountField,
    retirementDepreciationEndedMethod,
    revisedDepreciationRateField,
    usagePeriodMonthsField,
    lossField,
    gainField,
  ]

  const isSmallAmountDepreciationMethod = depreciationCode === DEPRECIATION_METHOD_CODE.SMALL_AMOUNT_DEPRECIABLE_ASSET
  const isEnableFullFields = ![
    DEPRECIATION_METHOD_CODE.NON_DEPRECIATION,
    DEPRECIATION_METHOD_CODE.NON_DEPRECIABLE,
  ].includes(depreciationCode)

  useEffect(() => {
    if (depreciationRateResponse.data) {
      const depreciationRateItem = depreciationRateResponse.data[0]
      setFieldValue(depreciationRateField, getIn(depreciationRateItem, DEPRECIATION_FIELDS.DEPRECIATION_RATE))
      setFieldValue(guaranteeRateField, getIn(depreciationRateItem, DEPRECIATION_FIELDS.GUARANTEE_RATE))
      setFieldValue(
        revisedDepreciationRateField,
        getIn(depreciationRateItem, DEPRECIATION_FIELDS.REVISED_DEPRECIATION_RATE)
      )
    }
  }, [depreciationRateResponse.data]) // eslint-disable-line

  useEffect(() => {
    if (!hasTransition) {
      setFieldValue(accumulatedExcessDepreciationValueField, null)
    }
  }, [values?.id, hasTransition]) // eslint-disable-line

  useEffect(() => {
    clearRateValues()
    const serviceLifeForCalc = serviceLifeAfterChangeValue || serviceLifeValue
    if (depreciationCode && serviceLifeForCalc) {
      getDepreciationRate({ depreciation_method: depreciationCode, service_life: serviceLifeForCalc })
    }
  }, [depreciationCode, serviceLifeValue, serviceLifeAfterChangeValue])

  useEffect(() => {
    fixedAssetLedgers.forEach((_, index) => {
      if (index > 0) {
        const otherTransitionAtField = genLedgerFieldName(DEPRECIATION_FIELDS.TRANSITION_AT, index)
        const isD10Method = depreciationCode === DEPRECIATION_METHOD_CODE.OLD_STRAIGHT_LINE

        setFieldValue(otherTransitionAtField, transitionAt)

        const transitionAtMoment = moment(transitionAt)
        const usageStartedAtMoment = moment(usageStartedAt)
        const depreciationMonthsValue = isD10Method
          ? 0
          : moment([transitionAtMoment.year(), transitionAtMoment.month()]).diff(
              moment([usageStartedAtMoment.year(), usageStartedAtMoment.month()]),
              'months'
            )
        setFieldValue(depreciatedMonthsField, depreciationMonthsValue < 0 ? 0 : depreciationMonthsValue)
      }
    })
    setFieldValue('transition_at', transitionAt)
  }, [transitionAt, depreciationCode, usageStartedAt]) // eslint-disable-line

  useEffect(() => {
    if (!hasTransition) {
      setFieldValue(transitionAtField, null)
    }
  }, [hasTransition])

  useEffect(() => {
    if (isSmallAmountDepreciationMethod) {
      fieldItems.forEach((fieldName: string) => {
        setFieldValue(fieldName, null)
      })
    }
  }, [isSmallAmountDepreciationMethod])

  useEffect(() => {
    if (!isEnableFullFields && transitionAt) {
      fieldItems.forEach((fieldName: string) => {
        let fieldValue = null

        if (isImportMode && depreciationCode === DEPRECIATION_METHOD_CODE.NON_DEPRECIABLE) {
          if (fieldName === accumulatedImpairmentAmount) {
            fieldValue = accumulatedImpairmentAmountValue
          }
          if (fieldName === accumulatedDepreciationAmount) {
            fieldValue = accumulatedDepreciationAmountValue
          }
        }

        setFieldValue(fieldName, fieldValue)
      })
    }
  }, [
    isEnableFullFields,
    transitionAt,
    accumulatedImpairmentAmount,
    accumulatedImpairmentAmountValue,
    accumulatedDepreciationAmount,
    accumulatedDepreciationAmountValue,
  ])

  useLedgerCopy(values, setFieldValue, ledgerIndex, isCopied)

  const { isEnable: isEnableForFixedAssetReduction } = useCheckFlag(FeatureFlagNames.FixedAssetReduction)
  useEffect(() => {
    dispatch(
      setShowMemorandumValueErrorMessage({
        ledgerId,
        isShowErrorMessage:
          (hasTransition &&
            transitionAt &&
            usageStartedAt &&
            checkIsAfterDate(transitionAt, usageStartedAt) &&
            memorandumValue > transitionBookValueFieldValue) ||
          memorandumValue > acquisitionCostInput ||
          (isEnableForFixedAssetReduction && bookValueRefer !== undefined && memorandumValue > bookValueRefer),
      })
    )
  }, [
    ledgerId,
    memorandumValue,
    bookValueRefer,
    transitionBookValueFieldValue,
    hasTransition,
    transitionAt,
    usageStartedAt,
    acquisitionCostInput,
    isEnableForFixedAssetReduction,
  ])

  const clearRateValues = () => {
    setFieldValue(depreciationRateField, null)
    setFieldValue(guaranteeRateField, null)
    setFieldValue(revisedDepreciationRateField, null)
  }

  const handleOnChangeDepreciationType = (depreciationCode?: number | string) => {
    const isNonDepreciableMethod = depreciationCode === DEPRECIATION_METHOD_CODE.NON_DEPRECIABLE

    switch (depreciationCode) {
      case DEPRECIATION_METHOD_CODE.AVERAGE_USAGE_PERIOD:
      case DEPRECIATION_METHOD_CODE.LEASE_PERIOD_STRAIGHT_LINE:
      case DEPRECIATION_METHOD_CODE.NON_DEPRECIATION:
      case DEPRECIATION_METHOD_CODE.NON_DEPRECIABLE:
        setFieldValue(serviceLifeField, null)
        setFieldValue(usagePeriodMonthsField, isNonDepreciableMethod ? null : 1)
        break
      case DEPRECIATION_METHOD_CODE.DEFERRED_EQUAL_INSTALLMENT:
        setFieldValue(serviceLifeField, 1)
        break
      case DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD:
        setFieldValue(serviceLifeField, 3)
        break
      case DEPRECIATION_METHOD_CODE.SMALL_AMOUNT_DEPRECIABLE_ASSET:
        setFieldValue(serviceLifeField, 0)
        break
      default:
        setFieldValue(serviceLifeField, 2)
    }

    resetDepreciatedMonths()
    setServiceLifeUpdatedManually()
  }

  const resetDepreciatedMonths = () => {
    setFieldValue(depreciatedMonthsField, 0)
  }

  const renderServiceLife = () => {
    const isBulkEqualInstallmentMethod = depreciationCode === DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD
    const isDisabledInput = disabled || isBulkEqualInstallmentMethod || isCopied
    const canEditServiceLife = isSmallAmountDepreciationMethod ? false : isDisabledInput
    const minServiceLifeByDmethod = depreciationCode === DEPRECIATION_METHOD_CODE.DEFERRED_EQUAL_INSTALLMENT ? 1 : 2
    const minServiceLife = isSmallAmountDepreciationMethod ? 0 : minServiceLifeByDmethod

    if (
      [DEPRECIATION_METHOD_CODE.AVERAGE_USAGE_PERIOD, DEPRECIATION_METHOD_CODE.LEASE_PERIOD_STRAIGHT_LINE].includes(
        depreciationCode
      )
    )
      return null

    return (
      <Item
        label={
          <ItemLabelTooltip
            showTooltip={isSmallAmountDepreciationMethod}
            label={t('service_life')}
            tooltip={parser(t('small_amount_servicelife_tooltip_content'))}
          />
        }
        required={!isSmallAmountDepreciationMethod}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <NumberInput
            name={serviceLifeField}
            defaultValue={isSmallAmountDepreciationMethod ? 0 : 2}
            min={minServiceLife}
            max={100}
            maxLength={3}
            disabled={canEditServiceLife}
            style={{ width: 60, marginRight: 5 }}
            isShowError={false}
            onChange={setServiceLifeUpdatedManually}
          />
          <label>{t('units.year')}</label>
        </div>
        <Error>{getIn(errors, serviceLifeField)}</Error>
      </Item>
    )
  }

  const renderTransitionAt = () => {
    const accountingPeriodDate = get(values, 'min_acquired_at')
    const acquiredAt = get(values, 'acquired_at')
    const isAccountingPeriodDateAfterAcquiredAt = moment(accountingPeriodDate).isAfter(acquiredAt)
    const isValidDate = isAccountingPeriodDateAfterAcquiredAt ? 'min_acquired_at' : 'acquired_at'

    return (
      <Item
        label={<ItemLabelTooltip label={t('transition_at')} tooltip={parser(t('transition_at_tooltip'))} />}
        required
        jsLabel={t('transition_at_of', { name: ledgerName })}
      >
        <DatePicker
          name={transitionAtField}
          disabled={isEditMode || isDisabledTransitionAt}
          minDateField={isValidDate}
          className={classNames({ error: showRequiredError && !get(values, transitionAtField) })}
          afterChanged={onAfterChangedTransitionAt}
        />
        {showRequiredError && !get(values, transitionAtField) && <Error>{t('transition_at_required_error')}</Error>}
      </Item>
    )
  }

  const renderDepreciationRate = () => {
    if (
      [
        DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD,
        DEPRECIATION_METHOD_CODE.AVERAGE_USAGE_PERIOD,
        DEPRECIATION_METHOD_CODE.LEASE_PERIOD_STRAIGHT_LINE,
        DEPRECIATION_METHOD_CODE.SMALL_AMOUNT_DEPRECIABLE_ASSET,
        DEPRECIATION_METHOD_CODE.DEFERRED_EQUAL_INSTALLMENT,
      ].includes(depreciationCode)
    )
      return null

    return (
      <Item label={t('depreciation_rate')}>
        <UnderlineInput name={depreciationRateField} placeholder={t('depreciation_rate_placeholder')} />
      </Item>
    )
  }

  const renderNumberOfAvgUsagePeriod = () => {
    if (
      ![DEPRECIATION_METHOD_CODE.AVERAGE_USAGE_PERIOD, DEPRECIATION_METHOD_CODE.LEASE_PERIOD_STRAIGHT_LINE].includes(
        depreciationCode
      )
    )
      return null

    return (
      <Item label={t('usage_period_months')} required>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <NumberInput
            name={usagePeriodMonthsField}
            min={1}
            defaultValue={1}
            maxLength={3}
            style={{ width: 60, marginRight: 5 }}
            isShowError={false}
            disabled={disabled || isCopied}
          />
          <label>{t('units.month_amount')}</label>
        </div>
        <Error>{getIn(errors, usagePeriodMonthsField)}</Error>
      </Item>
    )
  }

  const renderResidualValueEndOfUsage = () => {
    if (
      ![DEPRECIATION_METHOD_CODE.AVERAGE_USAGE_PERIOD, DEPRECIATION_METHOD_CODE.LEASE_PERIOD_STRAIGHT_LINE].includes(
        depreciationCode
      )
    )
      return null

    return (
      <Item
        label={t('residual_amount')}
        required
        style={prelineStyle}
        jsLabel={t('residual_amount_of', { name: ledgerName })}
      >
        <MoneyInput name={residualAmountField} style={{ textAlign: 'right' }} disabled={disabled || isCopied} />
      </Item>
    )
  }

  const renderAccumulatedExcessDepreciation = () => {
    const ledgerPurpose = ledgerSettingList?.[ledgerIndex]?.ledger_purpose
    const ledgerPurposeList = ledgerSettingList?.map(ledgetSetting => ledgetSetting?.ledger_purpose)
    if (
      isSmallAmountDepreciationMethod ||
      !hasTransition ||
      !ledgerPurposeList?.includes(LEDGER_PURPOSE.ACCOUNTING) ||
      ledgerPurpose !== LEDGER_PURPOSE.TAX
    )
      return
    const isError = get(values, accumulatedExcessDepreciationValueField) > acquisitionCostInput

    return (
      <Item label={t('accumulated_excess_depreciation')}>
        <MoneyInput
          name={accumulatedExcessDepreciationValueField}
          disabled={disabled}
          className={classNames({
            error: isError,
          })}
        />
        {isError && <Error>{t('accumulated_excess_depreciation_value_error')}</Error>}
      </Item>
    )
  }

  const renderMemorandumValue = () => {
    if (DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD === depreciationCode) {
      return (
        <Item label={t('memorandum_value')}>
          <LocalUnderlineInput
            value={0}
            placeholder=""
            hasManualValue={false}
            maxValue={1000000000000}
            editable={false}
          />
        </Item>
      )
    }
    return null
  }

  const canDisableTaxMemorandumField = useCallback(() => {
    // Prepare data by merge fixed_asset_ledgers with ledger_settings data
    const ledgerMergeSetting = fixedAssetLedgers?.map(faLedger => ({
      ...faLedger,
      ...ledgerSettingList?.find(ledgerSetting => ledgerSetting?.id === faLedger?.ledger_setting_id),
    }))
    const accountantDmethod =
      ledgerMergeSetting?.find(ledger => ledger?.ledger_purpose === LEDGER_PURPOSE.ACCOUNTING)
        ?.depreciation_method_code || 0
    const taxDmethod =
      ledgerMergeSetting?.find(ledger => ledger?.ledger_purpose === LEDGER_PURPOSE.TAX)?.depreciation_method_code || 0
    // Check 2 Dmethod belong to 5 Dmethods that allow input memorandum value
    return checkShowMemorandumValue(accountantDmethod) && checkShowMemorandumValue(taxDmethod)
  }, [fixedAssetLedgers, ledgerSettingList])

  const renderMemorandumValueWithSomeDMethods = () => {
    const isShowMemorandumValue = checkShowMemorandumValue(depreciationCode)
    const isAccountantLedger = isAccountingJournalTarget(ledgerSettingList?.[ledgerIndex])
    const hasImpairmentEvent = !!get(values, 'impaired_at') || !!accumulatedImpairmentAmountValue
    const isUnitUnlink = !get(values, 'unit.link_memorandum_value_quantity')

    const isShowMemorandumValueErrorMessage =
      showMemorandumValueErrorMessage && showMemorandumValueErrorMessage[ledgerId]
    const isTaxLedger = isTaxJournalTarget(ledgerSettingList?.[ledgerIndex])
    const getErrorMessage = () => {
      if (formMode === FORM_MODES.EDIT) {
        return t('memorandum_value_error_message_edit')
      }
      if ([FORM_MODES.NEW, FORM_MODES.COPY, FORM_MODES.IMPORT, FORM_MODES.CORRECTION].includes(formMode)) {
        return hasTransition ? t('memorandum_value_error_message_transaction') : t('memorandum_value_error_message_new')
      }
    }

    return isShowMemorandumValue ? (
      <Item label={t('memorandum_value')}>
        {linkMemorandumValueQuantity ? (
          <LocalUnderlineInput
            value={get(values, memorandumValueField)}
            placeholder=""
            hasManualValue={false}
            maxValue={1000000000000}
            editable={false}
            labelClassName={isShowMemorandumValueErrorMessage ? 'error' : undefined}
          />
        ) : (
          <MoneyInput
            name={memorandumValueField}
            style={{ textAlign: 'right' }}
            className={classNames({ error: isShowMemorandumValueErrorMessage })}
            disabled={
              (isTaxLedger && canDisableTaxMemorandumField()) ||
              isCopied ||
              (hasImpairmentEvent &&
                isAccountantLedger &&
                ((formMode === FORM_MODES.EDIT && !isUnitUnlink) || isUnitUnlink) &&
                formMode === FORM_MODES.EDIT)
            }
            defaultValue={
              assetType === ASSET_TYPE.TANGIBLE
                ? DEFAULT_MEMORANDUM_VALUE.TANGIBLE
                : DEFAULT_MEMORANDUM_VALUE.INTANGIBLE
            }
            onChange={() => setFieldValue(isEnteredField, true)}
          />
        )}
        {isShowMemorandumValueErrorMessage && <Error>{getErrorMessage()}</Error>}
      </Item>
    ) : null
  }

  const filteredDepreciationMethods = useMemo(
    () => masterData?.depreciation_method || [],
    [masterData?.depreciation_method]
  )

  const renderAccumulatedDepreciationAmount = () => {
    if (!hasTransition) return
    if ([FORM_MODES.NEW, FORM_MODES.COPY].includes(formMode)) return
    return (
      <Item label={t('accumulated_depreciation_amount')}>
        <UnderlineMoneyInput style={{ textAlign: 'right' }} name={accumulatedDepreciationAmount} />
      </Item>
    )
  }

  const renderAccumulatedImpairmentAmount = () => {
    const ledgerPurpose = ledgerSettingList?.[ledgerIndex]?.ledger_purpose
    if (!hasTransition) return
    if ([FORM_MODES.NEW, FORM_MODES.COPY].includes(formMode)) return
    if (![LEDGER_PURPOSE.ACCOUNTING, LEDGER_PURPOSE.TAX_AND_ACCOUNTING].includes(ledgerPurpose)) return
    return (
      <Item label={t('accumulated_impairment_amount')}>
        <UnderlineMoneyInput style={{ textAlign: 'right' }} name={accumulatedImpairmentAmount} />
      </Item>
    )
  }

  return (
    <div>
      <LedgerHeader>
        <TitleName>{ledgerName || ledgerSettingList?.[ledgerIndex]?.name}</TitleName>
        {ledgerIndex !== 0 && (
          <Checkbox name={isCopiedField} style={{ color: '#7C8291', fontSize: 13 }} disabled={isEditMode}>
            {t('copy_content_ledger_head', {
              ledger_name: ledgerSettingList?.[0]?.name || '',
            })}
          </Checkbox>
        )}
      </LedgerHeader>
      <Item label={t('depreciation_type')} required jsLabel={t('ledger_of', { name: ledgerName })}>
        <AutoCompleteSelect
          name={depreciationMethodCodeField}
          items={filteredDepreciationMethods}
          afterChanged={handleOnChangeDepreciationType}
          valueField="code"
          textField="name_jp"
          disabled={disabled || isCopied}
          allowClear={false}
          className={classNames({ error: showRequiredError && !get(values, depreciationMethodCodeField) })}
        />
        {showRequiredError && !get(values, depreciationMethodCodeField) && (
          <Error>{t('depreciation_type_required_error')}</Error>
        )}
      </Item>
      <If condition={isEnableFullFields}>
        <>
          {renderServiceLife()}
          {renderNumberOfAvgUsagePeriod()}
          <ServiceLifeAfterChange
            fieldName={serviceLifeAfterChangeField}
            enabled={
              !disabled &&
              !isCopied &&
              !isEmptyValue(serviceLifeValue) &&
              !transitionAtSameAsStartUsage(transitionAt, usageStartedAt)
            }
            isShown={shouldRenderAtChangeFields(hasTransition, depreciationCode)}
            referValue={serviceLifeValue}
            isCopied={Boolean(isCopied)}
            isUpdateReferManually={bookValueReferUpdatedManually || serviceLifeUpdatedManually}
            forceResetToRefer={transitionAtSameAsStartUsage(transitionAt, usageStartedAt)}
          />
          {hasTransition && renderTransitionAt()}
          <BookValueAtChange
            fieldName={bookValueAtChangeField}
            enabled={
              !disabled &&
              !isCopied &&
              !isEmptyValue(bookValueRefer) &&
              !transitionAtSameAsStartUsage(transitionAt, usageStartedAt)
            }
            isShown={shouldRenderBookValueAtChange(hasTransition, depreciationCode, transitionAt)}
            referValue={bookValueRefer}
            isUpdateReferManually={bookValueReferUpdatedManually || serviceLifeUpdatedManually}
            forceResetToRefer={transitionAtSameAsStartUsage(transitionAt, usageStartedAt)}
          />
        </>
      </If>
      <TransitionBookValues
        usageStartedAt={usageStartedAt}
        bookValueMax={bookValueRefer}
        ledgerIndex={ledgerIndex}
        ledgerName={ledgerName}
        disabled={disabled}
        isCopied={isCopied}
        isTransitionDayIsFirstDayOfTerm={isTransitionDayIsFirstDayOfTerm}
        isImportMode={isImportMode}
      />
      <BeginningBookValue
        ledgerIndex={ledgerIndex}
        hasTransition={hasTransition}
        depreciationCode={depreciationCode}
        referValue={acquisitionCostValueRefer}
      />
      <If condition={isEnableFullFields}>
        <>
          {renderResidualValueEndOfUsage()}
          {renderAccumulatedExcessDepreciation()}
          {renderMemorandumValue()}
          {renderDepreciationRate()}
          {renderAccumulatedDepreciationAmount()}
          {renderAccumulatedImpairmentAmount()}
          {!!getIn(values, guaranteeRateField) && (
            <Item label={t('guarantee_rate')}>
              <UnderlineInput name={guaranteeRateField} placeholder={t('guarantee_rate_placeholder')} />
            </Item>
          )}
          {!!getIn(values, revisedDepreciationRateField) && (
            <Item label={t('revised_depreciation_rate')}>
              <UnderlineInput
                name={revisedDepreciationRateField}
                placeholder={t('revised_depreciation_rate_placeholder')}
              />
            </Item>
          )}
          {renderMemorandumValueWithSomeDMethods()}
        </>
      </If>
      <If condition={!isEnableFullFields}>
        <>
          {hasTransition && renderTransitionAt()}
          {depreciationCode === DEPRECIATION_METHOD_CODE.NON_DEPRECIABLE && renderAccumulatedImpairmentAmount()}
        </>
      </If>
    </div>
  )
}

export default DepreciationLedge
