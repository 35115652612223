import isEqual from 'lodash/isEqual'
import moment from 'moment'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/selectors'

import { hasNegativeAcceleratedValue } from '../helpers/has-negative-accelerated-value'
import { AcceleratedDepreciationItem } from '../types'

export const useDisableRegisterButton = (
  depreciationList: AcceleratedDepreciationItem[],
  isSubmitting: boolean,
  selectedDate?: string
) => {
  const currentMonthlyClosing = useSelector(selectMonthlyClosingCurrent, isEqual)
  const startCurrentTerm = currentMonthlyClosing?.to_term_month?.term?.start_date

  return useMemo(() => {
    return (
      !selectedDate ||
      isSubmitting ||
      !depreciationList?.length ||
      !depreciationList.find(item => item.is_adjustable) ||
      hasNegativeAcceleratedValue(depreciationList) ||
      moment(selectedDate).isBefore(startCurrentTerm)
    )
  }, [depreciationList, isSubmitting, selectedDate, startCurrentTerm])
}
