import { If, UnborderCollapse, UnderlineMoneyInput } from 'aa_common/front-end/components'
import { ifElse } from 'aa_common/front-end/helpers'
import classNames from 'classnames'
import { loadNs } from 'common/i18n-config'
import { FORM_MODES } from 'common/models'
import { MoneyInput } from 'components/molecules'
import { useFormikContext } from 'formik'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getExciseSettingsByDateList } from 'store/settings/excise/selectors'

import { FORM_FIELDS } from '../const'
import { getExciseInputSetting, getFormMode } from '../helper'
import { useAcquisitionChanged } from '../hooks/use-acquisition-changed'
import { useExciseSettingUpdated } from '../hooks/use-excise-setting-updated'
import { Error } from '../styles'
import { ExciseInputs } from './ExciseInputs'
import { ReductionAmount } from './ReductionAmount'

const { Item } = UnborderCollapse

const t = loadNs(['components/organisms/assets/asset-form', 'common'])

type AcquisitionCostInputsProps = {
  formMode: FORM_MODES
  loadingAssetCategory: boolean
  showRequiredError?: boolean
  acquisitionCostValueRefer?: number
  setBookValueReferUpdatedManually: () => void
}

export const AcquisitionCostSection = ({
  formMode,
  loadingAssetCategory,
  showRequiredError,
  acquisitionCostValueRefer,
  setBookValueReferUpdatedManually,
}: AcquisitionCostInputsProps) => {
  const { data: exciseSettings } = useSelector(getExciseSettingsByDateList, isEqual)
  const { isExternalTax, isInternalTax, isShowTax } = getExciseInputSetting(exciseSettings)

  const { values, setFieldValue } = useFormikContext()

  const acquisitionCost = get(values, 'acquisition_cost')
  const acquisitionCostExcludeExcise = get(values, 'acquisition_cost_excluded_excise')
  const acquisitionCostInput = get(values, 'acquisition_cost_input')

  const { isEditMode } = getFormMode(formMode)

  const inputLabel = useMemo(() => {
    return ifElse(
      isExternalTax,
      t('acquisition_cost_excluded_excise'),
      ifElse(isInternalTax, t('acquisition_cost_included_excise'), t('acquisition_cost'))
    )
  }, [isExternalTax, isInternalTax])

  useExciseSettingUpdated()
  useEffect(() => {
    setFieldValue(
      FORM_FIELDS.CALCULATED_ACQUISITION_COST,
      ifElse(isInternalTax, acquisitionCostExcludeExcise, acquisitionCost)
    )
  }, [isInternalTax, acquisitionCost, acquisitionCostExcludeExcise])

  const handleAcquisitionCostChanged = useAcquisitionChanged()
  const onAcquisitionCostChanged = useCallback(
    (value?: number) => {
      handleAcquisitionCostChanged(value)
      setBookValueReferUpdatedManually()
    },
    [handleAcquisitionCostChanged, setBookValueReferUpdatedManually]
  )

  return (
    <>
      <Item label={inputLabel} required>
        <MoneyInput
          name="acquisition_cost_input"
          disabled={isEditMode}
          onChange={onAcquisitionCostChanged}
          style={{ textAlign: 'right' }}
          className={classNames({ error: showRequiredError && !acquisitionCostInput })}
        />
        <If condition={showRequiredError && !acquisitionCostInput}>
          <Error>{t('acquisition_cost_required_error')}</Error>
        </If>
      </Item>
      <ExciseInputs
        formMode={formMode}
        showRequiredError={showRequiredError}
        loadingAssetCategory={loadingAssetCategory}
        setBookValueReferUpdatedManually={setBookValueReferUpdatedManually}
      />
      <If condition={isShowTax}>
        <Item
          label={ifElse(isExternalTax, t('acquisition_cost_included_excise'), t('acquisition_cost_excluded_excise'))}
        >
          <UnderlineMoneyInput
            style={{ textAlign: 'right' }}
            name={FORM_FIELDS.CALCULATED_ACQUISITION_COST}
            placeholder={`${t('acquisition_cost_included_excise')} ${t('included_tax')}`}
          />
        </Item>
      </If>
      <ReductionAmount
        formMode={formMode}
        setBookValueReferUpdatedManually={setBookValueReferUpdatedManually}
        acquisitionCostValueRefer={acquisitionCostValueRefer}
      />
    </>
  )
}
