import { If, UnborderCollapse } from 'aa_common/front-end/components'
import { ifElse, someTrue } from 'aa_common/front-end/helpers'
import { DEPRECIATION_METHOD_CODE } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import { NumberInput } from 'components/atoms'
import { getIn, useFormikContext } from 'formik'
import parser from 'html-react-parser'
import React from 'react'

import { DEPRECIATION_FIELDS } from '../const'
import { genLedgerFieldName } from '../helper'
import { Error } from '../styles'
import { ItemLabelTooltip } from './ItemLabelTooltip'

const { Item } = UnborderCollapse
const t = loadNs(['components/organisms/assets/asset-form', 'common'])

type ServiceLifeProps = {
  ledgerIndex: number
  disabled: boolean
  onServiceLifeChanged: () => void
}

export const ServiceLife = ({ ledgerIndex, disabled, onServiceLifeChanged }: ServiceLifeProps) => {
  const { values, errors } = useFormikContext()

  const depreciationMethodCodeField = genLedgerFieldName(DEPRECIATION_FIELDS.DEPRECIATION_METHOD_CODE, ledgerIndex)
  const isCopiedField = genLedgerFieldName(DEPRECIATION_FIELDS.IS_COPIED, ledgerIndex)
  const serviceLifeField = genLedgerFieldName(DEPRECIATION_FIELDS.SERVICE_LIFE, ledgerIndex)

  const depreciationCode = getIn(values, depreciationMethodCodeField)
  const isCopied = getIn(values, isCopiedField)

  const isSmallAmountDepreciationMethod = depreciationCode === DEPRECIATION_METHOD_CODE.SMALL_AMOUNT_DEPRECIABLE_ASSET
  const isBulkEqualInstallmentMethod = depreciationCode === DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD
  const isDisabledInput = someTrue(disabled, isCopied, isBulkEqualInstallmentMethod)
  const canEditServiceLife = ifElse(isSmallAmountDepreciationMethod, false, isDisabledInput)
  const minServiceLifeByDMethod = ifElse(depreciationCode === DEPRECIATION_METHOD_CODE.DEFERRED_EQUAL_INSTALLMENT, 1, 2)
  const minServiceLife = ifElse(isSmallAmountDepreciationMethod, 0, minServiceLifeByDMethod)

  const shouldRender = ![
    DEPRECIATION_METHOD_CODE.AVERAGE_USAGE_PERIOD,
    DEPRECIATION_METHOD_CODE.LEASE_PERIOD_STRAIGHT_LINE,
  ].includes(depreciationCode)

  return (
    <If condition={shouldRender}>
      <Item
        label={
          <ItemLabelTooltip
            showTooltip={isSmallAmountDepreciationMethod}
            label={t('service_life')}
            tooltip={parser(t('small_amount_servicelife_tooltip_content'))}
          />
        }
        required={!isSmallAmountDepreciationMethod}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <NumberInput
            name={serviceLifeField}
            defaultValue={ifElse(isSmallAmountDepreciationMethod, 0, 2)}
            min={minServiceLife}
            max={100}
            maxLength={3}
            disabled={canEditServiceLife}
            style={{ width: 60, marginRight: 5 }}
            isShowError={false}
            onChange={onServiceLifeChanged}
          />
          <label>{t('units.year')}</label>
        </div>
        <Error>{getIn(errors, serviceLifeField)}</Error>
      </Item>
    </If>
  )
}
