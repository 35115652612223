import { Spin } from 'aa_common/front-end/antd'
import { If } from 'aa_common/front-end/components'
import { defaultTo } from 'aa_common/front-end/helpers'
import { Layout } from 'antd'
import { Asset, OperationLog } from 'common/models'
import isEqual from 'lodash/isEqual'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectOperationLogItem } from 'store/internalControl/operationLog/selectors'

import EventHistory from './components/EventHistory'
import { Header } from './components/header'
import Information from './components/Information'
import { TopContent } from './components/top-content'
import { OperationLogTarget, UpdateChangeMode } from './constant'
import { useFetchOperationLogItem } from './hooks/use-fetch-operation-log-item'
import { BodyContent, LogListItemDetailWrapper, Wrapper } from './styles'

interface LogListItemDetailProps {
  logItem: OperationLog | null
  onModalClose: () => void
}

const LogListItemDetail = ({ logItem, onModalClose }: LogListItemDetailProps) => {
  const [viewUpdateMode, setViewUpdateMode] = useState<UpdateChangeMode>(UpdateChangeMode.BEFORE)
  useFetchOperationLogItem(logItem, setViewUpdateMode)

  const operationLogItemSelector = useSelector(selectOperationLogItem, isEqual)
  const isFixedAssetMode = logItem?.target === OperationLogTarget.FIXED_ASSET
  const isEventOfFixedAssetMode = logItem?.target === OperationLogTarget.EVENT_OF_FIXED_ASSET
  const fixedAsset = useMemo<Asset>(
    () => defaultTo(operationLogItemSelector.data, null as any),
    [operationLogItemSelector.data]
  )

  return (
    <If condition={logItem}>
      <LogListItemDetailWrapper>
        <Spin loading={operationLogItemSelector.isLoading}>
          <Header onModalClose={onModalClose} logItem={logItem!} />
          <Layout>
            <Layout.Content>
              <Wrapper className="content">
                <TopContent
                  logItem={logItem!}
                  fixedAsset={fixedAsset}
                  viewUpdateMode={viewUpdateMode}
                  setViewUpdateMode={setViewUpdateMode}
                />
                <BodyContent>
                  <If condition={isFixedAssetMode}>
                    <div className="section">
                      <Information data={fixedAsset} />
                    </div>
                  </If>
                  <If condition={isEventOfFixedAssetMode}>
                    <div className="section">
                      <EventHistory item={fixedAsset} />
                    </div>
                  </If>
                </BodyContent>
              </Wrapper>
            </Layout.Content>
          </Layout>
        </Spin>
      </LogListItemDetailWrapper>
    </If>
  )
}

export default LogListItemDetail
