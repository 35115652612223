import classNames from 'classnames'
import { AssetDisplayDataRow } from 'common/components/report-row/styles'
import { ASSET_CHANGE_SITUATION_TYPES } from 'common/constants/masterData'
import { loadNs } from 'common/i18n-config'
import { Asset } from 'common/models'
import get from 'lodash/get'
import React from 'react'

import { renderAttachmentList, renderPhotoList, renderUrlList } from '../helpers'
import { StyledMediaWrapper } from '../styles'

const t = loadNs(['components/organisms/accounting/monthly-closing-asset-event-list'])
export const Media = ({ asset }: Props) => {
  const changeSituationCode = get(asset, 'change_situation.code')
  const isDividingEvent = changeSituationCode === ASSET_CHANGE_SITUATION_TYPES.DIVIDING

  return (
    <StyledMediaWrapper>
      <AssetDisplayDataRow>
        <div className="label">{t('photo')}</div>
        <div className="value">{renderPhotoList(asset?.photos || asset?.event_photos)}</div>
      </AssetDisplayDataRow>
      <AssetDisplayDataRow>
        <div className="label">{t('attachment')}</div>
        <div className={classNames('value', { attachment_list: isDividingEvent })} style={{ display: 'block' }}>
          {renderAttachmentList(asset?.attachments || asset?.event_attachments || [], isDividingEvent)}
        </div>
      </AssetDisplayDataRow>
      <AssetDisplayDataRow>
        <div className="label">{t('reference_link')}</div>
        <div className={classNames('value', { url_list: isDividingEvent })} style={{ display: 'block' }}>
          {renderUrlList(asset?.urls || asset?.event_urls || [], isDividingEvent)}
        </div>
      </AssetDisplayDataRow>
    </StyledMediaWrapper>
  )
}

type Props = {
  asset: Asset
}
