import { everyTrue } from 'aa_common/front-end'
import { If } from 'aa_common/front-end/components'
import { getNs } from 'common/i18n-config'
import React from 'react'

import { yen } from '../../helpers'
import { FeatureFlagNames } from '../../open-features'
import { useCheckFlag } from '../../open-features/hooks'
import { Row } from '../report-row'
import { useGetSubsidyReduction } from './hooks'

const { t, useNsReady } = getNs(['components/organisms/assets/asset-detail'])

export const ReportAssetReduction = ({ data }: Props) => {
  useNsReady()
  const { isEnable: isEnableForReduction } = useCheckFlag(FeatureFlagNames.FixedAssetReduction)

  const { subsidyAmount, reductionAmount, acquisitionCost } = useGetSubsidyReduction(data)

  return (
    <If condition={everyTrue(!!isEnableForReduction, subsidyAmount, reductionAmount)}>
      <Row label={t('subsidy_amount')} value={yen(subsidyAmount)} />
      <Row label={t('net_acquisition_cost')} value={yen(acquisitionCost - subsidyAmount)} />
      <Row label={t('reduction_amount')} value={yen(reductionAmount)} />
      <Row label={t('reduced_acquisition_cost')} value={yen(acquisitionCost - reductionAmount)} />
    </If>
  )
}

type Props = {
  data: any
}
