import { If, UnborderCollapse, UnderlineMoneyInput } from 'aa_common/front-end/components'
import { everyTrue } from 'aa_common/front-end/helpers'
import { DEPRECIATION_METHOD_CODE, LEDGER_PURPOSE } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import { FORM_MODES } from 'common/models'
import { getIn, useFormikContext } from 'formik'
import isEqual from 'lodash/isEqual'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectLedgerSettingList } from 'store/settings/ledgerSetting/selectors'

import { DEPRECIATION_FIELDS } from '../const'
import { genLedgerFieldName } from '../helper'
import { useClearValueOnHide } from '../hooks/useClearOnHide'

const { Item } = UnborderCollapse

const t = loadNs(['components/organisms/assets/asset-form', 'common'])

type AccumulatedImpairmentAmountProps = {
  ledgerIndex: number
  formMode: FORM_MODES
}

export const AccumulatedImpairmentAmount = ({ ledgerIndex, formMode }: AccumulatedImpairmentAmountProps) => {
  const { values, setFieldValue } = useFormikContext()

  const accumulatedImpairmentAmountField = genLedgerFieldName(
    DEPRECIATION_FIELDS.ACCUMULATED_IMPAIRED_AMOUNT,
    ledgerIndex
  )
  const depreciationMethodCodeField = genLedgerFieldName(DEPRECIATION_FIELDS.DEPRECIATION_METHOD_CODE, ledgerIndex)

  const depreciationCode = getIn(values, depreciationMethodCodeField)
  const ledgerSettingList = useSelector(selectLedgerSettingList, isEqual)
  const ledgerPurpose = ledgerSettingList?.[ledgerIndex]?.ledger_purpose
  const hasTransition = getIn(values, 'has_transition')

  const shouldRender = everyTrue(
    hasTransition,
    ![DEPRECIATION_METHOD_CODE.NON_DEPRECIATION, DEPRECIATION_METHOD_CODE.SMALL_AMOUNT_DEPRECIABLE_ASSET].includes(
      depreciationCode
    ),
    ![FORM_MODES.NEW, FORM_MODES.COPY].includes(formMode),
    [LEDGER_PURPOSE.ACCOUNTING, LEDGER_PURPOSE.TAX_AND_ACCOUNTING].includes(ledgerPurpose)
  )

  useClearValueOnHide(accumulatedImpairmentAmountField, setFieldValue, shouldRender)

  return (
    <If condition={shouldRender}>
      <Item label={t('accumulated_impairment_amount')}>
        <UnderlineMoneyInput style={{ textAlign: 'right' }} name={accumulatedImpairmentAmountField} />
      </Item>
    </If>
  )
}
