/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PresenterAWebNotification,
  PresenterAWebNotificationListResponse,
  PresenterCreateAWebNotificationRequest,
  PresenterError,
  PresenterIDResponse,
  PresenterUpdateAWebNotificationRequest,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class AWebNotification<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description Get notification list
   *
   * @tags AWeb-Notification
   * @name NotificationsList
   * @summary Get the notification list
   * @request GET:/admin/notifications
   */
  notificationsList = (
    query?: {
      'page[number]'?: number
      'page[size]'?: number
      sort?: string[]
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterAWebNotificationListResponse[], any>({
      path: `/admin/notifications`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Create the notification
   *
   * @tags AWeb-Notification
   * @name NotificationsCreate
   * @summary Create the notification
   * @request POST:/admin/notifications
   */
  notificationsCreate = (body: PresenterCreateAWebNotificationRequest, params: RequestParams = {}) =>
    this.http.request<PresenterAWebNotification, any>({
      path: `/admin/notifications`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Update nottification
   *
   * @tags AWeb-Notification
   * @name NotificationsUpdate
   * @summary Update the nottification
   * @request PUT:/admin/notifications/{id}
   */
  notificationsUpdate = (id: number, body: PresenterUpdateAWebNotificationRequest, params: RequestParams = {}) =>
    this.http.request<PresenterAWebNotification, any>({
      path: `/admin/notifications/${id}`,
      method: 'PUT',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Delete Notification with id
   *
   * @tags AWeb-Notification
   * @name NotificationsDelete
   * @summary Delete the Notification
   * @request DELETE:/admin/notifications/{id}
   */
  notificationsDelete = (id: number, params: RequestParams = {}) =>
    this.http.request<PresenterIDResponse, PresenterError>({
      path: `/admin/notifications/${id}`,
      method: 'DELETE',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Get notification list for aa
   *
   * @tags AWeb-Notification
   * @name NotificationsList2
   * @summary Get the notification list for aa
   * @request GET:/notifications
   * @originalName notificationsList
   * @duplicate
   */
  notificationsList2 = (
    query?: {
      'page[number]'?: number
      'page[size]'?: number
      sort?: string[]
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterAWebNotification[][], any>({
      path: `/notifications`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
