import { If, UnborderCollapse, UnderlineMoneyInput } from 'aa_common/front-end/components'
import { everyTrue, ifElse, someTrue } from 'aa_common/front-end/helpers'
import classNames from 'classnames'
import { EXCISE_CAN_EDIT_TAX } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import { FORM_MODES } from 'common/models'
import { ExciseSelect } from 'components/organisms'
import { useFormikContext } from 'formik'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getExciseSettingsByDateList } from 'store/settings/excise/selectors'

import { FORM_FIELDS } from '../const'
import { getExciseInputSetting, getFormMode } from '../helper'
import { setExciseValue as setExciseInputValue } from '../helpers/excise-value'
import { setAcquisitionCost } from '../helpers/set-acquisition-cost'
import { useCanResetExciseInput } from '../hooks/use-can-reset-excise-input'
import { Error } from '../styles'

const { Item } = UnborderCollapse

const t = loadNs(['components/organisms/assets/asset-form', 'common'])

type ExciseInputsProps = {
  formMode: FORM_MODES
  loadingAssetCategory: boolean
  showRequiredError?: boolean
  setBookValueReferUpdatedManually: () => void
}
export const ExciseInputs = ({
  formMode,
  loadingAssetCategory,
  showRequiredError,
  setBookValueReferUpdatedManually,
}: ExciseInputsProps) => {
  const { values, errors, setFieldValue } = useFormikContext()
  const acquiredAt = get(values, 'acquired_at')
  const exciseId = get(values, 'excise_id')
  const acquisitionCostInput = get(values, 'acquisition_cost_input')
  const manualExciseValue = get(values, 'manual_excise_value')
  const calculatedExciseValue = get(values, 'calculated_excise_value')
  const canResetManualExciseValue = useCanResetExciseInput(manualExciseValue, calculatedExciseValue)

  const { data: exciseSettings } = useSelector(getExciseSettingsByDateList, isEqual)
  const { isInputTaxWhenRegister, isTaxMethodExcluded, isInternalTax, isShowTax } =
    getExciseInputSetting(exciseSettings)
  const isCanEditExciseInputByManual = exciseSettings?.can_edit_tax === EXCISE_CAN_EDIT_TAX.YES

  const { isEditMode } = getFormMode(formMode)

  useEffect(() => {
    setExciseInputValue(setFieldValue, manualExciseValue, calculatedExciseValue)
  }, [setFieldValue, manualExciseValue, calculatedExciseValue])

  const onExciseInputChanged = useCallback(
    (newValue: number) => {
      setFieldValue('manual_excise_value', newValue)
      setBookValueReferUpdatedManually()
      setAcquisitionCost(
        acquisitionCostInput,
        newValue,
        { isInputTaxWhenRegister, isTaxMethodExcluded, isInternalTax },
        setFieldValue
      )
    },
    [
      setBookValueReferUpdatedManually,
      setFieldValue,
      acquisitionCostInput,
      isInputTaxWhenRegister,
      isTaxMethodExcluded,
      isInternalTax,
    ]
  )
  const handleResetManualExciseValue = useCallback(() => {
    setFieldValue('manual_excise_value', undefined)
  }, [setFieldValue])

  return (
    <If condition={isInputTaxWhenRegister}>
      <Item label={t('excise')} required>
        <ExciseSelect
          style={{
            width: '236px',
          }}
          name="excise_id"
          isDisabled={someTrue(!acquiredAt, isEditMode, loadingAssetCategory)}
          afterChanged={setBookValueReferUpdatedManually}
          className={classNames({ error: showRequiredError && !exciseId })}
        />
        <If condition={showRequiredError && !exciseId}>
          <Error>{t('excise_required_error')}</Error>
        </If>
        <Error>
          <label>{get(errors, 'excise_id')}</label>
        </Error>
      </Item>
      <If condition={isShowTax}>
        <Item label={t('excise_amount')}>
          <UnderlineMoneyInput
            style={{ textAlign: 'right' }}
            editable={everyTrue(isCanEditExciseInputByManual, !isEditMode)}
            maxValue={ifElse(isInternalTax, acquisitionCostInput, undefined)}
            name={FORM_FIELDS.EXCISE_INPUT}
            placeholder={`${t('excise_amount')} ${t('included_tax')}`}
            onChange={onExciseInputChanged}
            isResetManualValue={everyTrue(isCanEditExciseInputByManual, !isEditMode, canResetManualExciseValue)}
            onHandleClickOnReset={handleResetManualExciseValue}
          />
        </Item>
      </If>
    </If>
  )
}
