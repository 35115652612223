import { isNumberHaveValue } from 'aa_common/front-end/helpers'
import get from 'lodash/get'

import { FORM_FIELDS } from '../const'

export function getExciseValue(values: any) {
  return get(values, FORM_FIELDS.EXCISE_INPUT)
}

export function setExciseValue(
  setFieldValue: (field: string, value: any) => void,
  manualExciseValue?: number,
  calculatedExciseValue?: number
) {
  if (isNumberHaveValue(manualExciseValue)) {
    setFieldValue(FORM_FIELDS.EXCISE_INPUT, manualExciseValue)
  } else if (isNumberHaveValue(calculatedExciseValue)) {
    setFieldValue(FORM_FIELDS.EXCISE_INPUT, calculatedExciseValue)
  } else {
    setFieldValue(FORM_FIELDS.EXCISE_INPUT, undefined)
  }
}
