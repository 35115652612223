import { If } from 'aa_common/front-end/components'
import { ifElse } from 'aa_common/front-end/helpers'
import { Typography } from 'antd'
import { SupportLinkList } from 'common/components/support-link-list'
import {
  IMPORTANT_ANNOUNCEMENTS_URL,
  MAINTENANCE_ANNOUNCEMENTS_URL,
  SESSION_INFO,
  URL_ZENDECK,
  ZENDECK_SERVICE_NAME,
  ZENDECK_TICKET_FORM_ID,
} from 'common/constants'
import { objectToQueryParams } from 'common/helpers/request-helper'
import { getNs } from 'common/i18n-config'
import { FeatureFlagNames, useOpenFeature } from 'common/open-features'
import { ErrorTemplate, MainTemplate } from 'components/templates'
import i18n from 'i18n'
import { isEmpty } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { Translation } from 'react-i18next'

import { StyledContent, StyledTitle, StyledWrapper } from '../styles'
import { WrapperClass } from './styles'

const { Link } = Typography

const headerContent = { header: '500', title: 'Internal Server Error' }

const { t, useNsReady } = getNs(['pages/error-pages', 'common'])
const InternalServerErrorPage = () => {
  useNsReady()
  const sessionUserInfo = JSON.parse(sessionStorage.getItem(SESSION_INFO) || '{}')
  const [remoteFlags] = useOpenFeature(true)
  const isEnable = remoteFlags?.[FeatureFlagNames.UpdateErrorPages]

  useEffect(() => {
    document.title = t('page_title')
  }, []) // eslint-disable-line

  const contactToMFCloudCenter = useMemo(() => {
    const zendeckQueryParams = {
      identification_code: sessionUserInfo?.office_identification_code,
      mail: sessionUserInfo?.email,
      office_type_id: sessionUserInfo?.office_id,
      service_name: ZENDECK_SERVICE_NAME,
      ticket_form_id: ZENDECK_TICKET_FORM_ID,
      user_identification_code: sessionUserInfo?.identification_code,
    }

    return (
      <Translation i18n={i18n}>
        {t => {
          return (
            <div>
              <span>{t('pages.errorPages.problem_exists.text_1')}</span>
              <br />
              <Link
                disabled={isEmpty(sessionUserInfo)}
                href={`${URL_ZENDECK}?${objectToQueryParams(zendeckQueryParams)}`}
              >
                {t('pages.errorPages.problem_exists.link')}
              </Link>
              <span>{t('pages.errorPages.problem_exists.text_2')}</span>
            </div>
          )
        }}
      </Translation>
    )
  }, [sessionUserInfo])

  const supportLinks = [
    { text: t('important_announcements'), href: IMPORTANT_ANNOUNCEMENTS_URL },
    { text: t('maintenance_announcements'), href: MAINTENANCE_ANNOUNCEMENTS_URL },
  ]

  return (
    <>
      <If condition={!isEnable}>
        <ErrorTemplate contentClassName={WrapperClass} headerContent={headerContent}>
          <div className="text">
            <span>{t('internal_server_error.text_1')}</span>
            <br />
            <span>{t('internal_server_error.text_2')}</span>
            <br />
            <span>{t('internal_server_error.text_3')}</span>
            <br />
          </div>
          <div className="link">{contactToMFCloudCenter}</div>
        </ErrorTemplate>
      </If>

      <If condition={isEnable}>
        <MainTemplate breadcrumb={null} sidebar={null} containerClassName={ifElse(isEnable, 'bg-white', '')}>
          <StyledWrapper>
            <StyledTitle>{t('internal_server_error.title')}</StyledTitle>
            <StyledContent>{t('internal_server_error.content')}</StyledContent>
            <SupportLinkList links={supportLinks} />
          </StyledWrapper>
        </MainTemplate>
      </If>
    </>
  )
}

export default InternalServerErrorPage
