/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PresenterError,
  PresenterIDResponse,
  PresenterOneRoleResponse,
  PresenterSaveRoleRequest,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Roles<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description List roles
   *
   * @tags roles
   * @name RolesList
   * @summary List the roles
   * @request GET:/roles
   */
  rolesList = (
    query?: {
      'filter[name]'?: string
      sort?: string[]
      'page[number]'?: number
      'page[size]'?: number
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterOneRoleResponse[], PresenterError>({
      path: `/roles`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Create role
   *
   * @tags roles
   * @name RolesCreate
   * @summary Create the role
   * @request POST:/roles
   */
  rolesCreate = (body: PresenterSaveRoleRequest, params: RequestParams = {}) =>
    this.http.request<PresenterOneRoleResponse, PresenterError>({
      path: `/roles`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description GetByBIID role
   *
   * @tags roles
   * @name BiidList
   * @summary GetByBIID the role
   * @request GET:/roles/:biid
   */
  biidList = (biid: string, params: RequestParams = {}) =>
    this.http.request<PresenterOneRoleResponse, PresenterError>({
      path: `/roles/${biid}`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Update role
   *
   * @tags roles
   * @name BiidUpdate
   * @summary Update the role
   * @request PUT:/roles/:biid
   */
  biidUpdate = (biid: string, body: PresenterSaveRoleRequest, params: RequestParams = {}) =>
    this.http.request<PresenterOneRoleResponse, PresenterError>({
      path: `/roles/${biid}`,
      method: 'PUT',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Delete role
   *
   * @tags roles
   * @name BiidDelete
   * @summary Delete the role
   * @request DELETE:/roles/:biid
   */
  biidDelete = (biid: string, params: RequestParams = {}) =>
    this.http.request<PresenterIDResponse, PresenterError>({
      path: `/roles/${biid}`,
      method: 'DELETE',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description GetDefault role
   *
   * @tags roles
   * @name DefaultList
   * @summary GetDefault the role
   * @request GET:/roles/default
   */
  defaultList = (params: RequestParams = {}) =>
    this.http.request<PresenterOneRoleResponse, PresenterError>({
      path: `/roles/default`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
