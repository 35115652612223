import { ifElse } from 'aa_common/front-end/helpers'
import { DEPRECIATION_METHOD_CODE, LEDGER_PURPOSE } from 'common/constants'
import { FORM_MODES } from 'common/models'
import { useFormikContext } from 'formik'
import { useEffect } from 'react'

import { DEPRECIATION_FIELDS } from '../const'
import { checkShowMemorandumValue, genLedgerFieldName } from '../helper'
import { useCalculateMemorandum } from './use-calculate-memorandum'
import { useGetMemorandumValue } from './use-get-memorandum-value'
import { useLedgersWithSetting } from './use-ledgers-with-setting'

export const useMemorandumValue = (formMode: FORM_MODES, assetType?: number) => {
  const { setFieldValue } = useFormikContext()

  const { ledgersWithSetting, canCopyFromAccountant } = useLedgersWithSetting()
  const memorandumValueMap = useCalculateMemorandum(ledgersWithSetting, assetType)
  const getMemorandumValue = useGetMemorandumValue(memorandumValueMap, formMode, assetType)

  useEffect(() => {
    ledgersWithSetting?.forEach((ledgerWithSetting, ledgerIndex) => {
      const ledgerDMethod = ledgerWithSetting?.depreciation_method_code
      const ledgerPurpose = ledgerWithSetting?.ledger_purpose
      const memorandumField = genLedgerFieldName(DEPRECIATION_FIELDS.MEMORANDUM_VALUE, ledgerIndex)
      if (!checkShowMemorandumValue(Number(String(ledgerDMethod)))) {
        setFieldValue(
          memorandumField,
          ifElse(ledgerDMethod === DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD, 0, undefined)
        )
      } else if (ledgerPurpose === LEDGER_PURPOSE.TAX && canCopyFromAccountant) {
        const accountantLedgerIndex = ledgersWithSetting?.findIndex(
          ledgerSetting => ledgerSetting?.ledger_purpose === LEDGER_PURPOSE.ACCOUNTING
        )
        const accountantLedgerMemorandum = ledgersWithSetting?.[accountantLedgerIndex]?.memorandum_value
        setFieldValue(memorandumField, accountantLedgerMemorandum)
      } else {
        const memorandumValue = ledgerWithSetting?.is_copied
          ? ledgersWithSetting[0].memorandum_value
          : getMemorandumValue(ledgerWithSetting)
        setFieldValue(memorandumField, memorandumValue)
      }
    })
  }, [ledgersWithSetting, canCopyFromAccountant, getMemorandumValue])
}
