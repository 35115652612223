/* eslint-disable no-restricted-syntax */
import {
  ASSET_CODE_AUTO_GENERATE_SETTING_METHOD,
  DEPRECIATION_METHOD_CODE,
  EXCISE_TAX_INPUT_FORMAT,
  EXCISE_TAX_METHOD,
  EXECUTE_VAT,
  REGEX_FORMATS,
} from 'common/constants'
import { loadNs } from 'common/i18n-config'
import { Asset, FORM_MODES, LedgerSetting, Term } from 'common/models'
import moment, { Moment } from 'moment'

import { DEPRECIATION_FIELDS, ERROR_FROM_API, LEDGERS_FIELD_NAME } from './const'

const t = loadNs(['components/organisms/assets/asset-form', 'common'])

export const defaultAssetValues: Asset = {
  biid: '',
  name: '',
  name_kana: '',
  code: '',
  branch_code: '',
  asset_type: undefined,
  asset_category_id: undefined,
  area: null,
  area_biid: null,
  department_biid: null,
  acquired_at: null,
  min_acquired_at: undefined,
  usage_started_at: null,
  sold_at: null,
  asset_type_for_depreciable_property_tax: null,
  asset_type_for_depreciable_property_tax_code: undefined,
  asset_type_for_corporate_tax_code: undefined,
  asset_detail_for_corporate_tax: '',
  asset_structure_for_corporate_tax: '',
  acquisition_cost: 0,
  acquisition_cost_excluded_excise: 0,
  acquisition_cost_included_excise: 0,
  disposal_cost: 0,
  consumption_tax_category: undefined,
  consumption_tax_amount: 0,
  quantity: 0,
  unit_id: undefined,
  manual_excise_value: undefined,
  acquire_category_code: undefined,
  acquire_destination_name: '',
  pre_submit_number: '',
  has_exemption: false,
  exemption_numerator: 0,
  exemption_denominator: 0,
  summary_for_asset_depreciation_tax: '',
  photos: [],
  attachments: [],
  urls: [],
  fixed_asset_ledgers: [],
  tags: [],
  memo: '',
  comment: '',
  edited_at: moment(),
  retired_at: null,
  has_transition: false,
  impaired_at: null,
  accumulated_impaired_amount: 0,
  impaired_amount: 0,
  remaining_book_value: 0,
  divided_at: null,
}

export const getLedgerName = (ledgerSettingId: number | undefined, ledgerSettings: LedgerSetting[]) => {
  if (!ledgerSettingId || !ledgerSettings) return null

  const ledgerSetting = ledgerSettings.find(item => item.id === ledgerSettingId)
  return ledgerSetting?.name
}

export const isFirstDayOfTerm = (terms: Term[], date: string) => {
  if (!terms || terms.length === 0 || !date) return false

  return terms.findIndex(term => term.start_date === date) !== -1
}

export const checkValidKatakana = (name: string): boolean => {
  const nameArray = name.split('')
  for (const name of nameArray) {
    if (!REGEX_FORMATS.KATAKANA.test(name)) {
      return false
    }
  }
  return true
}

export const checkAcquiredAtBelongToAssetCodeAutoGenerate = (setting: any, acquiredAt?: string) => {
  let startDateOfSetting = null
  let endDateOfSetting = null
  let currentSettingStartDate = null

  if (!acquiredAt) return false

  if (setting.from_term) {
    startDateOfSetting = setting.from_term.start_date
  }

  if (setting.to_term) {
    endDateOfSetting = setting.to_term.end_date
  }

  currentSettingStartDate = acquiredAt

  if (
    currentSettingStartDate >= startDateOfSetting &&
    (!endDateOfSetting || currentSettingStartDate <= endDateOfSetting)
  )
    return true

  return false
}

export const isDifferentExciseSetting = (currentExciseSetting: any, newExciseSetting: any) => {
  if (
    currentExciseSetting.inputTaxWhenRegisterFixedAsset !== newExciseSetting?.input_tax_when_register_fixed_asset ||
    currentExciseSetting.taxInputMethod !== newExciseSetting?.tax_input_format ||
    currentExciseSetting.taxMethod !== newExciseSetting?.tax_method
  ) {
    return true
  }
  return false
}

export const fetchNewExciseSetting = async (
  newExciseDateValue: string,
  dispatch: any,
  fetchExciseSettingsByDate: any
) => {
  const promise = new Promise(resolve => {
    setTimeout(() => resolve(dispatch(fetchExciseSettingsByDate(newExciseDateValue))), 100)
  })

  const result = await promise
  return result
}

export const validateRequiredField = (values: any, currentSetting?: any) => {
  const error: any = []
  if (values) {
    !values.name?.length && error.push(t('name'))
    if (currentSetting?.generating_method === ASSET_CODE_AUTO_GENERATE_SETTING_METHOD.AUTO_NUMBER_BRANCH) {
      !values.code?.length && error.push(t('asset_code'))
    } else if (currentSetting?.generating_method === ASSET_CODE_AUTO_GENERATE_SETTING_METHOD.NO_AUTO_NUMBER) {
      !values.code?.length && error.push(t('asset_code'))
      !values.branch_code?.length && error.push(t('asset_branch_code'))
    }
    !values.acquired_at && error.push(t('acquired_at'))
    !values.acquire_category_code && error.push(t('acquisition_category'))
    !values.asset_category_id && error.push(t('asset_category'))
    !values.area_biid && error.push(t('area'))
    if (values.fixed_asset_ledgers?.length > 1) {
      // eslint-disable-next-line array-callback-return
      values.fixed_asset_ledgers?.map((item: any) => {
        const isNonDepreciation = item?.depreciation_method_code === DEPRECIATION_METHOD_CODE.NON_DEPRECIATION
        const isSmallAmountDepreciableAsset =
          item?.depreciation_method_code === DEPRECIATION_METHOD_CODE.SMALL_AMOUNT_DEPRECIABLE_ASSET
        if (values.has_transition && !isNonDepreciation && !isSmallAmountDepreciableAsset) {
          !item?.depreciation_method_code &&
            error.push(t('depreciation_type_of', { name: item?.name || item?.ledger_setting?.name }))
          !item?.transition_at && error.push(t('transition_at_of', { name: item?.name || item?.ledger_setting?.name }))
        } else {
          !item?.depreciation_method_code &&
            error.push(t('depreciation_type_of', { name: item?.name || item?.ledger_setting?.name }))
        }
      })
    } else {
      const isNonDepreciation =
        values.fixed_asset_ledgers[0].depreciation_method_code === DEPRECIATION_METHOD_CODE.NON_DEPRECIATION
      const isSmallAmountDepreciableAsset =
        values.fixed_asset_ledgers[0].depreciation_method_code ===
        DEPRECIATION_METHOD_CODE.SMALL_AMOUNT_DEPRECIABLE_ASSET
      if (
        values.has_transition &&
        values.fixed_asset_ledgers?.length === 1 &&
        !isNonDepreciation &&
        !isSmallAmountDepreciableAsset
      ) {
        !values.fixed_asset_ledgers[0].depreciation_method_code &&
          error.push(
            t('depreciation_type_of', {
              name: values.fixed_asset_ledgers[0].name || values.fixed_asset_ledgers[0].ledger_setting.name,
            })
          )
        !values.fixed_asset_ledgers[0].transition_at &&
          error.push(
            t('transition_at_of', {
              name: values.fixed_asset_ledgers[0].name || values.fixed_asset_ledgers[0].ledger_setting.name,
            })
          )
      } else if (!values.has_transition && values.fixed_asset_ledgers?.length === 1) {
        !values.fixed_asset_ledgers[0].depreciation_method_code &&
          error.push(
            t('depreciation_type_of', {
              name: values.fixed_asset_ledgers[0].name || values.fixed_asset_ledgers[0].ledger_setting.name,
            })
          )
      }
    }
  }
  return error.join('<br>')
}

export const setTransitionAtValue = (initialValues: any, setFieldValue: any, value: string | Moment | null) => {
  ;(initialValues?.fixed_asset_ledgers || []).forEach((_: any, index: any) => {
    setFieldValue(`fixed_asset_ledgers[${index}].transition_at`, value)
  })
}

export const checkShowMemorandumValue = (depreciationCode: number) => {
  return [
    DEPRECIATION_METHOD_CODE.OLD_STRAIGHT_LINE, // d.method 10
    DEPRECIATION_METHOD_CODE.NEW_STRAIGHT_LINE, // d.method 11
    DEPRECIATION_METHOD_CODE.OLD_DECLINING_BALANCE, // d.method 20
    DEPRECIATION_METHOD_CODE.NEW_DECLINING_BALANCE_250, // d.method 21
    DEPRECIATION_METHOD_CODE.NEW_DECLINING_BALANCE_200, // d.method 22
  ].includes(depreciationCode)
}

export const getLedgerFieldName = (index: number) => {
  return `${LEDGERS_FIELD_NAME}[${index}]`
}

export const genLedgerFieldName = (fieldName: string, index: number) => {
  return `${LEDGERS_FIELD_NAME}[${index}].${fieldName}`
}

export const getAllLedgerFieldNames = (index: number) => {
  return {
    ledgerSettingIdField: genLedgerFieldName(DEPRECIATION_FIELDS.LEDGER_SETTING_ID, index),
    serviceLifeField: genLedgerFieldName(DEPRECIATION_FIELDS.SERVICE_LIFE, index),
    serviceLifeAfterChangeField: genLedgerFieldName(DEPRECIATION_FIELDS.SERVICE_LIFE_AFTER_CHANGE, index),
    bookValueAtChangeField: genLedgerFieldName(DEPRECIATION_FIELDS.BOOK_VALUE_AT_CHANGE, index),
    usagePeriodMonthsField: genLedgerFieldName(DEPRECIATION_FIELDS.USAGE_PERIOD_MONTHS, index),
    residualAmountField: genLedgerFieldName(DEPRECIATION_FIELDS.RESIDUAL_AMOUNT, index),
    depreciationMethodCodeField: genLedgerFieldName(DEPRECIATION_FIELDS.DEPRECIATION_METHOD_CODE, index),
    depreciationRateField: genLedgerFieldName(DEPRECIATION_FIELDS.DEPRECIATION_RATE, index),
    guaranteeRateField: genLedgerFieldName(DEPRECIATION_FIELDS.GUARANTEE_RATE, index),
    revisedDepreciationRateField: genLedgerFieldName(DEPRECIATION_FIELDS.REVISED_DEPRECIATION_RATE, index),
    accountingBeginningBookInitialValueField: genLedgerFieldName(
      DEPRECIATION_FIELDS.ACCOUNTING_BEGINNING_BOOK_INITIAL_VALUE,
      index
    ),
    transitionBeginningBookValueField: genLedgerFieldName(DEPRECIATION_FIELDS.TRANSITION_BEGINNING_BOOK_VALUE, index),
    transitionBookValueField: genLedgerFieldName(DEPRECIATION_FIELDS.TRANSITION_BOOK_VALUE, index),
    revisedAcquisitionAmountField: genLedgerFieldName(DEPRECIATION_FIELDS.REVISED_ACQUISITION_AMOUNT, index),
    depreciatedMonthsField: genLedgerFieldName(DEPRECIATION_FIELDS.DEPRECIATED_MONTHS, index),
    transitionAtField: genLedgerFieldName(DEPRECIATION_FIELDS.TRANSITION_AT, index),
    accumulatedDepreciationAmountField: genLedgerFieldName(DEPRECIATION_FIELDS.ACCUMULATED_DEPRECIATION_AMOUNT, index),
    accumulatedImpairedAmountField: genLedgerFieldName(DEPRECIATION_FIELDS.ACCUMULATED_IMPAIRED_AMOUNT, index),
    bookValueField: genLedgerFieldName(DEPRECIATION_FIELDS.BOOK_VALUE, index),
    memorandumValueField: genLedgerFieldName(DEPRECIATION_FIELDS.MEMORANDUM_VALUE, index),
    retirementDepreciationEndedMethodField: genLedgerFieldName(
      DEPRECIATION_FIELDS.RETIREMENT_DEPRECIATION_ENDED_METHOD,
      index
    ),
    gainField: genLedgerFieldName(DEPRECIATION_FIELDS.GAIN, index),
    lossField: genLedgerFieldName(DEPRECIATION_FIELDS.LOSS, index),
    isCopiedField: genLedgerFieldName(DEPRECIATION_FIELDS.IS_COPIED, index),
    accumulatedOverDepreciationAmtField: genLedgerFieldName(
      DEPRECIATION_FIELDS.ACCUMULATED_OVER_DEPRECIATION_AMT,
      index
    ),
    isMemorandumValueEnteredField: genLedgerFieldName(DEPRECIATION_FIELDS.IS_MEMORANDUM_VALUE_ENTERED, index),
  }
}

export const addApiErrorKey = (errors: any) => {
  if (!errors || typeof errors !== 'object' || Object.keys(errors).length === 0) return errors
  return {
    ...errors,
    [ERROR_FROM_API]: true,
  }
}

export function shouldRenderAtChangeFields(hasTransition: boolean, depreciationCode: DEPRECIATION_METHOD_CODE) {
  return (
    hasTransition &&
    [DEPRECIATION_METHOD_CODE.NEW_STRAIGHT_LINE, DEPRECIATION_METHOD_CODE.OLD_STRAIGHT_LINE].includes(depreciationCode)
  )
}

export function shouldRenderBookValueAtChange(
  hasTransition: boolean,
  depreciationCode: DEPRECIATION_METHOD_CODE,
  transitionAt: string | null
) {
  return !!transitionAt && shouldRenderAtChangeFields(hasTransition, depreciationCode)
}

export function transitionAtSameAsStartUsage(transitionAt: string | null, usageStartedAt: string | null) {
  return !!usageStartedAt && !!transitionAt && transitionAt === usageStartedAt
}

export function getFormMode(formMode: FORM_MODES) {
  return {
    isNewMode: formMode === FORM_MODES.NEW,
    isEditMode: formMode === FORM_MODES.EDIT,
    isCopyMode: formMode === FORM_MODES.COPY,
    isCorrectionMode: formMode === FORM_MODES.CORRECTION,
    isImportMode: formMode === FORM_MODES.IMPORT,
  }
}

export function getExciseInputSetting(exciseSetting: any) {
  const isInputTaxWhenRegister = exciseSetting?.input_tax_when_register_fixed_asset === EXECUTE_VAT.EXECUTE
  const isTaxMethodExcluded = exciseSetting?.tax_method === EXCISE_TAX_METHOD.EXCLUDED_TAX
  const isTaxInputFormatExternalTax = exciseSetting?.tax_input_format === EXCISE_TAX_INPUT_FORMAT.EXTERNAL_TAX
  const isExternalTax = isTaxMethodExcluded && isInputTaxWhenRegister && isTaxInputFormatExternalTax
  const isInternalTax = isTaxMethodExcluded && isInputTaxWhenRegister && !isTaxInputFormatExternalTax
  return {
    isExciseSettingAvailable: !!exciseSetting,
    isShowTax: isExternalTax || isInternalTax,
    isInputTaxWhenRegister,
    isTaxMethodExcluded,
    isExternalTax,
    isInternalTax,
  }
}
