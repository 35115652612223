import { messageSuccess } from 'aa_common/front-end/antd'
import { getNs } from 'common/i18n-config'
import { FixedAssetsService } from 'common/open-api'
import { getAxiosSilent } from 'common/open-api/helper'
import { handleErrorResponse } from 'common/utils/response-helper'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { parseCancelAcceleratedError } from '../helpers/parse-cancel-accelerated-error'
import { Cancel_Accelerated_Depreciation_Error } from '../types'

const { t } = getNs(['common'])
export const useCancelAcceleratedDepreciation = (ledgerSettingId: number, onCancelSuccess?: () => void) => {
  const { biid } = useParams<{ biid: string }>()
  const [isCanceling, setCanceling] = useState(false)
  const [cancelError, setCancelError] = useState<Cancel_Accelerated_Depreciation_Error>({})

  const cancelAcceleratedDepreciation = async (onHideCancelModal: () => void) => {
    try {
      setCanceling(true)
      const response = await FixedAssetsService.depreciationResultsCancelAdjustUpdate(
        biid,
        {
          ledger_setting_id: ledgerSettingId,
        },
        getAxiosSilent()
      )
      if (response) {
        messageSuccess(t('messages.cancel_success'))
        onCancelSuccess?.()
      }
    } catch (error) {
      const parsedError = parseCancelAcceleratedError(error)
      if (!parsedError.isKnownError) {
        handleErrorResponse(error, undefined, { showToast: true })
      } else {
        setCancelError(parsedError)
      }
    } finally {
      setCanceling(false)
      onHideCancelModal()
    }
  }

  return { isCanceling, cancelError, cancelAcceleratedDepreciation }
}
