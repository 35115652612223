/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { QuestionCircleFilled } from '@ant-design/icons'
import { faMinus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Form from 'aa_common/front-end/components/form'
import { defaultTo } from 'aa_common/front-end/helpers'
import { Tooltip } from 'antd'
import { calculateMemorandumValue as calculateMemorandumValueApi } from 'api/app/asset'
import { DEPRECIATION_METHOD_CODE, EXCISE_TAX_METHOD } from 'common/constants'
import { yenStringToNumber } from 'common/helpers'
import { AssetLedger } from 'common/models'
import i18n from 'i18n'
import useResource from 'lib/hooks/useResource'
import get from 'lodash/get'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { unitsSlice } from '../../master/units/store'
import { StoreContext } from './asset-dividing-form'
import { checkMemorandumValueForCalculate } from './helper'
import { Row } from './row'
import { FIELDS, Ledger } from './schema'
import { NewBranchCodeWrapper, StyledTableHeader, StyledTableWrapper } from './styles'

export const DividingDetailTable = () => {
  const { useSelector } = StoreContext
  const { getValues, watch, setValue, setError, clearErrors } = useFormContext()
  const { FormField } = Form

  const {
    divided_acquisition_cost: dividedAcquisitionCost,
    new_acquisition_cost: newAcquisitionCost,
    divided_excise_amount: dividedExciseAmount,
    new_excise_amount: newExciseAmount,
    divided_acquisition_cost_tax_included: dividedAcquisitionCostTaxIncluded,
    new_acquisition_cost_tax_included: newAcquisitionCostTaxIncluded,
    new_quantity: newQuantity,
    ledgers,
  } = getValues()

  const asset = useSelector(state => state.dividingData?.asset)
  const exciseTaxMethod = useSelector(state => state.dividingData?.exciseTaxMethod)
  const [{ data: responseGetMemorandumValue }, calculateMemorandumValue] = useResource(calculateMemorandumValueApi)
  const [isUnitLink, setIsUnitLink] = useState(false)
  const units = defaultTo(
    unitsSlice.useSlice(state => state?.data?.units),
    []
  )

  const originalAcquisitionCost = get(asset, 'acquisition_cost_excluded_excise') || get(asset, 'acquisition_cost') || 0
  const originalExciseAmount = get(asset, 'manual_excise_value') || get(asset, 'calculated_excise_value') || 0
  const originalQuantity = get(asset, 'quantity', 0)
  const originalMemorandumValue = get(asset, 'fixed_asset_ledgers[0].memorandum_value', 0)
  const originalResidualAmount = get(asset, 'fixed_asset_ledgers[0].residual_amount', 0)
  const newMemorandumValue = get(ledgers, '[0].memorandum_value')
  const newResidualAmount = get(ledgers, '[0].residual_amount')
  const firstLedgerInfo: AssetLedger = get(asset, 'fixed_asset_ledgers[0]')
  const dMethodCodeOfFirstLedger = firstLedgerInfo?.depreciation_method_code
  const newAcquisitionCostFormatted =
    typeof newAcquisitionCost === 'string' ? yenStringToNumber(newAcquisitionCost) : newAcquisitionCost

  const originalLedgers = get(asset, 'fixed_asset_ledgers')?.map(
    ({ ledger_setting_id, depreciation_method_code, memorandum_value, residual_amount }: AssetLedger) =>
      ({
        ledger_setting_id,
        depreciation_method_code,
        memorandum_value,
        residual_amount,
      } as Ledger)
  )

  const isShowResidualAmountField =
    dMethodCodeOfFirstLedger !== null &&
    [DEPRECIATION_METHOD_CODE.AVERAGE_USAGE_PERIOD, DEPRECIATION_METHOD_CODE.LEASE_PERIOD_STRAIGHT_LINE].includes(
      dMethodCodeOfFirstLedger
    )

  const isExciseIncludedTaxMethod = exciseTaxMethod === EXCISE_TAX_METHOD.INCLUDED_TAX

  watch([
    FIELDS.new_acquisition_cost,
    FIELDS.new_excise_amount,
    FIELDS.new_quantity,
    FIELDS.new_acquisition_cost_tax_included,
    FIELDS.divided_excise_amount,
    FIELDS.ledgers,
  ])

  // Use effect to get unit information list
  useEffect(() => {
    unitsSlice.setFilters({}, true)
  }, [])

  // Use effect to get unit of the asset is link or unlink
  useEffect(() => {
    const assetUnitId = get(asset, 'unit.id')
    if (units?.length > 0) {
      const unit = units.find((item: any) => item?.id === assetUnitId)
      setIsUnitLink(unit?.link_memorandum_value_quantity as boolean)
    }
  }, [units])

  // Use effect to handle call calculate memorandum api to get memorandum value follow quantity value with unit is link
  useEffect(() => {
    if (isUnitLink) {
      const ledgers = asset?.fixed_asset_ledgers.reduce((filteredLedger: any, ledger: AssetLedger) => {
        const { ledger_setting_id, depreciation_method_code } = ledger
        if (depreciation_method_code !== null && checkMemorandumValueForCalculate(depreciation_method_code)) {
          filteredLedger.push({
            id: ledger_setting_id,
            depreciation_method: depreciation_method_code,
          })
        }
        return filteredLedger
      }, [])
      const payload = {
        ledgers,
        quantity: parseInt(newQuantity, 10),
        unit_id: asset?.unit?.id,
        asset_type: asset?.asset_category?.asset_type,
      }
      calculateMemorandumValue(payload)
    }
  }, [newQuantity, isUnitLink]) // eslint-disable-line
  // Handle set memorandum value for asset destination and re-calculate dividing asset memorandum
  useEffect(() => {
    if (
      isUnitLink &&
      !isShowResidualAmountField &&
      responseGetMemorandumValue &&
      dMethodCodeOfFirstLedger !== DEPRECIATION_METHOD_CODE.NON_DEPRECIATION
    ) {
      const newMemorandumValue = get(responseGetMemorandumValue, 'ledgers[0].memorandum_value') || 0
      const ledgersFromResponse = get(responseGetMemorandumValue, 'ledgers') || []

      const newLedgers = [...ledgers].map((ledger: Ledger) => {
        const foundLedger = ledgersFromResponse?.find((l: any) => l.id === ledger?.ledger_setting_id)
        if (foundLedger) {
          return { ...ledger, memorandum_value: foundLedger.memorandum_value }
        }
        return ledger
      })

      if (newMemorandumValue <= originalMemorandumValue) {
        setValue(FIELDS.divided_memorandum_value, originalMemorandumValue - newMemorandumValue)
        setValue(FIELDS.ledgers, newLedgers)
      }
    }
  }, [responseGetMemorandumValue, isUnitLink]) // eslint-disable-line

  useEffect(() => {
    /**
     * If we have value of new_acquisition_cost (destination asset) then we can calculate the value of divided_acquisition_cost (source asset)
     * If don't have, we will set default value = 0
     */

    if (newAcquisitionCostFormatted < originalAcquisitionCost) {
      // ------------ Set value for acquisition cost ------------
      setValue(
        FIELDS.divided_acquisition_cost,
        newAcquisitionCostFormatted ? originalAcquisitionCost - newAcquisitionCostFormatted : null
      )

      // ------------ Set value for quantity ------------
      // When user fill value of new_acquisition_cost (destination asset), we can calculate the value of new_quantity (destination asset)
      const newQuantity = Math.round((+originalQuantity * newAcquisitionCostFormatted) / originalAcquisitionCost)
      setValue(FIELDS.new_quantity, newQuantity)
      setValue(FIELDS.divided_quantity, originalQuantity - newQuantity)

      const newLedgers = defaultTo(originalLedgers, []).map((ledger: Ledger) => {
        // ------------ Set value for memorandum value ------------
        // With Depreciation method code = 30, We have a special case and value of this case always equal = 0
        if (ledger?.depreciation_method_code === DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD) {
          ledger.memorandum_value = 0
        }

        // When user fill value of new_acquisition_cost (destination asset), we can calculate the value of new_memorandum_value (destination asset)
        if (checkMemorandumValueForCalculate(ledger?.depreciation_method_code as number)) {
          const originalMemorandumValue = defaultTo(get(ledger, 'memorandum_value'), 0)
          const newMemorandumValue = Math.round(
            (originalMemorandumValue * newAcquisitionCostFormatted) / originalAcquisitionCost
          )

          setValue(FIELDS.divided_memorandum_value, originalMemorandumValue - newMemorandumValue)
          ledger.memorandum_value = newMemorandumValue
        }

        // ------------ Set value for residual amount ------------
        if (
          [DEPRECIATION_METHOD_CODE.AVERAGE_USAGE_PERIOD, DEPRECIATION_METHOD_CODE.LEASE_PERIOD_STRAIGHT_LINE].includes(
            ledger?.depreciation_method_code as number
          )
        ) {
          const originalResidualAmount = defaultTo(get(ledger, 'residual_amount'), 0)

          ledger.residual_amount = Math.round(
            (originalResidualAmount * newAcquisitionCostFormatted) / originalAcquisitionCost
          )
        }
        return ledger
      })

      setValue(FIELDS.divided_residual_amount, originalResidualAmount - get(newLedgers, '[0].residual_amount'))
      setValue(FIELDS.ledgers, newLedgers)
    }

    if (!newAcquisitionCostFormatted) {
      setValue(FIELDS.new_excise_amount, null)
      setValue(FIELDS.new_acquisition_cost_tax_included, null)
    }
  }, [newAcquisitionCostFormatted, originalAcquisitionCost]) // eslint-disable-line

  useEffect(() => {
    // Update quantity of source FA when user entered value
    if (newQuantity <= originalQuantity) {
      setValue(FIELDS.divided_quantity, originalQuantity - newQuantity)
    }
  }, [newQuantity]) // eslint-disable-line

  useEffect(() => {
    // Update memorandum_value of source FA when user entered value
    if (newMemorandumValue < originalMemorandumValue) {
      setValue(FIELDS.divided_memorandum_value, originalMemorandumValue - newMemorandumValue)
    }
  }, [newMemorandumValue]) // eslint-disable-line

  useEffect(() => {
    /**
     * Default value of divided_excise_amount (source asset) and divided_acquisition_cost_tax_included (source asset):
     * After set value for divided_acquisition_cost (source asset), we will calculate value for divided_excise_amount (source asset)
     */
    if (dividedAcquisitionCost) {
      const dividedExciseAmount = Math.ceil((originalExciseAmount * dividedAcquisitionCost) / originalAcquisitionCost)

      setValue(FIELDS.divided_excise_amount, dividedExciseAmount)
      setValue(FIELDS.divided_acquisition_cost_tax_included, dividedAcquisitionCost + dividedExciseAmount)
    } else {
      setValue(FIELDS.divided_excise_amount, null)
      setValue(FIELDS.divided_acquisition_cost_tax_included, null)
    }
  }, [dividedAcquisitionCost]) // eslint-disable-line

  useEffect(() => {
    /**
     * Default value of new_excise_amount (destination asset) and new_acquisition_cost_tax_included (destination asset):
     * After we set value of divided_excise_amount (source asset), we can calculate the default value of new_excise_amount (destination asset)
     * and new_acquisition_cost_tax_included (destination asset)
     */

    if (
      (dividedExciseAmount !== null || isExciseIncludedTaxMethod) &&
      !!newAcquisitionCostFormatted &&
      newAcquisitionCostFormatted < originalAcquisitionCost
    ) {
      const newExciseAmount = originalExciseAmount - dividedExciseAmount
      setValue(FIELDS.new_excise_amount, newExciseAmount || 0)

      // If excise tax method = excluded, We will have to calculate the value of new_acquisition_cost_tax_included
      // else we will get the value of new_acquisition_cost
      setValue(
        FIELDS.new_acquisition_cost_tax_included,
        exciseTaxMethod === EXCISE_TAX_METHOD.EXCLUDED_TAX
          ? newAcquisitionCostFormatted + newExciseAmount
          : newAcquisitionCostFormatted
      )
    }
  }, [dividedExciseAmount, newAcquisitionCostFormatted]) // eslint-disable-line

  useEffect(() => {
    // We will check, if new_excise_amount < originalExciseAmount then set new value for divided_excise_amount
    // If the condition is not satisfied, will set default value = 0
    const dividedExciseAmount =
      newExciseAmount !== null && newExciseAmount <= originalExciseAmount
        ? originalExciseAmount - newExciseAmount
        : null

    setValue(FIELDS.divided_excise_amount, dividedExciseAmount)
  }, [newExciseAmount]) // eslint-disable-line

  const handleInputNewValue = (
    fieldName: string,
    newValue: string | number,
    originValue: string | number,
    errorMsgKey: string
  ) => {
    const newValueInt = newValue ? parseInt(`${newValue}`, 10) : 0
    const dividedValueInt = typeof originValue === 'string' ? parseInt(originValue, 10) : originValue

    if (newValueInt > dividedValueInt) {
      setError(`ledgers[0].${fieldName}`, {
        type: 'manual',
        message: i18n.t(`components.AssetDividing.${errorMsgKey}`),
      })
    } else {
      clearErrors(`ledgers[0].${fieldName}`)
      setValue(`divided_${fieldName}`, dividedValueInt - newValueInt)
    }
  }

  const handleBlurredField = (fieldName: string, currentValue: number, maxValue: number) => {
    const isNewAcquisitionCostField = FIELDS.new_acquisition_cost.includes(fieldName)
    const isNewExciseAmountField = FIELDS.new_excise_amount.includes(fieldName)

    if (currentValue <= 0 && isNewAcquisitionCostField) {
      setError(`new_${fieldName}`, {
        type: 'manual',
        message: i18n.t(`components.AssetDividing.greaterThan1Yen`),
      })
      return
    }

    if (
      (isNewAcquisitionCostField && currentValue >= maxValue) ||
      (isNewExciseAmountField && currentValue > maxValue)
    ) {
      setError(`new_${fieldName}`, {
        type: 'manual',
        message: i18n.t(`components.AssetDividing.${fieldName}_error`),
      })
    } else {
      clearErrors(`new_${fieldName}`)
    }
  }

  const isShowMemorandumField =
    dMethodCodeOfFirstLedger !== null &&
    [
      DEPRECIATION_METHOD_CODE.OLD_STRAIGHT_LINE,
      DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD,
      DEPRECIATION_METHOD_CODE.NEW_DECLINING_BALANCE_200,
      DEPRECIATION_METHOD_CODE.NEW_DECLINING_BALANCE_250,
      DEPRECIATION_METHOD_CODE.OLD_DECLINING_BALANCE,
      DEPRECIATION_METHOD_CODE.NEW_STRAIGHT_LINE,
    ].includes(dMethodCodeOfFirstLedger)

  const rows = [
    {
      source: {
        title: i18n.t('components.AssetDividing.asset_name'),
        props: {
          name: FIELDS.divided_asset_name,
          bordered: false,
          underline: true,
          readOnly: true,
        },
      },
      destination: {
        props: {
          name: FIELDS.new_asset_name,
          required: true,
          maxLength: 50,
        },
      },
    },
    {
      source: {
        title: i18n.t('components.AssetDividing.asset_name_kana'),
        props: {
          name: FIELDS.divided_asset_name_kana,
          bordered: false,
          underline: true,
          readOnly: true,
        },
      },
      destination: {
        props: {
          name: 'new_asset_name_kana',
          maxLength: 50,
        },
      },
    },
    {
      source: {
        title: i18n.t('components.AssetDividing.acquisition_cost'),
        props: {
          name: FIELDS.divided_acquisition_cost,
          'data-testid': 'divided_acquisition_cost',
          className: 'text-right',
          bordered: false,
          readOnly: true,
          underline: true,
          allowPrefix: !!dividedAcquisitionCost,
        },
      },
      destination: {
        props: {
          name: FIELDS.new_acquisition_cost,
          'data-testid': 'new_acquisition_cost',
          className: 'text-right',
          required: true,
          allowPrefix: !!newAcquisitionCost,
          onBlur: () => handleBlurredField('acquisition_cost', newAcquisitionCost, originalAcquisitionCost),
        },
      },
    },
    ...(isExciseIncludedTaxMethod
      ? []
      : [
          {
            source: {
              title: i18n.t('components.AssetDividing.excise_amount'),
              props: {
                name: FIELDS.divided_excise_amount,
                'data-testid': 'divided_excise_amount',
                className: 'text-right',
                bordered: false,
                readOnly: true,
                underline: true,
                allowPrefix: !!dividedExciseAmount,
              },
            },
            destination: {
              props: {
                name: FIELDS.new_excise_amount,
                'data-testid': 'new_excise_amount',
                className: 'text-right',
                allowPrefix: !!newExciseAmount,
                onBlur: () => handleBlurredField('excise_amount', newExciseAmount, originalExciseAmount),
              },
            },
          },
        ]),
    ...(isExciseIncludedTaxMethod
      ? []
      : [
          {
            source: {
              title: i18n.t('components.AssetDividing.acquisition_included_excise_amount'),
              props: {
                name: FIELDS.divided_acquisition_cost_tax_included,
                'data-testid': 'divided_acquisition_cost_tax_included',
                className: 'text-right',
                bordered: false,
                readOnly: true,
                underline: true,
                allowPrefix: !!dividedAcquisitionCostTaxIncluded,
              },
            },
            destination: {
              props: {
                name: FIELDS.new_acquisition_cost_tax_included,
                'data-testid': 'new_acquisition_cost_tax_included',
                className: 'text-right',
                bordered: false,
                readOnly: true,
                underline: true,
                allowPrefix: !!newAcquisitionCostTaxIncluded,
              },
            },
          },
        ]),
    {
      type: 'number',
      source: {
        title: i18n.t('components.AssetDividing.quantity'),
        props: {
          name: FIELDS.divided_quantity,
          'data-testid': 'divided_quantity',
          bordered: false,
          readOnly: true,
          underline: true,
        },
      },
      destination: {
        props: {
          'data-testid': 'new_quantity',
          name: FIELDS.new_quantity,
          min: 0,
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          formatter: (val: any) => (!val ? '0' : `${val}`),
          onBlur: () => handleBlurredField('quantity', newQuantity, originalQuantity),
        },
      },
    },
    ...(isShowMemorandumField
      ? [
          {
            source: {
              title: i18n.t('components.AssetDividing.memorandum_value'),
              props: {
                name: FIELDS.divided_memorandum_value,
                'data-testid': 'divided_memorandum_value',
                className: 'text-right',
                bordered: false,
                readOnly: true,
                underline: true,
                allowPrefix: true,
              },
            },
            destination: {
              props: {
                name: `ledgers[0].${[FIELDS.memorandum_value]}`,
                'data-testid': 'new_memorandum_value',
                className: 'text-right',
                allowPrefix: true,
                readOnly:
                  isUnitLink || dMethodCodeOfFirstLedger === DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD,
                onBlur: () => {
                  handleInputNewValue(
                    FIELDS.memorandum_value,
                    newMemorandumValue,
                    originalMemorandumValue,
                    'memorandum_value_error'
                  )
                },
              },
            },
          },
        ]
      : []),
    ...(isShowResidualAmountField
      ? [
          {
            source: {
              title: i18n.t('components.AssetDividing.residual_amount'),
              props: {
                name: FIELDS.divided_residual_amount,
                'data-testid': 'divided_residual_amount',
                className: 'text-right',
                bordered: false,
                readOnly: true,
                underline: true,
                allowPrefix: true,
              },
            },
            destination: {
              props: {
                name: `ledgers[0].${[FIELDS.residual_amount]}`,
                'data-testid': 'new_residual_amount',
                className: 'text-right',
                allowPrefix: true,
                onBlur: () => {
                  handleInputNewValue(
                    FIELDS.residual_amount,
                    newResidualAmount,
                    originalResidualAmount,
                    'residual_amount_error'
                  )
                },
              },
            },
          },
        ]
      : []),
  ]

  return (
    <StyledTableWrapper data-testid="dividing-detail-table">
      <StyledTableHeader>
        <div>{i18n.t('components.AssetDividing.source')}</div>
        <div>{i18n.t('components.AssetDividing.destination')}</div>
      </StyledTableHeader>
      <div className="row">
        <FormField className="source-item" title={i18n.t('components.AssetDividing.asset_code')}>
          <Form.Input name={FIELDS.divided_asset_code} bordered={false} underline readOnly />
        </FormField>
        <FormField title="" className="destination-item asset-code">
          <Form.Input name={FIELDS.new_code} readOnly />
          <FontAwesomeIcon icon={faMinus} />
          <NewBranchCodeWrapper>
            <Form.Input className="new_branch_code" name={FIELDS.new_branch_code} maxLength={3} onlyNumber />
            <Tooltip
              title={i18n.t('components.AssetDividing.branch_code_tooltip')}
              placement="topRight"
              arrowPointAtCenter
              color="#000"
              overlayStyle={{ maxWidth: 'unset' }}
              overlayInnerStyle={{ borderRadius: 4, width: 292, fontSize: 13, lineHeight: '28px' }}
            >
              <QuestionCircleFilled />
            </Tooltip>
          </NewBranchCodeWrapper>
        </FormField>
      </div>
      {rows.map(row => (
        <Row key={row.source.title} source={row.source} destination={row.destination} type={row?.type} />
      ))}
    </StyledTableWrapper>
  )
}
