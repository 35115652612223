import { UnderlineInput } from 'aa_common/front-end/antd'
import {
  FormikInfiniteScrollSelect,
  If,
  RedirectToMasterPageLink,
  UnborderCollapse,
} from 'aa_common/front-end/components'
import { ifElse, isEmptyValue, someTrue } from 'aa_common/front-end/helpers'
import { search as searchAreas } from 'api/app/area'
import { search as searchDepartments } from 'api/app/department'
import classNames from 'classnames'
import { ENDPOINTS, FEATURES_ID } from 'common/constants'
import { handlePermissionByFeatureId } from 'common/helpers'
import { loadNs } from 'common/i18n-config'
import { FORM_MODES } from 'common/models'
import { getIn, useFormikContext } from 'formik'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectUserPermission } from 'store/session/selectors'

import { Error } from '../styles'

const t = loadNs(['components/organisms/assets/asset-form', 'common'])

const { Item } = UnborderCollapse

type AfterAcquisitionSectionProps = {
  formMode: FORM_MODES
  showRequiredError?: boolean
}

export const AfterAcquisitionSection = ({ formMode, showRequiredError }: AfterAcquisitionSectionProps) => {
  const { data: permissions } = useSelector(selectUserPermission, isEqual)
  const { values, setFieldValue } = useFormikContext()

  const acquiredAt = getIn(values, 'acquired_at')
  const transitionAt = getIn(values, 'transition_at')
  const validAtFilter = useMemo(() => {
    return transitionAt || acquiredAt
  }, [acquiredAt, transitionAt])

  const isEditMode = formMode === FORM_MODES.EDIT

  const handleAreaChanged = useCallback((selectedValue: any, selectedArea: any) => {
    setFieldValue('area', ifElse(!isEmptyValue(selectedArea), selectedArea, undefined))
  }, [])

  const handleDepartmentChanged = useCallback((selectedValue: any, selectedDepartment: any) => {
    setFieldValue('department', ifElse(!isEmptyValue(selectedDepartment), selectedDepartment, undefined))
  }, [])

  return (
    <>
      <Item label={t('area')} required>
        <FormikInfiniteScrollSelect
          name="area_biid"
          valueField="biid"
          textField="name"
          additionalQueries={validAtFilter}
          disabled={isEditMode}
          executeInfiniteScroll={({ textSearch, pageSize, page, additionalQueries: validAtFilter }) => {
            return searchAreas({
              filters: { name: textSearch, valid_at: validAtFilter },
              pageSize,
              pageNumber: page,
            })
          }}
          onChange={handleAreaChanged}
          className={classNames({ error: showRequiredError && !get(values, 'area_biid') })}
          renderFooter={({ refetch, isLoading, isRefetching, isFetching }) => {
            const isExecuting = someTrue(isRefetching, isLoading, isFetching)
            return (
              <RedirectToMasterPageLink
                permissions={handlePermissionByFeatureId(FEATURES_ID.MASTER_AREAS, permissions)}
                urlName={ENDPOINTS.AREAS}
                isDisabledReload={isExecuting}
                pageName={t('master.areas')}
                onHandleReload={refetch}
              />
            )
          }}
        />
        <If condition={showRequiredError && !get(values, 'area_biid')}>
          <Error>{t('area_required_error')}</Error>
        </If>
      </Item>
      <Item label={t('submit_destination')}>
        <UnderlineInput name="area.submit_destination.name" placeholder={t('submit_destination_placeholder')} />
      </Item>
      <Item label={t('department_allocation_rate')}>
        <FormikInfiniteScrollSelect
          name="department_biid"
          valueField="biid"
          textField="name"
          additionalQueries={validAtFilter}
          onChange={handleDepartmentChanged}
          executeInfiniteScroll={({ textSearch, pageSize, page, additionalQueries: validAtFilter }) => {
            return searchDepartments({
              filters: { name: textSearch, valid_at: validAtFilter },
              pageSize,
              pageNumber: page,
            })
          }}
          renderFooter={({ refetch, isLoading, isRefetching, isFetching }) => {
            const isExecuting = someTrue(isRefetching, isLoading, isFetching)
            return (
              <RedirectToMasterPageLink
                permissions={handlePermissionByFeatureId(FEATURES_ID.MASTER_DEPARTMENT, permissions)}
                urlName={ENDPOINTS.DEPARTMENTS}
                isDisabledReload={isExecuting}
                pageName={t('master.departments')}
                onHandleReload={refetch}
              />
            )
          }}
        />
      </Item>
    </>
  )
}
