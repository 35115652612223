import { If, UnborderCollapse } from 'aa_common/front-end/components'
import { checkIsAfterDate, everyTrue, ifElse, someTrue } from 'aa_common/front-end/helpers'
import { DEPRECIATION_METHOD_CODE } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import { MoneyInput } from 'components/molecules'
import { getIn, useFormikContext } from 'formik'
import parser from 'html-react-parser'
import React from 'react'

import { getAllLedgerFieldNames } from '../helper'
import { useClearValueOnHide } from '../hooks/useClearOnHide'
import { useTransitionBookValues } from '../hooks/useTransitionBookValues'
import { prelineStyle } from '../styles'
import { ItemLabelTooltip } from './ItemLabelTooltip'

const { Item } = UnborderCollapse

const t = loadNs(['components/organisms/assets/asset-form', 'common'])

type TransitionBookValuesProps = {
  usageStartedAt?: string
  bookValueMax?: number
  ledgerIndex: number
  ledgerName: string
  disabled: boolean
  isCopied: boolean
  isTransitionDayIsFirstDayOfTerm: boolean
  isImportMode: boolean
}

const shouldRender = (hasTransition: boolean, depreciationCode: any) => {
  return everyTrue(
    hasTransition,
    ![
      DEPRECIATION_METHOD_CODE.SMALL_AMOUNT_DEPRECIABLE_ASSET,
      DEPRECIATION_METHOD_CODE.NON_DEPRECIATION,
      DEPRECIATION_METHOD_CODE.NON_DEPRECIABLE,
    ].includes(depreciationCode)
  )
}

export const TransitionBookValues = ({
  usageStartedAt = '',
  bookValueMax,
  ledgerIndex,
  ledgerName,
  disabled,
  isCopied,
  isTransitionDayIsFirstDayOfTerm,
  isImportMode,
}: TransitionBookValuesProps) => {
  const { values, setFieldValue } = useFormikContext()
  const {
    depreciationMethodCodeField,
    transitionAtField,
    transitionBeginningBookValueField,
    transitionBookValueField,
    bookValueAtChangeField,
    revisedAcquisitionAmountField,
  } = getAllLedgerFieldNames(ledgerIndex)
  const depreciationCode = getIn(values, depreciationMethodCodeField)
  const transitionAt = getIn(values, transitionAtField)
  const transitionBeginningBookValue = getIn(values, transitionBeginningBookValueField)
  const bookValueAtChange = getIn(values, bookValueAtChangeField)

  const shouldRenderReviseAcquisitionAmount = [
    DEPRECIATION_METHOD_CODE.NEW_DECLINING_BALANCE_200,
    DEPRECIATION_METHOD_CODE.NEW_DECLINING_BALANCE_250,
  ].includes(depreciationCode)

  const beginBookValueMax: number = ifElse(
    Number.isNaN(parseInt(String(bookValueAtChange), 10)),
    bookValueMax,
    bookValueAtChange
  )

  useTransitionBookValues(usageStartedAt, ledgerIndex, isImportMode, isTransitionDayIsFirstDayOfTerm, beginBookValueMax)
  useClearValueOnHide(revisedAcquisitionAmountField, setFieldValue, shouldRenderReviseAcquisitionAmount)

  const shouldRenderUI = shouldRender(!!transitionAt, depreciationCode)
  const isUsageStartAtBeforeTransitionAt = checkIsAfterDate(transitionAt, usageStartedAt)

  return (
    <If condition={shouldRenderUI}>
      <If condition={isUsageStartAtBeforeTransitionAt}>
        <Item
          label={
            <ItemLabelTooltip
              label={t('transition_beginning_book_value')}
              tooltip={parser(t('transition_beginning_book_value_tooltip'))}
            />
          }
          style={prelineStyle}
          required
          jsLabel={t('transition_beginning_book_value_of', { name: ledgerName })}
        >
          <MoneyInput
            name={transitionBeginningBookValueField}
            disabled={someTrue(disabled, isCopied)}
            maxValue={beginBookValueMax}
          />
        </Item>
      </If>
      <If condition={everyTrue(!isTransitionDayIsFirstDayOfTerm, isUsageStartAtBeforeTransitionAt)}>
        <Item
          label={
            <ItemLabelTooltip label={t('transition_book_value')} tooltip={parser(t('transition_book_value_tooltip'))} />
          }
          style={prelineStyle}
          required
          jsLabel={t('transition_book_value_of', { name: ledgerName })}
        >
          <MoneyInput
            name={transitionBookValueField}
            disabled={disabled || isCopied}
            maxValue={transitionBeginningBookValue}
          />
        </Item>
      </If>
      <If condition={shouldRenderReviseAcquisitionAmount}>
        <Item
          label={
            <ItemLabelTooltip
              label={t('revised_acquisition_amount')}
              tooltip={parser(t('revised_acquisition_amount_tooltip'))}
              tooltipMaxWidth={600}
            />
          }
          required
          jsLabel={t('revised_acquisition_amount_of', { name: ledgerName })}
        >
          <MoneyInput name={revisedAcquisitionAmountField} disabled={someTrue(disabled, isCopied)} />
        </Item>
      </If>
    </If>
  )
}
