/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ContentType, HttpClient, RequestParams } from './http-client'

export class ExportJournalHistories<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description Download download ExportJournalHistory
   *
   * @tags export_journal_histories
   * @name DownloadDetail
   * @summary Download ExportJournalHistory
   * @request GET:/export_journal_histories/{id}/download
   */
  downloadDetail = (id: number, params: RequestParams = {}) =>
    this.http.request<File, any>({
      path: `/export_journal_histories/${id}/download`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
