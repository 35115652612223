import { ifElse } from 'aa_common/front-end'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import { useSelector } from 'react-redux'
import { getExciseSettingsByDateList } from 'store/settings/excise/selectors'

import { EXCISE_TAX_METHOD } from '../../constants'

export const useGetSubsidyReduction = (data: any) => {
  const subsidyAmount = get(data, 'subsidy_amount')
  const reductionAmount = get(data, 'reduction_amount')

  const { data: exciseSetting } = useSelector(getExciseSettingsByDateList, isEqual)
  const isTaxMethodExcluded = exciseSetting?.tax_method === EXCISE_TAX_METHOD.EXCLUDED_TAX
  const acquisitionCost = ifElse(
    isTaxMethodExcluded,
    get(data, 'acquisition_cost_excluded_excise'),
    get(data, 'acquisition_cost')
  )

  return { subsidyAmount, reductionAmount, acquisitionCost }
}
