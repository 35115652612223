import { initSlice } from 'aa_common/front-end/zustand'
import { AxiosResponse } from 'axios'

import { tryCallApi } from '../helpers'
import { FeatureFlagsService, PresenterEvaluateBatchFeatureFlagResponse } from '../open-api'

export enum FeatureFlagNames {
  FixedAssetReduction = 'fixed_asset_reduction',
  RefactorPagePhase3 = 'refactor_page_phase3',
  RefactorPagePhase4 = 'refactor_page_phase4',
  RefactorPagePhase6 = 'refactor_page_phase6',
  RefactorPagePhase7 = 'refactor_page_phase7',
  RefactorPagePhase8 = 'refactor_page_phase8',
  RefactorPagePhase9 = 'refactor_page_phase9',
  DisableReductionDividing = 'disable_reduction_dividing',
  RegisterAcceleratedDepreciation = 'register-accelerated-depr',
  UpdateErrorPages = 'update-error-pages',
  RefactorAssetForm = 'refactor_asset_form',
  DepartmentDepreciationAmountReport = 'department_depreciation_amount',
  ReductionIncreaseDecreaseReport = 'apply-feature-reduction-increase-decrease-report',
}

export type FeatureNameType =
  | FeatureFlagNames.FixedAssetReduction
  | FeatureFlagNames.RefactorPagePhase3
  | FeatureFlagNames.RefactorPagePhase4
  | FeatureFlagNames.RefactorPagePhase6
  | FeatureFlagNames.RefactorPagePhase7
  | FeatureFlagNames.RefactorPagePhase8
  | FeatureFlagNames.RefactorPagePhase9
  | FeatureFlagNames.DisableReductionDividing
  | FeatureFlagNames.RegisterAcceleratedDepreciation
  | FeatureFlagNames.UpdateErrorPages
  | FeatureFlagNames.RefactorAssetForm
  | FeatureFlagNames.DepartmentDepreciationAmountReport
  | FeatureFlagNames.ReductionIncreaseDecreaseReport

const initState: Partial<Record<FeatureNameType, boolean>> = {}

export const openFeatureSlice = initSlice('open-features', initState)

export const featureList: FeatureType[] = [
  {
    featureName: FeatureFlagNames.FixedAssetReduction,
    defaultValue: false,
  },
  {
    featureName: FeatureFlagNames.RefactorPagePhase3,
    defaultValue: false,
    onProduction: true,
  },
  {
    featureName: FeatureFlagNames.RefactorPagePhase4,
    defaultValue: false,
    onProduction: true,
  },
  {
    featureName: FeatureFlagNames.RefactorPagePhase6,
    defaultValue: false,
    onProduction: true,
  },
  {
    featureName: FeatureFlagNames.RefactorPagePhase7,
    defaultValue: false,
    onProduction: true,
  },
  {
    featureName: FeatureFlagNames.RefactorPagePhase8,
    defaultValue: false,
  },
  {
    featureName: FeatureFlagNames.RefactorPagePhase9,
    defaultValue: false,
    onProduction: true,
  },
  {
    featureName: FeatureFlagNames.RegisterAcceleratedDepreciation,
    defaultValue: false,
  },
  {
    featureName: FeatureFlagNames.DisableReductionDividing,
    defaultValue: false,
  },
  {
    featureName: FeatureFlagNames.UpdateErrorPages,
    defaultValue: false,
    onProduction: true,
  },
  {
    featureName: FeatureFlagNames.RefactorAssetForm,
    defaultValue: false,
    onProduction: true,
  },
  {
    featureName: FeatureFlagNames.DepartmentDepreciationAmountReport,
    defaultValue: false,
  },
  {
    featureName: FeatureFlagNames.ReductionIncreaseDecreaseReport,
    defaultValue: false,
  },
]

export const isOpenFeatureDevMode = () => {
  const { REACT_APP_ENV, REACT_APP_RELAY_URL } = process.env
  return REACT_APP_ENV === 'local' && !REACT_APP_RELAY_URL
}

const isTestEnv = () => ['staging', 'beta', 'idev'].includes(String(process.env.REACT_APP_ENV))

export const checkAllFeatures = async (isPublic?: boolean) => {
  const state: Record<string | number, boolean> = {}

  const featureFlags = featureList.map(({ featureName, defaultValue, onProduction }) => ({
    key: featureName,
    default_value: isTestEnv() ? onProduction || defaultValue : defaultValue,
  }))

  let response: {
    data?: AxiosResponse<PresenterEvaluateBatchFeatureFlagResponse>
    error?: any
  }
  if (!isPublic) {
    response = await tryCallApi(FeatureFlagsService.evaluateBatchCreate)({ bool_flags: featureFlags })
  } else {
    response = await tryCallApi(FeatureFlagsService.featureFlagsEvaluateBatchCreate)({ bool_flags: featureFlags })
  }

  response?.data?.data?.bool_flags?.forEach(({ key, enabled }) => {
    if (key) {
      state[key] = enabled ?? false

      if (isOpenFeatureDevMode()) {
        state[key] = true
      }
    }
  })

  isTestEnv() &&
    featureList.forEach(({ featureName, onProduction, defaultValue }) => {
      state[featureName] = state[featureName] ?? (onProduction || defaultValue)
    })

  openFeatureSlice.setState(state, 'set-open-features')
}

type FeatureType = {
  featureName: FeatureNameType
  defaultValue: any
  onProduction?: boolean
}
