import { If } from 'aa_common/front-end/components'
import { yen } from 'common/helpers'
import { getNs } from 'common/i18n-config'
import { AssetLedger } from 'common/models'
import { DEPRECIATION_FIELDS } from 'components/organisms/assets/AssetForm/const'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import uniqueId from 'lodash/uniqueId'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectAssetDetailPage } from 'store/asset/selectors'

import { AmortizationTable } from './styles'
import { DepreciationComparisonTable } from './tables'
import { getDepreciationData } from './tables/helper'
import { useGetDepreciation } from './tables/useGetDepreciation'

interface IAmortizationInfoTable {
  ledger: AssetLedger
  depreciationRate: number | null
  isShowComparisonTable: boolean
  biid?: string
  acquisitionCost: number
}

const { t, useNsReady } = getNs(['components/organisms/assets/asset-detail', 'common'])

const AmortizationInfoTable = ({
  ledger,
  depreciationRate,
  isShowComparisonTable,
  biid,
  acquisitionCost,
}: IAmortizationInfoTable) => {
  useNsReady()
  const { renderDepreciationRate } = useGetDepreciation(ledger)
  const { asset } = useSelector(selectAssetDetailPage, isEqual)
  const subsidyAmount = get(asset, 'subsidy_amount', 0) as number
  const netAcquisitionCost = useMemo(() => {
    return acquisitionCost - subsidyAmount
  }, [acquisitionCost, subsidyAmount])

  const depreciationRenderData = getDepreciationData(
    t,
    ledger,
    depreciationRate,
    renderDepreciationRate,
    ledger.depreciation_method?.code,
    acquisitionCost
  )

  const renderTable = () => {
    return (
      <AmortizationTable>
        <tbody>
          {depreciationRenderData?.map(data => (
            <tr key={`row-${uniqueId()}`}>
              {data.map(item => (
                <>
                  <If condition={item.key !== DEPRECIATION_FIELDS.BOOK_VALUE_AT_CHANGE} key={`col-${uniqueId()}`}>
                    <th>{item.title}</th>
                    <td colSpan={data.length === 1 ? 3 : 0}>
                      <span>{item.value}</span>
                    </td>
                  </If>
                  <If
                    condition={
                      item?.key === DEPRECIATION_FIELDS.BOOK_VALUE_AT_CHANGE && yen(netAcquisitionCost) !== item.value
                    }
                    key={`col-${uniqueId()}`}
                  >
                    <th>{item.title}</th>
                    <td colSpan={3}>
                      <span>{item.value}</span>
                    </td>
                  </If>
                </>
              ))}
            </tr>
          ))}
        </tbody>
      </AmortizationTable>
    )
  }

  if (isShowComparisonTable) {
    return <DepreciationComparisonTable biid={biid} />
  }

  return renderTable()
}

export default AmortizationInfoTable
