/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { PresenterEvaluateBatchFeatureFlagRequest, PresenterEvaluateBatchFeatureFlagResponse } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class FeatureFlag<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description EvaluateBatch evaluate multiple feature flags
   *
   * @tags feature_flag
   * @name EvaluateBatchCreate
   * @summary EvaluateBatch evaluate multiple feature flags
   * @request POST:/feature_flags/evaluate_batch
   */
  evaluateBatchCreate = (Body: PresenterEvaluateBatchFeatureFlagRequest, params: RequestParams = {}) =>
    this.http.request<PresenterEvaluateBatchFeatureFlagResponse, any>({
      path: `/feature_flags/evaluate_batch`,
      method: 'POST',
      body: Body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description EvaluateBatchPublic evaluate multiple feature flags in public (no evaluation context)
   *
   * @tags feature_flag
   * @name FeatureFlagsEvaluateBatchCreate
   * @summary EvaluateBatchPublic evaluate multiple feature flags in public (no evaluation context)
   * @request POST:/public/feature_flags/evaluate_batch
   */
  featureFlagsEvaluateBatchCreate = (Body: PresenterEvaluateBatchFeatureFlagRequest, params: RequestParams = {}) =>
    this.http.request<PresenterEvaluateBatchFeatureFlagResponse, any>({
      path: `/public/feature_flags/evaluate_batch`,
      method: 'POST',
      body: Body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
