import { MonthlyClosing } from 'common/models'
import { PresenterOneMonthlyClosingResponse } from 'common/open-api'

export const selectFromFiscalYearAcceleratedDepr = (
  currentMonthlyClosing: MonthlyClosing,
  monthlyClosingWhenRegister?: PresenterOneMonthlyClosingResponse
) => {
  if (
    monthlyClosingWhenRegister?.from_term_month?.term?.year &&
    monthlyClosingWhenRegister?.from_term_month?.term?.year < currentMonthlyClosing?.from_term_month?.term?.year
  ) {
    return Number(monthlyClosingWhenRegister?.from_term_month?.term?.year)
  }

  return Number(currentMonthlyClosing?.from_term_month?.term?.year)
}
