import { css } from '@emotion/css'
import { TooltipIcon } from 'components/atoms'
import React from 'react'

type LabelProps = {
  label: string | React.ReactNode
  showTooltip?: boolean
  tooltip?: string | React.ReactNode
  tooltipMaxWidth?: number | string
}

export const ItemLabelTooltip = ({
  label,
  tooltip,
  showTooltip = true,
  tooltipMaxWidth = 550,
}: React.PropsWithChildren<LabelProps>) => {
  return (
    <>
      <label style={{ marginRight: 5, fontWeight: 'inherit' }}>{label}</label>
      {showTooltip && tooltip && (
        <TooltipIcon overlayClassName={TooltipClass} overlayStyle={{ maxWidth: tooltipMaxWidth }}>
          {tooltip}
        </TooltipIcon>
      )}
    </>
  )
}

const TooltipClass = css`
  &.ant-tooltip {
    .ant-tooltip-content {
      max-width: unset;
    }
  }
`
