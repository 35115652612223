import { defaultTo, isEmptyValue } from 'aa_common/front-end/helpers'
import { ASSET_TYPE, DEFAULT_MEMORANDUM_VALUE } from 'common/constants'
import { AssetLedger, FORM_MODES } from 'common/models'
import { unitsSlice } from 'containers/master/units/store'
import { getIn, useFormikContext } from 'formik'
import { useCallback } from 'react'

import { getFormMode } from '../helper'

export const useGetMemorandumValue = (
  memorandumValueMap: Map<number, number>,
  formMode: FORM_MODES,
  assetType?: number
) => {
  const units = defaultTo(
    unitsSlice.useSlice(state => state?.data?.units),
    []
  )
  const { values } = useFormikContext()

  const unitId = getIn(values, 'unit_id')
  const quantity = getIn(values, 'quantity')
  const linkMemorandumValueQuantity = !!units?.find(unit => unit.id === unitId)?.link_memorandum_value_quantity
  const { isNewMode } = getFormMode(formMode)

  return useCallback(
    (ledger: AssetLedger) => {
      if (linkMemorandumValueQuantity && assetType === ASSET_TYPE.INTANGIBLE) return DEFAULT_MEMORANDUM_VALUE.INTANGIBLE
      if (linkMemorandumValueQuantity && memorandumValueMap.size)
        return defaultTo(memorandumValueMap.get(ledger.ledger_setting_id!), quantity)

      if (
        ledger?.is_memorandum_value_entered ||
        (!isEmptyValue(ledger?.memorandum_value) && !isNewMode) ||
        (!isEmptyValue(ledger?.memorandum_value) &&
          !linkMemorandumValueQuantity &&
          ![DEFAULT_MEMORANDUM_VALUE.INTANGIBLE, DEFAULT_MEMORANDUM_VALUE.TANGIBLE].includes(
            ledger?.memorandum_value as any
          ))
      )
        return ledger?.memorandum_value

      if (quantity >= 0 && linkMemorandumValueQuantity) return quantity

      return assetType === ASSET_TYPE.TANGIBLE ? DEFAULT_MEMORANDUM_VALUE.TANGIBLE : DEFAULT_MEMORANDUM_VALUE.INTANGIBLE
    },
    [assetType, linkMemorandumValueQuantity, memorandumValueMap, isNewMode, quantity]
  )
}
