import { ifElse } from 'aa_common/front-end/helpers'

import { ExciseInputSetting } from '../types'

export const setAcquisitionCost = (
  acquisitionCostInput: number,
  exciseValue: number,
  exciseInputSetting: ExciseInputSetting,
  setFieldValue: (field: string, value: any) => void
) => {
  const { isInputTaxWhenRegister, isTaxMethodExcluded, isInternalTax } = exciseInputSetting

  if (!isInputTaxWhenRegister) {
    setFieldValue('acquisition_cost', acquisitionCostInput)
    setFieldValue('acquisition_cost_excluded_excise', acquisitionCostInput)
  } else if (!isTaxMethodExcluded) {
    setFieldValue('acquisition_cost', acquisitionCostInput)
    setFieldValue('acquisition_cost_excluded_excise', undefined)
  } else {
    setFieldValue('acquisition_cost', ifElse(isInternalTax, acquisitionCostInput, acquisitionCostInput + exciseValue))
    setFieldValue(
      'acquisition_cost_excluded_excise',
      ifElse(isInternalTax, acquisitionCostInput - exciseValue, acquisitionCostInput)
    )
  }
}
