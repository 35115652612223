/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { PresenterError, PresenterOneDepreciationRateResponse } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class DepreciationRates<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description List depreciation rate
   *
   * @tags depreciation_rates
   * @name DepreciationRatesList
   * @summary List the depreciation rate
   * @request GET:/depreciation_rates
   */
  depreciationRatesList = (
    query?: {
      'filter[depreciation_method]'?: number
      'filter[service_life]'?: number
      sort?: string[]
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterOneDepreciationRateResponse, PresenterError>({
      path: `/depreciation_rates`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
