/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PresenterEditTermRequest,
  PresenterError,
  PresenterGenerateTermRequest,
  PresenterOneShortTermResponse,
  PresenterOneTermMonthResponse,
  PresenterOneTermPagingResponse,
  PresenterOneTermResponse,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class AccountingPeriods<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description ListFullTerms accounting periods
   *
   * @tags accounting_periods
   * @name FullTermsList
   * @summary ListFullTerms the accounting periods
   * @request GET:/accounting_periods/full_terms
   */
  fullTermsList = (
    query?: {
      'filter[end_date]'?: string
      'filter[period]'?: number
      'filter[start_date]'?: string
      'filter[year]'?: number
      sort?: string[]
      'page[number]'?: number
      'page[size]'?: number
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterOneShortTermResponse[], PresenterError>({
      path: `/accounting_periods/full_terms`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Generate terms
   *
   * @tags accounting_periods
   * @name GenerateTermsCreate
   * @summary Generate the terms
   * @request POST:/accounting_periods/generate_terms
   */
  generateTermsCreate = (body: PresenterGenerateTermRequest, params: RequestParams = {}) =>
    this.http.request<PresenterOneTermResponse[], PresenterError>({
      path: `/accounting_periods/generate_terms`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Get paging info with term_month info accounting period with id
   *
   * @tags accounting_periods
   * @name PagingByTermList
   * @summary Get the paging info accounting period by ID
   * @request GET:/accounting_periods/paging_by_term
   */
  pagingByTermList = (
    query: {
      cycle_type: number
      term_id: number
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterOneTermPagingResponse[], PresenterError>({
      path: `/accounting_periods/paging_by_term`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description GetTermMonthByDate accounting periods
   *
   * @tags accounting_periods
   * @name TermMonthByDateList
   * @summary GetTermMonthByDate the accounting periods
   * @request GET:/accounting_periods/term_month_by_date
   */
  termMonthByDateList = (
    query?: {
      'filter[time_date]'?: string
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterOneTermMonthResponse[], PresenterError>({
      path: `/accounting_periods/term_month_by_date`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description ListTermMonths accounting periods
   *
   * @tags accounting_periods
   * @name TermMonthsList
   * @summary ListTermMonths the accounting periods
   * @request GET:/accounting_periods/term_months
   */
  termMonthsList = (
    query?: {
      'filter[end_date]'?: string
      'filter[start_date]'?: string
      'page[number]'?: number
      'page[size]'?: number
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterOneTermMonthResponse[], PresenterError>({
      path: `/accounting_periods/term_months`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description ListTerms accounting periods
   *
   * @tags accounting_periods
   * @name TermsList
   * @summary ListTerms the accounting periods
   * @request GET:/accounting_periods/terms
   */
  termsList = (
    query?: {
      'filter[end_date]'?: string
      'filter[period]'?: number
      'filter[start_date]'?: string
      'filter[year]'?: number
      sort?: string[]
      'page[number]'?: number
      'page[size]'?: number
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterOneShortTermResponse[], PresenterError>({
      path: `/accounting_periods/terms`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Update and reenerate terms
   *
   * @tags accounting_periods
   * @name TermsUpdate
   * @summary Update and regenerate the terms
   * @request PUT:/accounting_periods/terms
   */
  termsUpdate = (body: PresenterEditTermRequest, params: RequestParams = {}) =>
    this.http.request<PresenterOneTermResponse[], PresenterError>({
      path: `/accounting_periods/terms`,
      method: 'PUT',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Generate and store terms to the database
   *
   * @tags accounting_periods
   * @name TermsCreate
   * @summary Generate and store the terms
   * @request POST:/accounting_periods/terms
   */
  termsCreate = (body: PresenterGenerateTermRequest, params: RequestParams = {}) =>
    this.http.request<PresenterOneTermResponse[], PresenterError>({
      path: `/accounting_periods/terms`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Get accounting period with id
   *
   * @tags accounting_periods
   * @name TermsDetail
   * @summary Get the accounting period by ID
   * @request GET:/accounting_periods/terms/{id}
   */
  termsDetail = (id: number, params: RequestParams = {}) =>
    this.http.request<PresenterOneTermResponse, PresenterError>({
      path: `/accounting_periods/terms/${id}`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
