import React, { ReactNode } from 'react'

import { Error, Title, Wrapper } from './styles'

type Props = {
  title?: ReactNode
  errors?: string | Array<string>
  style?: React.CSSProperties
}

const renderErrors = (errors: string | Array<string>) => {
  if (typeof errors === 'string') return <Error className="error">{errors}</Error>
  return Array.from(new Set(errors)).map(error => (
    <Error key={error} className="error">
      {error}
    </Error>
  ))
}

export const ErrorsList = ({ title, errors, style }: Props) => {
  return (
    <Wrapper style={style}>
      {title && <Title className="title">{title}</Title>}
      {errors && renderErrors(errors)}
    </Wrapper>
  )
}
