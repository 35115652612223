import { isEqual } from 'lodash'
import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { sessionsSlice } from 'share-store/sessions.store'
import { getUserInfoSelector, selectCurrentOffice } from 'store/session/selectors'

import { PresenterOneOfficeUserResponse } from '../../common/open-api'

const ZustandWatcher = () => {
  const currentOffice = useSelector(selectCurrentOffice, isEqual).data
  const currentUser = useSelector(getUserInfoSelector, isEqual) as PresenterOneOfficeUserResponse
  const exist = useRef<boolean>(false)

  useEffect(() => {
    exist.current = true
    return () => {
      exist.current = false
    }
  }, [])

  useEffect(() => {
    exist.current && sessionsSlice.setState({ currentOffice, currentUser })
  }, [currentOffice, currentUser])

  return <div />
}

export default ZustandWatcher
