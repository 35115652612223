import styled from '@emotion/styled'
import { Layout } from 'antd'

export const Wrapper = styled.div`
  position: relative;
  min-width: 1000px;
  color: #333333;
`

export const Header = styled(Layout.Header)`
  padding: 0;
  height: auto;
  position: sticky;
  top: 0;
  z-index: 2;
`

export const Body = styled(Layout)`
  position: relative;
  z-index: 1;
  min-height: calc(100vh - 40px);
`

export const BreadcrumbArea = styled.div`
  background-color: #ffffff;
  border-bottom: 1px solid #d4d8dd;
  &.has-search-panel {
    border-bottom: none;
  }
`

export const ActionWrapper = styled.div`
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;

  button.add-new {
    background: linear-gradient(180deg, #3b7de9 0%, #0054ac 100%) !important;
    color: white !important;
  }
`

export const WrapContent = styled.div`
  background-color: #eff1f4;
  height: 100%;
  display: table-row;
`

export const TopMessage = styled.div`
  position: absolute;
  top: 0;
  width: calc(100% - 130px);
  z-index: 99;
`

export const FilterPanel = styled.div`
  background: #ffffff;
`

export const MainTemplateLayout = styled(Layout)`
  padding: 0 0 24px;
`

export const SiteLayout = styled(Layout.Content)`
  margin: 0;
  padding: 15px;

  &.bg-white {
    background-color: #ffffff;
  }
`
