import { AcceleratedDepreciationItem } from '../types'

export const mergeTermsDepreciationToList = (
  acceleratedDepreciationGroups: { [p: string]: AcceleratedDepreciationItem },
  acceleratedDepreciation: AcceleratedDepreciationItem[]
) => {
  const clonedAcceleratedDepreciationGroups = { ...acceleratedDepreciationGroups }
  const mergedAcceleratedDepreciation: AcceleratedDepreciationItem[] = []
  acceleratedDepreciation.forEach(item => {
    const relatedGroup = clonedAcceleratedDepreciationGroups[String(item.period)]
    if (relatedGroup) {
      mergedAcceleratedDepreciation.push({ ...relatedGroup })
      delete clonedAcceleratedDepreciationGroups[String(item.period)]
    }
    mergedAcceleratedDepreciation.push({ ...item })
  })
  return mergedAcceleratedDepreciation
}
