import { If, UnborderCollapse, UnderlineMoneyInput } from 'aa_common/front-end/components'
import { everyTrue, ifElse, isNumberHaveValue } from 'aa_common/front-end/helpers'
import { loadNs } from 'common/i18n-config'
import { FORM_MODES } from 'common/models'
import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import { MoneyInput } from 'components/molecules'
import { getIn, useFormikContext } from 'formik'
import parser from 'html-react-parser'
import moment from 'moment/moment'
import React, { useMemo } from 'react'

import { getFormMode } from '../helper'
import { useSubsidyAmount } from '../hooks/use-subsidy-amount'
import { ItemLabelTooltip } from './ItemLabelTooltip'

const { Item } = UnborderCollapse

const t = loadNs(['components/organisms/assets/asset-form', 'common'])

type ReductionAmountProps = {
  formMode: FORM_MODES
  acquisitionCostValueRefer?: number
  setBookValueReferUpdatedManually: () => void
}

export const ReductionAmount = ({
  formMode,
  acquisitionCostValueRefer,
  setBookValueReferUpdatedManually,
}: ReductionAmountProps) => {
  const { isEnable: isEnableForFixedAssetReduction } = useCheckFlag(FeatureFlagNames.FixedAssetReduction)

  const { values } = useFormikContext()
  const acquiredAt = getIn(values, 'acquired_at')
  const usageStartedAt = getIn(values, 'usage_started_at')
  const hasTransition = !!getIn(values, 'transition_at')
  const reductionAmount = getIn(values, 'reduction_amount')
  const netAcquisitionCost = getIn(values, 'net_acquisition_cost')

  const { isEditMode } = getFormMode(formMode)

  const isDisabledSubsidyAmount = useMemo(
    () => isEditMode || !usageStartedAt || (!moment(acquiredAt).isSame(moment(usageStartedAt)) && !hasTransition),
    [isEditMode, hasTransition, acquiredAt, usageStartedAt]
  )

  useSubsidyAmount(acquisitionCostValueRefer)

  return (
    <If condition={isEnableForFixedAssetReduction}>
      <Item
        label={<ItemLabelTooltip label={t('subsidy_amount')} tooltip={parser(t('subsidy_amount_tooltip_message'))} />}
      >
        <MoneyInput
          name="subsidy_amount"
          disabled={isDisabledSubsidyAmount}
          onChange={setBookValueReferUpdatedManually}
        />
      </Item>
      <Item label={t('reduction_amount')}>
        <UnderlineMoneyInput
          name="reduction_amount"
          editable={everyTrue(Boolean(hasTransition), !isDisabledSubsidyAmount)}
          placeholder={t('reduction_amount_placeholder')}
          style={{ textAlign: ifElse(!isNumberHaveValue(reductionAmount), 'left', 'right') }}
          onChange={setBookValueReferUpdatedManually}
        />
      </Item>
      <Item label={t('net_acquisition_cost')}>
        <UnderlineMoneyInput
          style={{ textAlign: ifElse(!isNumberHaveValue(netAcquisitionCost), 'left', 'right') }}
          name="net_acquisition_cost"
          placeholder={t('net_acquisition_cost_placeholder')}
        />
      </Item>
    </If>
  )
}
