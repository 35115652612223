import { messageSuccess } from 'aa_common/front-end/antd'
import { getNs } from 'common/i18n-config'
import { FixedAssetsService } from 'common/open-api'
import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'

import { AcceleratedDepreciationItem } from '../types'

const { t } = getNs(['components/organisms/assets/asset-detail'])

export const useSubmitAcceleratedDepreciation = (
  dataSource: AcceleratedDepreciationItem[],
  onSubmitSuccess: () => void,
  selectedDate?: string,
  ledgerId?: number
) => {
  const { biid } = useParams<{ biid: string }>()
  const [isSubmitting, setSubmitting] = useState(false)

  const handleSubmit = useCallback(async () => {
    try {
      setSubmitting(true)
      const depreciationMonths = dataSource
        .filter(item => !item.isParent)
        .map(({ term_month_id, original_depreciation_amount, depreciation_amount, end_of_term_book_value }) => ({
          term_month_id,
          original_depreciation_amount,
          adjust_depreciation_amount: depreciation_amount,
          ending_book_value: end_of_term_book_value,
        }))

      const payload = {
        selected_date: selectedDate,
        ledger_setting_id: ledgerId,
        depr_months: depreciationMonths,
      }

      const response = await FixedAssetsService.depreciationResultsAdjustUpdate(biid, payload)
      setSubmitting(false)
      if (response.data) {
        messageSuccess(t('registered_accelerated_depreciation'))
        onSubmitSuccess()
      }
    } catch {
      setSubmitting(false)
    }
  }, [dataSource, onSubmitSuccess, selectedDate, ledgerId, biid])

  return { handleSubmit, isSubmitting }
}
