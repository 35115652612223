import { Button } from 'aa_common/front-end/antd'
import { If, UnborderCollapse } from 'aa_common/front-end/components'
import { DatePicker } from 'antd'
import { DATE_FORMAT, DATE_FORMAT_RFC } from 'common/constants'
import { formatDate } from 'common/helpers'
import { getNs } from 'common/i18n-config'
import { AssetLedger } from 'common/models'
import { PresenterFixedAssetDepreciationResponse } from 'common/open-api'
import isEqual from 'lodash/isEqual'
import moment from 'moment'
import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/selectors'

import { hasNegativeAcceleratedValue } from '../../helpers/has-negative-accelerated-value'
import { useAddTermsDepreciation } from '../../hooks/use-add-terms-depreciation'
import { useCalculateAcceleratedDepreciation } from '../../hooks/use-calculate-accelerated-depreciation'
import { useDisableRegisterButton } from '../../hooks/use-disable-register-button'
import { usePlannedDate } from '../../hooks/use-planned-date'
import { useSubmitAcceleratedDepreciation } from '../../hooks/use-submit-accelerated-depreciation'
import { StyledRightDrawer, StyledTopErrorMessage, StyledTopSection, StyledWarningMessage } from '../../styles'
import { EditableTable } from './editable-table'

const { Item } = UnborderCollapse
const { t, useNsReady } = getNs(['common', 'components/organisms/assets/asset-detail'])

export const AcceleratedDepreciationDrawer = ({
  assetLedger,
  depreciationResults: currentDepreciationResults,
  onModalClose,
  onRegisterSuccess,
}: Props) => {
  useNsReady()
  const currentMonthlyClosing = useSelector(selectMonthlyClosingCurrent, isEqual)
  const { isDateChanged, selectedDate, changeSelectedDate } = usePlannedDate()
  const { acceleratedDepreciation, handleInputChanged } = useCalculateAcceleratedDepreciation(
    currentDepreciationResults,
    assetLedger,
    selectedDate,
    isDateChanged
  )
  const onSubmitSuccess = useCallback(() => {
    onModalClose?.()
    onRegisterSuccess?.()
  }, [onModalClose, onRegisterSuccess])

  const { handleSubmit, isSubmitting } = useSubmitAcceleratedDepreciation(
    acceleratedDepreciation,
    onSubmitSuccess,
    selectedDate,
    assetLedger?.ledger_setting?.id as number
  )
  const acceleratedDepreciationWithTerms = useAddTermsDepreciation(acceleratedDepreciation)
  const isRegisterButtonDisabled = useDisableRegisterButton(
    acceleratedDepreciationWithTerms,
    isSubmitting,
    selectedDate
  )

  const isSelectedDateAfterLatestDepreciationPeriod = useMemo(
    () => acceleratedDepreciation.some(item => item.isExtraMonth),
    [acceleratedDepreciation]
  )

  const hasNegativeValue = hasNegativeAcceleratedValue(acceleratedDepreciationWithTerms)

  return (
    <StyledRightDrawer
      title={t('accelerated_depreciation_registration')}
      visible
      onClose={onModalClose}
      width={693}
      extra={
        <Button disabled={isRegisterButtonDisabled} onClick={handleSubmit}>
          {t('actions.register')}
        </Button>
      }
    >
      <If condition={hasNegativeValue}>
        <StyledTopErrorMessage>{t('depreciation_amount_is_negative')}</StyledTopErrorMessage>
      </If>

      <StyledTopSection>
        <Item label={t('selected_date')} contentWidth={175}>
          <DatePicker
            name="selected_date"
            allowClear={false}
            format={DATE_FORMAT}
            popupStyle={{ position: 'fixed' }}
            disabledDate={date => moment(date).isBefore(currentMonthlyClosing?.from_term_month?.start_date)}
            onChange={date => changeSelectedDate(formatDate(date, DATE_FORMAT_RFC) as string)}
            value={selectedDate ? moment(selectedDate, DATE_FORMAT) : undefined}
          />
        </Item>
      </StyledTopSection>

      <If condition={isSelectedDateAfterLatestDepreciationPeriod}>
        <StyledWarningMessage>{t('selected_date_is_after_latest_depreciation_period')}</StyledWarningMessage>
      </If>

      <EditableTable dataSource={acceleratedDepreciationWithTerms} handleInputChanged={handleInputChanged} />
    </StyledRightDrawer>
  )
}

type Props = {
  assetLedger?: AssetLedger
  depreciationResults: PresenterFixedAssetDepreciationResponse
  onModalClose?: () => void
  onRegisterSuccess?: () => void
}
