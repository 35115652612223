import 'moment/locale/ja'
import 'common/i18n-config'
import './assets/styles/antd.css'

import { waitFor } from 'aa_common/front-end/helpers'
import { ConfigProvider } from 'antd'
import jaJP from 'antd/lib/locale/ja_JP'
import { ErrorBoundaryWithRollbar } from 'components/organisms'
import dotenv from 'dotenv'
import { AntMessageInstanceProvider } from 'hooks/useAntMessageHook'
import { ModalProvider } from 'hooks/useModalHook'
import InternalServerErrorPage from 'pages/errors/InternalServerErrorPage'
import React from 'react'
import ReactDom from 'react-dom'
import { Provider } from 'react-redux'

import { GlobalStyles } from './assets/styles/global-styles'
import App from './components/App'
import store from './store'

dotenv.config()
;(async () => {
  await waitFor('i18n')
  ReactDom.render(
    <ErrorBoundaryWithRollbar fallback={<InternalServerErrorPage />}>
      <Provider store={store}>
        <ConfigProvider locale={jaJP} autoInsertSpaceInButton={false}>
          <ModalProvider>
            <AntMessageInstanceProvider>
              <App />
            </AntMessageInstanceProvider>
          </ModalProvider>
        </ConfigProvider>
      </Provider>
      <GlobalStyles />
    </ErrorBoundaryWithRollbar>,
    document.querySelector('#root')
  )
})()
