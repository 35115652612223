import { If } from 'aa_common/front-end/components'
import { everyTrue, isEmptyValue } from 'aa_common/front-end/helpers'
import { useBoolean } from 'aa_common/front-end/hooks'
import { DEPRECIATION_METHOD_CODE } from 'common/constants'
import { FORM_MODES } from 'common/models'
import { getIn, useFormikContext } from 'formik'
import React from 'react'

import { DEPRECIATION_FIELDS } from '../const'
import {
  genLedgerFieldName,
  getFormMode,
  shouldRenderAtChangeFields,
  shouldRenderBookValueAtChange,
  transitionAtSameAsStartUsage,
} from '../helper'
import { useCalculateDepreciationRate } from '../hooks/use-calculate-depreciation-rate'
import { useLedgerCopy } from '../hooks/use-ledger-copy'
import { BeginningBookValue } from './BeginningBookValue'
import { BookValueAtChange } from './BookValueAtChange'
import { DepreciatedMonthsInput } from './DepreciatedMonthsInputNew'
import { DepreciationAmountFields } from './DepreciationAmountFields'
import { DepreciationLedgerHeader } from './DepreciationLedgerHeader'
import { DepreciationMethods } from './DepreciationMethods'
import { NumberAverageUsagePeriod } from './NumberAverageUsagePeriod'
import { ServiceLife } from './ServiceLife'
import { ServiceLifeAfterChange } from './ServiceLifeAfterChange'
import { TransitionAt } from './TransitionAt'
import { TransitionBookValues } from './TransitionBookValuesNew'

type Props = {
  ledgerIndex: number
  disabled?: boolean
  isTransitionDayIsFirstDayOfTerm?: boolean
  ledgerName?: string
  showRequiredError?: boolean
  isDisabledTransitionAt: boolean
  onAfterChangedTransitionAt: any
  formMode: FORM_MODES
  bookValueRefer?: number
  bookValueReferUpdatedManually?: boolean
  acquisitionCostValueRefer?: number
}

export const DepreciationLedger: React.FC<Props> = ({
  ledgerIndex,
  disabled = false,
  isTransitionDayIsFirstDayOfTerm = false,
  ledgerName = '',
  showRequiredError = false,
  isDisabledTransitionAt,
  onAfterChangedTransitionAt,
  formMode,
  bookValueRefer,
  bookValueReferUpdatedManually,
  acquisitionCostValueRefer,
}) => {
  const { values } = useFormikContext()

  const serviceLifeField = genLedgerFieldName(DEPRECIATION_FIELDS.SERVICE_LIFE, ledgerIndex)
  const serviceLifeAfterChangeField = genLedgerFieldName(DEPRECIATION_FIELDS.SERVICE_LIFE_AFTER_CHANGE, ledgerIndex)
  const bookValueAtChangeField = genLedgerFieldName(DEPRECIATION_FIELDS.BOOK_VALUE_AT_CHANGE, ledgerIndex)
  const depreciationMethodCodeField = genLedgerFieldName(DEPRECIATION_FIELDS.DEPRECIATION_METHOD_CODE, ledgerIndex)
  const transitionAtField = genLedgerFieldName(DEPRECIATION_FIELDS.TRANSITION_AT, ledgerIndex)
  const isCopiedField = genLedgerFieldName(DEPRECIATION_FIELDS.IS_COPIED, ledgerIndex)

  const depreciationCode = getIn(values, depreciationMethodCodeField)
  const transitionAt = getIn(values, transitionAtField)
  const usageStartedAt = getIn(values, 'usage_started_at')
  const hasTransition = getIn(values, 'has_transition')
  const isCopied = getIn(values, isCopiedField)
  const serviceLifeValue = getIn(values, serviceLifeField)
  const [serviceLifeUpdatedManually, setServiceLifeUpdatedManually] = useBoolean(false)

  const { isImportMode } = getFormMode(formMode)

  const isEnableFullFields = ![
    DEPRECIATION_METHOD_CODE.NON_DEPRECIATION,
    DEPRECIATION_METHOD_CODE.NON_DEPRECIABLE,
  ].includes(depreciationCode)

  useLedgerCopy(ledgerIndex, isCopied)
  useCalculateDepreciationRate(ledgerIndex)

  return (
    <div>
      <DepreciationLedgerHeader ledgerName={ledgerName} ledgerIndex={ledgerIndex} formMode={formMode} />
      <DepreciationMethods
        ledgerName={ledgerName}
        ledgerIndex={ledgerIndex}
        disabled={disabled}
        showRequiredError={showRequiredError}
        afterDepreciationChanged={setServiceLifeUpdatedManually}
      />
      <If condition={isEnableFullFields}>
        <ServiceLife
          ledgerIndex={ledgerIndex}
          disabled={disabled}
          onServiceLifeChanged={setServiceLifeUpdatedManually}
        />
      </If>
      <NumberAverageUsagePeriod disabled={disabled} ledgerIndex={ledgerIndex} />
      <If condition={isEnableFullFields}>
        <ServiceLifeAfterChange
          fieldName={serviceLifeAfterChangeField}
          enabled={everyTrue(
            !disabled,
            !isCopied,
            !isEmptyValue(serviceLifeValue),
            !transitionAtSameAsStartUsage(transitionAt, usageStartedAt)
          )}
          isShown={shouldRenderAtChangeFields(hasTransition, depreciationCode)}
          referValue={serviceLifeValue}
          isCopied={Boolean(isCopied)}
          isUpdateReferManually={bookValueReferUpdatedManually || serviceLifeUpdatedManually}
          forceResetToRefer={transitionAtSameAsStartUsage(transitionAt, usageStartedAt)}
        />
      </If>
      <TransitionAt
        ledgerIndex={ledgerIndex}
        ledgerName={ledgerName}
        formMode={formMode}
        showRequiredError={showRequiredError}
        disabled={isDisabledTransitionAt}
        onAfterChangedTransitionAt={onAfterChangedTransitionAt}
      />
      <If condition={isEnableFullFields}>
        <BookValueAtChange
          fieldName={bookValueAtChangeField}
          enabled={everyTrue(
            !disabled,
            !isCopied,
            !isEmptyValue(bookValueRefer),
            !transitionAtSameAsStartUsage(transitionAt, usageStartedAt)
          )}
          isShown={shouldRenderBookValueAtChange(hasTransition, depreciationCode, transitionAt)}
          referValue={bookValueRefer}
          isUpdateReferManually={bookValueReferUpdatedManually || serviceLifeUpdatedManually}
          forceResetToRefer={transitionAtSameAsStartUsage(transitionAt, usageStartedAt)}
        />
      </If>
      <TransitionBookValues
        usageStartedAt={usageStartedAt}
        bookValueMax={bookValueRefer}
        ledgerIndex={ledgerIndex}
        ledgerName={ledgerName}
        disabled={disabled}
        isCopied={isCopied}
        isTransitionDayIsFirstDayOfTerm={isTransitionDayIsFirstDayOfTerm}
        isImportMode={isImportMode}
      />
      <DepreciatedMonthsInput
        ledgerIndex={ledgerIndex}
        ledgerName={ledgerName}
        disabled={disabled}
        isCopied={isCopied}
      />
      <BeginningBookValue
        ledgerIndex={ledgerIndex}
        hasTransition={hasTransition}
        depreciationCode={depreciationCode}
        referValue={acquisitionCostValueRefer}
      />
      <DepreciationAmountFields
        ledgerIndex={ledgerIndex}
        ledgerName={ledgerName}
        disabled={disabled}
        formMode={formMode}
        bookValueRefer={bookValueRefer}
      />
    </div>
  )
}
