import { useEffect, useState } from 'react'

import { calculateTermsDepreciation } from '../helpers/calculate-terms-depreciation'
import { getTermsForAcceleratedDepreciation } from '../helpers/get-terms-depreciation'
import { mergeTermsDepreciationToList } from '../helpers/merge-terms-depreciation-to-list'
import { AcceleratedDepreciationItem } from '../types'

export const useAddTermsDepreciation = (acceleratedDepreciation: AcceleratedDepreciationItem[]) => {
  const [acceleratedDepreciationWithTerms, setAcceleratedDepreciationWithTerms] = useState<
    AcceleratedDepreciationItem[]
  >([])
  useEffect(() => {
    const foundTerms = getTermsForAcceleratedDepreciation(acceleratedDepreciation)
    const acceleratedDepreciationGroups = calculateTermsDepreciation(foundTerms, acceleratedDepreciation)
    setAcceleratedDepreciationWithTerms(
      mergeTermsDepreciationToList(acceleratedDepreciationGroups, acceleratedDepreciation)
    )
  }, [acceleratedDepreciation])

  return acceleratedDepreciationWithTerms
}
