import { checkIsAfterDate, someTrue } from 'aa_common/front-end/helpers'
import { DEPRECIATION_METHOD_CODE } from 'common/constants'
import { getNs } from 'common/i18n-config'
import { AssetLedger } from 'common/models'
import { PresenterFixedAssetDepreciationResponse } from 'common/open-api'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import last from 'lodash/last'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentMonthlyClosing } from 'store/accounting/monthlyClosing/selectors'
import { selectAssetDetailPage } from 'store/asset/selectors'

const { t } = getNs(['common', 'components/organisms/assets/asset-detail'])

export const useAcceleratedDepreciationTooltip = (
  assetLedger: AssetLedger,
  depreciationResults: PresenterFixedAssetDepreciationResponse
) => {
  const { asset } = useSelector(selectAssetDetailPage, isEqual)
  const { data: currentMonthlyClosing } = useSelector(selectCurrentMonthlyClosing, isEqual)
  const lastTerm = last(depreciationResults?.terms)
  const endDateOfCurrentMonthlyClosing = get(currentMonthlyClosing, 'to_term_month.end_date')
  const isFullyDepreciatedFixedAsset = checkIsAfterDate(
    endDateOfCurrentMonthlyClosing,
    get(lastTerm, 'end_date') as string
  )

  return useMemo(() => {
    const depreciationMethodCode = get(assetLedger, 'depreciation_method_code') as number
    const isMethodExcluded = [
      DEPRECIATION_METHOD_CODE.SMALL_AMOUNT_DEPRECIABLE_ASSET,
      DEPRECIATION_METHOD_CODE.NON_DEPRECIABLE,
      DEPRECIATION_METHOD_CODE.NON_DEPRECIATION,
    ].includes(depreciationMethodCode)

    const isSoldOrRetiredOrImpaired = someTrue(!!asset?.sold_at, !!asset?.retired_at, !!asset?.impaired_at)

    const CONTINUE_DEPRECIATION_CODE = 1
    const isContinueToDepreciate =
      depreciationMethodCode === DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD &&
      assetLedger?.ledger_setting?.bulk_equal_installment_depreciation_method === CONTINUE_DEPRECIATION_CODE

    const isDisabled = someTrue(
      isMethodExcluded,
      isFullyDepreciatedFixedAsset,
      isSoldOrRetiredOrImpaired,
      isContinueToDepreciate
    )

    const tooltipContentByDMethod: { [key: number]: string } = {
      [DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD]: t('tooltip_content.cannot_adjust_with_d30'),
      [DEPRECIATION_METHOD_CODE.SMALL_AMOUNT_DEPRECIABLE_ASSET]: t('tooltip_content.cannot_adjust_with_d80'),
      [DEPRECIATION_METHOD_CODE.NON_DEPRECIABLE]: t('tooltip_content.cannot_adjust_with_d90'),
      [DEPRECIATION_METHOD_CODE.NON_DEPRECIATION]: t('tooltip_content.cannot_adjust_with_d99'),
    }

    const getTooltipContent = () => {
      if (isFullyDepreciatedFixedAsset) {
        return t('tooltip_content.fully_depreciated_fixed_asset')
      }

      if (isSoldOrRetiredOrImpaired) {
        return t('tooltip_content.cannot_adjust_with_events')
      }

      return tooltipContentByDMethod[depreciationMethodCode]
    }

    return { isDisabled, tooltipContent: getTooltipContent() }
  }, [asset, assetLedger, isFullyDepreciatedFixedAsset])
}
