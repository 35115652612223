import { If } from 'aa_common/front-end/components'
import { defaultTo } from 'aa_common/front-end/helpers'
import { loadNs } from 'common/i18n-config'
import { Asset, FORM_MODES } from 'common/models'
import { Checkbox } from 'components/atoms'
import { getIn, useFormikContext } from 'formik'
import { isEqual } from 'lodash'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectTermsData } from 'store/settings/accountingPeriod/selectors'

import { DEPRECIATION_FIELDS } from '../const'
import { genLedgerFieldName, getFormMode, isFirstDayOfTerm } from '../helper'
import { useMemorandumValue } from '../hooks/use-memorandum-value'
import { useTransitionAtChanged } from '../hooks/use-transition-at-changed'
import { useTransitionCheckChanged } from '../hooks/use-transition-check-changed'
import { Heading, HeadingTitle } from '../styles'
import { DepreciationLedger } from './DepreciationLedger'

const t = loadNs(['components/organisms/assets/asset-form', 'common'])

type DepreciationLedgersProps = {
  initialValues: Asset
  formMode: FORM_MODES
  assetType?: number
  bookValueReference?: number
  bookValueReferUpdatedManually?: boolean
  acquisitionCostValueRefer?: number
  showRequiredError?: boolean
  setBookValueReferUpdatedManually: () => void
  setExciseSettingDate: React.Dispatch<React.SetStateAction<string>>
}

export const DepreciationLedgers = ({
  initialValues,
  formMode,
  assetType,
  bookValueReference,
  bookValueReferUpdatedManually,
  acquisitionCostValueRefer,
  showRequiredError,
  setBookValueReferUpdatedManually,
  setExciseSettingDate,
}: DepreciationLedgersProps) => {
  const { values } = useFormikContext()

  const terms = useSelector(selectTermsData, isEqual)
  const transitionAt = getIn(values, 'transition_at')
  const isTransitionDayIsFirstDayOfTerm = useMemo(() => {
    return isFirstDayOfTerm(terms, transitionAt)
  }, [transitionAt, terms])

  const { isEditMode, isCopyMode } = getFormMode(formMode)

  const firstLedgerTransitionAtField = genLedgerFieldName(DEPRECIATION_FIELDS.TRANSITION_AT, 0)
  const firstLedgerHasTransitionAt = !!getIn(values, firstLedgerTransitionAtField)
  const onTransitionCheckBoxChanged = useTransitionCheckChanged(setBookValueReferUpdatedManually, setExciseSettingDate)
  const onTransitionAtChanged = useTransitionAtChanged(setBookValueReferUpdatedManually, setExciseSettingDate)

  useMemorandumValue(formMode, assetType)

  return (
    <>
      <Heading>
        <HeadingTitle>{t('depreciation')}</HeadingTitle>
        <Checkbox name="has_transition" disabled={isEditMode} afterChanged={onTransitionCheckBoxChanged}>
          {t('has_transition')}
        </Checkbox>
      </Heading>
      <If condition={!!initialValues?.fixed_asset_ledgers}>
        <div
          style={{
            display: 'flex',
            flexFlow: 'wrap',
            paddingTop: 20,
            paddingBottom: 10,
            justifyContent: 'space-between',
          }}
        >
          {initialValues.fixed_asset_ledgers?.map((item, index) => (
            <DepreciationLedger
              key={`${item.ledger_setting_id}`}
              ledgerName={isEditMode || isCopyMode ? defaultTo(item?.ledger_setting?.name, '') : item.name}
              ledgerIndex={index}
              disabled={isEditMode}
              isTransitionDayIsFirstDayOfTerm={isTransitionDayIsFirstDayOfTerm}
              onAfterChangedTransitionAt={onTransitionAtChanged}
              showRequiredError={showRequiredError}
              isDisabledTransitionAt={index === 0 ? false : firstLedgerHasTransitionAt}
              formMode={formMode}
              bookValueRefer={bookValueReference}
              bookValueReferUpdatedManually={bookValueReferUpdatedManually}
              acquisitionCostValueRefer={acquisitionCostValueRefer}
            />
          ))}
        </div>
      </If>
    </>
  )
}
