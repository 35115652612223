/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PresenterAcceleratedDepreciationRequest,
  PresenterAssetType,
  PresenterBulkChangeMovementReq,
  PresenterBulkDeleteFixedAssetRequest,
  PresenterBulkRetireFixedAssetRequest,
  PresenterBulkUpdateAssetTypeRequest,
  PresenterBulkUsageFixedAssetRequest,
  PresenterCancelAcceleratedDepreciationRequest,
  PresenterCorrectFixedAssetRequest,
  PresenterCreateFixedAssetRequest,
  PresenterDepreciationMonthlyResponse,
  PresenterDepreciationRecalculateHistoryResponse,
  PresenterDivideAssetPreviewResponse,
  PresenterDivideStandardDateResponse,
  PresenterEditFixedAssetRequest,
  PresenterError,
  PresenterFixedAssetAnnualDepreciationResponse,
  PresenterFixedAssetBookInformationRequest,
  PresenterFixedAssetBookInformationResponse,
  PresenterFixedAssetBookValueDividingRequest,
  PresenterFixedAssetBookValueDividingResponse,
  PresenterFixedAssetDepreciationResponse,
  PresenterFixedAssetRanges,
  PresenterFixedAssetRecalculateResponse,
  PresenterFixedAssetsActionStatusReq,
  PresenterFixedAssetsActionStatusResp,
  PresenterFullOneFixedAssetResponse,
  PresenterGetPreviousRecordsRequest,
  PresenterIDResponse,
  PresenterImpairFixedAssetRequest,
  PresenterJob,
  PresenterMemorandumResponse,
  PresenterMeta,
  PresenterMoveFixedAssetRequest,
  PresenterOneFixedAssetHistoryResponse,
  PresenterOneFixedAssetResponse,
  PresenterOnePreviousFixedAssetEventResponse,
  PresenterResponse,
  PresenterRetireFixedAssetRequest,
  PresenterSellFixedAssetRequest,
  PresenterStartUsageFixedAssetRequest,
  PresenterSwitchAssetCategoryRequest,
  PresenterUpdateFixedAssetTypeRequest,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class FixedAssets<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description GetActionStatus calculate the action status of fixed assets
   *
   * @tags fixed_assets
   * @name ActionsStatusCreate
   * @summary GetActionStatus calculate the action status of fixed assets
   * @request POST:/fixed_assets/actions_status
   */
  actionsStatusCreate = (body: PresenterFixedAssetsActionStatusReq, params: RequestParams = {}) =>
    this.http.request<PresenterFixedAssetsActionStatusResp, PresenterError>({
      path: `/fixed_assets/actions_status`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description CalculateMemorandumValue calculate the value memorandum value base on the context
   *
   * @tags fixed_assets
   * @name V1FixedAssetsCalculateMemorandumCreate
   * @summary CalculateMemorandumValue calculate the value memorandum value base on the context
   * @request POST:/api/v1/fixed_assets/calculate_memorandum
   */
  v1FixedAssetsCalculateMemorandumCreate = (params: RequestParams = {}) =>
    this.http.request<PresenterMemorandumResponse, PresenterError>({
      path: `/api/v1/fixed_assets/calculate_memorandum`,
      method: 'POST',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description CalculateStandardDateForDividingEvent calculate the standard date for dividing event base on the context
   *
   * @tags fixed_assets
   * @name V1FixedAssetsDivideStandardDateCreate
   * @summary CalculateStandardDateForDividingEvent calculate the standard date for dividing event
   * @request POST:/api/v1/fixed_assets/divide/standard_date
   */
  v1FixedAssetsDivideStandardDateCreate = (params: RequestParams = {}) =>
    this.http.request<PresenterDivideStandardDateResponse, PresenterError>({
      path: `/api/v1/fixed_assets/divide/standard_date`,
      method: 'POST',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Divide to preview before dividing
   *
   * @tags fixed_assets
   * @name V1FixedAssetsDivideUpdate
   * @summary Divide to preview before dividing
   * @request PUT:/api/v1/fixed_assets/{biid}/divide
   */
  v1FixedAssetsDivideUpdate = (biid: string, params: RequestParams = {}) =>
    this.http.request<PresenterOneFixedAssetResponse[], PresenterError>({
      path: `/api/v1/fixed_assets/${biid}/divide`,
      method: 'PUT',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description DividingCalculate to preview before dividing
   *
   * @tags fixed_assets
   * @name V1FixedAssetsDividingCalculateCreate
   * @summary DividingCalculate to preview before dividing
   * @request POST:/api/v1/fixed_assets/{biid}/dividing_calculate
   */
  v1FixedAssetsDividingCalculateCreate = (biid: string, params: RequestParams = {}) =>
    this.http.request<PresenterDivideAssetPreviewResponse, PresenterError>({
      path: `/api/v1/fixed_assets/${biid}/dividing_calculate`,
      method: 'POST',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description BulkCreate fixed asset
   *
   * @tags fixed_assets
   * @name FixedAssetImportCreate
   * @summary BulkCreate the fixed asset
   * @request POST:/fixed_asset_import
   */
  fixedAssetImportCreate = (body: PresenterCreateFixedAssetRequest[], params: RequestParams = {}) =>
    this.http.request<PresenterFullOneFixedAssetResponse[], PresenterError>({
      path: `/fixed_asset_import`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description ValidateCSV from csv file
   *
   * @tags fixed_assets
   * @name ValidateCsvCreate
   * @summary ValidateCSV for importing fixed assets
   * @request POST:/fixed_asset_import/validate_csv
   */
  validateCsvCreate = (
    data: {
      /** fixed assets csv file to import */
      file: File
      /** fixed assets csv file to import */
      charset?: string
    },
    params: RequestParams = {}
  ) =>
    this.http.request<
      PresenterResponse & {
        data?: PresenterFullOneFixedAssetResponse[]
      },
      any
    >({
      path: `/fixed_asset_import/validate_csv`,
      method: 'POST',
      body: data,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    })
  /**
   * @description Get the fixed asset ranges
   *
   * @tags fixed_assets
   * @name FixedAssetRangesList
   * @summary Get the fixed asset ranges
   * @request GET:/fixed_asset_ranges
   */
  fixedAssetRangesList = (
    query?: {
      'filter[valid_at]'?: string
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterFixedAssetRanges[], any>({
      path: `/fixed_asset_ranges`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Get the fixed assets list
   *
   * @tags fixed_assets
   * @name FixedAssetsList
   * @summary Get the fixed assets list
   * @request GET:/fixed_assets
   */
  fixedAssetsList = (
    query?: {
      'filter[acquire_category]'?: number[]
      'filter[acquire_destination_name]'?: string
      'filter[acquired_at]'?: string[]
      'filter[acquisition_cost]'?: number[]
      'filter[area_biid]'?: string[]
      'filter[area_codes]'?: string[]
      'filter[asset_category_id]'?: number[]
      'filter[asset_detail_for_corporate_tax]'?: string
      'filter[asset_status]'?: (1 | 10 | 20 | 29 | 30 | 40)[]
      'filter[asset_structure_for_corporate_tax]'?: string
      'filter[asset_type_for_corporate_tax]'?: number[]
      'filter[asset_type_for_depreciable_property_tax]'?: number[]
      'filter[biid]'?: string[]
      'filter[branch_code]'?: string
      'filter[calculate_cost]'?: boolean
      'filter[category_codes]'?: string[]
      'filter[change_situation]'?: number
      'filter[code]'?: string
      'filter[created_at]'?: string[]
      'filter[department_biid]'?: string[]
      'filter[department_codes]'?: string[]
      'filter[depreciation_method_code]'?: number[]
      'filter[event_type_code]'?: 10 | 20 | 30 | 40 | 50 | 60 | 70
      'filter[exemption_denominator]'?: number
      'filter[exemption_numerator]'?: number
      'filter[include_history]'?: boolean
      'filter[is_get_detail_one_fixed_asset]'?: boolean
      'filter[is_retroactive]'?: boolean
      'filter[journal_id]'?: number
      'filter[keyword]'?: string
      'filter[memo]'?: string
      'filter[name]'?: string
      'filter[name_kana]'?: string
      'filter[pre_submit_number]'?: string
      'filter[quantity]'?: number[]
      'filter[service_life]'?: number[]
      'filter[tag]'?: string[]
      'filter[unit_id]'?: number[]
      'filter[usage_started_at]'?: string[]
      'filter[valid_at]'?: string
      'page[number]'?: number
      'page[size]'?: number
      sort?: string[]
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterOneFixedAssetResponse[], any>({
      path: `/fixed_assets`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Create fixed asset with name
   *
   * @tags fixed_assets
   * @name FixedAssetsCreate
   * @summary Create the fixed asset
   * @request POST:/fixed_assets
   */
  fixedAssetsCreate = (body: PresenterCreateFixedAssetRequest, params: RequestParams = {}) =>
    this.http.request<PresenterOneFixedAssetResponse, PresenterError>({
      path: `/fixed_assets`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Delete fixed asset with OfficeID
   *
   * @tags fixed_assets
   * @name FixedAssetsDelete
   * @summary Delete the fixed asset
   * @request DELETE:/fixed_assets
   */
  fixedAssetsDelete = (params: RequestParams = {}) =>
    this.http.request<any, any>({
      path: `/fixed_assets`,
      method: 'DELETE',
      type: ContentType.Json,
      ...params,
    })
  /**
   * @description Bulk update the fixed asset type
   *
   * @tags fixed_assets
   * @name BulkChangeAssetTypeUpdate
   * @summary Bulk update the fixed asset type
   * @request PUT:/fixed_assets/bulk_change/asset_type
   */
  bulkChangeAssetTypeUpdate = (body: PresenterBulkUpdateAssetTypeRequest, params: RequestParams = {}) =>
    this.http.request<string, string>({
      path: `/fixed_assets/bulk_change/asset_type`,
      method: 'PUT',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description BulkDeleteByID deletes a list of asset events
   *
   * @tags fixed_assets
   * @name BulkChangeDeleteDelete
   * @summary BulkDeleteByID deletes a list of asset events
   * @request DELETE:/fixed_assets/bulk_change/delete
   */
  bulkChangeDeleteDelete = (body: PresenterBulkDeleteFixedAssetRequest, params: RequestParams = {}) =>
    this.http.request<PresenterJob, string>({
      path: `/fixed_assets/bulk_change/delete`,
      method: 'DELETE',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description BulkChangeMove a fixed asset
   *
   * @tags fixed_assets
   * @name BulkChangeMoveUpdate
   * @summary BulkChangeMove a fixed asset
   * @request PUT:/fixed_assets/bulk_change/move
   */
  bulkChangeMoveUpdate = (body: PresenterBulkChangeMovementReq, params: RequestParams = {}) =>
    this.http.request<string, string>({
      path: `/fixed_assets/bulk_change/move`,
      method: 'PUT',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description BulkChangeRetire a fixed asset
   *
   * @tags fixed_assets
   * @name BulkChangeRetireUpdate
   * @summary BulkChangeRetire a fixed asset
   * @request PUT:/fixed_assets/bulk_change/retire
   */
  bulkChangeRetireUpdate = (body: PresenterBulkRetireFixedAssetRequest, params: RequestParams = {}) =>
    this.http.request<string, string>({
      path: `/fixed_assets/bulk_change/retire`,
      method: 'PUT',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description BulkChangeStartUsage a fixed asset
   *
   * @tags fixed_assets
   * @name BulkChangeStartUsageUpdate
   * @summary BulkChangeStartUsage a fixed asset
   * @request PUT:/fixed_assets/bulk_change/start_usage
   */
  bulkChangeStartUsageUpdate = (body: PresenterBulkUsageFixedAssetRequest, params: RequestParams = {}) =>
    this.http.request<string, string>({
      path: `/fixed_assets/bulk_change/start_usage`,
      method: 'PUT',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Get book informations of the fixed asset by BIID (the latest record)
   *
   * @tags fixed_assets
   * @name DivideBookValueCreate
   * @summary Get book informations of the fixed asset by BIID at dividing
   * @request POST:/fixed_assets/divide/book_value
   */
  divideBookValueCreate = (
    biid: string,
    body: PresenterFixedAssetBookValueDividingRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterFixedAssetBookValueDividingResponse, PresenterError>({
      path: `/fixed_assets/divide/book_value`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description GetRecalculateHistory depreciation recalculate history
   *
   * @tags fixed_assets
   * @name RecalculateDeprsHistoryList
   * @summary GetRecalculateHistory the depreciation recalculate history
   * @request GET:/fixed_assets/recalculate_deprs/history
   */
  recalculateDeprsHistoryList = (params: RequestParams = {}) =>
    this.http.request<PresenterDepreciationRecalculateHistoryResponse, PresenterError>({
      path: `/fixed_assets/recalculate_deprs/history`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description TriggerReCalculateDepreciation
   *
   * @tags fixed_assets, re-calculate
   * @name RecalculationDepreciationCreate
   * @summary TriggerReCalculateDepreciation  re-calculate the depreciation for an specific office
   * @request POST:/fixed_assets/recalculation_depreciation
   */
  recalculationDepreciationCreate = (params: RequestParams = {}) =>
    this.http.request<PresenterFixedAssetRecalculateResponse, PresenterError>({
      path: `/fixed_assets/recalculation_depreciation`,
      method: 'POST',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Get the fixed asset by BIID (the latest record)
   *
   * @tags fixed_assets
   * @name FixedAssetsDetail
   * @summary Get the fixed asset by BIID (the latest record)
   * @request GET:/fixed_assets/{biid}
   */
  fixedAssetsDetail = (
    biid: string,
    query?: {
      /** Valid at date */
      valid_at?: string
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterFullOneFixedAssetResponse, PresenterError>({
      path: `/fixed_assets/${biid}`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Update the fixed asset data
   *
   * @tags fixed_assets
   * @name FixedAssetsUpdate
   * @summary Edit the fixed asset data
   * @request PUT:/fixed_assets/{biid}
   */
  fixedAssetsUpdate = (biid: string, body: PresenterEditFixedAssetRequest, params: RequestParams = {}) =>
    this.http.request<PresenterOneFixedAssetResponse, PresenterError>({
      path: `/fixed_assets/${biid}`,
      method: 'PUT',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Delete fixed asset with BIID
   *
   * @tags fixed_assets
   * @name FixedAssetsDelete2
   * @summary Delete the fixed asset
   * @request DELETE:/fixed_assets/{biid}
   * @originalName fixedAssetsDelete
   * @duplicate
   */
  fixedAssetsDelete2 = (biid: string, params: RequestParams = {}) =>
    this.http.request<PresenterIDResponse, any>({
      path: `/fixed_assets/${biid}`,
      method: 'DELETE',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description comparison between 2 ledgers in terms of depreciation year close with a specific asset
   *
   * @tags fixed_assets, ledger
   * @name AnnualDepreciationDetail
   * @summary comparison between 2 ledgers in terms of depreciation year close with a specific asset
   * @request GET:/fixed_assets/{biid}/annual_depreciation
   */
  annualDepreciationDetail = (biid: string, params: RequestParams = {}) =>
    this.http.request<PresenterFixedAssetAnnualDepreciationResponse[], PresenterError>({
      path: `/fixed_assets/${biid}/annual_depreciation`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Update the fixed asset type
   *
   * @tags fixed_assets
   * @name AssetTypeUpdate
   * @summary Update the fixed asset type
   * @request PUT:/fixed_assets/{biid}/asset_type
   */
  assetTypeUpdate = (biid: string, body: PresenterUpdateFixedAssetTypeRequest, params: RequestParams = {}) =>
    this.http.request<PresenterAssetType, PresenterError>({
      path: `/fixed_assets/${biid}/asset_type`,
      method: 'PUT',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Get book informations of the fixed asset by BIID (the latest record)
   *
   * @tags fixed_assets
   * @name BookInformationsCreate
   * @summary Get book informations of the fixed asset by BIID (the latest record)
   * @request POST:/fixed_assets/{biid}/book_informations
   */
  bookInformationsCreate = (
    biid: string,
    body: PresenterFixedAssetBookInformationRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterFixedAssetBookInformationResponse, PresenterError>({
      path: `/fixed_assets/${biid}/book_informations`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description This function is responsible for modifying event histories after acquisition, start usage or transition Currently, we still don't support yet fixed asset that impaired, sale, retirement, dividing
   *
   * @tags fixed_assets
   * @name CorrectUpdate
   * @summary Correct a fixed asset
   * @request PUT:/fixed_assets/{biid}/correct
   */
  correctUpdate = (biid: string, body: PresenterCorrectFixedAssetRequest, params: RequestParams = {}) =>
    this.http.request<PresenterOneFixedAssetResponse, PresenterError>({
      path: `/fixed_assets/${biid}/correct`,
      method: 'PUT',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Detail depreciationresult for specific fixed_asset with given ledger_setting_id
   *
   * @tags fixed_assets
   * @name DepreciationResultsDetail
   * @summary Detail the depreciationresult
   * @request GET:/fixed_assets/{biid}/depreciation_results
   */
  depreciationResultsDetail = (
    biid: string,
    query?: {
      ledger_setting_id?: number
    },
    params: RequestParams = {}
  ) =>
    this.http.request<
      PresenterResponse & {
        data?: PresenterFixedAssetDepreciationResponse
      },
      any
    >({
      path: `/fixed_assets/${biid}/depreciation_results`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Register accelerated depreciation for specific fixed_asset with given ledger_setting_id
   *
   * @tags fixed_assets
   * @name DepreciationResultsAdjustUpdate
   * @summary Register accelerated depreciation
   * @request PUT:/fixed_assets/{biid}/depreciation_results/adjust
   */
  depreciationResultsAdjustUpdate = (
    biid: string,
    body: PresenterAcceleratedDepreciationRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<
      PresenterResponse & {
        data?: PresenterFixedAssetDepreciationResponse
      },
      any
    >({
      path: `/fixed_assets/${biid}/depreciation_results/adjust`,
      method: 'PUT',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Cancel accelerated depreciation for specific fixed_asset with given ledger_setting_id
   *
   * @tags fixed_assets
   * @name DepreciationResultsCancelAdjustUpdate
   * @summary Cancel accelerated depreciation
   * @request PUT:/fixed_assets/{biid}/depreciation_results/cancel_adjust
   */
  depreciationResultsCancelAdjustUpdate = (
    biid: string,
    body: PresenterCancelAcceleratedDepreciationRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterMeta, any>({
      path: `/fixed_assets/${biid}/depreciation_results/cancel_adjust`,
      method: 'PUT',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Register accelerated depreciation for specific fixed_asset with given ledger_setting_id
   *
   * @tags fixed_assets
   * @name DepreciationResultsOriginalDetail
   * @summary Register accelerated depreciation
   * @request GET:/fixed_assets/{biid}/depreciation_results/original/{ledger_setting_id}
   */
  depreciationResultsOriginalDetail = (biid: string, ledgerSettingId: string, params: RequestParams = {}) =>
    this.http.request<PresenterDepreciationMonthlyResponse[], any>({
      path: `/fixed_assets/${biid}/depreciation_results/original/${ledgerSettingId}`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Impair a fixed asset
   *
   * @tags fixed_assets
   * @name ImpairUpdate
   * @summary Impair a fixed asset
   * @request PUT:/fixed_assets/{biid}/impair
   */
  impairUpdate = (biid: string, body: PresenterImpairFixedAssetRequest, params: RequestParams = {}) =>
    this.http.request<PresenterOneFixedAssetResponse, PresenterError>({
      path: `/fixed_assets/${biid}/impair`,
      method: 'PUT',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description This function is responsible for checking a fixed asset to be corrected or not
   *
   * @tags fixed_assets
   * @name IsCorrectableDetail
   * @summary IsCorrectable a fixed asset
   * @request GET:/fixed_assets/{biid}/is_correctable
   */
  isCorrectableDetail = (biid: string, params: RequestParams = {}) =>
    this.http.request<boolean, PresenterError>({
      path: `/fixed_assets/${biid}/is_correctable`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Move a fixed asset
   *
   * @tags fixed_assets
   * @name MoveUpdate
   * @summary Move a fixed asset
   * @request PUT:/fixed_assets/{biid}/move
   */
  moveUpdate = (biid: string, body: PresenterMoveFixedAssetRequest, params: RequestParams = {}) =>
    this.http.request<PresenterOneFixedAssetResponse, PresenterError>({
      path: `/fixed_assets/${biid}/move`,
      method: 'PUT',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Get the fixed asset by BIID (the all records)
   *
   * @tags fixed_assets
   * @name RecordsDetail
   * @summary Get the fixed asset by BIID (the all records)
   * @request GET:/fixed_assets/{biid}/records
   */
  recordsDetail = (biid: string, params: RequestParams = {}) =>
    this.http.request<PresenterOneFixedAssetHistoryResponse[], PresenterError>({
      path: `/fixed_assets/${biid}/records`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Get the fixed asset record by ID
   *
   * @tags fixed_assets
   * @name RecordsDetail2
   * @summary Get the fixed asset record by ID
   * @request GET:/fixed_assets/{biid}/records/{id}
   * @originalName recordsDetail
   * @duplicate
   */
  recordsDetail2 = (
    biid: string,
    id: number,
    query?: {
      /** Set true if want to get deleted record */
      includes_deleted?: boolean
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterFullOneFixedAssetResponse, PresenterError>({
      path: `/fixed_assets/${biid}/records/${id}`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Delete fixed asset with id
   *
   * @tags fixed_assets
   * @name RecordsDelete
   * @summary Delete the fixed asset
   * @request DELETE:/fixed_assets/{biid}/records/{id}
   */
  recordsDelete = (biid: string, id: number, params: RequestParams = {}) =>
    this.http.request<PresenterIDResponse, PresenterError>({
      path: `/fixed_assets/${biid}/records/${id}`,
      method: 'DELETE',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Delete the acquisition fixed asset event
   *
   * @tags fixed_assets
   * @name RecordsAcquisitionDelete
   * @summary Delete the acquisition fixed asset event
   * @request DELETE:/fixed_assets/{biid}/records/{id}/acquisition
   */
  recordsAcquisitionDelete = (biid: string, id: number, params: RequestParams = {}) =>
    this.http.request<PresenterIDResponse, PresenterError>({
      path: `/fixed_assets/${biid}/records/${id}/acquisition`,
      method: 'DELETE',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Delete the divide fixed asset event
   *
   * @tags fixed_assets
   * @name RecordsDivideDelete
   * @summary Delete the dividing fixed asset event
   * @request DELETE:/fixed_assets/{biid}/records/{id}/divide
   */
  recordsDivideDelete = (biid: string, id: number, params: RequestParams = {}) =>
    this.http.request<PresenterIDResponse, PresenterError>({
      path: `/fixed_assets/${biid}/records/${id}/divide`,
      method: 'DELETE',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Delete the edit fixed asset event
   *
   * @tags fixed_assets
   * @name RecordsEditDelete
   * @summary Delete the edit fixed asset event
   * @request DELETE:/fixed_assets/{biid}/records/{id}/edit
   */
  recordsEditDelete = (biid: string, id: number, params: RequestParams = {}) =>
    this.http.request<PresenterIDResponse, PresenterError>({
      path: `/fixed_assets/${biid}/records/${id}/edit`,
      method: 'DELETE',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Update the movement event a fixed asset
   *
   * @tags fixed_assets
   * @name RecordsMoveUpdate
   * @summary Update the movement event a fixed asset
   * @request PUT:/fixed_assets/{biid}/records/{id}/move
   */
  recordsMoveUpdate = (biid: string, id: string, body: PresenterMoveFixedAssetRequest, params: RequestParams = {}) =>
    this.http.request<PresenterOneFixedAssetResponse, PresenterError>({
      path: `/fixed_assets/${biid}/records/${id}/move`,
      method: 'PUT',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Delete the move fixed asset event
   *
   * @tags fixed_assets
   * @name RecordsMoveDelete
   * @summary Delete the move fixed asset event
   * @request DELETE:/fixed_assets/{biid}/records/{id}/move
   */
  recordsMoveDelete = (biid: string, id: number, params: RequestParams = {}) =>
    this.http.request<PresenterIDResponse, PresenterError>({
      path: `/fixed_assets/${biid}/records/${id}/move`,
      method: 'DELETE',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Get the previous record of the fixed asset record by ID
   *
   * @tags fixed_assets
   * @name RecordsPreviousDetail
   * @summary Get the previous record of the fixed asset record by ID
   * @request GET:/fixed_assets/{biid}/records/{id}/previous
   */
  recordsPreviousDetail = (biid: string, id: number, params: RequestParams = {}) =>
    this.http.request<PresenterFullOneFixedAssetResponse, PresenterError>({
      path: `/fixed_assets/${biid}/records/${id}/previous`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Update the retirement event a fixed asset
   *
   * @tags fixed_assets
   * @name RecordsRetireUpdate
   * @summary Update the retirement event a fixed asset
   * @request PUT:/fixed_assets/{biid}/records/{id}/retire
   */
  recordsRetireUpdate = (
    biid: string,
    id: string,
    body: PresenterRetireFixedAssetRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterOneFixedAssetResponse, PresenterError>({
      path: `/fixed_assets/${biid}/records/${id}/retire`,
      method: 'PUT',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Delete the retire fixed asset event
   *
   * @tags fixed_assets
   * @name RecordsRetireDelete
   * @summary Delete the retire fixed asset event
   * @request DELETE:/fixed_assets/{biid}/records/{id}/retire
   */
  recordsRetireDelete = (biid: string, id: number, params: RequestParams = {}) =>
    this.http.request<PresenterIDResponse, PresenterError>({
      path: `/fixed_assets/${biid}/records/${id}/retire`,
      method: 'DELETE',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Update the sell event a fixed asset
   *
   * @tags fixed_assets
   * @name RecordsSellUpdate
   * @summary Update the sell event a fixed asset
   * @request PUT:/fixed_assets/{biid}/records/{id}/sell
   */
  recordsSellUpdate = (biid: string, id: string, body: PresenterSellFixedAssetRequest, params: RequestParams = {}) =>
    this.http.request<PresenterOneFixedAssetResponse, PresenterError>({
      path: `/fixed_assets/${biid}/records/${id}/sell`,
      method: 'PUT',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Delete the sell fixed asset event
   *
   * @tags fixed_assets
   * @name RecordsSellDelete
   * @summary Delete the sell fixed asset event
   * @request DELETE:/fixed_assets/{biid}/records/{id}/sell
   */
  recordsSellDelete = (biid: string, id: number, params: RequestParams = {}) =>
    this.http.request<PresenterIDResponse, PresenterError>({
      path: `/fixed_assets/${biid}/records/${id}/sell`,
      method: 'DELETE',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Update the StartUsage event a fixed asset
   *
   * @tags fixed_assets
   * @name RecordsStartUsageUpdate
   * @summary Update the StartUsage event a fixed asset
   * @request PUT:/fixed_assets/{biid}/records/{id}/start_usage
   */
  recordsStartUsageUpdate = (
    biid: string,
    id: string,
    body: PresenterStartUsageFixedAssetRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterOneFixedAssetResponse, PresenterError>({
      path: `/fixed_assets/${biid}/records/${id}/start_usage`,
      method: 'PUT',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Delete the start_usage fixed asset event
   *
   * @tags fixed_assets
   * @name RecordsStartUsageDelete
   * @summary Delete the start_usage fixed asset event
   * @request DELETE:/fixed_assets/{biid}/records/{id}/start_usage
   */
  recordsStartUsageDelete = (biid: string, id: number, params: RequestParams = {}) =>
    this.http.request<PresenterIDResponse, PresenterError>({
      path: `/fixed_assets/${biid}/records/${id}/start_usage`,
      method: 'DELETE',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Update the switch_category event a fixed asset
   *
   * @tags fixed_assets
   * @name RecordsSwitchCategoryUpdate
   * @summary Update the switch_category event a fixed asset
   * @request PUT:/fixed_assets/{biid}/records/{id}/switch_category
   */
  recordsSwitchCategoryUpdate = (
    biid: string,
    id: string,
    body: PresenterSwitchAssetCategoryRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterOneFixedAssetResponse, PresenterError>({
      path: `/fixed_assets/${biid}/records/${id}/switch_category`,
      method: 'PUT',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Delete the switch_category fixed asset event
   *
   * @tags fixed_assets
   * @name RecordsSwitchCategoryDelete
   * @summary Delete the switch_category fixed asset event
   * @request DELETE:/fixed_assets/{biid}/records/{id}/switch_category
   */
  recordsSwitchCategoryDelete = (biid: string, id: number, params: RequestParams = {}) =>
    this.http.request<PresenterIDResponse, PresenterError>({
      path: `/fixed_assets/${biid}/records/${id}/switch_category`,
      method: 'DELETE',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Retire a fixed asset
   *
   * @tags fixed_assets
   * @name RetireUpdate
   * @summary Retire a fixed asset
   * @request PUT:/fixed_assets/{biid}/retire
   */
  retireUpdate = (biid: string, body: PresenterRetireFixedAssetRequest, params: RequestParams = {}) =>
    this.http.request<PresenterOneFixedAssetResponse, PresenterError>({
      path: `/fixed_assets/${biid}/retire`,
      method: 'PUT',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Sell a fixed asset
   *
   * @tags fixed_assets
   * @name SellUpdate
   * @summary Sell a fixed asset
   * @request PUT:/fixed_assets/{biid}/sell
   */
  sellUpdate = (biid: string, body: PresenterSellFixedAssetRequest, params: RequestParams = {}) =>
    this.http.request<PresenterOneFixedAssetResponse, PresenterError>({
      path: `/fixed_assets/${biid}/sell`,
      method: 'PUT',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description StartUsage a fixed asset
   *
   * @tags fixed_assets
   * @name StartUsageUpdate
   * @summary StartUsage a fixed asset
   * @request PUT:/fixed_assets/{biid}/start_usage
   */
  startUsageUpdate = (biid: string, body: PresenterStartUsageFixedAssetRequest, params: RequestParams = {}) =>
    this.http.request<PresenterOneFixedAssetResponse, PresenterError>({
      path: `/fixed_assets/${biid}/start_usage`,
      method: 'PUT',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description SwitchCategory a fixed asset
   *
   * @tags fixed_assets
   * @name SwitchCategoryUpdate
   * @summary SwitchCategory a fixed asset
   * @request PUT:/fixed_assets/{biid}/switch_category
   */
  switchCategoryUpdate = (biid: string, body: PresenterSwitchAssetCategoryRequest, params: RequestParams = {}) =>
    this.http.request<PresenterOneFixedAssetResponse, PresenterError>({
      path: `/fixed_assets/${biid}/switch_category`,
      method: 'PUT',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Get the previous record of the fixed asset record by ID
   *
   * @tags fixed_assets
   * @name PreviousFixedAssetsCreate
   * @summary Get the previous record of the fixed asset record by ID
   * @request POST:/search/previous_fixed_assets
   */
  previousFixedAssetsCreate = (body: PresenterGetPreviousRecordsRequest, params: RequestParams = {}) =>
    this.http.request<PresenterOnePreviousFixedAssetEventResponse[], PresenterError>({
      path: `/search/previous_fixed_assets`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
