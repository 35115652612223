/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { PresenterError, PresenterOneFixedAssetOperationLogResponse } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class FixedAssetOperationLogs<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description List fixed_asset_operation_logs
   *
   * @tags fixed_asset_operation_logs
   * @name FixedAssetOperationLogsList
   * @summary List the fixed_asset_operation_logs
   * @request GET:/fixed_asset_operation_logs
   */
  fixedAssetOperationLogsList = (
    query?: {
      'filter[after_id]'?: number
      'filter[before_id]'?: number
      'filter[cached_fixed_asset_biid]'?: string
      'filter[cached_fixed_asset_branch_code]'?: string
      'filter[cached_fixed_asset_code]'?: string
      'filter[cached_fixed_asset_name]'?: string
      'filter[created_at]'?: string[]
      'filter[operation_target]'?: 1 | 2
      'filter[operation_type]'?: 1 | 2 | 3 | 4 | 5 | 6 | 7
      'filter[user]'?: number
      sort?: string[]
      'page[number]'?: number
      'page[size]'?: number
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterOneFixedAssetOperationLogResponse[], PresenterError>({
      path: `/fixed_asset_operation_logs`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
