import { If, UnborderCollapse } from 'aa_common/front-end/components'
import { DEPRECIATION_METHOD_CODE } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import { MoneyInput } from 'components/molecules'
import { getIn, useFormikContext } from 'formik'
import React from 'react'

import { DEPRECIATION_FIELDS } from '../const'
import { genLedgerFieldName } from '../helper'
import { useClearValueOnHide } from '../hooks/useClearOnHide'
import { prelineStyle } from '../styles'

const { Item } = UnborderCollapse
const t = loadNs(['components/organisms/assets/asset-form', 'common'])

type ResidualAmountProps = {
  ledgerIndex: number
  ledgerName: string
  disabled: boolean
}

export const ResidualAmount = ({ ledgerIndex, ledgerName, disabled }: ResidualAmountProps) => {
  const { values, setFieldValue } = useFormikContext()
  const depreciationMethodCodeField = genLedgerFieldName(DEPRECIATION_FIELDS.DEPRECIATION_METHOD_CODE, ledgerIndex)
  const residualAmountField = genLedgerFieldName(DEPRECIATION_FIELDS.RESIDUAL_AMOUNT, ledgerIndex)
  const isCopiedField = genLedgerFieldName(DEPRECIATION_FIELDS.IS_COPIED, ledgerIndex)

  const depreciationCode = getIn(values, depreciationMethodCodeField)
  const isCopied = getIn(values, isCopiedField)

  const shouldRender = [
    DEPRECIATION_METHOD_CODE.AVERAGE_USAGE_PERIOD,
    DEPRECIATION_METHOD_CODE.LEASE_PERIOD_STRAIGHT_LINE,
  ].includes(depreciationCode)

  useClearValueOnHide(residualAmountField, setFieldValue, shouldRender)

  return (
    <If condition={shouldRender}>
      <Item
        label={t('residual_amount')}
        required
        style={prelineStyle}
        jsLabel={t('residual_amount_of', { name: ledgerName })}
      >
        <MoneyInput name={residualAmountField} style={{ textAlign: 'right' }} disabled={disabled || isCopied} />
      </Item>
    </If>
  )
}
