import { FilterFunction, initAsyncSlice } from 'aa_common/front-end/zustand/init-async-slice'
import { FixedAssetsService, PresenterFixedAssetsActionStatusResp } from 'common/open-api'

const initFilters: { biids: string[] } = {
  biids: [],
}

const initState: PresenterFixedAssetsActionStatusResp = {}

const fetchData: FilterFunction<typeof initState, typeof initFilters> = async filters => {
  return FixedAssetsService.actionsStatusCreate({ biids: filters?.biids })
}

export const assetListActionsStatusSlice = initAsyncSlice<typeof initState, typeof initFilters>(
  'fixed-asset/action-status',
  initState,
  initFilters,
  fetchData
)
