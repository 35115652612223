/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PresenterError,
  PresenterNumJournalsOfType,
  PresenterOneExportJournalHistoryResponse,
  PresenterOneJournalResponse,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Journals<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description Get list journal
   *
   * @tags journals
   * @name JournalsList
   * @summary Get list journal
   * @request GET:/journals
   */
  journalsList = (
    query?: {
      'filter[journal_event_type]'?: (1 | 2 | 3 | 4 | 5)[]
      'filter[term_month_id]'?: number
      sort?: string[]
      'page[number]'?: number
      'page[size]'?: number
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterOneJournalResponse[], PresenterError>({
      path: `/journals`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Get export journal histories in the current MonthlyClosing
   *
   * @tags journals
   * @name ExportJournalHistoriesList
   * @summary Get export journal histories in the current MonthlyClosing
   * @request GET:/journals/export_journal_histories
   */
  exportJournalHistoriesList = (
    query?: {
      monthly_closing_id?: number
      /** @min 1 */
      'page[number'?: number
      /** @min 1 */
      'page[size'?: number
      type?: number
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterOneExportJournalHistoryResponse[], PresenterError>({
      path: `/journals/export_journal_histories`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Get export journal histories in the current MonthlyClosing
   *
   * @tags journals
   * @name JournalLinkageHistoriesList
   * @summary Get journal linkage histories in the current MonthlyClosing
   * @request GET:/journals/journal_linkage_histories
   */
  journalLinkageHistoriesList = (
    query?: {
      monthly_closing_id?: number
      /** @min 1 */
      'page[number'?: number
      /** @min 1 */
      'page[size'?: number
      type?: number
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterOneExportJournalHistoryResponse[], PresenterError>({
      path: `/journals/journal_linkage_histories`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Get total journals of each journal event type
   *
   * @tags journals
   * @name TotalList
   * @summary Get total journals of each journal event type
   * @request GET:/journals/total
   */
  totalList = (
    query?: {
      'filter[journal_event_type]'?: (1 | 2 | 3 | 4 | 5)[]
      'filter[term_month_id]'?: number
      sort?: string[]
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterNumJournalsOfType[], PresenterError>({
      path: `/journals/total`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
