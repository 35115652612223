import { isAccountingJournalTarget, isTaxJournalTarget } from 'common/helpers'
import { FORM_MODES } from 'common/models'
import { getIn, useFormikContext } from 'formik'
import isEqual from 'lodash/isEqual'
import { useSelector } from 'react-redux'
import { selectLedgerSettingList } from 'store/settings/ledgerSetting/selectors'

import { DEPRECIATION_FIELDS } from '../const'
import { genLedgerFieldName } from '../helper'
import { useLedgersWithSetting } from './use-ledgers-with-setting'

export const useDisableMemorandum = (ledgerIndex: number, formMode: FORM_MODES) => {
  const { values } = useFormikContext()

  const isCopiedField = genLedgerFieldName(DEPRECIATION_FIELDS.IS_COPIED, ledgerIndex)
  const accumulatedImpairmentAmount = genLedgerFieldName(DEPRECIATION_FIELDS.ACCUMULATED_IMPAIRED_AMOUNT, ledgerIndex)

  const isCopied = !!getIn(values, isCopiedField)
  const accumulatedImpairmentAmountValue = getIn(values, accumulatedImpairmentAmount)
  const hasImpairmentEvent = !!getIn(values, 'impaired_at') || !!accumulatedImpairmentAmountValue

  const ledgerSettingList = useSelector(selectLedgerSettingList, isEqual)
  const isTaxLedger = isTaxJournalTarget(ledgerSettingList?.[ledgerIndex])
  const isAccountantLedger = isAccountingJournalTarget(ledgerSettingList?.[ledgerIndex])

  const { canCopyFromAccountant } = useLedgersWithSetting()

  return (
    (isTaxLedger && canCopyFromAccountant) ||
    isCopied ||
    (hasImpairmentEvent && isAccountantLedger && formMode === FORM_MODES.EDIT)
  )
}
