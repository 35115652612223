/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PresenterCreateCauseRequest,
  PresenterError,
  PresenterIDResponse,
  PresenterIsUsedResponse,
  PresenterOneCauseResponse,
  PresenterResponse,
  PresenterUpdateCauseRequest,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Causes<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description Get cause list
   *
   * @tags causes
   * @name CausesList
   * @summary Get the cause list
   * @request GET:/causes
   */
  causesList = (params: RequestParams = {}) =>
    this.http.request<PresenterOneCauseResponse[], any>({
      path: `/causes`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Create cause with name
   *
   * @tags causes
   * @name CausesCreate
   * @summary Create the cause
   * @request POST:/causes
   */
  causesCreate = (body: PresenterCreateCauseRequest, params: RequestParams = {}) =>
    this.http.request<PresenterIDResponse, PresenterError>({
      path: `/causes`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Import cause from csv file
   *
   * @tags causes
   * @name ImportCreate
   * @summary Import the cause
   * @request POST:/causes/import
   */
  importCreate = (
    data: {
      /** asset accounting csv file to import */
      file: File
      /** asset accounting csv file to import */
      charset?: string
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterResponse, any>({
      path: `/causes/import`,
      method: 'POST',
      body: data,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    })
  /**
   * @description Get cause
   *
   * @tags causes
   * @name CausesDetail
   * @summary Get the cause by ID
   * @request GET:/causes/{id}
   */
  causesDetail = (id: number, params: RequestParams = {}) =>
    this.http.request<PresenterOneCauseResponse, any>({
      path: `/causes/${id}`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Update cause
   *
   * @tags causes
   * @name CausesUpdate
   * @summary Update the cause
   * @request PUT:/causes/{id}
   */
  causesUpdate = (id: number, body: PresenterUpdateCauseRequest, params: RequestParams = {}) =>
    this.http.request<PresenterResponse, any>({
      path: `/causes/${id}`,
      method: 'PUT',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Delete cause by id
   *
   * @tags causes
   * @name CausesDelete
   * @summary Delete cause
   * @request DELETE:/causes/{id}
   */
  causesDelete = (
    id: number,
    query?: {
      /** dry_run enable to check whether you can delete Cause or not */
      dry_run?: boolean
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterIDResponse, PresenterError>({
      path: `/causes/${id}`,
      method: 'DELETE',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description CheckIsUsed cause by id
   *
   * @tags causes
   * @name IsUsedDetail
   * @summary Check cause is used or not
   * @request GET:/causes/{id}/is_used
   */
  isUsedDetail = (id: number, params: RequestParams = {}) =>
    this.http.request<PresenterIsUsedResponse, any>({
      path: `/causes/${id}/is_used`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
