import { ConfirmModal, If } from 'aa_common/front-end/components'
import { defaultTo } from 'aa_common/front-end/helpers'
import { MASTER_PAGES } from 'common/constants'
import { getTrimmedData, parseDataForSubmit, transformObjectWithPath } from 'common/helpers'
import { getNs } from 'common/i18n-config'
import { ExciseListData, FORM_MODES } from 'common/models'
import { FixedAssetsService } from 'common/open-api'
import { getAxiosSilent } from 'common/open-api/helper'
import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import { HorizontalLine } from 'components/atoms'
import { BasicForm } from 'components/molecules'
import {
  addApiErrorKey,
  checkAcquiredAtBelongToAssetCodeAutoGenerate,
} from 'components/organisms/assets/AssetForm/helper'
import { tagListSlice } from 'containers/master/tags/store'
import { unitsSlice } from 'containers/master/units/store'
import { get, isEqual } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { fetchMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/actions'
import { selectMonthlyClosingCurrent } from 'store/accounting/monthlyClosing/selectors'
import { selectMasterData } from 'store/masterData/selectors'
import { fetchAutoGenerateSettings } from 'store/settings/assetCode/actions'
import { selectAssetCodeAutoGenerateSettingListData } from 'store/settings/assetCode/selectors'
import { cleanupExcise, fetchExciseList } from 'store/settings/excise/actions'
import { getExciseListSelector } from 'store/settings/excise/selectors'

import { useCorrection } from '../hooks/use-correction'
import ErrorComponent from './ErrorComponent'
import FormData from './FormData'
import { FormData as FormDataNew } from './FormDataNew'
import { useFormatInitialAsset } from './hooks/use-format-initial-asset'
import { TopMessage, Wrapper } from './styles'
import { assetSchema, resetGeneralValidationErrors } from './validations/asset-schema'

const { t, useNsReady } = getNs(['components/organisms/assets/asset-form', 'common', 'components/molecules/basic-form'])

type Props = {
  errors?: any[]
  loading?: boolean
  initialAssetData: any
  formMode?: FORM_MODES
  confirming?: boolean
  isTemporaryData?: boolean
  onSubmit: (values: any) => void
  onSaveTemp?: (key: string, data: any, extraKey?: string) => void
  onDelete?: (biid: string) => void
  showRequiredError?: boolean
  asset?: any
  isValidateAssetLoading?: boolean
}

const AssetForm: React.FC<Props> = ({
  errors,
  loading = false,
  initialAssetData,
  formMode = FORM_MODES.NEW,
  confirming = false,
  isValidateAssetLoading = false,
  isTemporaryData = false,
  onSubmit,
  onSaveTemp,
  onDelete,
  showRequiredError,
  asset,
}) => {
  useNsReady()

  const dispatch = useDispatch()
  const { biid } = useParams<{ biid: string }>()
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false)

  const tags = defaultTo(
    tagListSlice.useSlice(state => state?.data?.tags),
    []
  )
  const units = defaultTo(
    unitsSlice.useSlice(state => state?.data?.units),
    []
  )
  const exciseListSelector: ExciseListData = useSelector(getExciseListSelector, isEqual)
  const masterData: any = useSelector(selectMasterData, isEqual) || {}
  const assetCodeAutoGenerateSettingListData = useSelector(selectAssetCodeAutoGenerateSettingListData, isEqual)
  const currentMonthlyClosing = useSelector(selectMonthlyClosingCurrent, isEqual)

  const [isCorrectable, setCorrectable] = useState<boolean>(false)
  const isEditMode = formMode === FORM_MODES.EDIT
  const isImportMode = formMode === FORM_MODES.IMPORT
  const isCorrectionMode = formMode === FORM_MODES.CORRECTION
  const formattedAssetData = useFormatInitialAsset(initialAssetData)

  useEffect(() => {
    const checkCorrectable = async () => {
      try {
        const response = await FixedAssetsService.isCorrectableDetail(formattedAssetData.biid, getAxiosSilent())
        setCorrectable(Boolean(response?.data))
      } catch (err) {
        setCorrectable(false)
      }
    }

    isEditMode && checkCorrectable()
  }, [])

  const currentSetting = useMemo(() => {
    const acquiredAt = get(asset, 'acquired_at')

    if (!assetCodeAutoGenerateSettingListData || !acquiredAt) return null
    return assetCodeAutoGenerateSettingListData.find(setting =>
      checkAcquiredAtBelongToAssetCodeAutoGenerate(setting, acquiredAt)
    )
  }, [assetCodeAutoGenerateSettingListData, asset])

  const handleSubmit = useCallback((values: any) => {
    const parsedValues = parseDataForSubmit(getTrimmedData(values))
    onSubmit(parsedValues)
  }, [])

  const handleCancelDelete = (): void => {
    setIsConfirmingDelete(false)
  }

  const handleDelete = (): void => {
    onDelete?.(get(formattedAssetData, 'biid'))
    setIsConfirmingDelete(false)
  }

  const renderTopMessage = (children: string | React.ReactNode) => <TopMessage>{children}</TopMessage>

  const handleReloadMasterOptions = (pageName: string) => {
    if (pageName === MASTER_PAGES.UNITS) {
      unitsSlice.setFilters({}, true)
    }
  }

  useEffect(() => {
    const filters = {
      is_active: true,
    }

    if (isImportMode) {
      !exciseListSelector?.data?.length && dispatch(fetchExciseList({ filters }))
      !assetCodeAutoGenerateSettingListData?.length && dispatch(fetchAutoGenerateSettings())
      !currentMonthlyClosing && dispatch(fetchMonthlyClosingCurrent())
      tags?.length === 0 && tagListSlice.setFilters({}, true)
      units?.length === 0 && unitsSlice.setFilters({}, true)
    } else {
      dispatch(fetchExciseList({ filters }))
      dispatch(fetchAutoGenerateSettings())
      dispatch(fetchMonthlyClosingCurrent())
      tagListSlice.setFilters({}, true)
      unitsSlice.setFilters({}, true)
    }

    return function cleanup() {
      if (!isImportMode) {
        dispatch(cleanupExcise())
      }
      resetGeneralValidationErrors()
    }
  }, []) // eslint-disable-line

  const { change_situation } = defaultTo(formattedAssetData, {})
  const {
    isCorrectable: isCorrectableWithPermission,
    isCorrectionLinkHidden,
    checkEventsAllowedForCorrection,
  } = useCorrection()
  const isEventsAllowedForCorrection = checkEventsAllowedForCorrection(change_situation?.code)
  const isShowTopMessageWithoutCorrect =
    !isCorrectableWithPermission ||
    !isEventsAllowedForCorrection ||
    isCorrectionLinkHidden ||
    (isCorrectableWithPermission && !isEventsAllowedForCorrection)

  const apiErrors = useMemo(() => addApiErrorKey(transformObjectWithPath(errors)), [errors])

  const { isEnable: enableRefactorAssetForm } = useCheckFlag(FeatureFlagNames.RefactorAssetForm)

  return (
    <Wrapper>
      {isEditMode && isShowTopMessageWithoutCorrect && !isCorrectable && renderTopMessage(t('msg_notice_for_change'))}
      {isCorrectionMode && renderTopMessage(t('correction_notice_msg'))}
      {isCorrectableWithPermission &&
        isEditMode &&
        isCorrectable &&
        !isCorrectionLinkHidden &&
        !isShowTopMessageWithoutCorrect &&
        renderTopMessage(
          <>
            {t('correction_text_paragraph_1')}
            <Link to={`/assets/${biid}/correction`}>{t('actions.correction')}</Link>
            {t('correction_text_paragraph_2')}
          </>
        )}
      <BasicForm
        initialValues={formattedAssetData}
        onSubmit={handleSubmit}
        errors={apiErrors}
        loading={loading || isValidateAssetLoading}
        preventSubmitOnEnter
        validationSchema={assetSchema}
      >
        <ErrorComponent errors={errors} values={asset} currentSetting={currentSetting} />
        <If condition={formattedAssetData}>
          <If condition={!enableRefactorAssetForm}>
            <FormData
              initialValues={formattedAssetData}
              masterData={masterData}
              formMode={formMode}
              isImportMode={isImportMode}
              isTemporaryData={isTemporaryData}
              onSaveTemp={onSaveTemp}
              onHandleReloadMasterOptions={handleReloadMasterOptions}
              showRequiredError={showRequiredError}
              onSubmit={handleSubmit}
              confirming={confirming}
            />
          </If>
          <If condition={enableRefactorAssetForm}>
            <FormDataNew
              initialValues={formattedAssetData}
              formMode={formMode}
              isTemporaryData={isTemporaryData}
              onSaveTemp={onSaveTemp}
              onHandleReloadMasterOptions={handleReloadMasterOptions}
              showRequiredError={showRequiredError}
              onSubmit={handleSubmit}
              confirming={confirming}
            />
          </If>
        </If>
        <HorizontalLine style={{ width: '100%', backgroundColor: '#D4D8DD' }} />

        <div style={{ height: 30 }} />
      </BasicForm>
      <ConfirmModal
        message={t('messages.confirm_delete', { name: get(formattedAssetData, 'name') })}
        visible={isConfirmingDelete}
        onOK={handleDelete}
        onCancel={handleCancelDelete}
      />
    </Wrapper>
  )
}

export default AssetForm
