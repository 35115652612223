import { FilterFunction, initAsyncSlice } from 'aa_common/front-end/zustand'
import { FixedAssetsService, PresenterDepreciationMonthlyResponse } from 'common/open-api'

const initFilter: {
  biid?: string
  ledgerSettingId?: string
} = {}

const initState: PresenterDepreciationMonthlyResponse[] = []

const fetchData: FilterFunction<typeof initState, typeof initFilter> = async filters => {
  const biid = String(filters?.biid)
  const ledgerSettingId = String(filters?.ledgerSettingId)
  return FixedAssetsService.depreciationResultsOriginalDetail(biid, ledgerSettingId)
}

export const originalDepreciationStore = initAsyncSlice<typeof initState, typeof initFilter>(
  'asset-detail/accelerated-depreciation/original-depreciation',
  initState,
  initFilter,
  fetchData
)

export const getOriginalDepreciation = (biid: string, ledgerSettingId: string) =>
  originalDepreciationStore.setFilters({ biid, ledgerSettingId }, true)

export const resetOriginalDepreciation = () => originalDepreciationStore.setData(initState)
