import { FilterFunction, initAsyncSlice } from 'aa_common/front-end/zustand'
import { AccountingPeriodsService, PresenterOneTermMonthResponse } from 'common/open-api'

const initFilter: {
  start_date?: string
  end_date?: string
} = {}

const initState: PresenterOneTermMonthResponse[] = []

const fetchData: FilterFunction<typeof initState, typeof initFilter> = async filters => {
  return AccountingPeriodsService.termMonthsList({
    'filter[start_date]': String(filters?.start_date),
    'filter[end_date]': String(filters?.end_date),
  })
}

export const termMonthsInRange = initAsyncSlice<typeof initState, typeof initFilter>(
  'asset-detail/accelerated-depreciation/term-months-in-range',
  initState,
  initFilter,
  fetchData
)

export const getTermMonths = (start_date: string, end_date: string) =>
  termMonthsInRange.setFilters({ start_date, end_date }, true)

export const resetTermMonths = () => termMonthsInRange.setData(initState)
