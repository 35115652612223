import styled from '@emotion/styled/macro'
import { Dropdown, Menu, Table } from 'antd'
import { BLUE_COLOR, GREY_BORDER_COLOR } from 'common/constants/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ConfirmModal, RightDrawer } from 'aa_common/front-end/components'
import NumberFormat from 'react-number-format'

export const Wrapper = styled.div`
  .depreciation-cell {
    &.center {
      text-align: center;
    }
    &.right {
      text-align: right;
    }
  }

  td.depreciation-cell {
    &.month-simulation {
      padding: 0;
    }
  }

  col.ant-table-expand-icon-col {
    width: 30px;
  }

  .ant-table-expanded-row.month-simulation-detail {
    > .ant-table-cell {
      padding: 0;
    }

    .ant-table {
      border: unset;
      margin: 0;
    }
  }
`

export const Section = styled.section``

export const SectionHeaderText = styled.h4`
  font-weight: 800;
  font-size: 13px;
  line-height: 150%;
`

export const WrapperLedger = styled.div`
  margin-bottom: 15px;
  display: flex;
  align-items: center;

  .ant-btn {
    border: none;
    border-radius: 15px;
    margin-right: 10px;
  }

  .disable {
    color: #333333;
    background-color: #d4d8dd;
  }

  .ant-btn:hover,
  .active {
    background-color: #3b7de9;
    color: #ffffff;
  }
`
export const StyledDropdownContent = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  width: fit-content;
  border: 1px solid ${GREY_BORDER_COLOR};
  border-radius: 4px;
  height: 30px;

  & > span {
    color: ${BLUE_COLOR};
  }

  &:hover {
    cursor: pointer;
  }
`

export const StyledMenu = styled(Menu)`
  min-width: 190px;
  border: 1px solid ${GREY_BORDER_COLOR};
  border-radius: 4px;
  padding: 4px 0;

  .ant-menu-item {
    margin: 0;
    color: #303030;
    background-color: unset;

    &:hover {
      background-color: #ebf1fb;
      color: #303030;
      cursor: pointer;
    }

    &.canceling {
      &:hover {
        cursor: not-allowed;
      }
    }
  }
`

export const StyledSectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StyledDropdown = styled(Dropdown)`
  &.disabled {
    color: #919191;
    background-color: #e2e2e2;
    cursor: not-allowed;

    & > span {
      color: #919191;
    }
  }
`

export const StyledArrowIcon = styled(FontAwesomeIcon)`
  margin-left: 5px;
  padding-bottom: 3px;
  color: ${BLUE_COLOR};

  &.disabled {
    color: #919191;
  }
`

export const StyledTopSection = styled.div`
  .label {
    min-width: 100px;
  }

  .item {
    margin-left: 0;
  }
`

export const StyledAntTable = styled(Table)`
  .expandable-table {
    border-radius: unset;

    .ant-table {
      width: 100%;
    }
  }

  .ant-table {
    .ant-table-thead {
      padding: 0 20px;
    }

    th.ant-table-cell,
    td.ant-table-cell {
      padding: 10px;
    }

    td.ant-table-cell:first-of-type,
    th.ant-table-cell:first-of-type {
      padding-left: 30px;
      padding-right: 0;
    }

    td.ant-table-cell:last-of-type,
    th.ant-table-cell:last-of-type {
      padding-right: 30px;
    }
  }

  .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
    border: none;
    margin-left: 0;
  }

  .ant-table-row-level-0 {
    background-color: #fafafa;
  }

  .ant-table-expanded-row {
    & > .ant-table-cell {
      padding: 0;
    }

    .ant-table-row-level-0 {
      background-color: #ffffff;
    }

    .ant-table-thead {
      background-color: #722ed1 !important;
      padding: 5px;
    }
  }

  td.ant-table-cell:not(:first-of-type),
  th.ant-table-cell:not(:first-of-type) {
    text-align: right;
  }

  .child {
    background-color: #ffffff;
  }

  span.negative {
    color: #ec4949;
  }

  input.negative {
    border-color: #ec4949;
  }
`

export const StyledBreakLine = styled.span`
  white-space: pre-line;
  font-weight: 500;
`

export const StyledRightDrawer = styled(RightDrawer)`
  .ant-drawer-body {
    padding: 15px 24px;
  }
`

export const StyledNumberFormat = styled(NumberFormat)`
  padding: 8px;
  border: 1px solid ${GREY_BORDER_COLOR};
  width: 100%;
  height: 30px;
  border-radius: 4px;
  text-align: right;
  outline: none;

  &:focus {
    border-color: rgb(128, 189, 255);
    box-shadow: rgba(0, 123, 255, 0.25) 0px 0px 0px 0.1rem;
  }
`

export const StyledConfirmModal = styled(ConfirmModal)`
  .btn-confirm.color-blue {
    color: #ec4949;
    background: linear-gradient(180deg, #ffffff 0%, #eff1f4 100%);
    border-color: #d4d8dd;

    &:hover {
      color: #ec4949;
      background: linear-gradient(180deg, #eff1f4 0%, #ffffff 100%);
    }
  }
`

export const StyledWarningMessage = styled.div`
  padding: 16px;
  color: #b75b02;
  margin-bottom: 16px;
  background: #fef0c8;
  border-radius: 4px;
`

export const StyledErrorMessage = styled.span`
  color: #bc1227;
  display: inline-block;
  text-align: left;
`
export const StyledTopErrorMessage = styled.div`
  padding: 16px;
  color: #bc1227;
  background: #ffedea;
  margin-bottom: 16px;
  border-radius: 4px;
  white-space: break-spaces;
`
