/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PresenterChangeUnitPositionRequest,
  PresenterCreateUnitRequest,
  PresenterError,
  PresenterIDResponse,
  PresenterOneUnitResponse,
  PresenterResponse,
  PresenterUpdateUnitRequest,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Units<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description Get unit list
   *
   * @tags units
   * @name UnitsList
   * @summary Get the unit list
   * @request GET:/units
   */
  unitsList = (params: RequestParams = {}) =>
    this.http.request<PresenterOneUnitResponse[], any>({
      path: `/units`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Create unit with name
   *
   * @tags units
   * @name UnitsCreate
   * @summary Create the unit
   * @request POST:/units
   */
  unitsCreate = (body: PresenterCreateUnitRequest, params: RequestParams = {}) =>
    this.http.request<PresenterIDResponse, PresenterError>({
      path: `/units`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description change position of unit
   *
   * @tags units
   * @name ChangePositionPartialUpdate
   * @summary change position of unit
   * @request PATCH:/units/change_position
   */
  changePositionPartialUpdate = (body: PresenterChangeUnitPositionRequest, params: RequestParams = {}) =>
    this.http.request<PresenterResponse, PresenterError>({
      path: `/units/change_position`,
      method: 'PATCH',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Get unit
   *
   * @tags units
   * @name UnitsDetail
   * @summary Get the unit by ID
   * @request GET:/units/{id}
   */
  unitsDetail = (id: number, params: RequestParams = {}) =>
    this.http.request<PresenterOneUnitResponse, any>({
      path: `/units/${id}`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Update unit
   *
   * @tags units
   * @name UnitsUpdate
   * @summary Update the unit
   * @request PUT:/units/{id}
   */
  unitsUpdate = (id: number, body: PresenterUpdateUnitRequest, params: RequestParams = {}) =>
    this.http.request<PresenterResponse, any>({
      path: `/units/${id}`,
      method: 'PUT',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Delete unit by id
   *
   * @tags units
   * @name UnitsDelete
   * @summary Delete unit
   * @request DELETE:/units/{id}
   */
  unitsDelete = (
    id: number,
    query?: {
      /** dry_run enable to check whether you can delete Unit or not */
      dry_run?: boolean
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterIDResponse, PresenterError>({
      path: `/units/${id}`,
      method: 'DELETE',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
