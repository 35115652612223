import { someTrue } from 'aa_common/front-end/helpers'
import { ASSET_CODE_AUTO_GENERATE_SETTING_METHOD } from 'common/constants'
import { useFormikContext } from 'formik'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectAssetCodeAutoGenerateSettingListData } from 'store/settings/assetCode/selectors'

import { branchCodeField, codeField } from '../const'
import { checkAcquiredAtBelongToAssetCodeAutoGenerate } from '../helper'

export const useResetFACode = () => {
  const assetCodeAutoGenerateSettingListData = useSelector(selectAssetCodeAutoGenerateSettingListData, isEqual)

  const { values, setFieldValue } = useFormikContext()
  const acquiredAt = get(values, 'acquired_at')
  const transitionAt = get(values, 'transition_at')

  const currentSetting = useMemo(() => {
    if (!assetCodeAutoGenerateSettingListData || !acquiredAt) return null
    return assetCodeAutoGenerateSettingListData.find(setting =>
      someTrue(
        checkAcquiredAtBelongToAssetCodeAutoGenerate(setting, acquiredAt),
        checkAcquiredAtBelongToAssetCodeAutoGenerate(setting, transitionAt)
      )
    )
  }, [assetCodeAutoGenerateSettingListData, acquiredAt, transitionAt])

  useEffect(() => {
    if (currentSetting?.generating_method === ASSET_CODE_AUTO_GENERATE_SETTING_METHOD.AUTO_NUMBER) {
      setFieldValue(codeField.text, '')
      setFieldValue(branchCodeField.text, '')
    } else if (currentSetting?.generating_method === ASSET_CODE_AUTO_GENERATE_SETTING_METHOD.AUTO_NUMBER_BRANCH) {
      setFieldValue(branchCodeField.text, '')
    }
  }, [currentSetting, setFieldValue])
}
