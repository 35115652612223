import { OperationLog } from 'common/models'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchOperationLogItem } from 'store/internalControl/operationLog/actions'

import { OperationLogType, UpdateChangeMode } from '../constant'

const UpdateTypes = [OperationLogType.UPDATE, OperationLogType.CORRECT, OperationLogType.CORRECT_FA]
const DeleteTypes = [OperationLogType.DELETE, OperationLogType.REMOVE]

export const useFetchOperationLogItem = (
  logItem: OperationLog | null,
  setViewUpdateMode: Dispatch<SetStateAction<UpdateChangeMode>>
) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (!logItem) return

    setViewUpdateMode(UpdateChangeMode.BEFORE)
    const isUpdateOrDeleteType = [...UpdateTypes, ...DeleteTypes].indexOf(logItem.type) >= 0
    if (isUpdateOrDeleteType) {
      dispatch(fetchOperationLogItem(logItem.asset.biid, logItem.beforeId))
    } else {
      dispatch(fetchOperationLogItem(logItem.asset.biid, logItem.afterId))
    }
  }, [logItem, setViewUpdateMode])
}
