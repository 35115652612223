import styled from '@emotion/styled'

export const StyledWrapper = styled.div`
  box-sizing: border-box;
  width: 570px;
  margin: 0 auto;
`

export const StyledTitle = styled.h1`
  padding: 10px 0;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0;
`
export const StyledContent = styled.div`
  white-space: break-spaces;
`
