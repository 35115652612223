import { AssetDisplayDataRow } from 'common/components/report-row/styles'
import { getNs } from 'common/i18n-config'
import { Asset, AssetReport } from 'common/models'
import { FileLink, PhotoView, ReferenceLink } from 'components/atoms'
import get from 'lodash/get'
import React from 'react'

const { t, useNsReady } = getNs(['components/organisms/accounting/monthly-closing-asset-event-list'])
export const Media = ({
  asset,
  photoField = 'event_photos',
  attachmentField = 'event_attachments',
  urlField = 'event_urls',
}: Props) => {
  useNsReady()
  return (
    <>
      <AssetDisplayDataRow>
        <div className="label">{t('photo')}</div>
        <div className="value">
          {asset?.[photoField as keyof typeof asset]?.map((photo: any) => (
            <div key={photo?.id}>
              <PhotoView src={get(photo, 'url')} style={{ marginRight: 10 }} />
            </div>
          ))}
        </div>
      </AssetDisplayDataRow>
      <AssetDisplayDataRow>
        <div className="label">{t('attachment')}</div>
        <div className="value" style={{ display: 'block' }}>
          {asset?.[attachmentField as keyof typeof asset]?.map((attachment: any) => (
            <div key={attachment?.id}>
              <FileLink file={attachment} />
            </div>
          ))}
        </div>
      </AssetDisplayDataRow>
      <AssetDisplayDataRow>
        <div className="label">{t('reference_link')}</div>
        <div className="value" style={{ display: 'block' }}>
          {asset?.[urlField as keyof typeof asset]?.map((url: any) => (
            <div key={url}>
              <ReferenceLink url={url} />
            </div>
          ))}
        </div>
      </AssetDisplayDataRow>
    </>
  )
}

type Props = {
  asset: Asset | AssetReport
  photoField?: string
  attachmentField?: string
  urlField?: string
}
