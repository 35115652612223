/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PresenterAgentLoginSessionRequest,
  PresenterCurrentUser,
  PresenterMFIDSessionRequest,
  PresenterMFIDSessionResponse,
  PresenterOneOfficeResponse,
  PresenterSSOSessionRequest,
  PresenterUserInfo,
  PresenterWithdrawableStatus,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Sessions<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description MFID calls this endpoint after a user sign out
   *
   * @tags sessions
   * @name BackchannelLogoutCreate
   * @summary BackChannelLogout
   * @request POST:/backchannel_logout
   */
  backchannelLogoutCreate = (
    data: {
      /** userID to revoke all of session */
      user_id: number
    },
    params: RequestParams = {}
  ) =>
    this.http.request<void, any>({
      path: `/backchannel_logout`,
      method: 'POST',
      body: data,
      type: ContentType.UrlEncoded,
      ...params,
    })
  /**
   * @description return current office
   *
   * @tags sessions
   * @name CurrentOfficeList
   * @summary CurrentOffice
   * @request GET:/current_office
   */
  currentOfficeList = (params: RequestParams = {}) =>
    this.http.request<PresenterOneOfficeResponse, any>({
      path: `/current_office`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * @description return current user
   *
   * @tags sessions
   * @name CurrentUserList
   * @summary CurrentUser
   * @request GET:/current_user
   */
  currentUserList = (params: RequestParams = {}) =>
    this.http.request<PresenterCurrentUser, any>({
      path: `/current_user`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * @description return user info
   *
   * @tags sessions
   * @name GetSessions
   * @summary UserInfo
   * @request GET:/me
   */
  getSessions = (params: RequestParams = {}) =>
    this.http.request<PresenterUserInfo, any>({
      path: `/me`,
      method: 'GET',
      format: 'json',
      ...params,
    })
  /**
   * @description Create session from OAuth Code, nonce, tenant_uid for Replay Attacks
   *
   * @tags sessions
   * @name AgentloginCreate
   * @summary Create session with customer support user, delegated user
   * @request POST:/sessions/agentlogin
   */
  agentloginCreate = (body: PresenterAgentLoginSessionRequest, params: RequestParams = {}) =>
    this.http.request<PresenterMFIDSessionResponse, any>({
      path: `/sessions/agentlogin`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Create session from OAuth Code, nonce for Replay Attacks
   *
   * @tags sessions
   * @name MfidCreate
   * @summary Create session with  MFID
   * @request POST:/sessions/mfid
   */
  mfidCreate = (body: PresenterMFIDSessionRequest, params: RequestParams = {}) =>
    this.http.request<PresenterMFIDSessionResponse, any>({
      path: `/sessions/mfid`,
      method: 'POST',
      body: body,
      type: ContentType.UrlEncoded,
      format: 'json',
      ...params,
    })
  /**
   * @description Revoke User's Access Token and Refresh Token
   *
   * @tags sessions
   * @name MfidDelete
   * @summary Revoke User's Access Token and Refresh Token
   * @request DELETE:/sessions/mfid
   */
  mfidDelete = (params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/sessions/mfid`,
      method: 'DELETE',
      ...params,
    })
  /**
   * @description Refresh JWT from refresh token in Cookie
   *
   * @tags sessions
   * @name RefreshCreate
   * @summary Refresh JWT
   * @request POST:/sessions/refresh
   */
  refreshCreate = (params: RequestParams = {}) =>
    this.http.request<PresenterMFIDSessionResponse, any>({
      path: `/sessions/refresh`,
      method: 'POST',
      format: 'json',
      ...params,
    })
  /**
   * @description Create session from OAuth Code, nonce, tenant_uid for Replay Attacks
   *
   * @tags sessions
   * @name PostSessions
   * @summary Create session with  MFID and office_id
   * @request POST:/sessions/sso
   */
  postSessions = (body: PresenterSSOSessionRequest, params: RequestParams = {}) =>
    this.http.request<PresenterMFIDSessionResponse, any>({
      path: `/sessions/sso`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description MFID calls this endpoint to check whether user can withdraw
   *
   * @tags sessions
   * @name WithdrawableList
   * @summary WithdrawableStatus
   * @request GET:/withdrawable
   */
  withdrawableList = (
    query?: {
      /** required */
      user_id?: string
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterWithdrawableStatus, any>({
      path: `/withdrawable`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    })
}
