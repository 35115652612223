import { checkAllFeatures, openFeatureSlice } from 'common/open-features/store'
import { useEffect } from 'react'

export * from './store'
export const useOpenFeature = (isPublic?: boolean) => {
  const remoteFlags = openFeatureSlice.useSlice()
  useEffect(() => {
    checkAllFeatures(isPublic)
  }, [isPublic])

  return [remoteFlags]
}
