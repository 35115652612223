import { isNumberHaveValue } from 'aa_common/front-end/helpers'
import { useMemo } from 'react'

export const useCanResetExciseInput = (manualExciseValue?: number, calculatedExciseValue?: number) => {
  return useMemo(() => {
    return (
      isNumberHaveValue(manualExciseValue) &&
      isNumberHaveValue(calculatedExciseValue) &&
      manualExciseValue !== calculatedExciseValue
    )
  }, [manualExciseValue, calculatedExciseValue])
}
