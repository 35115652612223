import { ConfirmModal } from 'aa_common/front-end/components'
import { CONFIRM_TYPES } from 'common/constants'
import { getNs } from 'common/i18n-config'
import { PresenterFixedAssetDepreciationResponse } from 'common/open-api'
import React, { useCallback, useEffect, useState } from 'react'

import { Cancel_Accelerated_Depreciation_Error } from '../../types'
import { ErrorDialogContent } from './error-dialog-content'

const { t, useNsReady } = getNs(['common', 'components/organisms/assets/asset-detail'])

type ErrorDialogProps = {
  error: Cancel_Accelerated_Depreciation_Error
  depreciationResults: PresenterFixedAssetDepreciationResponse
  onClickDetail?: (isMonthlyError: boolean) => void
}
export const ErrorDialog = ({ error, depreciationResults, onClickDetail }: ErrorDialogProps) => {
  useNsReady()

  const [isVisible, setIsVisible] = useState(false)
  const handleOnClickDetail = useCallback(() => {
    setIsVisible(false)
    onClickDetail?.(!!error.isMonthlyClosingError)
  }, [error, onClickDetail, setIsVisible])

  useEffect(() => {
    setIsVisible(!!error.isKnownError)
  }, [error, setIsVisible])

  return (
    <ConfirmModal
      width={600}
      closable
      type={CONFIRM_TYPES.NOTICE}
      cancelText={t('actions.close')}
      title={t('cancel_modal.cannot_accelerated_title')}
      message={
        <ErrorDialogContent
          isMonthlyClosingError={!!error.isMonthlyClosingError}
          depreciationResults={depreciationResults}
          onClickDetail={handleOnClickDetail}
        />
      }
      visible={isVisible}
      onCancel={() => setIsVisible(false)}
      onOK={() => setIsVisible(false)}
    />
  )
}
