/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PresenterAreaFilter,
  PresenterCreateAreaRequest,
  PresenterError,
  PresenterIDResponse,
  PresenterInsertAreaRecordRequest,
  PresenterMeta,
  PresenterOneAreaResponse,
  PresenterResponse,
  PresenterUpdateAreaRequest,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Areas<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description Get area list
   *
   * @tags areas
   * @name AreasList
   * @summary Get the area list
   * @request GET:/areas
   */
  areasList = (
    query?: {
      'filter[biid]'?: string[]
      'filter[code]'?: string
      'filter[is_show_future]'?: boolean
      'filter[is_show_past]'?: boolean
      'filter[name]'?: string
      'filter[search_key]'?: string
      'filter[submit_destination_id]'?: number
      'filter[valid_at]'?: string
      'page[number]'?: number
      'page[size]'?: number
      sort?: string[]
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterOneAreaResponse[], any>({
      path: `/areas`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Create area with name
   *
   * @tags areas
   * @name AreasCreate
   * @summary Create the area
   * @request POST:/areas
   */
  areasCreate = (body: PresenterCreateAreaRequest, params: RequestParams = {}) =>
    this.http.request<PresenterOneAreaResponse, PresenterError>({
      path: `/areas`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Delete area with biid (all history)
   *
   * @tags areas
   * @name DeleteByBiidDelete
   * @summary Delete the whole area
   * @request DELETE:/areas/delete_by_biid/{biid}
   */
  deleteByBiidDelete = (
    biid: string,
    query?: {
      /** dry_run enable to check whether you can delete Area or not */
      dry_run?: boolean
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterMeta, PresenterError>({
      path: `/areas/delete_by_biid/${biid}`,
      method: 'DELETE',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Delete area with id
   *
   * @tags areas
   * @name DeleteByIdDelete
   * @summary Delete the area
   * @request DELETE:/areas/delete_by_id/{id}
   */
  deleteByIdDelete = (
    id: number,
    query?: {
      /** dry_run enable to check whether you can delete Area or not */
      dry_run?: boolean
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterIDResponse, PresenterError>({
      path: `/areas/delete_by_id/${id}`,
      method: 'DELETE',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Import area from csv file
   *
   * @tags areas
   * @name ImportCreate
   * @summary Import area
   * @request POST:/areas/import
   */
  importCreate = (
    data: {
      /** asset accounting csv file to import */
      file: File
      /** asset accounting csv file to import */
      charset?: string
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterResponse, any>({
      path: `/areas/import`,
      method: 'POST',
      body: data,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    })
  /**
   * @description Insert the area record
   *
   * @tags areas
   * @name InsertRecordCreate
   * @summary Insert the area record
   * @request POST:/areas/insert_record
   */
  insertRecordCreate = (body: PresenterInsertAreaRecordRequest, params: RequestParams = {}) =>
    this.http.request<PresenterResponse, any>({
      path: `/areas/insert_record`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Search area list with body
   *
   * @tags areas
   * @name SearchCreate
   * @summary Search the area list with body
   * @request POST:/areas/search
   */
  searchCreate = (body: PresenterAreaFilter, params: RequestParams = {}) =>
    this.http.request<PresenterOneAreaResponse[], any>({
      path: `/areas/search`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Update area
   *
   * @tags areas
   * @name UpdateByIdUpdate
   * @summary Update the area
   * @request PUT:/areas/update_by_id/{id}
   */
  updateByIdUpdate = (id: number, body: PresenterUpdateAreaRequest, params: RequestParams = {}) =>
    this.http.request<PresenterResponse, any>({
      path: `/areas/update_by_id/${id}`,
      method: 'PUT',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Get area with id
   *
   * @tags areas
   * @name AreasDetail
   * @summary Get the area by ID
   * @request GET:/areas/{id}
   */
  areasDetail = (id: number, params: RequestParams = {}) =>
    this.http.request<PresenterOneAreaResponse, PresenterError>({
      path: `/areas/${id}`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
