/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PresenterCreateSubmitDestinationRequest,
  PresenterError,
  PresenterIDResponse,
  PresenterOneShortSubmitDestinationResponse,
  PresenterOneSubmitDestinationResponse,
  PresenterResponse,
  PresenterUpdateSubmitDestinationRequest,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class SubmitDestinations<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description Get submit destination list
   *
   * @tags submit_destinations
   * @name SubmitDestinationsList
   * @summary Get the submit destination list
   * @request GET:/submit_destinations
   */
  submitDestinationsList = (
    query?: {
      'filter[apply_destination]'?: string
      'filter[asset_location_1]'?: string
      'filter[asset_location_2]'?: string
      'filter[asset_location_3]'?: string
      'filter[capital_amount]'?: number
      'filter[code]'?: string
      'filter[commercial_name]'?: string
      'filter[company_established_at_month]'?: number
      'filter[company_established_at_year]'?: number
      'filter[company_name]'?: string
      'filter[company_name_kana]'?: string
      'filter[details_export_setting]'?: number
      'filter[has_additional_depreciation_report]'?: boolean
      'filter[has_approval_of_shortened_service_life]'?: boolean
      'filter[has_blue_return]'?: boolean
      'filter[has_borrow_asset]'?: boolean
      'filter[has_depreciation_method_on_ta_for_dbm]'?: boolean
      'filter[has_depreciation_method_on_ta_for_slm]'?: boolean
      'filter[has_non_taxable_asset]'?: boolean
      'filter[has_special_depreciation_or_compress_entry]'?: boolean
      'filter[has_taxation_standard_special]'?: boolean
      'filter[industry_category]'?: string
      'filter[lender_name]'?: string
      'filter[name]'?: string
      'filter[owner_address]'?: string
      'filter[owner_address_kana]'?: string
      'filter[owner_category_of_building_of_company_rented_house]'?: boolean
      'filter[owner_category_of_building_of_company_self_owned]'?: boolean
      'filter[owner_code]'?: string
      'filter[owner_telephone]'?: string
      'filter[owner_zip_code]'?: string
      'filter[person_in_charge_department]'?: string
      'filter[person_in_charge_name]'?: string
      'filter[person_in_charge_telephone]'?: string
      'filter[remark]'?: string
      'filter[representative_name]'?: string
      'filter[representative_name_kana]'?: string
      'filter[representative_position]'?: string
      'filter[search_key]'?: string
      'filter[tax_accountant_name]'?: string
      'filter[tax_accountant_telephone]'?: string
      sort?: string[]
      'page[number]'?: number
      'page[size]'?: number
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterOneShortSubmitDestinationResponse[], any>({
      path: `/submit_destinations`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Create submit destination with name
   *
   * @tags submit_destinations
   * @name SubmitDestinationsCreate
   * @summary Create the submit destination
   * @request POST:/submit_destinations
   */
  submitDestinationsCreate = (body: PresenterCreateSubmitDestinationRequest, params: RequestParams = {}) =>
    this.http.request<PresenterIDResponse, PresenterError>({
      path: `/submit_destinations`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Import submit destination from csv file
   *
   * @tags submit_destinations
   * @name ImportOneCreate
   * @summary Import the submit destination
   * @request POST:/submit_destinations/import_one
   */
  importOneCreate = (
    data: {
      /** asset accounting csv file to import */
      file: File
      /** asset accounting csv file to import */
      charset?: string
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterResponse, any>({
      path: `/submit_destinations/import_one`,
      method: 'POST',
      body: data,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    })
  /**
   * @description Get submit destination with id
   *
   * @tags submit_destinations
   * @name SubmitDestinationsDetail
   * @summary Get the submit destination by ID
   * @request GET:/submit_destinations/{id}
   */
  submitDestinationsDetail = (id: number, params: RequestParams = {}) =>
    this.http.request<PresenterOneSubmitDestinationResponse, PresenterError>({
      path: `/submit_destinations/${id}`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Update submit destination
   *
   * @tags submit_destinations
   * @name SubmitDestinationsUpdate
   * @summary Update the submit destination
   * @request PUT:/submit_destinations/{id}
   */
  submitDestinationsUpdate = (id: number, body: PresenterUpdateSubmitDestinationRequest, params: RequestParams = {}) =>
    this.http.request<PresenterResponse, any>({
      path: `/submit_destinations/${id}`,
      method: 'PUT',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Delete submit destination with id
   *
   * @tags submit_destinations
   * @name SubmitDestinationsDelete
   * @summary Delete the submit destination
   * @request DELETE:/submit_destinations/{id}
   */
  submitDestinationsDelete = (
    id: number,
    query?: {
      /** dry_run enable to check whether you can delete SubmitDestination or not */
      dry_run?: boolean
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterIDResponse, PresenterError>({
      path: `/submit_destinations/${id}`,
      method: 'DELETE',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
