import * as assetCategoryApi from 'api/app/assetCategory'
import { useFormikContext } from 'formik'
import useResource from 'lib/hooks/useResource'
import get from 'lodash/get'
import { useEffect } from 'react'

export const useSelectAssetCategory = () => {
  const { values } = useFormikContext()
  const assetCategoryId = get(values, 'asset_category_id')?.value
  const [resultSelectedAssetCategory, getSelectedAssetCategory] = useResource(assetCategoryApi.getAssetCategory)

  useEffect(() => {
    assetCategoryId && getSelectedAssetCategory(assetCategoryId)
  }, [assetCategoryId])

  return {
    resultSelectedAssetCategory,
    assetType: resultSelectedAssetCategory?.data?.asset_type as number | undefined,
  }
}
