import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { changeLocation } from 'store/app/actions'

export const useClickErrorDetail = (changeToTabEvent?: () => void) => {
  const dispatch = useDispatch()
  return useCallback(
    (isMonthlyError: boolean) => {
      !isMonthlyError && changeToTabEvent?.()
      isMonthlyError && dispatch(changeLocation('/accounting/monthly-closing'))
    },
    [changeToTabEvent]
  )
}
