import styled from '@emotion/styled'
import { Table as AntTable } from 'antd'

export const AmortizationTable = styled.table`
  width: 100%;
  margin-bottom: 15px;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;

  border-radius: 4px;
  border: 1px solid #d4d8dd;

  tr {
    min-height: 32px;

    th {
      background-color: #e7f1fd;
      text-align: left;
      width: 180px;
    }

    td {
      background-color: transparent;
      width: auto;
    }

    th,
    td {
      padding: 5px 10px;
      border: none;
      border-top: 1px solid #d4d8dd;
    }

    &:first-of-type {
      th,
      td {
        border-top: none;
      }
    }
  }
`

export const Table = styled(AntTable)`
  .ant-table {
    border: none;

    &.ant-table-bordered {
      & > .ant-table-container {
        border-color: #d4d8dd;
        border-radius: 4px;

        & > .ant-table-content {
          & > table {
            border-top-color: #d4d8dd;
            border-radius: 4px;
          }
        }

        .ant-table-cell {
          border-color: #d4d8dd;

          &.no-border-right {
            border-right: none;
          }

          &:first-child {
            width: 160px;
          }
        }
      }
    }

    .ant-table-thead {
      tr:first-of-type {
        th {
          background-color: #ffffff;
        }

        .ant-table-cell {
          text-align: left;
          font-weight: 500;
        }
      }
    }

    .ant-table-cell {
      padding: 10px;
      font-weight: 400;
      border-color: #d4d8dd !important;

      &.border-double-left {
        border-left: 3px double #d4d8dd;
      }
    }
  }
`
