import { ExportModal, If } from 'aa_common/front-end'
import { getNs } from 'common/i18n-config'
import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import RadioGroup from 'components/atoms/v2/RadioGroup'
import React, { MutableRefObject } from 'react'

import { EXPORT_BY_SUMMARIZE_OPTIONS, EXPORT_BY_UNIT_OPTIONS, SUMMARY_METHODS } from '../const'
import { ExportModalBody } from '../styles'

type ExportModalProps = {
  isShowExportModal: boolean
  setIsShowExportModal: (value: boolean) => void
  handleExport: (charset: string) => void
  outputUnitRef: MutableRefObject<any>
  exportBy?: string
}

const { t, useNsReady } = getNs(['components/organisms/depreciation/depreciation-result-list'])
export const DepreciationExportModal = ({
  isShowExportModal,
  setIsShowExportModal,
  handleExport,
  outputUnitRef,
  exportBy,
}: ExportModalProps) => {
  useNsReady()

  const { isEnable: isDepartmentFlagOn } = useCheckFlag(FeatureFlagNames.DepartmentDepreciationAmountReport)

  return (
    <If condition={isDepartmentFlagOn}>
      <ExportModal
        title={t('export_title')}
        isShow={isShowExportModal}
        onCancel={() => setIsShowExportModal(false)}
        onExport={handleExport}
      >
        <ExportModalBody>
          <h4>{t('select_output_unit')}</h4>
          <RadioGroup
            name="output_unit"
            options={exportBy === SUMMARY_METHODS.DEPARTMENT ? EXPORT_BY_SUMMARIZE_OPTIONS : EXPORT_BY_UNIT_OPTIONS}
            defaultValue={outputUnitRef.current}
            onChange={e => (outputUnitRef.current = e.target.value)}
          />
          <h4>{t('select_file_format')}</h4>
        </ExportModalBody>
      </ExportModal>
    </If>
  )
}
