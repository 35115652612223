import { Tooltip } from 'aa_common/front-end/antd'
import { If } from 'aa_common/front-end/components'
import { ifElse } from 'aa_common/front-end/helpers'
import classNames from 'classnames'
import { ASSET_CODE_AUTO_GENERATE_SETTING_METHOD } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import { AutoGenerateSetting, FORM_MODES } from 'common/models'
import { Input, RegexInput } from 'components/atoms'
import { useFormikContext } from 'formik'
import get from 'lodash/get'
import React from 'react'

import { branchCodeField, codeField } from '../const'
import { AssetCodeWrapper, Error } from '../styles'

const t = loadNs(['components/organisms/assets/asset-form', 'common'])

type AssetCodeInputsProps = {
  formMode: FORM_MODES
  currentSetting?: AutoGenerateSetting | null
  showRequiredError?: boolean
}
export const AssetCodeInputs = ({ currentSetting, formMode, showRequiredError }: AssetCodeInputsProps) => {
  const { values } = useFormikContext()
  const isEditMode = formMode === FORM_MODES.EDIT

  return (
    <>
      <If condition={currentSetting?.generating_method === ASSET_CODE_AUTO_GENERATE_SETTING_METHOD.AUTO_NUMBER}>
        <AssetCodeWrapper>
          <div style={{ width: 120 }}>
            <Tooltip content={t('auto_generate_code_tooltip')} placement="top">
              <Input
                name={codeField.text}
                type="code"
                maxLength={codeField.maxLength}
                isShowError={false}
                disabled
                value=""
              />
            </Tooltip>
          </div>
          <div style={{ width: 76 }}>
            <Tooltip content={t('auto_generate_code_tooltip')} placement="top">
              <Input
                name={branchCodeField.text}
                type="code"
                maxLength={branchCodeField.maxLength}
                style={{ marginLeft: 24 }}
                isShowError={false}
                disabled
                value=""
              />
            </Tooltip>
          </div>
        </AssetCodeWrapper>
      </If>
      <If condition={currentSetting?.generating_method === ASSET_CODE_AUTO_GENERATE_SETTING_METHOD.AUTO_NUMBER_BRANCH}>
        <AssetCodeWrapper>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <RegexInput
              name={codeField.text}
              type="code"
              maxLength={codeField.maxLength}
              style={{ width: 120 }}
              isShowError={false}
              disabled={isEditMode}
              placeholder={ifElse(!isEditMode, t('placeholder.under_15'), '')}
              className={classNames({ error: showRequiredError && !get(values, codeField.text) })}
            />
            <If condition={showRequiredError && !get(values, codeField.text)}>
              <Error>{t('code_required_error')}</Error>
            </If>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Tooltip content={t('auto_generate_code_tooltip')} placement="top">
              <Input
                name={branchCodeField.text}
                value=""
                type="branch_code"
                maxLength={branchCodeField.maxLength}
                style={{ width: 76, marginLeft: 24 }}
                isShowError={false}
                disabled
              />
            </Tooltip>
          </div>
        </AssetCodeWrapper>
      </If>
      <If
        condition={
          currentSetting?.generating_method !== ASSET_CODE_AUTO_GENERATE_SETTING_METHOD.AUTO_NUMBER &&
          currentSetting?.generating_method !== ASSET_CODE_AUTO_GENERATE_SETTING_METHOD.AUTO_NUMBER_BRANCH
        }
      >
        <div style={{ display: 'flex' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <RegexInput
              name={codeField.text}
              type="code"
              maxLength={codeField.maxLength}
              style={{ width: 120 }}
              isShowError={false}
              disabled={isEditMode}
              placeholder={!isEditMode ? t('placeholder.under_15') : ''}
              className={classNames({ error: showRequiredError && !get(values, codeField.text) })}
            />
            <If condition={showRequiredError && !get(values, 'code')}>
              <Error>{t('code_required_error')}</Error>
            </If>
          </div>
          <div>
            <RegexInput
              name={branchCodeField.text}
              type="branch_code"
              maxLength={branchCodeField.maxLength}
              style={{ width: 76, marginLeft: 24 }}
              placeholder={!isEditMode ? t('placeholder.under_3') : ''}
              isShowError={false}
              disabled={isEditMode}
              className={classNames({ error: showRequiredError && !get(values, branchCodeField.text) })}
            />
            <If condition={showRequiredError && !get(values, branchCodeField.text)}>
              <Error style={{ marginLeft: 24 }}>
                <label>{t('branch_code_required_error')}</label>
              </Error>
            </If>
          </div>
        </div>
      </If>
    </>
  )
}
