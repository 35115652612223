import { If, UnborderCollapse } from 'aa_common/front-end/components'
import { defaultTo, everyTrue } from 'aa_common/front-end/helpers'
import classNames from 'classnames'
import { loadNs } from 'common/i18n-config'
import { AutoCompleteSelect } from 'components/molecules'
import { getIn, useFormikContext } from 'formik'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectMasterData } from 'store/masterData/selectors'

import { DEPRECIATION_FIELDS } from '../const'
import { genLedgerFieldName } from '../helper'
import { useDepreciationMethodChanged } from '../hooks/use-depreciation-method-changed'
import { Error } from '../styles'

const { Item } = UnborderCollapse
const t = loadNs(['components/organisms/assets/asset-form', 'common'])

type DepreciationMethodsProps = {
  ledgerName: string
  ledgerIndex: number
  disabled: boolean
  showRequiredError: boolean
  afterDepreciationChanged: () => void
}

export const DepreciationMethods = ({
  ledgerName,
  ledgerIndex,
  disabled,
  showRequiredError,
  afterDepreciationChanged,
}: DepreciationMethodsProps) => {
  const { values } = useFormikContext()
  const masterData = useSelector(selectMasterData, isEqual)

  const depreciationMethodCodeField = genLedgerFieldName(DEPRECIATION_FIELDS.DEPRECIATION_METHOD_CODE, ledgerIndex)
  const isCopiedField = genLedgerFieldName(DEPRECIATION_FIELDS.IS_COPIED, ledgerIndex)
  const isCopied = getIn(values, isCopiedField)

  const filteredDepreciationMethods = useMemo(
    () => defaultTo(masterData?.depreciation_method, []),
    [masterData?.depreciation_method]
  )

  const onDepreciationMethodChanged = useDepreciationMethodChanged(ledgerIndex, afterDepreciationChanged)

  return (
    <Item label={t('depreciation_type')} required jsLabel={t('ledger_of', { name: ledgerName })}>
      <AutoCompleteSelect
        name={depreciationMethodCodeField}
        items={filteredDepreciationMethods}
        afterChanged={onDepreciationMethodChanged}
        valueField="code"
        textField="name_jp"
        disabled={disabled || isCopied}
        allowClear={false}
        className={classNames({ error: everyTrue(showRequiredError, !get(values, depreciationMethodCodeField)) })}
      />
      <If condition={everyTrue(showRequiredError, !get(values, depreciationMethodCodeField))}>
        <Error>{t('depreciation_type_required_error')}</Error>
      </If>
    </Item>
  )
}
