import { message } from 'antd'
import { CONFIRM_TYPES, DATE_FORMAT_RFC } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import { useFormikContext } from 'formik'
import useModalHook from 'hooks/useModalHook'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import moment, { Moment } from 'moment/moment'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchExciseSettingsByDate } from 'store/settings/excise/actions'
import { getExciseSettingsByDateList } from 'store/settings/excise/selectors'

import { fetchNewExciseSetting, isDifferentExciseSetting } from '../helper'

const t = loadNs(['components/organisms/assets/asset-form', 'common'])

export const useAcquiredAtChanged = (
  setBookValueReferUpdatedManually: () => void,
  setExciseSettingDate: React.Dispatch<React.SetStateAction<string>>
) => {
  const modal: any = useModalHook()
  const { values, setFieldValue } = useFormikContext()
  const dispatch = useDispatch()
  const { data } = useSelector(getExciseSettingsByDateList, isEqual)
  const transitionAt = get(values, 'transition_at')

  return useCallback(
    (acquiredAt: Moment | null) => {
      if (acquiredAt) {
        const newAcquiredAtValue = acquiredAt.format(DATE_FORMAT_RFC)

        if (acquiredAt > moment()) {
          message.warning(t('msg_acquired_at_future'))
        }

        const currentExciseSetting = {
          inputTaxWhenRegisterFixedAsset: data?.input_tax_when_register_fixed_asset,
          taxMethod: data?.tax_method,
          taxInputMethod: data?.tax_input_format,
        }
        const preAcquiredAt = get(values, 'acquired_at')

        if (!transitionAt) {
          setFieldValue('area_biid', null)
          setFieldValue('area', undefined)
          setFieldValue('department_biid', undefined)
          setFieldValue('manual_excise_value', undefined)
          fetchNewExciseSetting(newAcquiredAtValue, dispatch, fetchExciseSettingsByDate)
            .then((newExciseSetting: any) => {
              setExciseSettingDate(newAcquiredAtValue)

              if (!!data && isDifferentExciseSetting(currentExciseSetting, newExciseSetting)) {
                return modal.confirm({
                  title: t('notify_change_excise_setting_title'),
                  message: t('notify_change_excise_setting_message'),
                  type: CONFIRM_TYPES.CONFIRM,
                })
              }
            })
            .then((isOK?: boolean) => {
              if (isOK === false) {
                setFieldValue('acquired_at', preAcquiredAt)
                setExciseSettingDate(preAcquiredAt)
              }
            })
        }
      }
      setBookValueReferUpdatedManually()
    },
    [setFieldValue, modal, dispatch, data, transitionAt, setExciseSettingDate]
  )
}
