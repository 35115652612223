import { assetListActionsStatusSlice } from '../store'

export const useActionsStatus = () => {
  const actionsStatus = assetListActionsStatusSlice.useSlice(state => state?.data?.action_status)

  const getActionsStatus = (biids: string[]) => assetListActionsStatusSlice.setFilters({ biids }, true)

  const findActionsStatusByBiid = (biid: string) => actionsStatus?.find(actionStatus => actionStatus.biid === biid)

  return { actionsStatus, getActionsStatus, findActionsStatusByBiid }
}
