/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { PresenterError, PresenterIDResponse, PresenterJournalItemSettingResponse } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class JournalItemSettings<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description Get list journal item setting by filter
   *
   * @tags journal_item_settings
   * @name JournalItemSettingsList
   * @summary Get list journal item setting
   * @request GET:/journal_item_settings
   */
  journalItemSettingsList = (
    query?: {
      /** asset_category_id */
      asset_category_id?: number
      /** sort by created_at or -created_at */
      sort?: string
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterJournalItemSettingResponse[], PresenterError>({
      path: `/journal_item_settings`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Get latest list journal item setting of each asset category
   *
   * @tags journal_item_settings
   * @name LatestList
   * @summary Get latest list journal item setting of each asset category
   * @request GET:/journal_item_settings/latest
   */
  latestList = (params: RequestParams = {}) =>
    this.http.request<PresenterJournalItemSettingResponse[], PresenterError>({
      path: `/journal_item_settings/latest`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Delete journal item setting
   *
   * @tags journal_item_settings
   * @name JournalItemSettingsDelete
   * @summary Delete the journal item setting
   * @request DELETE:/journal_item_settings/{id}
   */
  journalItemSettingsDelete = (id: string, params: RequestParams = {}) =>
    this.http.request<PresenterIDResponse, PresenterError>({
      path: `/journal_item_settings/${id}`,
      method: 'DELETE',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
