import { isNumberHaveValue } from 'aa_common/front-end/helpers'
import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import { useFormikContext } from 'formik'
import get from 'lodash/get'
import moment from 'moment/moment'
import { useEffect } from 'react'

export const useSubsidyAmount = (acquisitionCostReference: number | undefined) => {
  const { values, setFieldValue } = useFormikContext()
  const { isEnable } = useCheckFlag(FeatureFlagNames.FixedAssetReduction)
  const acquiredAt = get(values, 'acquired_at')
  const usageStartedAt = get(values, 'usage_started_at')
  const subsidyAmount = get(values, 'subsidy_amount')
  const reductionAmount = get(values, 'reduction_amount')
  const netAcquisitionCost = get(values, 'net_acquisition_cost')
  const hasTransition = !!get(values, 'transition_at')
  const isAcquiredAtSameAsUsageStartedAt = moment(acquiredAt).isSame(moment(usageStartedAt))

  useEffect(() => {
    if (
      isEnable &&
      !Number.isNaN(parseInt(String(subsidyAmount), 10)) &&
      !Number.isNaN(parseInt(String(acquisitionCostReference), 10)) &&
      subsidyAmount <= acquisitionCostReference!
    ) {
      setFieldValue('net_acquisition_cost', acquisitionCostReference! - subsidyAmount)
    }
  }, [acquisitionCostReference, subsidyAmount, isEnable])

  useEffect(() => {
    if (isEnable && (!hasTransition || !isNumberHaveValue(reductionAmount) || reductionAmount > subsidyAmount)) {
      setFieldValue('reduction_amount', subsidyAmount)
    }
  }, [subsidyAmount, reductionAmount, hasTransition, isEnable])

  useEffect(() => {
    if (isEnable && !isAcquiredAtSameAsUsageStartedAt && !hasTransition) {
      setFieldValue('subsidy_amount', undefined)
      isNumberHaveValue(reductionAmount) && setFieldValue('reduction_amount', 0)
      isNumberHaveValue(netAcquisitionCost) && setFieldValue('net_acquisition_cost', acquisitionCostReference)
    }
  }, [
    isEnable,
    isAcquiredAtSameAsUsageStartedAt,
    hasTransition,
    reductionAmount,
    netAcquisitionCost,
    acquisitionCostReference,
  ])
}
