/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PresenterCreateDepartmentRequest,
  PresenterDepartmentFilter,
  PresenterError,
  PresenterIDResponse,
  PresenterInsertDepartmentRecordRequest,
  PresenterMeta,
  PresenterOneDepartmentResponse,
  PresenterResponse,
  PresenterUpdateDepartmentRequest,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Departments<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description Get department list
   *
   * @tags departments
   * @name DepartmentsList
   * @summary Get the department list
   * @request GET:/departments
   */
  departmentsList = (
    query?: {
      'filter[biid]'?: string[]
      'filter[code]'?: string
      'filter[is_show_future]'?: boolean
      'filter[is_show_past]'?: boolean
      'filter[name]'?: string
      'filter[prepress_category_code]'?: number
      'filter[search_key]'?: string
      'filter[valid_at]'?: string
      'page[number]'?: number
      'page[size]'?: number
      sort?: string[]
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterOneDepartmentResponse[], any>({
      path: `/departments`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Create department
   *
   * @tags departments
   * @name DepartmentsCreate
   * @summary Create the department
   * @request POST:/departments
   */
  departmentsCreate = (body: PresenterCreateDepartmentRequest, params: RequestParams = {}) =>
    this.http.request<PresenterOneDepartmentResponse, PresenterError>({
      path: `/departments`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Delete department with biid (all history)
   *
   * @tags departments
   * @name DeleteByBiidDelete
   * @summary Delete the whole department
   * @request DELETE:/departments/delete_by_biid/{biid}
   */
  deleteByBiidDelete = (
    biid: string,
    query?: {
      /** dry_run enable to check whether you can delete Department or not */
      dry_run?: boolean
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterMeta, PresenterError>({
      path: `/departments/delete_by_biid/${biid}`,
      method: 'DELETE',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Delete department with id
   *
   * @tags departments
   * @name DeleteByIdDelete
   * @summary Delete the department
   * @request DELETE:/departments/delete_by_id/{id}
   */
  deleteByIdDelete = (
    id: number,
    query?: {
      /** dry_run enable to check whether you can delete Department or not */
      dry_run?: boolean
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterIDResponse, PresenterError>({
      path: `/departments/delete_by_id/${id}`,
      method: 'DELETE',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Import department from csv file
   *
   * @tags departments
   * @name ImportCreate
   * @summary Import department
   * @request POST:/departments/import
   */
  importCreate = (
    data: {
      /** asset accounting csv file to import */
      file: File
      /** asset accounting csv file to import */
      charset?: string
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterResponse, any>({
      path: `/departments/import`,
      method: 'POST',
      body: data,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    })
  /**
   * @description InsertRecord department
   *
   * @tags departments
   * @name InsertRecordCreate
   * @summary InsertRecord the department
   * @request POST:/departments/insert_record
   */
  insertRecordCreate = (body: PresenterInsertDepartmentRecordRequest, params: RequestParams = {}) =>
    this.http.request<PresenterOneDepartmentResponse, PresenterError>({
      path: `/departments/insert_record`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Search department list
   *
   * @tags departments
   * @name SearchCreate
   * @summary Search the department list
   * @request POST:/departments/search
   */
  searchCreate = (body: PresenterDepartmentFilter, params: RequestParams = {}) =>
    this.http.request<PresenterOneDepartmentResponse[], any>({
      path: `/departments/search`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description UpdateByBIID department
   *
   * @tags departments
   * @name UpdateByBiidUpdate
   * @summary UpdateByBIID the department
   * @request PUT:/departments/update_by_biid/{biid}
   */
  updateByBiidUpdate = (biid: string, body: PresenterUpdateDepartmentRequest, params: RequestParams = {}) =>
    this.http.request<PresenterOneDepartmentResponse, PresenterError>({
      path: `/departments/update_by_biid/${biid}`,
      method: 'PUT',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description UpdateByID department
   *
   * @tags departments
   * @name UpdateByIdUpdate
   * @summary UpdateByID the department
   * @request PUT:/departments/update_by_id/{id}
   */
  updateByIdUpdate = (id: string, body: PresenterUpdateDepartmentRequest, params: RequestParams = {}) =>
    this.http.request<PresenterOneDepartmentResponse, PresenterError>({
      path: `/departments/update_by_id/${id}`,
      method: 'PUT',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Get department with id
   *
   * @tags departments
   * @name DepartmentsDetail
   * @summary Get the department by ID
   * @request GET:/departments/{id}
   */
  departmentsDetail = (id: number, params: RequestParams = {}) =>
    this.http.request<PresenterOneDepartmentResponse, PresenterError>({
      path: `/departments/${id}`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
