import { If, RedirectToMasterPageLink, UnborderCollapse } from 'aa_common/front-end/components'
import { defaultTo, ifElse } from 'aa_common/front-end/helpers'
import { ENDPOINTS, FEATURES_ID } from 'common/constants'
import { handlePermissionByFeatureId } from 'common/helpers'
import { loadNs } from 'common/i18n-config'
import { Checkbox, Input, NumberInput, TextArea } from 'components/atoms'
import { AutoCompleteSelect } from 'components/molecules'
import { unitsSlice } from 'containers/master/units/store'
import { getIn, useFormikContext } from 'formik'
import isEqual from 'lodash/isEqual'
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { selectUserPermission } from 'store/session/selectors'

const { Item, RightGroup } = UnborderCollapse
const t = loadNs(['components/organisms/assets/asset-form', 'common'])

type RightAreaProps = {
  onHandleReloadMasterOptions?: (pageName: string) => void
}
export const RightArea = ({ onHandleReloadMasterOptions }: RightAreaProps) => {
  const { values, setFieldValue } = useFormikContext()
  const hasExemption = getIn(values, 'has_exemption')

  const units = defaultTo(
    unitsSlice.useSlice(state => state?.data?.units),
    []
  )
  const { data: permissions } = useSelector(selectUserPermission, isEqual)

  const onCheckExemptionChanged = useCallback((value: boolean) => {
    const exemptionValue = ifElse(value, 1, 0)
    setFieldValue('exemption_numerator', exemptionValue)
    setFieldValue('exemption_denominator', exemptionValue)
  }, [])

  return (
    <RightGroup>
      <Item label={t('quantity_unit')}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <NumberInput name="quantity" style={{ width: 130 }} type="real" />
          <AutoCompleteSelect
            name="unit_id"
            style={{ width: 85 }}
            items={units}
            valueField="id"
            textField="name"
            childrenNode={
              <RedirectToMasterPageLink
                permissions={handlePermissionByFeatureId(FEATURES_ID.MASTER_UNITS, permissions)}
                urlName={ENDPOINTS.UNITS}
                pageName={t('master.units')}
                onHandleReload={() => onHandleReloadMasterOptions?.(t('master.units'))}
              />
            }
          />
        </div>
      </Item>
      <Item label={t('acquire_destination')}>
        <Input name="acquire_destination_name" placeholder={t('placeholder.under_50')} maxLength={50} />
      </Item>
      <Item label={t('taxation_standard_special')}>
        <Checkbox name="has_exemption" afterChanged={onCheckExemptionChanged} style={{ color: '#333', marginTop: 5 }}>
          {t('has_exemption')}
        </Checkbox>
        <If condition={hasExemption}>
          <div style={{ display: 'flex', marginTop: 10 }}>
            <NumberInput
              name="exemption_numerator"
              style={{ width: 70, marginRight: 10 }}
              placeholder={t('numerator')}
              min={1}
              max={99}
              maxLength={2}
            />
            <label style={{ color: '#D4D8DD' }}>/</label>
            <NumberInput
              name="exemption_denominator"
              style={{ width: 70, marginLeft: 10 }}
              placeholder={t('denomiator')}
              min={1}
              max={99}
              maxLength={2}
            />
          </div>
        </If>
      </Item>
      <Item
        label={t('description_property_tax_return')}
        style={{
          whiteSpace: 'pre-line',
        }}
      >
        <TextArea
          name="summary_for_asset_depreciation_tax"
          style={{ height: 50 }}
          placeholder={t('placeholder.under_100')}
          maxLength={100}
        />
      </Item>
    </RightGroup>
  )
}
