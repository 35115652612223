import { If, UnborderCollapse } from 'aa_common/front-end/components'
import { everyTrue } from 'aa_common/front-end/helpers'
import classNames from 'classnames'
import { DEPRECIATION_METHOD_CODE, LEDGER_PURPOSE } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import { MoneyInput } from 'components/molecules'
import { getIn, useFormikContext } from 'formik'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectLedgerSettingList } from 'store/settings/ledgerSetting/selectors'

import { DEPRECIATION_FIELDS } from '../const'
import { genLedgerFieldName } from '../helper'
import { useClearValueOnHide } from '../hooks/useClearOnHide'
import { Error } from '../styles'

const { Item } = UnborderCollapse
const t = loadNs(['components/organisms/assets/asset-form', 'common'])

type AccumulatedExcessDepreciationProps = {
  ledgerIndex: number
  disabled: boolean
}

export const AccumulatedExcessDepreciation = ({ ledgerIndex, disabled }: AccumulatedExcessDepreciationProps) => {
  const { values, setFieldValue } = useFormikContext()

  const ledgerSettingList = useSelector(selectLedgerSettingList, isEqual)
  const ledgerPurpose = ledgerSettingList?.[ledgerIndex]?.ledger_purpose
  const ledgerPurposeList = ledgerSettingList?.map(ledgerSetting => ledgerSetting?.ledger_purpose)

  const accumulatedExcessDepreciationValueField = genLedgerFieldName(
    DEPRECIATION_FIELDS.ACCUMULATED_OVER_DEPRECIATION_AMT,
    ledgerIndex
  )
  const depreciationMethodCodeField = genLedgerFieldName(DEPRECIATION_FIELDS.DEPRECIATION_METHOD_CODE, ledgerIndex)

  const depreciationCode = getIn(values, depreciationMethodCodeField)
  const acquisitionCostInput = getIn(values, 'acquisition_cost_input')
  const hasTransition = getIn(values, 'has_transition')

  const shouldRender = everyTrue(
    hasTransition,
    ledgerPurposeList?.includes(LEDGER_PURPOSE.ACCOUNTING),
    ledgerPurpose === LEDGER_PURPOSE.TAX,
    ![
      DEPRECIATION_METHOD_CODE.NON_DEPRECIATION,
      DEPRECIATION_METHOD_CODE.NON_DEPRECIABLE,
      DEPRECIATION_METHOD_CODE.SMALL_AMOUNT_DEPRECIABLE_ASSET,
    ].includes(depreciationCode)
  )

  useClearValueOnHide(accumulatedExcessDepreciationValueField, setFieldValue, shouldRender)

  const isError = get(values, accumulatedExcessDepreciationValueField) > acquisitionCostInput

  return (
    <If condition={shouldRender}>
      <Item label={t('accumulated_excess_depreciation')}>
        <MoneyInput
          name={accumulatedExcessDepreciationValueField}
          disabled={disabled}
          className={classNames({
            error: isError,
          })}
        />
        <If condition={isError}>
          <Error>{t('accumulated_excess_depreciation_value_error')}</Error>
        </If>
      </Item>
    </If>
  )
}
