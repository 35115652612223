import { defaultTo } from 'aa_common/front-end/helpers'
import { PresenterOneTermMonthResponse } from 'common/open-api'
import moment from 'moment'
import { useEffect, useState } from 'react'

import {
  getTermMonths,
  resetTermMonths,
  termMonthsInRange,
} from '../components/accelerated-depreciation-drawer/stores/term-months-in-range'
import { AcceleratedDepreciationItem } from '../types'

export const useGetExtraTermMonths = (monthlyDepreciation: AcceleratedDepreciationItem[], selectedDate?: string) => {
  const [extraTermMonths, setExtraTermMonths] = useState<PresenterOneTermMonthResponse[]>([])
  const termMonths = defaultTo(
    termMonthsInRange.useSlice(state => state.data),
    []
  )

  useEffect(() => {
    if (!selectedDate || !monthlyDepreciation.length) return

    const lastMonth = monthlyDepreciation[monthlyDepreciation.length - 1]
    if (moment(selectedDate).isSameOrBefore(lastMonth.end_date)) return

    getTermMonths(String(lastMonth.end_date), selectedDate)
    return () => resetTermMonths()
  }, [monthlyDepreciation, selectedDate])

  useEffect(() => {
    setExtraTermMonths(termMonths)
  }, [termMonths])

  return extraTermMonths
}
