/* eslint-disable react/default-props-match-prop-types */
import { CommonErrorMsg } from 'aa_common/front-end/antd'
import { InputNumber } from 'antd'
import classNames from 'classnames'
import { formatNumber } from 'common/helpers'
import { getIn, useFormikContext } from 'formik'
import { get } from 'lodash'
import React, { CSSProperties, useEffect, useState } from 'react'

import { Wrapper } from './styles'

type Props = {
  name: string
  placeholder: string
  min: number
  max: number
  type: 'integer' | 'real'
  style?: CSSProperties
  maxLength: number
  disabled?: boolean
  isShowError?: boolean
  defaultValue?: number
  className?: string
  onChange?: (value?: number) => void
}

export const NumberInput = ({
  max,
  min,
  name,
  placeholder,
  style,
  type,
  maxLength,
  disabled = false,
  isShowError = true,
  defaultValue,
  className,
  onChange,
}: Props) => {
  const { values, errors, setFieldValue } = useFormikContext()
  const [, setPreviousValue] = useState<number>(getIn(values, name))
  const [value, setValue] = useState<number>(getIn(values, name))
  const fieldValue = getIn(values, name)
  const error = get(errors, name)

  const handleChange = (inputValue: any) => {
    let newValue = inputValue || 0

    if (max && newValue > max) {
      newValue = max
    } else if (min && newValue < min) {
      newValue = min
    }

    if (type === 'integer') {
      setValue(parseInt(newValue, 10))
    } else {
      setValue(parseFloat(newValue))
    }
  }

  const handleBlur = () => {
    setFieldValue(name, value)
    setPreviousValue(value)
    onChange?.(value)
  }

  useEffect(() => {
    setValue(fieldValue)
    setPreviousValue(fieldValue)
  }, [fieldValue]) // eslint-disable-line

  return (
    <Wrapper>
      <InputNumber
        value={value || defaultValue || 0}
        name={name}
        className={classNames(className, { error })}
        formatter={value => formatNumber(value)}
        min={min}
        max={max}
        placeholder={placeholder}
        style={style}
        maxLength={maxLength}
        disabled={disabled}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {isShowError && error && <CommonErrorMsg>{error}</CommonErrorMsg>}
    </Wrapper>
  )
}

NumberInput.defaultProps = {
  name: '',
  placeholder: '',
  min: 0,
  max: 1000000000,
  type: 'integer',
  maxLength: 20,
  defaultValue: 0,
}

export default NumberInput
