import { AcceleratedDepreciationItem } from '../types'

export const initializeDepreciationAmount = (
  monthlyDepreciation: AcceleratedDepreciationItem[],
  memorandumValue?: number | null
) => {
  const _monthlyDepreciation = monthlyDepreciation.map(item => ({ ...item }))

  const firstEditableItem = _monthlyDepreciation.find(record => record.is_adjustable)
  const numberOfEditableItem = _monthlyDepreciation.filter(record => record.is_adjustable).length

  if (!firstEditableItem) return _monthlyDepreciation

  const beginningBookValue = firstEditableItem.beginning_book_value as number
  const bookValue = !memorandumValue ? beginningBookValue : beginningBookValue - memorandumValue
  const depreciationAmountChanged = Math.floor(bookValue / numberOfEditableItem)

  _monthlyDepreciation.forEach((item, index) => {
    if (item.is_adjustable) {
      item.depreciation_amount = depreciationAmountChanged
      if (index === _monthlyDepreciation.length - 1) {
        item.depreciation_amount = bookValue - depreciationAmountChanged * (numberOfEditableItem - 1)
      }
      if (index === 0) {
        item.end_of_term_book_value = beginningBookValue - item.depreciation_amount
      } else {
        item.end_of_term_book_value =
          (_monthlyDepreciation[index - 1].end_of_term_book_value as number) - item.depreciation_amount
      }
    }
  })

  return _monthlyDepreciation
}
