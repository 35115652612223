import { ReportAssetReduction } from 'common/components/report-asset-reduction'
import { AssetDisplayDataRow } from 'common/components/report-row/styles'
import { DATE_FORMAT } from 'common/constants'
import { formatDate, formatDateTime, identifyJournalStatusType, objectToQueryParams, yen } from 'common/helpers'
import { loadNs } from 'common/i18n-config'
import { AssetReport } from 'common/models'
import { NoImage } from 'components/atoms'
import get from 'lodash/get'
import moment from 'moment'
import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { cleanupMonthlyClosingAssetEvents } from 'store/accounting/monthlyClosing/actions'
import { changeLocation } from 'store/app/actions'

import { AssetDisplay, AssetDisplayHeader } from '../styles'
import { JournalStatus } from './journal-status'
import { Media } from './media'

type Props = {
  assets: AssetReport[]
}

const t = loadNs(['common', 'components/organisms/accounting/monthly-closing-asset-event-list'])
export const AcquisitionEventView: React.FC<Props> = ({ assets }) => {
  const dispatch = useDispatch()

  const renderAssetPhoto = useCallback((asset: AssetReport) => {
    const isEmpty = !asset.photos || asset.photos.length === 0

    return isEmpty ? <NoImage /> : <img src={get(asset, 'photos[0].thumbnail_url')} alt="" />
  }, [])

  const handleAssetClick = (asset: AssetReport) => {
    if (!asset.deleted_at) {
      const paramObj = {
        valid_at: asset.acquired_at,
      }
      const params = `?${objectToQueryParams(paramObj)}`
      dispatch(changeLocation(`/assets/${asset.biid}${params}`))
    }
  }

  useEffect(() => {
    return function cleanup() {
      dispatch(cleanupMonthlyClosingAssetEvents())
    }
  }, []) // eslint-disable-line

  return (
    <>
      {assets?.map(asset => {
        const isDeleted = !!asset.deleted_at

        return (
          <AssetDisplay
            key={asset?.biid}
            className={isDeleted ? 'deleted' : ''}
            onClick={() => handleAssetClick(asset)}
          >
            <AssetDisplayHeader>
              <div className="status">{!isDeleted && <JournalStatus type={identifyJournalStatusType(asset)} />}</div>
              <div className="photo">{renderAssetPhoto(asset)}</div>
              <div className="asset-code">{`${asset.code}-${asset.branch_code}`}</div>
              <div className="asset-name">{asset.name}</div>
            </AssetDisplayHeader>
            <AssetDisplayDataRow>
              <div className="status no-bottom-border">
                {isDeleted && <JournalStatus type={identifyJournalStatusType(asset)} />}
              </div>
              <div className="empty-space no-bottom-border" />
              <div className="short-label">{asset.valid_from}</div>
              <div className="short-value">{get(asset.change_situation, 'name_jp')}</div>
              <div className="label text-color-666">{t('acquired_at')}</div>
              <div className="value text-color-666">{formatDateTime(asset.created_at as string)}</div>
            </AssetDisplayDataRow>
            <AssetDisplayDataRow>
              <div className="label">{t('business_start_date')}</div>
              <div className="value">
                {asset?.usage_started_at ? moment(asset?.usage_started_at).format(DATE_FORMAT) : null}
              </div>
            </AssetDisplayDataRow>
            <AssetDisplayDataRow>
              <div className="label">
                {get(asset, 'acquisition_cost_excluded_excise') === get(asset, 'acquisition_cost')
                  ? t('acquisition_cost_exclude')
                  : t('acquisition_cost_include')}
              </div>
              <div className="value">{yen(asset.acquisition_cost)}</div>
            </AssetDisplayDataRow>
            <ReportAssetReduction data={asset} />
            <AssetDisplayDataRow>
              <div className="label">{t('asset_category')}</div>
              <div className="value">{get(asset.asset_category, 'name')}</div>
            </AssetDisplayDataRow>
            <AssetDisplayDataRow>
              <div className="label">{t('department')}</div>
              <div className="value">{get(asset.department, 'name')}</div>
            </AssetDisplayDataRow>
            <AssetDisplayDataRow>
              <div className="label">{t('area')}</div>
              <div className="value">{get(asset.area, 'name')}</div>
            </AssetDisplayDataRow>
            {asset?.fixed_asset_ledgers?.map(
              ({ ledger_setting_id, depreciation_method, ledger_setting, service_life }) => (
                <React.Fragment key={`ledger-${asset.id}-${ledger_setting_id}`}>
                  <AssetDisplayDataRow>
                    <div className="label">
                      {t('depreciation_method', {
                        ledger_name: ledger_setting?.name,
                      })}
                    </div>
                    <div className="value">{get(depreciation_method, 'name_jp')}</div>
                  </AssetDisplayDataRow>
                  <AssetDisplayDataRow>
                    <div className="label">
                      {t('service_life', {
                        ledger_name: ledger_setting?.name,
                      })}
                    </div>
                    <div className="value">
                      {service_life || 0}
                      {t('units.year')}
                    </div>
                  </AssetDisplayDataRow>
                </React.Fragment>
              )
            )}
            <Media asset={asset} photoField="photos" attachmentField="attachments" urlField="urls" />
            <AssetDisplayDataRow className={isDeleted ? '' : 'last'}>
              <div className="label">{t('acquired_by')}</div>
              <div className="value">{get(asset.user_info, 'display_name')}</div>
            </AssetDisplayDataRow>
            {isDeleted && (
              <AssetDisplayDataRow className="last">
                <div className="status">
                  <JournalStatus type={identifyJournalStatusType(asset)} />
                </div>
                <div className="empty-space" />
                <div className="short-label top-border">{get(asset, 'change_situations[0].valid_from')}</div>
                <div className="short-value top-border">{t('cancel_event')}</div>
                <div className="label">{t('cancel_acquired_at')}</div>
                <div className="value">{formatDate(get(asset, 'change_situations[0].created_at'))}</div>
              </AssetDisplayDataRow>
            )}
          </AssetDisplay>
        )
      })}
    </>
  )
}
