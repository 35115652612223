import { If } from 'aa_common/front-end/components'
import { defaultTo, ifElse } from 'aa_common/front-end/helpers'
import { loadNs } from 'common/i18n-config'
import { FORM_MODES } from 'common/models'
import { Checkbox } from 'components/atoms'
import isEqual from 'lodash/isEqual'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectLedgerSettingList } from 'store/settings/ledgerSetting/selectors'

import { DEPRECIATION_FIELDS } from '../const'
import { genLedgerFieldName, getFormMode } from '../helper'
import { LedgerHeader, TitleName } from '../styles'

const t = loadNs(['components/organisms/assets/asset-form', 'common'])

type DepreciationLedgerHeaderProps = {
  ledgerName: string
  ledgerIndex: number
  formMode: FORM_MODES
}

export const DepreciationLedgerHeader = ({ ledgerName, ledgerIndex, formMode }: DepreciationLedgerHeaderProps) => {
  const ledgerSettingList = useSelector(selectLedgerSettingList, isEqual)
  const { isEditMode } = getFormMode(formMode)
  const isCopiedField = genLedgerFieldName(DEPRECIATION_FIELDS.IS_COPIED, ledgerIndex)

  return (
    <LedgerHeader>
      <TitleName>{ifElse(ledgerName, ledgerName, ledgerSettingList?.[ledgerIndex]?.name)}</TitleName>
      <If condition={ledgerIndex !== 0}>
        <Checkbox name={isCopiedField} style={{ color: '#7C8291', fontSize: 13 }} disabled={isEditMode}>
          {t('copy_content_ledger_head', {
            ledger_name: defaultTo(ledgerSettingList?.[0]?.name, ''),
          })}
        </Checkbox>
      </If>
    </LedgerHeader>
  )
}
