import {
  FormikInfiniteScrollSelect,
  If,
  LinkLabel,
  RedirectToMasterPageLink,
  UnborderCollapse,
  VerticalLine,
} from 'aa_common/front-end/components'
import { defaultTo, someTrue } from 'aa_common/front-end/helpers'
import * as assetCategoryApi from 'api/app/assetCategory'
import classNames from 'classnames'
import { ASSET_CODE_AUTO_GENERATE_SETTING_METHOD, ENDPOINTS, FEATURES_ID } from 'common/constants'
import { handlePermissionByFeatureId } from 'common/helpers'
import { loadNs } from 'common/i18n-config'
import { AssetLedger, FORM_MODES } from 'common/models'
import { AutoKanaInput, CopyIcon, HorizontalLine, Input } from 'components/atoms'
import { AutoCompleteSelect, DatePicker } from 'components/molecules'
import { getIn, useFormikContext } from 'formik'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectMasterData } from 'store/masterData/selectors'
import { selectUserPermission } from 'store/session/selectors'
import { selectAssetCodeAutoGenerateSettingListData } from 'store/settings/assetCode/selectors'

import { branchCodeField, codeField, nameField } from '../const'
import { checkAcquiredAtBelongToAssetCodeAutoGenerate, getFormMode } from '../helper'
import { useAcquiredAtChanged } from '../hooks/use-acquired-at-changed'
import { useResetFACode } from '../hooks/use-reset-fa-code'
import { Error } from '../styles'
import { AssetCodeInputs } from './AssetCodeInputs'

const t = loadNs(['components/organisms/assets/asset-form', 'common'])

const { Item } = UnborderCollapse

type BeforeAcquisitionSectionProps = {
  formMode: FORM_MODES
  loadingAssetCategory: boolean
  showRequiredError?: boolean
  setBookValueReferUpdatedManually: () => void
  setExciseSettingDate: React.Dispatch<React.SetStateAction<string>>
}

export const BeforeAcquisitionSection = ({
  formMode,
  loadingAssetCategory,
  showRequiredError,
  setBookValueReferUpdatedManually,
  setExciseSettingDate,
}: BeforeAcquisitionSectionProps) => {
  useResetFACode()
  const { data: permissions } = useSelector(selectUserPermission, isEqual)
  const masterData = defaultTo(useSelector(selectMasterData, isEqual), {} as any)
  const { values, errors, setFieldValue } = useFormikContext()
  const ledgerList: Array<AssetLedger> = get(values, 'fixed_asset_ledgers')
  const acquiredAt = getIn(values, 'acquired_at')
  const transitionAt = getIn(values, 'transition_at')
  const assetCodeAutoGenerateSettingListData = useSelector(selectAssetCodeAutoGenerateSettingListData, isEqual)

  const { isNewMode, isEditMode, isCopyMode, isCorrectionMode } = getFormMode(formMode)

  const currentSetting = useMemo(() => {
    return someTrue(!assetCodeAutoGenerateSettingListData, !acquiredAt)
      ? null
      : assetCodeAutoGenerateSettingListData.find(
          setting =>
            checkAcquiredAtBelongToAssetCodeAutoGenerate(setting, acquiredAt) ||
            checkAcquiredAtBelongToAssetCodeAutoGenerate(setting, transitionAt)
        )
  }, [assetCodeAutoGenerateSettingListData, acquiredAt, transitionAt])

  const isUseAutoNumberGenerate =
    currentSetting?.generating_method === ASSET_CODE_AUTO_GENERATE_SETTING_METHOD.AUTO_NUMBER

  const handleAfterCheckSameWithAcquiredAt = useCallback(() => {
    setFieldValue('usage_started_at', get(values, 'acquired_at'))
  }, [setFieldValue, values])

  const handleAssetCategoryChange = useCallback(
    (selectedValue: any, assetCategoryInfo: any) => {
      setFieldValue('asset_type', assetCategoryInfo?.asset_type)

      for (let i = 0; i < ledgerList?.length; i++) {
        setFieldValue(`fixed_asset_ledgers[${i}].is_memorandum_value_entered`, false)
      }
    },
    [setFieldValue, ledgerList]
  )

  const getAssetCategories = useCallback(async (filters: any) => assetCategoryApi.search(filters), [])
  const handleAfterChangedAcquiredAt = useAcquiredAtChanged(setBookValueReferUpdatedManually, setExciseSettingDate)

  return (
    <>
      <Item label={t('name')} required>
        <AutoKanaInput
          name={nameField.text}
          className={classNames({ error: showRequiredError && !get(values, nameField.text) })}
          kanaField="name_kana"
          placeholder={t('placeholder.under_50')}
          maxLength={nameField.maxLength}
        />
        <If condition={showRequiredError && !get(values, 'name')}>
          <Error>{t('name_required_error')}</Error>
        </If>
      </Item>
      <Item label={t('name_kana')}>
        <Input name="name_kana" placeholder={t('placeholder.under_50')} maxLength={50} />
      </Item>
      <Item label={t('asset_code')} required={!isUseAutoNumberGenerate}>
        <AssetCodeInputs formMode={formMode} showRequiredError={showRequiredError} currentSetting={currentSetting} />
        <HorizontalLine
          style={{
            width: 8,
            backgroundColor: '#333333',
            position: 'absolute',
            top: 15,
            left: 128,
          }}
        />
        <Error>
          <label>{get(errors, codeField.text)}</label>
          <label>{get(errors, branchCodeField.text)}</label>
        </Error>
      </Item>
      <Item label={t('acquired_at')} required>
        <DatePicker
          name="acquired_at"
          afterChanged={handleAfterChangedAcquiredAt}
          maxDateField="usage_started_at"
          allowClear={false}
          disabled={isEditMode}
        />
      </Item>
      <Item label={t('used_at')}>
        <DatePicker name="usage_started_at" minDateField="acquired_at" disabled={isEditMode} />
        <If condition={someTrue(isNewMode, isCopyMode, isCorrectionMode)}>
          <div style={{ display: 'flex', marginTop: 3 }} onClick={handleAfterCheckSameWithAcquiredAt}>
            <CopyIcon style={{ marginTop: 4, cursor: 'pointer' }} />
            <LinkLabel style={{ marginLeft: 3 }}>{t('same_as_acquired_at')}</LinkLabel>
          </div>
        </If>
      </Item>
      <Item label={t('acquisition_category')} required>
        <AutoCompleteSelect
          name="acquire_category_code"
          items={masterData.acquire_category}
          valueField="code"
          textField="name_jp"
          allowClear={false}
          className={classNames({ error: showRequiredError && !get(values, 'acquire_category_code') })}
        />
        <If condition={showRequiredError && !get(values, 'acquire_category_code')}>
          <Error>{t('acquisition_category_required_error')}</Error>
        </If>
      </Item>
      <Item label={t('asset_category')} required>
        <FormikInfiniteScrollSelect
          name="asset_category_id"
          valueField="id"
          textField="name"
          disabled={isEditMode}
          onChange={handleAssetCategoryChange}
          executeInfiniteScroll={({ textSearch, pageSize, page }) => {
            return getAssetCategories({ filters: { name: textSearch }, pageSize, page })
          }}
          renderFooter={({ refetch, isLoading, isRefetching, isFetching }) => {
            const isExecuting = someTrue(isRefetching, isLoading, isFetching)
            return (
              <RedirectToMasterPageLink
                permissions={handlePermissionByFeatureId(FEATURES_ID.MASTER_ASSET_CATEGORIES, permissions)}
                urlName={ENDPOINTS.ASSET_CATEGORIES}
                isDisabledReload={isExecuting}
                pageName={t('master.asset_categories')}
                onHandleReload={refetch}
              />
            )
          }}
        />
        <If condition={showRequiredError && !get(values, 'asset_category_id')}>
          <Error>{t('asset_category_required_error')}</Error>
        </If>
      </Item>
      <Item
        label={t('asset_type_property_tax')}
        style={{
          display: 'flex',
          alignItems: 'center',
          whiteSpace: 'pre-line',
        }}
      >
        <AutoCompleteSelect
          name="asset_type_for_depreciable_property_tax_code"
          disabled={loadingAssetCategory || isEditMode}
          items={masterData.asset_type_for_depreciable_property_tax}
          valueField="code"
          textField="name_jp"
        />
      </Item>
      <Item
        label={t('asset_type_corporate_tax')}
        style={{
          display: 'flex',
          alignItems: 'center',
          whiteSpace: 'pre-line',
        }}
      >
        <AutoCompleteSelect
          name="asset_type_for_corporate_tax_code"
          items={masterData.asset_type_for_corporate_tax}
          disabled={loadingAssetCategory || isEditMode}
          valueField="code"
          textField="name_jp"
        />
        <VerticalLine style={{ position: 'absolute', top: 38, left: -146 }} />
        <VerticalLine style={{ position: 'absolute', top: 33, left: 120 }} />
      </Item>
      <Item label={t('structure')}>
        <Input
          name="asset_structure_for_corporate_tax"
          placeholder={t('placeholder.under_50')}
          disabled={loadingAssetCategory || isEditMode}
          maxLength={50}
        />
        <VerticalLine style={{ position: 'absolute', top: 29, left: -146 }} />
        <VerticalLine style={{ position: 'absolute', top: 29, left: 120 }} />
      </Item>
      <Item label={t('detail')}>
        <Input
          name="asset_detail_for_corporate_tax"
          disabled={loadingAssetCategory || isEditMode}
          placeholder={t('placeholder.under_50')}
          maxLength={50}
        />
      </Item>
    </>
  )
}
