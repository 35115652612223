import { DATE_FORMAT_RFC } from 'common/constants'
import { Asset } from 'common/models'
import { useFormikContext } from 'formik'
import moment from 'moment/moment'
import React, { useEffect } from 'react'

export const useInitialFormData = (
  initialValues: Asset,
  isTemporaryData: boolean,
  setExciseSettingDate: (value: React.SetStateAction<string>) => void
) => {
  const { setFieldValue, resetForm } = useFormikContext()
  const currentDate = moment().format(DATE_FORMAT_RFC)
  useEffect(() => {
    const initialAcquiredAt = initialValues?.acquired_at || currentDate
    const transitionAt = initialValues?.transition_at

    const initialExciseSettingDate = transitionAt || initialAcquiredAt
    initialExciseSettingDate && setExciseSettingDate(initialExciseSettingDate)

    isTemporaryData && resetForm({ values: initialValues })
    setFieldValue('acquired_at', initialAcquiredAt)
  }, [resetForm, setFieldValue, initialValues, isTemporaryData, setExciseSettingDate])
}
