import { defaultTo, ifElse } from 'aa_common/front-end/helpers'
import { DEPRECIATION_METHOD_CODE } from 'common/constants'
import { FORM_MODES } from 'common/models'
import { useFormikContext } from 'formik'
import get from 'lodash/get'
import { useEffect, useRef } from 'react'

import { getFormMode } from '../helper'

const DEFAULT_SERVICE_LIFE_CODE = 3

export const useSelectedAssetCategoryChange = (formMode: FORM_MODES, selectedAssetCategory: any) => {
  const { values, setFieldValue } = useFormikContext()
  const { isNewMode } = getFormMode(formMode)
  const firstTime = useRef(true)

  useEffect(() => {
    if (selectedAssetCategory || isNewMode) {
      if (firstTime.current) {
        firstTime.current = false
        return
      }

      const assetTypeForCorporateTaxCode = defaultTo(
        get(values, ['asset_type_for_corporate_tax', 'code']),
        get(selectedAssetCategory, ['asset_type_for_corporate_tax', 'code'])
      )
      const depreciablePropertyTaxValue = defaultTo(
        get(values, ['asset_type_for_depreciable_property_tax', 'code']),
        get(selectedAssetCategory, ['asset_type_for_depreciable_property_tax', 'code'])
      )
      const assetStructureForCorporateTax = defaultTo(
        get(values, 'asset_structure_for_corporate_tax'),
        get(selectedAssetCategory, 'asset_structure_for_corporate_tax')
      )
      const assetDetailForCorporateTaxValue = defaultTo(
        get(values, 'asset_detail_for_corporate_tax'),
        get(selectedAssetCategory, 'asset_detail_for_corporate_tax')
      )
      const depreciationMethodCode = defaultTo(
        get(selectedAssetCategory, 'depreciation_method.code'),
        get(values, 'fixed_asset_ledgers[0].depreciation_method_code')
      )
      const serviceLifeValue = get(selectedAssetCategory, 'service_life')
      const exciseId = defaultTo(get(selectedAssetCategory, ['excise_id']), get(values, 'excise_id'))

      setFieldValue('excise_id', exciseId)
      !exciseId && setFieldValue('calculated_excise_value', undefined)
      setFieldValue('asset_type_for_corporate_tax_code', assetTypeForCorporateTaxCode)
      setFieldValue('asset_type_for_depreciable_property_tax_code', depreciablePropertyTaxValue)
      setFieldValue('asset_structure_for_corporate_tax', assetStructureForCorporateTax)
      setFieldValue('asset_detail_for_corporate_tax', assetDetailForCorporateTaxValue)

      const isBulkEqualInstallment = depreciationMethodCode === DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD
      setFieldValue('fixed_asset_ledgers[0].depreciation_method_code', depreciationMethodCode)
      const currentServiceLifeValue = ifElse(isBulkEqualInstallment, DEFAULT_SERVICE_LIFE_CODE, serviceLifeValue)
      setFieldValue('fixed_asset_ledgers[0].service_life', currentServiceLifeValue)
    }
  }, [selectedAssetCategory])
}
