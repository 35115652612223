import { If, UnborderCollapse } from 'aa_common/front-end/components'
import { DEPRECIATION_METHOD_CODE } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import { NumberInput } from 'components/atoms'
import { getIn, useFormikContext } from 'formik'
import React, { useEffect } from 'react'

import { DEPRECIATION_FIELDS } from '../const'
import { genLedgerFieldName } from '../helper'
import { useClearValueOnHide } from '../hooks/useClearOnHide'
import { Error } from '../styles'

const { Item } = UnborderCollapse
const t = loadNs(['components/organisms/assets/asset-form', 'common'])

type NumberAverageUsagePeriodProps = {
  ledgerIndex: number
  disabled: boolean
}

export const NumberAverageUsagePeriod = ({ ledgerIndex, disabled }: NumberAverageUsagePeriodProps) => {
  const { values, errors, setFieldValue } = useFormikContext()

  const usagePeriodMonthsField = genLedgerFieldName(DEPRECIATION_FIELDS.USAGE_PERIOD_MONTHS, ledgerIndex)
  const depreciationMethodCodeField = genLedgerFieldName(DEPRECIATION_FIELDS.DEPRECIATION_METHOD_CODE, ledgerIndex)
  const isCopiedField = genLedgerFieldName(DEPRECIATION_FIELDS.IS_COPIED, ledgerIndex)

  const usagePeriodMonths = getIn(values, usagePeriodMonthsField)
  const depreciationCode = getIn(values, depreciationMethodCodeField)
  const isCopied = getIn(values, isCopiedField)

  const shouldRender = [
    DEPRECIATION_METHOD_CODE.AVERAGE_USAGE_PERIOD,
    DEPRECIATION_METHOD_CODE.LEASE_PERIOD_STRAIGHT_LINE,
  ].includes(depreciationCode)

  useClearValueOnHide(usagePeriodMonthsField, setFieldValue, shouldRender)

  useEffect(() => {
    if (shouldRender && !usagePeriodMonths) {
      setFieldValue(usagePeriodMonthsField, 1)
    }
  }, [usagePeriodMonths, shouldRender, setFieldValue])

  return (
    <If condition={shouldRender}>
      <Item label={t('usage_period_months')} required>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <NumberInput
            name={usagePeriodMonthsField}
            min={1}
            defaultValue={1}
            maxLength={3}
            style={{ width: 60, marginRight: 5 }}
            isShowError={false}
            disabled={disabled || isCopied}
          />
          <label>{t('units.month_amount')}</label>
        </div>
        <Error>{getIn(errors, usagePeriodMonthsField)}</Error>
      </Item>
    </If>
  )
}
