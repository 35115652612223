import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Row } from 'antd'
import { DATETIME_NO_DOT_FORMAT } from 'common/constants'
import { OperationLog } from 'common/models'
import { BreadcrumbNavigation } from 'components/molecules'
import find from 'lodash/find'
import isEqual from 'lodash/isEqual'
import moment from 'moment/moment'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectMasterData } from 'store/masterData/selectors'

import { BreadcrumbArea } from '../styles'

type HeaderProps = {
  onModalClose: () => void
  logItem: OperationLog
}

export const Header = ({ onModalClose, logItem }: HeaderProps) => {
  const masterData = useSelector(selectMasterData, isEqual)

  const breadcrumbModalClose = useMemo(
    () => <BreadcrumbNavigation.CloseIconTemplate onCloseClick={onModalClose} />,
    [onModalClose]
  )

  const breadcrumbTitle = useMemo(() => {
    const date = moment(logItem.createdAt).format(DATETIME_NO_DOT_FORMAT)
    const target = find(masterData.operation_target, item => item.code === logItem.target)?.name_jp
    return (
      <div className="breadcrumb-title-text">
        <span style={{ marginRight: 20 }}>{date}</span>
        <span>{target}</span>
      </div>
    )
  }, [logItem, masterData])

  return (
    <>
      <div className="help">
        <FontAwesomeIcon style={{ color: '#7C8291', fontSize: 20 }} icon={faQuestionCircle} />
      </div>
      <BreadcrumbArea>
        <Row>
          <Col flex="1 1" style={{ minWidth: 0 }}>
            <BreadcrumbNavigation customBackElement={breadcrumbModalClose} sectionName={breadcrumbTitle} />
          </Col>
        </Row>
      </BreadcrumbArea>
    </>
  )
}
