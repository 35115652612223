import { defaultTo, ifElse } from 'aa_common/front-end/helpers'
import { Asset } from 'common/models'
import { useFormikContext } from 'formik'
import isEqual from 'lodash/isEqual'
import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { getExciseSettingsByDateList } from 'store/settings/excise/selectors'

import { getExciseInputSetting } from '../helper'

export const useInitAcquisitionCostInput = (initialValues: Asset) => {
  const { data: exciseSetting } = useSelector(getExciseSettingsByDateList, isEqual)

  const { setFieldValue } = useFormikContext()
  const { isTaxMethodExcluded, isExternalTax, isExciseSettingAvailable } = getExciseInputSetting(exciseSetting)

  const firstTime = useRef(true)

  useEffect(() => {
    if (firstTime.current && isExciseSettingAvailable) {
      const acquisitionCostValue = initialValues?.acquisition_cost
      const acquisitionCostExcludedExcise = initialValues?.acquisition_cost_excluded_excise
      setFieldValue(
        'acquisition_cost_input',
        ifElse(
          isTaxMethodExcluded && isExternalTax,
          defaultTo(acquisitionCostExcludedExcise, 0),
          defaultTo(acquisitionCostValue, 0)
        )
      )
      firstTime.current = false
    }
  }, [isExciseSettingAvailable, isTaxMethodExcluded, isExternalTax, initialValues])
}
