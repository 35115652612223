/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  DtoJournalLinkageStatusResponse,
  PresenterDeletableAccountItem,
  PresenterDeletableAccountSubItem,
  PresenterDeletableDept,
  PresenterDeletableExcise,
  PresenterDisableableAccountItem,
  PresenterDisableableAccountSubItem,
  PresenterError,
  PresenterJournalLinkageResultResponse,
  PresenterOneAccPlusDeptResponse,
  PresenterOneExportJournalHistoryResponse,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class AccountingPlus<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description Sync account_items/sub_items list from accounting plus
   *
   * @tags accounting_plus
   * @name AccountItemsCreate
   * @summary Sync the account_items/sub_items list from accounting plus
   * @request POST:/acc_plus/account_items
   */
  accountItemsCreate = (params: RequestParams = {}) =>
    this.http.request<string, any>({
      path: `/acc_plus/account_items`,
      method: 'POST',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Get department list from accounting plus
   *
   * @tags accounting_plus
   * @name DepartmentsList
   * @summary Get the department list from accounting plus
   * @request GET:/acc_plus/departments
   */
  departmentsList = (params: RequestParams = {}) =>
    this.http.request<PresenterOneAccPlusDeptResponse[], any>({
      path: `/acc_plus/departments`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description get journal list with status of a linkage history
   *
   * @tags accounting_plus
   * @name JournalLinkageHistoryDetailList
   * @summary get journal list of a linkage history
   * @request GET:/acc_plus/journal_linkage_history_detail
   */
  journalLinkageHistoryDetailList = (
    query: {
      /** ID of a linkage history */
      linkage_history_id: number
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterJournalLinkageResultResponse[], PresenterError>({
      path: `/acc_plus/journal_linkage_history_detail`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description check accounting plus permission, setting and last submit
   *
   * @tags accounting_plus
   * @name JournalLinkageStatusList
   * @summary check journal linkage status
   * @request GET:/acc_plus/journal_linkage_status
   */
  journalLinkageStatusList = (params: RequestParams = {}) =>
    this.http.request<DtoJournalLinkageStatusResponse, PresenterError>({
      path: `/acc_plus/journal_linkage_status`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description send journals to AccPlus
   *
   * @tags accounting_plus
   * @name JournalLinkageSubmitCreate
   * @summary send journals to AccPlus
   * @request POST:/acc_plus/journal_linkage_submit
   * @secure
   */
  journalLinkageSubmitCreate = (params: RequestParams = {}) =>
    this.http.request<PresenterOneExportJournalHistoryResponse, any>({
      path: `/acc_plus/journal_linkage_submit`,
      method: 'POST',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description check department is deletable or not
   *
   * @tags accounting_plus
   * @name TenantsDeptsDeletableDetail
   * @summary check department is deletable or not
   * @request GET:/ca_mid/tenants/{office_id}/depts/deletable
   * @secure
   */
  tenantsDeptsDeletableDetail = (
    officeId: number,
    query: {
      /** list of department id from accountint plus */
      item_ids: number[]
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterDeletableDept[], any>({
      path: `/ca_mid/tenants/${officeId}/depts/deletable`,
      method: 'GET',
      query: query,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description check excises is deletable or not
   *
   * @tags accounting_plus
   * @name TenantsExcisesDeletableDetail
   * @summary check excises is deletable or not
   * @request GET:/ca_mid/tenants/{office_id}/excises/deletable
   * @secure
   */
  tenantsExcisesDeletableDetail = (
    officeId: number,
    query: {
      /** list of excise id */
      excise_ids: number[]
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterDeletableExcise[], any>({
      path: `/ca_mid/tenants/${officeId}/excises/deletable`,
      method: 'GET',
      query: query,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description check items is deletable or not
   *
   * @tags accounting_plus
   * @name TenantsItemsDeletableDetail
   * @summary check items is deletable or not
   * @request GET:/ca_mid/tenants/{office_id}/items/deletable
   * @secure
   */
  tenantsItemsDeletableDetail = (
    officeId: number,
    query: {
      /** list of item id */
      item_ids: number[]
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterDeletableAccountItem[], any>({
      path: `/ca_mid/tenants/${officeId}/items/deletable`,
      method: 'GET',
      query: query,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description check sub_items is deletable or not
   *
   * @tags accounting_plus
   * @name TenantsSubItemsDeletableDetail
   * @summary check sub_items is deletable or not
   * @request GET:/ca_mid/tenants/{office_id}/sub_items/deletable
   * @secure
   */
  tenantsSubItemsDeletableDetail = (
    officeId: number,
    query: {
      /** list of sub item id */
      item_ids: number[]
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterDeletableAccountSubItem[], any>({
      path: `/ca_mid/tenants/${officeId}/sub_items/deletable`,
      method: 'GET',
      query: query,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description check items can disable or not
   *
   * @tags accounting_plus
   * @name TenantsItemsDisableableDetail
   * @summary check items can disable or not
   * @request GET:/ca_mid/tenants/{tenant_uid}/items/disableable
   * @secure
   */
  tenantsItemsDisableableDetail = (
    tenantUid: number,
    query: {
      /** list of item id */
      item_ids: number[]
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterDisableableAccountItem[], any>({
      path: `/ca_mid/tenants/${tenantUid}/items/disableable`,
      method: 'GET',
      query: query,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description check items can disable or not
   *
   * @tags accounting_plus
   * @name TenantsSubItemsDisableableDetail
   * @summary check items can disable or not
   * @request GET:/ca_mid/tenants/{tenant_uid}/sub_items/disableable
   * @secure
   */
  tenantsSubItemsDisableableDetail = (
    tenantUid: number,
    query: {
      /** list of sub item id */
      sub_item_ids: number[]
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterDisableableAccountSubItem[], any>({
      path: `/ca_mid/tenants/${tenantUid}/sub_items/disableable`,
      method: 'GET',
      query: query,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
