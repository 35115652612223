/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PresenterAwebFullOfficeResponse,
  PresenterAwebOfficeResponse,
  PresenterContractResponse,
  PresenterOfficesUsersResponse,
  PresenterRoleListResponse,
  PresenterUpdateContractRequest,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class AWebOffices<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description Get office list
   *
   * @tags AWeb-Offices
   * @name OfficesList
   * @summary Get office list
   * @request GET:/admin/offices
   */
  officesList = (
    query?: {
      'filter[contract_end_date_from]'?: string
      'filter[contract_end_date_to]'?: string
      'filter[contract_type]'?: number
      'filter[id]'?: number
      'filter[identification_code]'?: string
      'page[number]'?: number
      'page[size]'?: number
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterAwebOfficeResponse[], any>({
      path: `/admin/offices`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Get office by id
   *
   * @tags AWeb-Offices
   * @name OfficesDetail
   * @summary Get office by id
   * @request GET:/admin/offices/{id}
   */
  officesDetail = (id: string, params: RequestParams = {}) =>
    this.http.request<PresenterAwebFullOfficeResponse, any>({
      path: `/admin/offices/${id}`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Update contract
   *
   * @tags AWeb-Offices
   * @name OfficesContractUpdate
   * @summary Update the contract
   * @request PUT:/admin/offices/{id}/contract
   */
  officesContractUpdate = (id: number, body: PresenterUpdateContractRequest, params: RequestParams = {}) =>
    this.http.request<PresenterContractResponse, any>({
      path: `/admin/offices/${id}/contract`,
      method: 'PUT',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Get office users list
   *
   * @tags AWeb-Offices
   * @name OfficesContractsDetail
   * @summary Get contract in office
   * @request GET:/admin/offices/{id}/contracts
   */
  officesContractsDetail = (
    id: number,
    query?: {
      'page[number]'?: number
      'page[size]'?: number
      sort?: string[]
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterContractResponse[], any>({
      path: `/admin/offices/${id}/contracts`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Get office by id
   *
   * @tags AWeb-Offices
   * @name OfficesRolesDetail
   * @summary Get role list
   * @request GET:/admin/offices/{id}/roles
   */
  officesRolesDetail = (
    id: number,
    query?: {
      'page[number]'?: number
      'page[size]'?: number
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterRoleListResponse[], any>({
      path: `/admin/offices/${id}/roles`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Get office users list
   *
   * @tags AWeb-Offices
   * @name OfficesUsersDetail
   * @summary Get office users list
   * @request GET:/admin/offices/{id}/users
   */
  officesUsersDetail = (
    id: number,
    query?: {
      'filter[display_name]'?: string
      'filter[is_active]'?: boolean
      'filter[role_biid]'?: string
      'filter[user_id]'?: number
      'page[number]'?: number
      'page[size]'?: number
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterOfficesUsersResponse[], any>({
      path: `/admin/offices/${id}/users`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
