/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { PresenterAggregateDepreciationResultResponse, PresenterError, PresenterResponse } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class DepreciationResults<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description Aggregate depreciation result
   *
   * @tags depreciation_results
   * @name AggregateList
   * @summary Aggregate the depreciation result
   * @request GET:/depreciation_results/aggregate
   */
  aggregateList = (
    query: {
      /** @default "category" */
      aggregation_method?: 'category' | 'department'
      ledger_setting_id: number
      mode?: 0 | 1
      term_id: number
    },
    params: RequestParams = {}
  ) =>
    this.http.request<
      PresenterResponse & {
        data?: PresenterAggregateDepreciationResultResponse
      },
      PresenterError
    >({
      path: `/depreciation_results/aggregate`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
