import { LedgerSettingsItem } from 'common/models'
import { PresenterFixedAssetDepreciationResponse } from 'common/open-api'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  getOriginalDepreciation,
  originalDepreciationStore,
  resetOriginalDepreciation,
} from '../components/accelerated-depreciation-drawer/stores/original-depreciation'
import { AcceleratedDepreciationItem } from '../types'

export const useMonthlyDepreciation = (
  currentDepreciationResult: PresenterFixedAssetDepreciationResponse,
  ledgerSetting?: LedgerSettingsItem,
  isDateChanged?: boolean
) => {
  const { biid } = useParams<{ biid: string }>()
  const [monthlyDepreciation, setMonthlyDepreciation] = useState<AcceleratedDepreciationItem[]>([])
  const originalMonthlyDepreciation = originalDepreciationStore.useSlice(state => state.data)

  useEffect(() => {
    const monthlyDepreciation: AcceleratedDepreciationItem[] = []
    currentDepreciationResult.data?.forEach(item => {
      item.months?.forEach(monthlyData => {
        monthlyDepreciation.push({
          ...monthlyData,
          key: String(monthlyData.term_month_id),
          fiscal_year: item.fiscal_year,
          period: item.period,
        })
      })
    })
    setMonthlyDepreciation(monthlyDepreciation)
  }, [currentDepreciationResult])

  useEffect(() => {
    if (originalMonthlyDepreciation?.length) {
      setMonthlyDepreciation(originalMonthlyDepreciation.map(item => ({ ...item, key: String(item.term_month_id) })))
    }
  }, [originalMonthlyDepreciation])

  useEffect(() => {
    if (isDateChanged && ledgerSetting?.id && biid) {
      getOriginalDepreciation(biid, String(ledgerSetting.id))
    }
    return () => {
      resetOriginalDepreciation()
    }
  }, [isDateChanged, ledgerSetting?.id, biid])

  return monthlyDepreciation
}
