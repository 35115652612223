import { defaultTo } from 'aa_common/front-end/helpers'
import { calculateMemorandumValue as calculateMemorandumValueApi } from 'api/app/asset'
import { AssetLedger } from 'common/models'
import { unitsSlice } from 'containers/master/units/store'
import { getIn, useFormikContext } from 'formik'
import useResource from 'lib/hooks/useResource'
import { useEffect, useMemo } from 'react'

import { checkShowMemorandumValue } from '../helper'
import { ILedgerWithSetting } from '../types'

export const useCalculateMemorandum = (ledgersWithSetting: ILedgerWithSetting[], assetType?: number) => {
  const units = defaultTo(
    unitsSlice.useSlice(state => state?.data?.units),
    []
  )
  const { values } = useFormikContext()
  const unitId = getIn(values, 'unit_id')
  const quantity = getIn(values, 'quantity')
  const linkMemorandumValueQuantity = !!units?.find(unit => unit.id === unitId)?.link_memorandum_value_quantity

  const allDMethodsSelected = useMemo(
    () => ledgersWithSetting?.every((ledger: AssetLedger) => typeof ledger.depreciation_method_code === 'number'),
    [ledgersWithSetting]
  )
  const ledgerWithDepreciation = useMemo(() => {
    const mappingLedgers: { id?: number; depreciation_method: number | null }[] = []
    ledgersWithSetting.forEach(({ ledger_setting_id, depreciation_method_code }) => {
      if (checkShowMemorandumValue(depreciation_method_code!)) {
        mappingLedgers.push({
          id: ledger_setting_id,
          depreciation_method: depreciation_method_code,
        })
      }
    })
    return mappingLedgers
  }, [ledgersWithSetting])

  const [{ data: responseMemorandumValue }, calculateMemorandumValue] = useResource(calculateMemorandumValueApi)
  useEffect(() => {
    if (allDMethodsSelected && assetType && unitId && linkMemorandumValueQuantity && Number(quantity) >= 0) {
      const payload = {
        ledgers: ledgerWithDepreciation,
        quantity: parseInt(quantity, 10),
        unit_id: unitId,
        asset_type: assetType,
      }
      calculateMemorandumValue(payload)
    }
  }, [
    assetType,
    unitId,
    quantity,
    allDMethodsSelected,
    linkMemorandumValueQuantity,
    ledgerWithDepreciation.map(ledger => ledger.depreciation_method).join('-'),
  ])

  return useMemo(() => {
    return new Map<number, number>(
      responseMemorandumValue?.ledgers?.map(({ id, memorandum_value }: { id: number; memorandum_value: number }) => [
        id,
        memorandum_value,
      ])
    )
  }, [responseMemorandumValue])
}
