import { defaultTo, isNumberHaveValue } from 'aa_common/front-end/helpers'
import { useFormikContext } from 'formik'
import isEqual from 'lodash/isEqual'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { getExciseSettingsByDateList } from 'store/settings/excise/selectors'

import { getExciseInputSetting } from '../helper'
import { getExciseValue } from '../helpers/excise-value'
import { setAcquisitionCost } from '../helpers/set-acquisition-cost'

export const useAcquisitionChanged = () => {
  const { values, setFieldValue } = useFormikContext()
  const exciseValue = getExciseValue(values)

  const { data } = useSelector(getExciseSettingsByDateList, isEqual)
  const { isInputTaxWhenRegister, isTaxMethodExcluded, isInternalTax } = getExciseInputSetting(data)

  return useCallback(
    (value?: number) => {
      if (value === undefined) return

      !isNumberHaveValue(exciseValue) && setFieldValue('manual_excise_value', 0)
      const exciseForCalculation = defaultTo(exciseValue, 0)

      setAcquisitionCost(
        value,
        exciseForCalculation,
        { isInputTaxWhenRegister, isTaxMethodExcluded, isInternalTax },
        setFieldValue
      )
    },
    [exciseValue, isInputTaxWhenRegister, isTaxMethodExcluded, isInternalTax]
  )
}
