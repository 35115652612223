import { isNumberHaveValue } from 'aa_common/front-end/helpers'
import { RETIREMENT_DEPRECIATION_ENDED_METHOD } from 'common/constants'
import { PresenterDepreciationMonthlyResponse } from 'common/open-api'
import moment from 'moment'

import { AcceleratedDepreciationItem } from '../types'

export const getFiscalYearBySelectedDate = (
  selectedDate: string,
  monthlyDepreciation: PresenterDepreciationMonthlyResponse[]
) => {
  return monthlyDepreciation.find(
    ({ start_date, end_date }) =>
      moment(selectedDate).isSameOrAfter(start_date) && moment(selectedDate).isSameOrBefore(end_date)
  )?.fiscal_year
}

export const filterStartMonthlyDepreciation = (
  monthlyDepreciation: AcceleratedDepreciationItem[],
  currentProcessingMonth: { from_term_month: { term: { year: number } } }
) => {
  const currentFiscalYear = currentProcessingMonth?.from_term_month?.term?.year
  return monthlyDepreciation.filter(({ fiscal_year }) => {
    return isNumberHaveValue(fiscal_year) && fiscal_year! >= currentFiscalYear
  })
}

export const filterEndMonthlyDepreciation = (
  monthlyDepreciation: AcceleratedDepreciationItem[],
  selectedDate: string,
  retirementDepreciationEndedMethod: number
) => {
  const fiscalYearOfSelectedDate =
    getFiscalYearBySelectedDate(selectedDate, monthlyDepreciation) ?? moment(selectedDate).year()

  return monthlyDepreciation.filter(({ start_date, end_date, fiscal_year }) => {
    if (
      [
        RETIREMENT_DEPRECIATION_ENDED_METHOD.END_OF_CURRENT_MONTH,
        RETIREMENT_DEPRECIATION_ENDED_METHOD.SELECT_WHEN_SELL_AND_RETIREMENT,
      ].includes(retirementDepreciationEndedMethod)
    ) {
      return moment(selectedDate).isSameOrAfter(start_date)
    }
    if (retirementDepreciationEndedMethod === RETIREMENT_DEPRECIATION_ENDED_METHOD.END_OF_LAST_MONTH) {
      return moment(selectedDate).isAfter(end_date)
    }
    if (retirementDepreciationEndedMethod === RETIREMENT_DEPRECIATION_ENDED_METHOD.END_OF_LAST_YEAR) {
      return Number(fiscal_year) < fiscalYearOfSelectedDate
    }

    return true
  })
}
