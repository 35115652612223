import { UnborderCollapse } from 'aa_common/front-end/components'
import { defaultTo } from 'aa_common/front-end/helpers'
import { loadNs } from 'common/i18n-config'
import { PresenterOneTagResponse } from 'common/open-api'
import { TextArea } from 'components/atoms'
import { TagSelect } from 'components/molecules'
import { tagListSlice } from 'containers/master/tags/store'
import { OptionsType } from 'rc-select/lib/interface'
import React, { useMemo } from 'react'

const t = loadNs(['components/organisms/assets/asset-form', 'common'])
const { Item, LeftGroup, RightGroup } = UnborderCollapse

export const OtherArea = () => {
  const tags = defaultTo(
    tagListSlice.useSlice(state => state?.data?.tags),
    []
  )

  const tagOptions = useMemo(() => {
    return tags?.map((tag: PresenterOneTagResponse) => ({
      label: tag.name,
      value: tag.id,
    })) as OptionsType
  }, [tags])

  return (
    <UnborderCollapse title={t('title_other')} collapsable={false}>
      <LeftGroup>
        <Item label={t('tag')}>
          <TagSelect name="tags" options={tagOptions} />
        </Item>
        <Item label={t('note')}>
          <TextArea name="memo" style={{ height: 75 }} placeholder={t('placeholder.under_200')} maxLength={200} />
        </Item>
      </LeftGroup>
      <RightGroup>
        <Item label={t('comment')}>
          <TextArea name="comment" style={{ height: 75 }} placeholder={t('placeholder.under_200')} maxLength={200} />
        </Item>
      </RightGroup>
    </UnborderCollapse>
  )
}
