import { faSortDown } from '@fortawesome/free-solid-svg-icons'
import { Tooltip } from 'aa_common/front-end/antd'
import { ifElse, someTrue } from 'aa_common/front-end/helpers'
import { useBoolean } from 'aa_common/front-end/hooks'
import { Menu } from 'antd'
import { CONFIRM_TYPES } from 'common/constants'
import { getNs } from 'common/i18n-config'
import { AssetLedger } from 'common/models'
import { PresenterFixedAssetDepreciationResponse } from 'common/open-api'
import React, { useMemo, useState } from 'react'

import { useAcceleratedDepreciationTooltip } from '../../hooks/use-accelerated-depreciation-tooltip'
import { useCancelAcceleratedDepreciation } from '../../hooks/use-cancel-accelerated-depreciation'
import { useClickErrorDetail } from '../../hooks/use-click-error-detail-cancel-accelerated'
import { StyledArrowIcon, StyledConfirmModal, StyledDropdown, StyledDropdownContent, StyledMenu } from '../../styles'
import { ErrorDialog } from './error-dialog'

const { t, useNsReady } = getNs(['common', 'components/organisms/assets/asset-detail'])

export const DepreciationAdjustmentDropdown = ({
  onOpen,
  onCancelSuccess,
  changeToEventTab,
  assetLedger,
  depreciationResults,
  isShowAcceleratedDepreciationDrawer,
}: {
  onOpen: () => void
  onCancelSuccess?: () => void
  changeToEventTab?: () => void
  assetLedger: AssetLedger
  depreciationResults: PresenterFixedAssetDepreciationResponse
  isShowAcceleratedDepreciationDrawer: boolean
}) => {
  useNsReady()

  const { isDisabled, tooltipContent } = useAcceleratedDepreciationTooltip(assetLedger, depreciationResults)
  const [, setIsShowDropdown] = useState(false)
  const [isShowConfirmModal, showConfirmShowModal, hideConfirmModal] = useBoolean(false)

  const isAdjusted = useMemo(() => {
    return depreciationResults?.data?.some(item => item?.months?.some(month => month?.is_adjust))
  }, [depreciationResults])

  const { isCanceling, cancelError, cancelAcceleratedDepreciation } = useCancelAcceleratedDepreciation(
    assetLedger?.ledger_setting_id as number,
    onCancelSuccess
  )
  const handleOnClickErrorDetail = useClickErrorDetail(changeToEventTab)
  const disabled = isDisabled || isShowAcceleratedDepreciationDrawer
  return (
    <>
      <Tooltip content={isDisabled ? tooltipContent : undefined} placement="bottomRight">
        <StyledDropdown
          overlay={
            <StyledMenu>
              <Menu.Item key="register" onClick={onOpen}>
                {t('accelerated_depreciation_registration')}
              </Menu.Item>
              <Menu.Item
                key="cancel"
                className={ifElse(isCanceling, 'canceling', '')}
                disabled={someTrue(!isAdjusted, isCanceling)}
                onClick={showConfirmShowModal}
              >
                {t('cancel_accelerated_depreciation')}
              </Menu.Item>
            </StyledMenu>
          }
          onVisibleChange={setIsShowDropdown}
          trigger={['click']}
          mouseLeaveDelay={0.3}
          disabled={disabled}
          className={ifElse(disabled, 'disabled', '')}
          placement="bottomRight"
          overlayStyle={{ zIndex: 999 }}
        >
          <StyledDropdownContent>
            <span>{t('depreciation_adjustment')}</span>
            <StyledArrowIcon icon={faSortDown} className={ifElse(isDisabled, 'disabled', '')} />
          </StyledDropdownContent>
        </StyledDropdown>
      </Tooltip>
      <StyledConfirmModal
        closable
        width={600}
        visible={isShowConfirmModal}
        isLoading={isCanceling}
        type={CONFIRM_TYPES.CONFIRM}
        title={t('cancel_modal.initial_title')}
        message={t('cancel_modal.initial_message')}
        okText={t('cancel_modal.initialize')}
        onOK={() => cancelAcceleratedDepreciation(hideConfirmModal)}
        onCancel={hideConfirmModal}
      />
      <ErrorDialog
        error={cancelError}
        depreciationResults={depreciationResults}
        onClickDetail={handleOnClickErrorDetail}
      />
    </>
  )
}
