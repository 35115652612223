import { AcceleratedDepreciationItem, TermDepreciation } from '../types'

export const getTermsForAcceleratedDepreciation = (acceleratedDepreciation: AcceleratedDepreciationItem[]) => {
  if (!acceleratedDepreciation?.length) return {}

  const foundTerms: { [key: string]: TermDepreciation } = {}
  acceleratedDepreciation.forEach(item => {
    foundTerms[String(item.period)] = {
      fiscal_year: item.fiscal_year,
      period: item.period,
    }
  })

  return foundTerms
}
