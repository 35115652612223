import { UnderlineInput } from 'aa_common/front-end/antd'
import { If, UnborderCollapse } from 'aa_common/front-end/components'
import { checkIsAfterDate, checkTheSameDate, everyTrue, ifElse } from 'aa_common/front-end/helpers'
import { DEPRECIATION_METHOD_CODE } from 'common/constants'
import { loadNs } from 'common/i18n-config'
import { TooltipIcon } from 'components/atoms'
import { getIn, useFormikContext } from 'formik'
import parser from 'html-react-parser'
import { get } from 'lodash'
import { useMemo } from 'react'

import { getAllLedgerFieldNames } from '../helper'
import { useCalculateDepreciationMonth } from '../hooks/use-calculate-depreciation-month'
import { preLineStyle } from '../styles'

const { Item } = UnborderCollapse

const t = loadNs(['components/organisms/assets/asset-form'])

const OLD_DEPRECIATION_METHOD_CODES = [
  DEPRECIATION_METHOD_CODE.OLD_STRAIGHT_LINE,
  DEPRECIATION_METHOD_CODE.OLD_DECLINING_BALANCE,
]

function isOldDMethod(dMethod: DEPRECIATION_METHOD_CODE) {
  return OLD_DEPRECIATION_METHOD_CODES.includes(dMethod)
}

function shouldRender(dMethod: DEPRECIATION_METHOD_CODE, usageStartedAt: string, transitionAt: string) {
  if (!transitionAt) return false
  if (!usageStartedAt) return false
  if (checkTheSameDate(usageStartedAt, transitionAt)) return false
  if (checkIsAfterDate(usageStartedAt, transitionAt)) return false

  return [
    ...OLD_DEPRECIATION_METHOD_CODES,
    DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD,
    DEPRECIATION_METHOD_CODE.AVERAGE_USAGE_PERIOD,
    DEPRECIATION_METHOD_CODE.LEASE_PERIOD_STRAIGHT_LINE,
    DEPRECIATION_METHOD_CODE.DEFERRED_EQUAL_INSTALLMENT,
  ].includes(dMethod)
}

function getMaxValue(dMethodCode: any, values: any, serviceLifeField: string, usagePeriodMonthsField: string) {
  if (isOldDMethod(dMethodCode)) {
    return 60
  }
  if (dMethodCode === DEPRECIATION_METHOD_CODE.BULK_EQUAL_INSTALLMENT_METHOD) {
    return 36
  }

  if (dMethodCode === DEPRECIATION_METHOD_CODE.DEFERRED_EQUAL_INSTALLMENT) {
    return get(values, serviceLifeField) * 12
  }

  return get(values, usagePeriodMonthsField)
}

type Props = {
  ledgerIndex: number
  ledgerName: string
  disabled?: boolean
  isCopied?: boolean
}

export const DepreciatedMonthsInput = ({ ledgerIndex, ledgerName, isCopied, disabled }: Props) => {
  const { values, setFieldValue } = useFormikContext()
  const {
    depreciationMethodCodeField,
    transitionAtField,
    depreciatedMonthsField,
    usagePeriodMonthsField,
    serviceLifeField,
  } = getAllLedgerFieldNames(ledgerIndex)

  const dMethodCode = getIn(values, depreciationMethodCodeField)
  const transitionAt = getIn(values, transitionAtField)
  const usageStartedAt = getIn(values, 'usage_started_at')

  const itemProps = useMemo(() => {
    return ifElse(
      isOldDMethod(dMethodCode),
      {
        jsLabel: t('deprecated_months_60_of', { name: ledgerName }),
        label: (
          <>
            <label style={{ marginRight: 2 }}>{t('deprecated_months_60')}</label>
            <TooltipIcon overlayStyle={{ maxWidth: 800 }}>{parser(t('deprecated_months_60_tooltip'))}</TooltipIcon>
          </>
        ),
      },
      {
        jsLabel: t('deprecated_months_of', { name: ledgerName }),
        label: (
          <>
            <label style={{ marginRight: 5 }}>{t('deprecated_months')}</label>
            <TooltipIcon overlayStyle={{ maxWidth: 600 }}>{parser(t('deprecated_months_tooltip'))}</TooltipIcon>
          </>
        ),
      }
    )
  }, [dMethodCode, ledgerName])

  const maxValue = useMemo(() => {
    return getMaxValue(dMethodCode, values, serviceLifeField, usagePeriodMonthsField)
  }, [dMethodCode, values, usagePeriodMonthsField, serviceLifeField])

  const shouldRenderUI = shouldRender(dMethodCode, usageStartedAt, transitionAt)
  useCalculateDepreciationMonth(shouldRenderUI, ledgerIndex)

  return (
    <If condition={shouldRenderUI}>
      <Item {...itemProps} style={preLineStyle} required>
        <UnderlineInput
          name={depreciatedMonthsField}
          editable={everyTrue(!disabled, !isCopied)}
          type="number"
          min={0}
          max={maxValue}
          isResetManualValue={everyTrue(!disabled, !isCopied, get(values, depreciatedMonthsField) !== 0)}
          onHandleClickOnReset={() => setFieldValue(depreciatedMonthsField, 0)}
        />
      </Item>
    </If>
  )
}

export const _internal = {
  shouldRender,
  getMaxValue,
}
