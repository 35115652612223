import styled from '@emotion/styled'

import { GREY_BORDER_COLOR } from 'common/constants'

export const Wrapper = styled.div`
  width: 1050px;
  margin: 15px;
  font-size: 13px;
  color: #333333;
`

export const Box = styled.div`
  background-color: #ffffff;
  border: 1px solid ${GREY_BORDER_COLOR};
  border-radius: 4px;
  margin-top: 15px;
`

export const BoxHeader = styled.div`
  font-weight: 500;
  display: flex;
  height: 50px;
  align-items: center;

  .status {
    width: 130px;
    text-align: center;
  }

  .photo {
    width: 50px;
    text-align: center;
  }

  .asset-code {
    width: 130px;
    padding-left: 15px;
  }

  .asset-name {
    width: 130px;
    padding-left: 15px;
  }
`

export const AssetDisplay = styled.div`
  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), #ffffff;
    cursor: pointer;
  }

  &.deleted:hover {
    cursor: not-allowed;
  }
`

export const AssetDisplayHeader = styled.div`
  background-color: #fafafa;
  min-height: 50px;
  border-top: 1px solid ${GREY_BORDER_COLOR};
  border-bottom: 1px solid ${GREY_BORDER_COLOR};
  display: flex;
  align-items: center;

  .status {
    width: 130px;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .photo {
    text-align: center;

    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
    }
  }

  .asset-code {
    width: 130px;
    padding-left: 15px;
  }

  .asset-name {
    width: 130px;
    padding-left: 15px;
  }
`

export const PagingWrapper = styled.div`
  display: flex;
  height: 20px;
  align-items: center;
  justify-content: space-between;

  .ant-radio-button-wrapper {
    margin-right: 10px;
    border-radius: 15px;
    background: #d4d8dd;
    color: #666666;

    &:last-child {
      border-radius: 15px;
      margin-right: 0;
    }

    &.ant-radio-button-wrapper-checked,
    &:hover {
      background: #3b7de9;
      color: white;
    }
    &:not(:first-of-type) {
      border-left-width: 1px;
      &::before {
        display: none;
      }
    }
  }

  .fa-chevron-left,
  .fa-chevron-right {
    color: #7c8291;
  }
`

export const JournalStatusWrapper = styled.div`
  width: 106px;
  border-radius: 4px;
  color: #ffffff;
  min-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.blue {
    background-color: #3b7de9;
  }

  &.grey {
    background-color: #7c8291;
  }
`
