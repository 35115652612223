import { defaultTo } from 'aa_common/front-end/helpers'
import { LEDGER_PURPOSE } from 'common/constants'
import { AssetLedger } from 'common/models'
import { getIn, useFormikContext } from 'formik'
import isEqual from 'lodash/isEqual'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectLedgerSettingList } from 'store/settings/ledgerSetting/selectors'

import { LEDGERS_FIELD_NAME } from '../const'
import { checkShowMemorandumValue } from '../helper'

export const useLedgersWithSetting = () => {
  const { values } = useFormikContext()
  const ledgerSettingList = useSelector(selectLedgerSettingList, isEqual)
  const fixedAssetLedgers = getIn(values, LEDGERS_FIELD_NAME) as AssetLedger[]
  const [ledgersWithSetting, setLedgersWithSetting] = useState<(AssetLedger & { ledger_purpose?: number })[]>([])
  const [canCopyFromAccountant, setCanCopyFromAccountant] = useState<boolean>(false)

  useEffect(() => {
    const calculatedLedgers = defaultTo(
      fixedAssetLedgers?.map(faLedger => ({
        ...faLedger,
        ledger_purpose: ledgerSettingList?.find(ledgerSetting => ledgerSetting?.id === faLedger?.ledger_setting_id)
          ?.ledger_purpose,
      })),
      []
    )
    const accountantDMethod = defaultTo(
      calculatedLedgers?.find(ledger => ledger?.ledger_purpose === LEDGER_PURPOSE.ACCOUNTING)?.depreciation_method_code,
      0
    )
    const taxDMethod = defaultTo(
      calculatedLedgers?.find(ledger => ledger?.ledger_purpose === LEDGER_PURPOSE.TAX)?.depreciation_method_code,
      0
    )

    setLedgersWithSetting(calculatedLedgers)
    setCanCopyFromAccountant(checkShowMemorandumValue(accountantDMethod) && checkShowMemorandumValue(taxDMethod))
  }, [fixedAssetLedgers, ledgerSettingList])

  return {
    ledgersWithSetting,
    canCopyFromAccountant,
  }
}
