/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PresenterCountFixedAssetCommentRequrest,
  PresenterCreateFixedAssetCommentRequest,
  PresenterError,
  PresenterIDResponse,
  PresenterOneFixedAssetCommentCountResponse,
  PresenterOneFixedAssetCommentResponse,
  PresenterUpdateFixedAssetCommentRequest,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class FixedAssetComments<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  /**
   * @description Count the fixed asset comments
   *
   * @tags fixed_asset_comments
   * @name CountByFixedAssetsCreate
   * @summary Count the fixed asset comments
   * @request POST:/comments/count_by_fixed_assets
   */
  countByFixedAssetsCreate = (body: PresenterCountFixedAssetCommentRequrest, params: RequestParams = {}) =>
    this.http.request<PresenterOneFixedAssetCommentCountResponse[], PresenterError>({
      path: `/comments/count_by_fixed_assets`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Update comment for fixed asset
   *
   * @tags fixed_asset_comments
   * @name CommentsUpdate
   * @summary Update the comment for fixed asset
   * @request PUT:/comments/{id}
   */
  commentsUpdate = (id: number, body: PresenterUpdateFixedAssetCommentRequest, params: RequestParams = {}) =>
    this.http.request<PresenterOneFixedAssetCommentResponse, any>({
      path: `/comments/${id}`,
      method: 'PUT',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Delete fixed asset comment
   *
   * @tags fixed_asset_comments
   * @name CommentsDelete
   * @summary Delete the fixed asset comment
   * @request DELETE:/comments/{id}
   */
  commentsDelete = (id: string, params: RequestParams = {}) =>
    this.http.request<PresenterIDResponse, PresenterError>({
      path: `/comments/${id}`,
      method: 'DELETE',
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description Create comment with name
   *
   * @tags fixed_asset_comments
   * @name CommentsCreate
   * @summary Create the new comment
   * @request POST:/fixed_asset/{biid}/comments
   */
  commentsCreate = (biid: string, body: PresenterCreateFixedAssetCommentRequest, params: RequestParams = {}) =>
    this.http.request<PresenterOneFixedAssetCommentResponse, PresenterError>({
      path: `/fixed_asset/${biid}/comments`,
      method: 'POST',
      body: body,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * @description List fixed asset comment
   *
   * @tags fixed_asset_comments
   * @name CommentsDetail
   * @summary List the fixed asset comment
   * @request GET:/fixed_assets/{biid}/comments
   */
  commentsDetail = (
    biid: string,
    query?: {
      sort?: string[]
      'page[number]'?: number
      'page[size]'?: number
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PresenterOneFixedAssetCommentResponse[], PresenterError>({
      path: `/fixed_assets/${biid}/comments`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
