import { defaultTo, everyTrue } from 'aa_common/front-end/helpers'
import { MonthlyClosing } from 'common/models'
import find from 'lodash/find'
import last from 'lodash/last'
import moment, { Moment } from 'moment'

import {
  ConvertDepreciationResultTermMonth,
  ConvertedDepreciationResultItem,
  ConvertedDepreciationResultTermMonthItem,
  DepreciationOfYearResult,
  DepreciationResultItem,
  DepreciationResults,
} from './model'

const hasDifferentDepreciationAmount = (termYear: DepreciationResultItem) => {
  return (
    termYear.book_value_of_tax_annual_depr !== 0 &&
    termYear.end_of_term_book_value !== termYear.book_value_of_tax_annual_depr
  )
}

const getFinishDepreciationDate = (currentFinishDate: string, depreciationItem?: DepreciationResultItem) => {
  let finishDepreciationDate = currentFinishDate
  depreciationItem?.months.forEach((month, index) => {
    const nextMonth = depreciationItem.months[index + 1]
    if (nextMonth?.depreciation_amount === 0 && month.depreciation_amount > 0) {
      finishDepreciationDate = month.end_date
    }
  })

  return finishDepreciationDate
}

const getLastDepreciationDate = (depreciationResults: DepreciationResults) => {
  let finishDepreciationDate = ''

  if (depreciationResults.data?.length > 0) {
    finishDepreciationDate = getFinishDepreciationDate(finishDepreciationDate, last(depreciationResults.data))

    let lastTermYearIndex = depreciationResults.data.length - 1
    if (finishDepreciationDate === '' && depreciationResults.data.length > 1) {
      do {
        lastTermYearIndex -= 1
        finishDepreciationDate = getFinishDepreciationDate(
          finishDepreciationDate,
          depreciationResults.data[lastTermYearIndex]
        )
      } while (lastTermYearIndex !== 0)
    }
  }

  return finishDepreciationDate
}

const getDividedAt = (dividedAts: string[], startDate: Moment, endDate: Moment) => {
  const dividedAtIndex =
    dividedAts &&
    dividedAts.findIndex((dividedAt: string) => moment(dividedAt).isBetween(startDate, endDate, 'date', '[]'))
  return dividedAtIndex > -1
    ? {
        isDivided: dividedAtIndex > -1,
        dividedAt: dividedAts[dividedAtIndex],
      }
    : {}
}

export const convertDepreciationResults = ({
  depreciationResults,
  currentMonthlyClosing,
  retiredAt,
  soldAt,
  usageStartedAt,
  acquiredAt,
  impairAt,
  isShowComparison,
  isDeclineBalanceMethods,
  dividedAts,
}: {
  depreciationResults: DepreciationResults
  currentMonthlyClosing: MonthlyClosing
  retiredAt: string | null
  soldAt: string | null
  usageStartedAt: string | null
  acquiredAt: string
  impairAt: string | null
  isShowComparison: boolean
  isDeclineBalanceMethods: boolean
  dividedAts: string[] | []
}) => {
  let assetHasBeenRetiredOrSold = false
  let completeDepreciation = false

  const results: ConvertedDepreciationResultItem[] = []
  if (everyTrue(depreciationResults.data?.length > 0, depreciationResults.terms?.length > 0, !!currentMonthlyClosing)) {
    const finishDepreciationDate = getLastDepreciationDate(depreciationResults)

    depreciationResults.data.forEach(termYear => {
      const renderMonthsOfTerm = find(depreciationResults.terms, item => item.id === termYear.term_id)

      if (renderMonthsOfTerm) {
        const convertedRenderMonths = renderMonthsOfTerm.term_months.map(item => {
          const targetMonth = find(
            termYear.months,
            monthOfTargetTermYear => monthOfTargetTermYear.term_month_id === item.id
          )

          const converted = {
            ...item,
            hasBeenSoldOrRetired: assetHasBeenRetiredOrSold,
            hasCompletedDepreciation: completeDepreciation,
          }

          if (everyTrue(!!targetMonth?.is_retired_or_sold, !assetHasBeenRetiredOrSold)) {
            assetHasBeenRetiredOrSold = true
          }

          completeDepreciation =
            completeDepreciation || moment(item.end_date).isSame(moment(finishDepreciationDate), 'date')

          return converted
        }) as ConvertedDepreciationResultTermMonthItem[]

        const convertedDetailMonths = termYear.months.map(termMonth => {
          const targetTermMonth = find(convertedRenderMonths, item => item.id === termMonth.term_month_id)

          const startDate = moment(targetTermMonth?.start_date)
          const endDate = moment(targetTermMonth?.end_date)

          const { isActive, monthHasBeenAppliedDepreciation } = calculateMonthStatus(
            currentMonthlyClosing,
            usageStartedAt,
            acquiredAt,
            targetTermMonth
          )
          const dividedInfo = getDividedAt(dividedAts, startDate, endDate)

          return {
            ...termMonth,
            ...dividedInfo,
            id: `${termMonth.year}-${termMonth.term_month_id}`,
            retiredAt,
            soldAt,
            isActive,
            monthHasBeenAppliedDepreciation,
            hasBeenSoldOrRetired: targetTermMonth?.hasBeenSoldOrRetired,
            hasCompletedDepreciation: targetTermMonth?.hasCompletedDepreciation,
            impairAt,
          }
        }) as ConvertDepreciationResultTermMonth[]

        const months = everyTrue(isShowComparison, hasDifferentDepreciationAmount(termYear), isDeclineBalanceMethods)
          ? [
              ...convertedDetailMonths,
              {
                accumulated_depreciation_amount: termYear.accumulated_recognized_depreciation_amt,
                adjusted_accumulated_depreciation_amount: termYear.accumulated_recognized_depreciation_amt,
                end_of_term_book_value: termYear.book_value_of_tax_annual_depr,
              } as DepreciationOfYearResult,
            ]
          : convertedDetailMonths

        results.push({
          ...termYear,
          id: termYear.term_id,
          months: defaultTo(months, []),
          renderMonths: defaultTo(convertedRenderMonths, []),
        })
      }
    })
  }

  return results
}

export const calculateMonthStatus = (
  currentMonthlyClosing: MonthlyClosing,
  usageStartedAt: string | null,
  acquiredAt: string,
  targetTermMonth?: ConvertedDepreciationResultTermMonthItem
) => {
  const current = moment(currentMonthlyClosing.from_term_month.start_date)
  const startDate = moment(targetTermMonth?.start_date)
  const endDate = moment(targetTermMonth?.end_date)
  const usageStartedAtDate = moment(usageStartedAt)
  const acquiredAtDate = moment(acquiredAt)

  const assetHasBeenAcquired = endDate.isSameOrAfter(acquiredAtDate)
  const assetAcquiredButNotUsed = assetHasBeenAcquired && (!usageStartedAt || usageStartedAtDate.isAfter(endDate))

  const isNotDepreciationYet = startDate.isBefore(current, 'date') && (!assetHasBeenAcquired || assetAcquiredButNotUsed)
  const hasStartDepreciation = !isNotDepreciationYet
  const isActive =
    current.isBetween(startDate, endDate, 'date', '[]') &&
    !targetTermMonth?.hasBeenSoldOrRetired &&
    !targetTermMonth?.hasCompletedDepreciation
  const monthHasBeenAppliedDepreciation =
    hasStartDepreciation &&
    current.isAfter(startDate, 'date') &&
    current.isAfter(endDate, 'date') &&
    !targetTermMonth?.hasBeenSoldOrRetired &&
    !targetTermMonth?.hasCompletedDepreciation

  return {
    isActive,
    isNotDepreciationYet,
    hasStartDepreciation,
    monthHasBeenAppliedDepreciation,
  }
}
