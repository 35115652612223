import { ifElse } from 'aa_common/front-end/helpers'
import { DEPRECIATION_METHOD_CODE } from 'common/constants'
import { getIn, useFormikContext } from 'formik'
import moment from 'moment/moment'
import { useEffect, useRef } from 'react'

import { getAllLedgerFieldNames } from '../helper'

export const useCalculateDepreciationMonth = (shouldRender: boolean, ledgerIndex: number) => {
  const { values, setFieldValue } = useFormikContext()

  const { depreciationMethodCodeField, transitionAtField, depreciatedMonthsField } = getAllLedgerFieldNames(ledgerIndex)
  const depreciationCode = getIn(values, depreciationMethodCodeField)
  const transitionAt = getIn(values, transitionAtField)
  const usageStartedAt = getIn(values, 'usage_started_at')
  const firstTimeRef = useRef(true)

  useEffect(() => {
    if (firstTimeRef.current) {
      firstTimeRef.current = false
      return
    }

    if (shouldRender) {
      const transitionAtMoment = moment(transitionAt)
      const usageStartedAtMoment = moment(usageStartedAt)
      const isD10Method = depreciationCode === DEPRECIATION_METHOD_CODE.OLD_STRAIGHT_LINE
      const numberMonths = moment([transitionAtMoment.year(), transitionAtMoment.month()]).diff(
        moment([usageStartedAtMoment.year(), usageStartedAtMoment.month()]),
        'months'
      )
      const depreciationMonthsValue = ifElse(isD10Method, 0, numberMonths)
      setFieldValue(depreciatedMonthsField, depreciationMonthsValue)
    } else {
      setFieldValue(depreciatedMonthsField, undefined)
    }
  }, [shouldRender, transitionAt, usageStartedAt, depreciationCode])
}
