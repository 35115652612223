import { PresenterDepreciationMonthlyResponse } from 'common/open-api'

export type AcceleratedDepreciationItem = PresenterDepreciationMonthlyResponse & {
  key?: string
  isParent?: boolean
  isExtraMonth?: boolean
}

export type TermDepreciation = {
  period?: number
  fiscal_year?: number
}

export const ERR_CANCEL_ACCELERATED_DEPRECIATION_DUE_TO_EVENT = 'ERR_FIXED_ASSET_EVENT_AFTER_ADJUSTED_DEPRECIATION'
export const ERR_CANCEL_ACCELERATED_DEPRECIATION_DUE_TO_MONTHLY_CLOSING = 'ERR_MONTHLY_CLOSING_IN_ADJUSTED_DEPRECIATION'
export type Cancel_Accelerated_Depreciation_Error = {
  isEventError?: boolean
  isMonthlyClosingError?: boolean
  isKnownError?: boolean
}
