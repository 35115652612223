import { FilterFunction, initAsyncSlice } from 'aa_common/front-end/zustand'
import {
  DepreciationResultAdjustHistoryService,
  PresenterDepreciationResultsAdjustHistoryResponse,
} from 'common/open-api'

const initFilter: {
  biid?: string
} = {}

const initState: PresenterDepreciationResultsAdjustHistoryResponse = {}

const fetchData: FilterFunction<typeof initState, typeof initFilter> = async filters => {
  return DepreciationResultAdjustHistoryService.adjustHistoryBiidList(String(filters?.biid))
}

export const adjustHistoryStore = initAsyncSlice<typeof initState, typeof initFilter>(
  'asset-detail/accelerated-depreciation/adjust-history',
  initState,
  initFilter,
  fetchData
)

export const getAdjustHistory = (biid: string) => adjustHistoryStore.setFilters({ biid }, true)
export const resetAdjustHistory = () => adjustHistoryStore.setData(initState)
