import { ASSET_CODE_SETTING_RULE_SYMBOL_CODE, ASSET_CODE_SETTING_RULE_TYPE_CODE } from 'common/constants'
import { getPastFutureRecordCount } from 'common/helpers/monthly-closing-helper'
import { loadNs } from 'common/i18n-config'
import {
  AssetCodeSettingRule,
  AssetCodeSettings,
  AutoGenerateSetting,
  MonthlyClosing,
  SettingRule,
} from 'common/models'
import { get } from 'lodash'

const t = loadNs(['components/organisms/settings/asset-code-auto-generation-view'])

export const getSettingMethodText = (assetCodeSetting: AssetCodeSettings, value: number) => {
  if (!assetCodeSetting) return ''

  const settingMethods = assetCodeSetting?.auto_generating_serial_number_setting_method?.values || []

  const selectedValue = settingMethods.find(settingMethod => settingMethod.code === value)
  return get(selectedValue, 'name_jp', '')
}

export const getSettingScopeText = (assetCodeSetting: AssetCodeSettings, value: number) => {
  if (!assetCodeSetting) return ''

  const settingMethods = assetCodeSetting?.auto_generating_serial_number_setting_scope?.values || []

  const selectedValue = settingMethods.find(settingMethod => settingMethod.code === value)
  return get(selectedValue, 'name_jp', '')
}

export const getSettingLengthText = (assetCodeSetting: AssetCodeSettings, value: number) => {
  if (!assetCodeSetting) return ''

  const settingMethods = assetCodeSetting?.auto_generating_serial_number_setting_length?.values || []

  const selectedValue = settingMethods.find(settingMethod => settingMethod.code === value)
  return get(selectedValue, 'name_jp', '')
}

export const displayPeriod = (setting: AutoGenerateSetting) => {
  if (setting.to_term) {
    if (setting.from_term?.year === setting.to_term.year) return t('year_format', { year: setting.from_term?.year })
    return t('from_to_year_format', {
      from_year: setting.from_term?.year,
      to_year: setting.to_term.year,
    })
  }
  return t('from_year_format', { from_year: setting.from_term?.year })
}

export const renderOtherSettingsCount = (settings: AutoGenerateSetting[], monthlyClosing: MonthlyClosing) => {
  const count = getPastFutureRecordCount(settings, monthlyClosing)
  return count && t('display_all_year_with_count', count)
}

export const displayAssetCodeFormatByRules = (
  assetCodeSetting: AssetCodeSettings,
  rules: SettingRule[],
  length_rule: number
) => {
  let result = ''

  if (!assetCodeSetting || !rules) return ''

  const masterDataRules = assetCodeSetting.auto_generating_serial_number_setting_rules

  rules.forEach(rule => {
    const selectedMasterDataRule = (masterDataRules || []).find(masterDataRule => masterDataRule.code === rule?.field)

    if (!selectedMasterDataRule) return

    if (rule.field === ASSET_CODE_SETTING_RULE_TYPE_CODE.SYMBOL) {
      switch (rule.value) {
        case ASSET_CODE_SETTING_RULE_SYMBOL_CODE.HYPHEN:
          result += '-'
          break
        case ASSET_CODE_SETTING_RULE_SYMBOL_CODE.UNDERSCORE:
          result += '_'
          break
        case ASSET_CODE_SETTING_RULE_SYMBOL_CODE.PERIOD:
          result += '.'
          break
        default:
          break
      }
    } else {
      result += selectedMasterDataRule.name_jp
    }
  })

  if (length_rule) result += t('serial_number')

  return result
}

const setSelectedRule = (
  currentResult: string,
  PLUS: string,
  rule: SettingRule,
  selectedMasterDataRule: AssetCodeSettingRule
) => {
  let result = currentResult

  if (selectedMasterDataRule.code === ASSET_CODE_SETTING_RULE_TYPE_CODE.FIXED_VALUE) {
    if (result !== '') result += PLUS

    result += `${selectedMasterDataRule.name_jp}（${rule.value}）`
  } else {
    const selectedValue = selectedMasterDataRule.values.find(valueItem => valueItem.code === rule.value)

    if (selectedValue) {
      if (result !== '') result += PLUS

      result += `${selectedMasterDataRule.name_jp}（${selectedValue.name_jp}）`
    }
  }

  return result
}

export const displayFulltextAssetCodeFormatByRules = (
  assetCodeSetting: AssetCodeSettings,
  rules: SettingRule[],
  length_rule: number
) => {
  let result = ''
  const PLUS = ' + '

  if (!assetCodeSetting || !rules) return ''

  const masterDataRules = assetCodeSetting.auto_generating_serial_number_setting_rules

  rules.forEach(rule => {
    const selectedMasterDataRule = masterDataRules.find(masterDataRule => masterDataRule.code === rule.field)
    if (!selectedMasterDataRule) return
    result = setSelectedRule(result, PLUS, rule, selectedMasterDataRule)
  })

  if (length_rule) result += PLUS + t('serial_number')

  return result
}
