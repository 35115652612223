import { If } from 'aa_common/front-end'
import { getNs } from 'common/i18n-config'
import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import { AutoCompleteSelect } from 'components/molecules'
import React from 'react'

import { SUMMARY_METHODS } from '../const'

const { t, useNsReady } = getNs(['components/organisms/depreciation/depreciation-result-list'])
export const SelectSummaryMethod = () => {
  useNsReady()
  const { isEnable: isDepartmentFlagOn } = useCheckFlag(FeatureFlagNames.DepartmentDepreciationAmountReport)

  const selectOptions = [
    { value: SUMMARY_METHODS.CATEGORY, text: t('summary_methods.category') },
    { value: SUMMARY_METHODS.DEPARTMENT, text: t('summary_methods.department') },
  ]
  return (
    <If condition={isDepartmentFlagOn}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <label>{t('summary_methods_title')}</label>
        <AutoCompleteSelect
          name="aggregation_method"
          items={selectOptions}
          valueField="value"
          textField="text"
          style={{ width: 150, marginLeft: 10 }}
          allowClear={false}
        />
      </div>
    </If>
  )
}
