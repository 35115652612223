import { checkIsAfterDate } from 'aa_common/front-end/helpers'
import { FeatureFlagNames } from 'common/open-features'
import { useCheckFlag } from 'common/open-features/hooks'
import { getIn, useFormikContext } from 'formik'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setShowMemorandumValueErrorMessage } from 'store/asset/actions'

import { DEPRECIATION_FIELDS } from '../const'
import { genLedgerFieldName } from '../helper'

export const useShowMemorandumValueError = (ledgerIndex: number, bookValueRefer?: number) => {
  const dispatch = useDispatch()
  const { values } = useFormikContext()

  const ledgerIdField = genLedgerFieldName(DEPRECIATION_FIELDS.LEDGER_SETTING_ID, ledgerIndex)
  const transitionAtField = genLedgerFieldName(DEPRECIATION_FIELDS.TRANSITION_AT, ledgerIndex)
  const memorandumValueField = genLedgerFieldName(DEPRECIATION_FIELDS.MEMORANDUM_VALUE, ledgerIndex)
  const transitionBookValueField = genLedgerFieldName(DEPRECIATION_FIELDS.TRANSITION_BOOK_VALUE, ledgerIndex)

  const hasTransition = getIn(values, 'has_transition')
  const transitionAt = getIn(values, transitionAtField)
  const usageStartedAt = getIn(values, 'usage_started_at')
  const memorandumValue = getIn(values, memorandumValueField)
  const transitionBookValueFieldValue = getIn(values, transitionBookValueField)
  const acquisitionCostInput = getIn(values, 'acquisition_cost_input')

  const { isEnable: isEnableForFixedAssetReduction } = useCheckFlag(FeatureFlagNames.FixedAssetReduction)

  const ledgerId = getIn(values, ledgerIdField)

  useEffect(() => {
    dispatch(
      setShowMemorandumValueErrorMessage({
        ledgerId,
        isShowErrorMessage:
          (hasTransition &&
            transitionAt &&
            usageStartedAt &&
            checkIsAfterDate(transitionAt, usageStartedAt) &&
            memorandumValue > transitionBookValueFieldValue) ||
          memorandumValue > acquisitionCostInput ||
          (isEnableForFixedAssetReduction && bookValueRefer !== undefined && memorandumValue > bookValueRefer),
      })
    )
  }, [
    ledgerId,
    memorandumValue,
    bookValueRefer,
    transitionBookValueFieldValue,
    hasTransition,
    transitionAt,
    usageStartedAt,
    acquisitionCostInput,
    isEnableForFixedAssetReduction,
  ])
}
