import { useMemo } from 'react'

export const useFormatInitialAsset = (asset: any) => {
  return useMemo(() => {
    if (!asset) return asset

    const formattedAsset = { ...asset }
    delete formattedAsset?.net_acquisition_cost
    return formattedAsset
  }, [asset])
}
