import { getIn, useFormikContext } from 'formik'
import { useEffect, useMemo } from 'react'

import { DEPRECIATION_FIELDS } from '../const'
import { genLedgerFieldName, getLedgerFieldName } from '../helper'

const getValueOfFirstItem = (name: string, formValues: any) => {
  return getIn(formValues, genLedgerFieldName(name, 0))
}

export function useLedgerCopy(index: number, isCopied: boolean) {
  const { values, setFieldValue } = useFormikContext()

  const copyFields = useMemo(() => {
    return [
      DEPRECIATION_FIELDS.DEPRECIATION_METHOD_CODE,
      DEPRECIATION_FIELDS.SERVICE_LIFE,
      DEPRECIATION_FIELDS.USAGE_PERIOD_MONTHS,
      DEPRECIATION_FIELDS.RESIDUAL_AMOUNT,
      DEPRECIATION_FIELDS.DEPRECIATION_RATE,
      DEPRECIATION_FIELDS.GUARANTEE_RATE,
      DEPRECIATION_FIELDS.REVISED_DEPRECIATION_RATE,
      DEPRECIATION_FIELDS.ACCOUNTING_BEGINNING_BOOK_INITIAL_VALUE,
      DEPRECIATION_FIELDS.TRANSITION_BEGINNING_BOOK_VALUE,
      DEPRECIATION_FIELDS.TRANSITION_BOOK_VALUE,
      DEPRECIATION_FIELDS.REVISED_ACQUISITION_AMOUNT,
      DEPRECIATION_FIELDS.DEPRECIATED_MONTHS,
      DEPRECIATION_FIELDS.TRANSITION_AT,
      DEPRECIATION_FIELDS.SERVICE_LIFE_AFTER_CHANGE,
      DEPRECIATION_FIELDS.BOOK_VALUE_AT_CHANGE,
      DEPRECIATION_FIELDS.ACCUMULATED_DEPRECIATION_AMOUNT,
      DEPRECIATION_FIELDS.ACCUMULATED_IMPAIRED_AMOUNT,
      DEPRECIATION_FIELDS.MEMORANDUM_VALUE,
    ]
  }, [])

  const firstItemValues = useMemo(
    () => copyFields.map(name => String(getValueOfFirstItem(name, values))).join('-'),
    [copyFields, values]
  )
  useEffect(() => {
    if (isCopied) {
      const ledgerField = getLedgerFieldName(index)
      const updatedLedger = getIn(values, ledgerField)
      let hasDifferentValue = false
      copyFields.forEach(name => {
        const valueFirstItem = getValueOfFirstItem(name, values)
        if (updatedLedger[name] !== valueFirstItem) {
          updatedLedger[name] = valueFirstItem
          hasDifferentValue = true
        }
      })
      hasDifferentValue && setFieldValue(ledgerField, updatedLedger)
    }
  }, [firstItemValues, setFieldValue, index, copyFields, isCopied])
}
