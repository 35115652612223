import { TermMonth } from './term'

export enum DataTypes {
  ASSET_TYPE = 1,
  ASSET_CATEGORY = 2,
  ASSET = 3,
  DEPARTMENT = 4,
}

export enum MoneyUnits {
  YEN = 1,
  THOUSAND_YEN = 2,
}

export enum Aggregation {
  DEPRECIATION_CALCULATION = 0,
  JOURNAL_RESULT = 1,
}

export type AmountMonth = {
  term_month_id: number
  amount: number
}

export type AssetTypeObject = {
  type: string
  total_amount: number
  amount_months: AmountMonth[]
  items: AssetCategoryObject[]
  items_department: DepartmentObject[]
}

export type AssetCategoryObject = {
  asset_category_code: string
  asset_category_name: string
  total_amount: number
  amount_months: AmountMonth[]
  items: AssetObject[]
}

export type AssetObject = {
  biid: string
  code: string
  branch_code: string
  name: string
  usage_started_at: string | null
  service_life: number
  total_amount: number
  depreciation_method: any
  amount_months: AmountMonth[]
}

export type DepartmentObject = {
  department_code: string
  department_name: string
  total_amount: number
  amount_months: AmountMonth[]
  items: AssetCategoryObject[]
}

export type DepreciationData = {
  items: AssetTypeObject[]
  year: number
  term_months: TermMonth[]
}
