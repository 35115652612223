import { useFormikContext } from 'formik'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getExciseSettingsByDateList } from 'store/settings/excise/selectors'

import { getExciseInputSetting } from '../helper'

export const useExciseSettingUpdated = () => {
  const { values, setFieldValue } = useFormikContext()
  const acquisitionCostInput = get(values, 'acquisition_cost_input')

  const { data: exciseSettings } = useSelector(getExciseSettingsByDateList, isEqual)
  const { isInputTaxWhenRegister, isTaxMethodExcluded, isExciseSettingAvailable } =
    getExciseInputSetting(exciseSettings)

  useEffect(() => {
    if (!isExciseSettingAvailable || acquisitionCostInput === undefined) {
      return
    }
    if (!isInputTaxWhenRegister) {
      setFieldValue('excise_id', undefined)
      setFieldValue('calculated_excise_value', undefined)
      setFieldValue('manual_excise_value', undefined)
      setFieldValue('acquisition_cost_excluded_excise', acquisitionCostInput)
    }
    if (!isTaxMethodExcluded) {
      setFieldValue('acquisition_cost_excluded_excise', undefined)
    }
    if (!isInputTaxWhenRegister || !isTaxMethodExcluded) {
      setFieldValue('acquisition_cost', acquisitionCostInput)
    }
  }, [isExciseSettingAvailable, isInputTaxWhenRegister, isTaxMethodExcluded])
}
