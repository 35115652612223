export function tryCallApi<R, T extends Array<any>>(func: (...args: T) => Promise<R>) {
  return async (...params: T) => {
    const result: { error?: any; data?: R } = {}
    try {
      result.data = await func(...params)
    } catch (error) {
      result.error = error
    }
    return result
  }
}
