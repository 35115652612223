import { UnborderCollapse } from 'aa_common/front-end/components'
import { loadNs } from 'common/i18n-config'
import { LinkListField, UploadFileListField, UploadImageListField } from 'components/molecules'
import React from 'react'

import { FormGroup } from '../styles'

const { Item, LeftGroup, RightGroup } = UnborderCollapse

const t = loadNs(['components/organisms/assets/asset-form', 'common'])

export const UploadArea = () => {
  return (
    <FormGroup style={{ marginTop: 15 }}>
      <LeftGroup>
        <Item label={t('reference_link')}>
          <LinkListField name="urls" />
        </Item>
        <Item label={t('attachment')}>
          <UploadFileListField name="attachments" />
        </Item>
      </LeftGroup>
      <RightGroup>
        <Item label={t('photo')}>
          <UploadImageListField name="photos" />
        </Item>
      </RightGroup>
    </FormGroup>
  )
}
